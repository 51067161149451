<template>
  <div class="edit-user">
    <div class="back" @click="$emit('CancelEditUser')">
      <img src="@/assets/back.png" alt="back to users"/>
      <p>Back to Users</p>
    </div>
    <div class="profil">

      <div class="edit-icon">
        <img v-if="user.icon !== undefined && user.icon !== null"
             :src="user.icon.url"
              alt="icon of the user"/>
        <img v-else
             src="@/assets/no-icon.png"
              alt="user doesn't have icon"/>
        <b-form-file ref="el" :accept="accept" type="file" :id="imageId" placeholder="Profile photo - Optional"
                     v-model="image" :required="true" :disabled="false" class="select-img"/>
      </div>
      <div class="edit-profil">
        <div class="input-field username">
          <div :class="[{'active': name.length > 0}, 'required']">*</div>
          <label class="label">username</label>
          <input v-model="name" placeholder="User name">
        </div>
        <div class="names">
          <div class="input-field">
            <div :class="[{'active': firstname.length > 0}, 'required']">*</div>
            <label class="label">first name</label>
            <input v-model="firstname" placeholder="First name">
          </div>
          <div class="input-field">
            <div :class="[{'active': lastname.length > 0}, 'required']">*</div>
            <label class="label">last name</label>
            <input v-model="lastname" placeholder="Last name">
          </div>
        </div>
        <div class="email input-field">
          <label class="label">email</label>
          <input v-model="email" placeholder="Email - Optional">
        </div>
      </div>
    </div>
    <div class="password">
      <label class="label">Change password</label>
      <input  type="password" v-model="newPassword" placeholder="New password" />
      <input  type="password" ref="confirmPassword" v-model="confirmPassword" placeholder="Confirm new password"/>
    </div>
  </div>
</template>

<script>
import TypeAhead from "@/components/form/TypeAhead.vue";
import {sanitize} from "@/common";

export default {
  name: "editUserForm",
  components: {TypeAhead},
  props: {
    user: Object,
    accept: {
      default: "image/*",
      type: String,
    },
    resetEditUser: Boolean,
    validatEditUser: Boolean,
  },
  data() {
    return {
      image: null,
      imageId: "Icone",
      name: "",
      firstname: "",
      lastname: "",
      email: null,
      newPassword: null,
      confirmPassword: null
    };
  },
  watch: {
    resetEditUser() {
      if(this.resetEditUser) this.resetForm()
    },
    validatEditUser() {
      if(this.validatEditUser) this.validateEdit()
    }
  },
  mounted(){
    this.init();
  },
  methods: {
    init(){
      this.firstname = this.user.firstname;
      this.lastname = this.user.lastname;
      this.email = this.user.email;
      this.name = this.user.name;
    },
    resetForm(){
      this.firstname = this.user.firstname;
      this.lastname = this.user.lastname;
      this.email = this.user.email;
      this.name = this.user.name;
      this.newPassword = null;
      this.confirmPassword = null;
      this.$emit('resetForm')
      this.image = null;
    },
    async validateEdit() {
      this.$emit("loading");
      let data = {
        "name": this.name,
        "firstname": this.firstname,
        "lastname": this.lastname,
        "email": this.email
      }
      if(this.image !== null) {
        data['icon'] = this.image
      }
      if(this.confirmPassword !== null){
        if(this.newPassword !== this.confirmPassword) {
          this.$eventBus.$emit('error', 'Passwords mismatch');
          this.$refs.confirmPassword.focus();
          return;
        }
        data = {
          'password': this.confirmPassword
        }
      }
      await this.$store.dispatch('users/update', {identifier: this.user.identifier, data: sanitize(data)}).then(() => {
        this.$eventBus.$emit('success', 'The user has been updated')
        this.$emit('CancelEditUser')
        this.$emit("validateForm")
      }).catch((response) => {
        this.$eventBus.$emit('http-error', response)
        this.$emit("validateForm")
      })
    },
  }
}
</script>

<style scoped>
.edit-user{
  width: 100%;
  position: relative;
}

.back {
  margin-bottom: 10px;
  cursor: pointer;
  border-radius: 5px 5px;
  padding: 5px 5px;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  width: 35px;
  overflow-x: hidden;
  white-space: nowrap;
  transition: 0.5s;
  top: 10px;
}

.back:hover {
  background-color: var(--lade-color-white);
  width: 120px;
}

.back img {
  width: 25px;
  margin-right: 5px;
}

.back p {
  margin: 0 0 4px 0;
  height: 18px;
  transition: 0.1s;
}


.profil {
  display: flex;
}

.profil,
.password{
  width: 100%;
  padding: 20px 10px 15px 10px;
  border: solid 1px var(--lade-color-white);
  border-radius: 10px 10px;
  position: relative;
  margin-bottom: 20px;
}

.edit-icon{
  width: 20%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.edit-icon img{
  margin-bottom: 20px;
  width: 70%;
}

.edit-profil{
  margin-left: 60px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 5px;
}

.edit-profil .username {
  margin-bottom: 20px;
}

.edit-profil .names {
  display: flex;
  align-items: center;
}

.edit-profil .names .input-field {
  margin-right: 25px;
}

.edit-profil .input-field {
  margin-right: 25px;
  position: relative;
}

.required {
  color: var(--lade-color-red);
  position: absolute;
  font-size: 1.5em;
  font-weight: bold;
  left: -8px;
  top: 5px;
}

.required.active{
  color: var(--lade-color-green);
}

.edit-profil p,
.edit-profil label{
  margin: 0 0;
}

.input-field p {
  font-size: 1.5em;
}

.input-field label {
  position: absolute;
  top: -10px;
  font-size: 0.9em;
  text-transform: uppercase;
  white-space: nowrap;
  color: var(--lade-color-grey);
}

.input-field input {
  margin-top: 8px;
}

.email {
  margin-top: 20px;
  margin-left: 0;
}

.email p{
  font-size: 1.2em;
  color: var(--lade-color-dark);
}

.email label{
  top: -12px;
}

.select-img {
  cursor: pointer;
}

.password {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 40px;
}

.password label {
  top: 10px;
}

.password input:nth-child(2){
  margin-bottom: 40px;
}

.password input:nth-child(2),
.password input:nth-child(3){
  width: 40%;
}

.password input:nth-child(3){
  margin-bottom: 20px;
}

.password .border-submit input {
  border: none;
  background-color: var(--lade-color-blue);
  color: var(--lade-color-white-hover);
  border-radius: 5px 5px;
  width: 100%;
}

.password .border-submit img{
  width: 15px;
  position: absolute;
  right: 45px;
  top: 5px;
  opacity: 0;
}

.password .border-submit:hover img{
  opacity: 1;
  animation: go-right 0.4s;
}

@keyframes go-right {
  0%{
    right: 55px;
    transform: rotateZ(-90deg);
  }
  100%{
    right: 45px;
    transform: rotateZ(0deg);
  }
}

</style>
