import Vue from 'vue'
import Config from '../../Config'
import {setDictValue} from '@/common'
import {field_created_at, field_actions} from './fields'

const state = {
  list: [],
  filteredList: [],
  _lookup: {},
  bundleList: {},
  pagination: {
    page: 1,
    total_pages: 0,
    total: 0,
  },
  fields: [
    {
      "key": "alias",
      "sortable": true
    },
    {
      "key": "name",
      "sortable": true
    },
    {
      "key": "category_identifier",
      "label": "Parent",
      "sortable": true
    },
    field_created_at,
    field_actions
  ],
  filters: {
    isEditable: (entry) => true,
    isExportable: (entry) => false,
    isDeletable: (entry) => true,
    isDownloadable: (entry) => false
  }
}

const getters = {
  get: (state) => (identifier) =>
    state._lookup[identifier],
  getBundle: (state) => (bundleIdentifier) =>
    state.bundleList[bundleIdentifier],
  getAll: (state) => () =>
    state.list,
  getFilters: (state) => () =>
    state.filters,
  getFields: (state) => () =>
    state.fields
}

const actions = {
  loadAll({dispatch, commit}, {filters = {}, ...resource_list_params}) {
    let params
    if (resource_list_params.search)
      params = {q: resource_list_params.search}
    else
      params = {}

    if (resource_list_params.expand)
      params["expand"] = true

    let url
    if (filters.bundleIdentifier !== undefined)
      url = Config.API_BUNDLES_URL + "/" + filters.bundleIdentifier + "/categories"
    else
      url = Config.API_CATEGORIES_URL
    return Vue.http.get(url, {params}).then((response) => {
      let categories = response.data
      commit('setAll', categories)
    })
  },
  loadBundle({dispatch, commit}, bundleIdentifier) {
    return Vue.http.get(Config.API_BUNDLES_URL + "/" + bundleIdentifier + "/categories").then((response) => {
      let categories = response.data
      commit('setBundle', {categories, identifier: bundleIdentifier})
    })
  },
  load({dispatch, commit}, identifier) {
    return Vue.http.get(Config.API_CATEGORIES_URL + '/' + identifier).then((response) => {
      let category = response.data
      commit('set', {identifier, category})
    })
  },
  add({dispatch, commit}, {bundleIdentifier, data}) {
    let formData = new FormData()
    if (data['icon']) {
      formData.append('icon', data['icon'])
    }
    formData.append('data', JSON.stringify(data))
    return Vue.http.post(Config.API_BUNDLES_URL + "/" + bundleIdentifier + "/categories", formData).then((response) => {
      let category = response.data
      return dispatch('load', category.identifier)
    })
  },
  update({dispatch, commit}, {identifier, data}) {
    let category = state._lookup[identifier]

    let formData = new FormData()
    if (data['icon']) {
      formData.append('icon', data['icon'])
    }
    formData.append('data', JSON.stringify(data))

    return Vue.http.put(Config.API_BUNDLES_URL + "/" + category.bundle_identifier + "/categories/" + identifier, formData).then((response) => {
      return dispatch('load', identifier)
    })
  },
  delete({dispatch, commit}, identifier) {
    let category = state._lookup[identifier]
    return Vue.http.delete(Config.API_BUNDLES_URL + "/" + category.bundle_identifier + "/categories/" + identifier).then((response) => {
      return commit('delete', identifier)
    })
  },
  search({dispatch, commit}, {search, filters = {}}) {
    return dispatch('search/category', {search, filters}, {root: true}).then((resources) => {
      commit('setAll', resources)
      commit('sort')
    })
  },
}

const mutations = {
  delete(state, identifier) {
    let category = state._lookup[identifier]
    let bundleList = state.bundleList[category.bundle_identifier]
    if (bundleList !== undefined)
      Vue.delete(bundleList, bundleList.indexOf(bundleList.find(item => item.identifier === identifier)))
    Vue.delete(state.list, state.list.indexOf(state.list.find(item => item.identifier === identifier)))
  },
  setAll(state, categories) {
    state._lookup = {}
    state.pagination.page = actions.page
    state.pagination.total_pages = actions.total_pages
    state.pagination.total = actions.total
    state.list.splice(0, state.list.length)

    for (let category of categories.data) {
      state.list.push(category)
    }
    for (let category of categories.data) {
      state._lookup[category.identifier] = category
    }
  },
  setBundle(state, {categories, identifier}) {
    if (state.bundleList[identifier] !== undefined)
      state.bundleList[identifier].splice(0, state.bundleList[identifier].length)
    else
      state.bundleList[identifier] = []

    for (let category of categories)
      state.bundleList[identifier].push(category)

    for (let category of categories)
      state._lookup[category.identifier] = category
  },
  set(state, {identifier, category}) {
    let categories = state.list
    let notfound = true
    for (let i = 0; i < categories.length; i++) {
      let c_action = categories[i];
      if (c_action.identifier === identifier) {
        category._showDetails = state.list[i]._showDetails
        Vue.set(state.list, i, category)
        notfound = false
        break
      }
    }
    if (notfound) state.list.push(category)
    setDictValue(state._lookup, category.identifier, category)
    let list = state.bundleList[category.bundle_identifier]
    if (list !== undefined && !list.find(category => category['identifier'] === identifier))
      list.push(category)
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true
}
