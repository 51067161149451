<script>
import {Pie, mixins} from 'vue-chartjs'

export default {
  name: "CapacityChart",
  extends: Pie,
  props: ['options'],
  mixins: [mixins.reactiveProp],
  mounted() {
    this.renderChart(this.chartData, this.options)
  }
}
</script>
