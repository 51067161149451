<template>
  <div>

    <UserEditForm ref="editUserFormModal" v-on:user-added="get_users" v-on:user-updated="userUpdated"></UserEditForm>

    <b-modal size="md" ref="editRolesFormModal" hide-footer>
      <template v-slot:modal-title>
        Edit users roles
      </template>

      <b-spinner v-if="roles === []" variant="primary"
                 small
                 style="margin-left: -1.25rem; margin-right: 0.25rem;"/>
      <form v-else>
        <div v-for="role in roles" :key="role.identifier" class="form-check">
          <input v-if="role.default" class="form-check-input"
                 :ref="role.identifier"
                 type="checkbox"
                 :id="role.identifier + '-role-checkbox'"
                 :checked="role.default"
                 :disabled="role.default">
          <input v-else class="form-check-input"
                 :ref="role.identifier"
                 type="checkbox"
                 :value="role"
                 :id="role.identifier + '-role-checkbox'"
                 :checked="role.default"
                 :disabled="role.default"
                 v-model="selectedRoles">

          <label class="form-check-label"
                 style="margin-left:7px; vertical-align:middle"
                 :for="role.identifier + '-role-checkbox'">

            <b-badge variant="light"
                     v-b-tooltip.hover
                     :title="role.description">
              <font-awesome-icon :icon="['fa', 'lock']"
                                 v-if="role.protected"
                                 style="margin-right: 2px; color:grey;"/>
              {{ role.name }}
            </b-badge>
          </label>
        </div>
        <div style="margin-top: 10px; padding-right:30px; padding-bottom:10px;">
          <b-button class="limit-button"
                    @click="addUsersRoles"
                    title="Add checked roles to users">
            <font-awesome-icon :icon="['fas', 'plus']" style="color: dodgerblue;" />
            <span style="margin-left: 5px; color: dodgerblue;">Add roles</span>
          </b-button>
          <b-button class="limit-button"
                    @click="deleteUsersRoles"
                    title="Delete checked roles from users">
            <font-awesome-icon
              :icon="['fas', 'minus']"
              style="color: indianred;"/>
            <span style="margin-left: 5px; color: indianred;">Delete roles</span>
          </b-button>
        </div>

      </form>

    </b-modal>

    <div class="users-bar">
      <b-tabs card v-if="addUser" v-model="userAddTabIndex">
        <b-tab title="Create user" ref="createUserTab" active>
          <form id="userForm" ref="userForm">
            <div class="input-group">
              <span class="input-group-text input-group-prepend">
                  <font-awesome-icon :icon="['fas', 'user']"/> <span style="margin-left:4px;"> Name </span>
              </span>
              <input type="text" v-model="name" class="form-control" autocomplete="off"
                     placeholder="Name" pattern="[0-9a-zA-ZÀ-ÿ-._ ]+" minlength="3" maxlength="20"
                     title="Must be alphanumeric (dashes and underscores are authorized)"
                     form="userForm" required>
            </div>
            <div class="input-group">
              <span class="input-group-text input-group-prepend">
                  <font-awesome-icon :icon="['fas', 'at']"/> <span style="margin-left:4px;"> Email </span>
              </span>
              <input type="email" v-model="email" class="form-control"
                     autocomplete="off" placeholder="Email - Optional" form="userForm">

            </div>
            <div class="input-group">
                          <span class="input-group-text input-group-prepend">
                              <font-awesome-icon :icon="['fas', 'key']"/>  <span
                            style="margin-left:4px;"> Password </span>
                          </span>
              <input type="password" ref="passwordField" v-model="password"
                     class="form-control" autocomplete="off" id="UserPassword" placeholder="Password"
                     minlength="6" maxlength="50" form="userForm" required>
            </div>

            <div class="input-group">
                          <span class="input-group-text input-group-prepend input-group-append">
                              <font-awesome-icon :icon="['fas', 'key']"/> <span
                            style="margin-left:4px;"> Confirmation</span>
                          </span>
              <input type="password" v-model="confirmPassword" class="form-control"
                     autocomplete="off" id="UserConfirmPassword" placeholder="Password confirmation" minlength="6"
                     maxlength="50"
                     form="userForm" required ref="passwordConfirm">
            </div>
          </form>
        </b-tab>
        <b-tab title="Add existing user" ref="addExistingTab">
          <form ref="existingUserForm" id="existingUserForm">
            <TypeAhead prefix="existingUserForm" resourceType="user"
                       v-model="existingUser" class="mb-2" id="existingUser"
                       ref="existingUser" required
                       @hit="existingUserIdentifier = $event"/>
          </form>
        </b-tab>

        <div style="margin-left: 10px; padding-right:30px; padding-bottom:10px;">
          <b-button class="limit-button"
                    @click="addUserEvent">
            <font-awesome-icon
              :icon="['fas', 'check']"
              style="color: dodgerblue;"/>
          </b-button>
          <b-button class="limit-button"
                    @click="addUser=false">
            <font-awesome-icon
              :icon="['fas', 'times']"
              style="color: indianred;"/>
          </b-button>
        </div>
      </b-tabs>
    </div>

    <div class="actions-bar">
      <!-- add/refresh/selected options delete/set_roles -->
      <div class="users-actions">
        <input type="checkbox" id="select-all-users" name="select" v-model="selectedUsers.length === users.length"
               @click="selectAll">
        <div v-if="selectedUsers.length > 0" class="resource-selected-actions">
          <b-button v-if="groupIdentifier" class="limit-button"
                    @click="showEditRolesModal()">
            <font-awesome-icon
              :icon="['fas', 'user-shield']"
              title="Set roles selected users"/>
          </b-button>
          <b-button class="limit-button"
                    @click.stop="deleteUsers()">
            <font-awesome-icon
              :icon="['fas', 'trash']"
              style="color: indianred;"
              title="Delete selected users"/>
          </b-button>
          <span style="margin-left: 5px">|</span>
        </div>

        <div class="resource-selected-actions">

          <b-button class="limit-button"
                    @click="page=1 ; get_users()">
            <font-awesome-icon :icon="['fas', 'sync']"/>
          </b-button>
          <b-button v-if="groupIdentifier === undefined || !addUser" class="limit-button"
                    @click="groupIdentifier ? addUser=true: addUserEvent()">
            <font-awesome-icon :icon="['fas', 'user-plus']"/>
          </b-button>
        </div>
      </div>

      <div class="right-actions">
        <!-- search -->
        <div class="search">
          <div class="" style="margin: 0.350rem auto;">
            <input type="text" v-model="search" placeholder="Search" class="search" @keyup="filter"
                   style=" margin-top: 0; padding: 2px; height:20px; width: 100%"/>
          </div>
        </div>
        <!-- pagination -->
        <div class="pagination">
          <b-button :class="[page > 1 ? 'page-chevron-active' : 'page-chevron-deactivated', 'page-chevron']"
                    @click="previous_page()">
            <font-awesome-icon :icon="['fas', 'chevron-left']"/>
          </b-button>
          <span class="pagination-text">
            <b-button class="pagination-button">
            {{ skip + 1 }} - {{ Math.min(skip + limit, total) }} on {{ total }}
            </b-button>
            <div class="page-options">
              <div style="text-align: center; color: #5f6368; font-weight: 500; user-select: none; ">
                Order By
              </div>
              <div style="display: flex; justify-content: center; flex-wrap: wrap">
                <b-button v-for="item in sortOptions"
                          :key="item.text"
                          @click.up="updateSort(item)"
                          :class="[isActiveSortOption(item) ? 'page-option-active': '', 'page-option']">
                  {{ item.text }}
                </b-button>
              </div>
              <div style="text-align: center; color: #5f6368; font-weight: 500; user-select: none; ">
                Display up to
              </div>
              <div style="display: flex; justify-content: center; flex-wrap: wrap">
                <b-button v-for="lim in limitOptions"
                          :key="lim"
                          @click.up="updateLimit(lim)"
                          :class="[lim === limit? 'limit-selected': '', 'page-option']">
                  {{ lim }}
                </b-button>
              </div>
            </div>
          </span>
          <b-button :class="[page < total_pages ? 'page-chevron-active' : 'page-chevron-deactivated', 'page-chevron']"
                    @click="next_page()">
            <font-awesome-icon :icon="['fas', 'chevron-right']" />
          </b-button>

        </div>
      </div>
    </div>

    <b-table show-empty v-if="!busy" :items="users"
             ref="user-table"
             :tbody-tr-attr="getLineAttr"
             :fields="['checkbox', 'status', 'roles', 'name', 'email',  'created_at', 'last_logged_in', 'actions']">

      <template v-slot:head(checkbox)="row">
        <br/>
      </template>

      <template v-slot:cell(checkbox)="row">
        <input type="checkbox" id="select-user" name="select" :value="row.item" v-model="selectedUsers">
      </template>

      <template v-slot:cell(status)="row">
        <b-badge pill variant="secondary" style="background-color: #fa8d22" v-if="row.item.locked">Locked</b-badge>
        <b-badge pill variant="secondary" style="background-color: #28a745" v-else-if="row.item.active">Online</b-badge>
        <b-badge pill variant="secondary" v-else>Offline</b-badge>
      </template>

      <template v-slot:cell(roles)="row">
        <div v-for="role of row.item.roles.filter((role) => role.group_identifier === groupIdentifier && !role.default)"
             :key="role.identifier">
          <b-badge variant="light"
                   v-b-tooltip.hover
                   :title="role.description">
            <font-awesome-icon :icon="['fa', 'lock']"
                               v-if="role.protected"
                               style="margin-right: 2px; color:grey;"/>
            {{role.name}}
          </b-badge>
        </div>
      </template>

      <template v-slot:cell(created_at)="row">
        created the {{ row.item.created_at | print_date }}
      </template>

      <template v-slot:cell(last_logged_in)="row">
        Last login on {{ row.item.last_logged_in | print_date }}
      </template>

      <template v-slot:cell(actions)="row">
        <font-awesome-icon
          class='fa-icon action-icon'
          v-if="isEditable(row.item)"
          @click.stop="editUser(row.item)"
          :icon="['fas', 'pencil-alt']"
          title="Edit resource"/>
        <font-awesome-icon
          class='fa-icon action-icon'
          v-if="isDeletable(row.item)"
          @click.stop="deleteUser(row.item)"
          :icon="['fas', 'trash']"
          style="color: indianred;"
          title="Delete user"/>
        <font-awesome-icon
          class='fa-icon action-icon'
          v-if="isDisconnectable(row.item) && row.item.active"
          @click.stop="disconnect(row.item)"
          :icon="['fas', 'sign-out-alt']"
          title="Log out user"/>

        <font-awesome-icon
          class='fa-icon action-icon'
          v-if="isLockable(row.item) && !row.item.locked"
          @click.stop="lock(row.item.identifier)"
          :icon="['fas', 'lock']"
          title="Lock user"/>

        <font-awesome-icon
          class='fa-icon action-icon'
          v-if="isLockable(row.item) && row.item.locked"
          @click.stop="unlock(row.item.identifier)"
          :icon="['fas', 'unlock']"
          title="Unlock user"/>

        <slot name="customActions" :row="row"/>
      </template>

    </b-table>
    <div class="text-center"
         style="padding-top:10px; padding-bottom:10px;"
         v-else>
      <b-spinner class="align-middle"></b-spinner>
      <strong style="margin-left:10px;">Loading...</strong>
    </div>
  </div>
</template>

<script>
import store from '../../store'
import TypeAhead from '../form/TypeAhead'
import UserEditForm from '@/components/groups/UserEditForm'
import moment from 'moment'
import {sanitize} from "@/common";

export default {
  name: "UserTab",
  components: {
    TypeAhead, UserEditForm, moment
  },
  props: {
    groupIdentifier: String
  },
  mounted: function() {
    this.page = 1
    this.skip = 0
    this.get_users()
    if (this.groupIdentifier) {
      this.$store.dispatch('roles/loadGroup', this.groupIdentifier).then(() => {
        this.roles = this.$store.getters["roles/getGroup"](this.groupIdentifier)
      })
    }
  },
  data() {
    return {
      searchFilter: {},
      addUser: false,
      userAddTabIndex: null,
      name: "",
      email: "",
      password: "",
      confirmPassword: "",
      existingUser: null,
      existingUserIdentifier: "",
      busy: false,
      skip: 0,
      total: 0,
      page: 0,
      total_pages: 0,
      limit: 25,
      showLimitOptions: false,
      limitOptions: [10, 15, 25, 50],
      sortOptions: [
        {orderBy: 'name', desc: false, text: 'Alphabetical'},
        {orderBy: 'last_logged_in', desc: true, text: 'Last logged in'},
        {orderBy: 'created_date', desc: false, text: 'Created date'},
        {orderBy: 'created_date', desc: true, text: 'Oldest'},
      ],
      orderBy: 'created_date',
      desc: true,
      selectedUsers: [],
      selectedRoles: [],
      roles: [],
      search: "",
    }
  },
  computed: {
    users: function() {
      return this.$store.getters['users/getAll']()
    },
    group: function() {
      if (this.groupIdentifier !== undefined) {
        return this.$store.getters['groups/get'](this.groupIdentifier)
      }
    }
  },
  methods: {
    get_users() {
      this.busy = true

      let payload = {
        orderBy: this.orderBy, desc: this.desc,
        page: this.page, limit: this.limit,
        load: "normal",
        search: this.search,
      }

      let loadType = 'users/loadAll'
      if (this.groupIdentifier) {
        loadType = 'users/loadGroup'
        payload['groupIdentifier'] = this.groupIdentifier
      }

      this.$store.dispatch(loadType, payload).then(() => {
        let pagination = this.$store.getters['users/getPagination']()
        this.page = pagination.page
        this.total_pages = pagination.total_pages
        this.limit = pagination.limit
        this.skip = pagination.skip
        this.total = pagination.total
        this.busy = false
        this.selectedUsers = []
      }).catch((response) => {
        console.log(response)
        this.$eventBus.$emit('http-error', response)
      })
    },
    isActiveSortOption(data) {
      return data.orderBy === this.orderBy && data.desc === this.desc
    },
    updateSort(data) {
      this.showSortOptions = false
      if (!this.isActiveSortOption(data)) {
        this.orderBy = data.orderBy
        this.desc = data.desc
      }
      this.get_users()
    },
    disconnect(user) {
      store.dispatch("users/disconnect", {userIdentifier: user.identifier})
    },
    addUserEvent() {
      if (this.groupIdentifier) {
        if (this.userAddTabIndex === 0) {
          this.createUserInGroup()
        } else {
          this.addUserInGroup()
        }
      } else {
        this.$refs.editUserFormModal.show()
      }

    },
    addUserInGroup() {
      if (this.existingUserIdentifier != null) {
        store.dispatch("users/addGroup", {
          userIdentifier: this.existingUserIdentifier,
          groupIdentifier: this.groupIdentifier
        }).then(() => {
          this.get_users()
          this.$eventBus.$emit('success', 'The user has been added.')
          this.$refs.existingUser.clear()
        })
      }
    },
    createUserInGroup() {

      this.$refs.passwordConfirm.setCustomValidity("")
      if (this.password !== this.confirmPassword) {
        this.$refs.passwordConfirm.setCustomValidity("Passwords Don't Match")
        this.$refs.userForm.reportValidity()
        return
      } else if (!this.$refs.userForm.reportValidity()) {
        return
      }

      let data = {
        "name": this.name,
        "password": this.password
      }

      if (this.email !== "") {
        data["email"] = this.email
      }

      store.dispatch('users/addInGroup', {data: data, groupIdentifier: this.groupIdentifier, groups: []}).then(() => {
        this.get_users()
        this.resetFields()
      }).catch((response) => {
        this.$eventBus.$emit('http-error', response)
      })
    },
    filter() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.get_users()
      }, 600);
    },
    isEditable(entry) {
      return !(entry.authz && (entry.authz.permission & 0x04) === 0);
    },
    isDeletable(entry) {
      return !(entry.authz && (entry.authz.permission & 0x08) === 0);

    },
    isDisconnectable(entry) {
      return !(entry.authz && (entry.authz.permission & 0x04) === 0);
    },
    isLockable(entry) {
      return !(entry.authz && (entry.authz.permission & 0x04) === 0);
    },
    getLineAttr(entry) {
      return (entry !== null && entry.locked) ? {"style": "color: #888888;"} : {}
    },
    next_page() {
      if ( this.page < this.total_pages ) {
        this.page += 1
        this.get_users()
      }
    },
    previous_page() {
      if ( this.page > 1 ) {
        this.page -= 1
        this.get_users()
      }
    },
    sort_change() {
      this.orderBy = this.sortSelected['orderBy']
      this.desc = this.sortSelected['desc']
      this.get_users()
    },
    updateLimit (limit) {
      this.showLimitOptions = false
      if (limit !== this.limit){
        this.limit = limit
        this.get_users()
      }
    },
    mouseupSearchEvent(_) {
      let oldvalue = this.$refs.searchcontent.value;

      if (oldvalue === "") {
        return;
      }

      //// When this event is fired after clicking on the clear button
      //// the value is not cleared yet. We have to wait for it.
      setTimeout(() => {
        var newValue = this.searchContent
        if (newValue === "") {
          this.get_users()
        }
      }, 1);
    },
    editUser(user) {
      this.$refs.editUserFormModal.show(user)
    },
    deleteUser(user) {
      if (this.groupIdentifier) {
        this.$deleteModal({
          text: "Are you sure to delete selected users from groups " + this.group.name + " ?",
          input: "checkbox",
          inputValue: false,
          inputPlaceholder: 'Delete user from platform if orphaned (not present in any group)'
        }).then((result) => {
          if (result.isConfirmed) {
            store.dispatch('users/deleteGroup', {
              userIdentifier: user.identifier,
              groupIdentifier: this.groupIdentifier,
              delete_orphan: result.value
            }).then(() => {
              this.get_users()
            })
          }
        })
      } else {
        let text = ""
        if (user.origin === 'sql')
          text = "Are you sure to delete user " + user.name + "?"
        else if (user.origin === 'ldap')
          text = "Are you sure to unregister user " + user.name + "? It will not affect LDAP server in any kind."
        else
          text = "Are you sure to delete user " + user.name + "? It only unregisters the user from LADE but does not affect the authentication provider in any kind."
        this.$deleteModal({
          text: text
        }).then((result) => {
          if (result.value) {
            store.dispatch('users/delete', user.identifier).then(() => {
              this.$eventBus.$emit('success', 'The user has been deleted')
              this.get_users()
            }).catch((response) => {
              this.$eventBus.$emit('http-error', response)
            })
          }
        })
      }
    },
    selectAll() {
      if (this.selectedUsers.length !== this.users.length) {
        this.selectedUsers = [...this.users]
      } else {
        this.selectedUsers = []
      }
    },
    deleteUsers() {
      let selected_users = this.selectedUsers
      let promises = []
      if (this.groupIdentifier !== undefined) {
        this.$deleteModal({
          text: "Are you sure to delete selected users from " + this.group.name + " ?",
          input: "checkbox",
          inputValue: false,
          inputPlaceholder: 'Removing users from the platform when they are orphans'
        }).then((result) => {
          if (! result.isConfirmed) {
            return
          }
          for (let user of selected_users) {
            let promise
            promise = store.dispatch('users/deleteGroup',
              {
                groupIdentifier: this.groupIdentifier, userIdentifier: user.identifier,
                delete_orphan: result.value
              })
            promises.push(promise)
          }
          Promise.all(promises).then(() => {
            this.get_users()
          })
        })
      }
      else {
        this.$deleteModal({
          text: "Are you sure to delete selected users ?"
        }).then((result) => {
          if (!result.isConfirmed) {
            return
          }
          for (let user of selected_users) {
            let promise
            promise = store.dispatch('users/delete', user.identifier)
            promises.push(promise)
          }
          Promise.all(promises).then(() => {
            this.get_users()
          })
        })
      }

    },
    resetFields() {
      this.name = ""
      this.email = ""
      this.password = ""
      this.confirmPassword = ""
      this.existingUser = null
      this.existingUserIdentifier = ""
    },
    showEditRolesModal() {
      this.$refs.editRolesFormModal.show()
    },
    addUsersRoles() {
      this.$store.dispatch('roles/assign_roles',
        {groupIdentifier: this.groupIdentifier, users: this.selectedUsers, addRoles: this.selectedRoles})
        .then(() => {
          this.get_users()
          this.selectedRoles = []
          this.$refs.editRolesFormModal.hide()
        }).catch((response) => {
          this.$eventBus.$emit('http-error', response)
        })
    },
    deleteUsersRoles() {
      this.$store.dispatch('roles/assign_roles',
        {groupIdentifier: this.groupIdentifier, users: this.selectedUsers, deleteRoles: this.selectedRoles})
        .then(() => {
          this.get_users()
          this.selectedRoles = []
          this.$bvModal.hide("editRolesFormModal")
        }).catch((response) => {
          this.$eventBus.$emit('http-error', response)
        })
    },
    lock (identifier) {
      this.$store.dispatch('users/update', {identifier: identifier, data: {"locked": true}}).catch((response) => {
        this.$eventBus.$emit('http-error', response)
      })
    },
    unlock (identifier) {
      this.$store.dispatch('users/update', {identifier: identifier, data: {"locked": false}}).catch((response) => {
        this.$eventBus.$emit('http-error', response)
      })
    },
    userUpdated(identifier) {
      for (let user in this.users) {
        if (user.identifier === identifier) {
          user.update(this.$store.getters["users/get"](identifier))
          break
        }
      }
    }
  }
}
</script>

<style scoped>
.fa-icon {
  margin-left: 4px;
  cursor: pointer;
}

.actions-bar {
  display: block;
  position: relative;
}

.users-actions {
  float: left;
  margin-left: 0.75rem;
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.resource-selected-actions {
  margin-left: 5px;
}

.pagination-button {
  color: black !important;
  background-color: Transparent !important;
  border: none !important;
  border-radius: 3px;
}

.pagination-button:hover {
  background-color: rgba(28, 22, 22, 0.05) !important;
}

.pagination-button:focus {
  background-color: rgba(28, 22, 22, 0.05) !important;
  border: none !important;
  box-shadow: none ! important;
}

.page-options {
  white-space: normal;
  position: absolute;
  z-index: 101;
  list-style: none;
  overflow-y: auto;
  display: none;
  max-height: 150px;
  border: 0px !important;
  background-color: white !important;
  box-shadow: 0px 2px 4px black;
  border-radius: 3px;
  width: 250px;
}

.page-options button {
  background-color: white !important;
}

.pagination-text:hover .page-options {
  display: block;
}

.page-option {
  border: none !important;
  background-color: rgba(255, 255, 255, 0) !important;
}

.page-option:hover {
  background-color: rgba(28, 22, 22, 0.05) !important;
}

.page-option:focus {
  box-shadow: none ! important;
}

select {
  height: 100px;
  line-height: 25px;
  background: #f4f4f4;
}

select:focus {
  outline: none;
}

select[multiple] {
  height: 100px;
  line-height: 25px;
  background: #f4f4f4;
}

.right-actions {
  display: flex;
  float: right;
}

.pagination {
  white-space: nowrap;
  display: inline-block;
  float: right;
  margin: 0.350rem 25vh 0.350rem auto;
}

.search {
  position: relative;
  margin-right: 5px;
  margin-top: auto;
  margin-bottom: auto;
}
</style>
