<template>
  <div v-if="!loading && profile">
    <b-tabs pills card vertical style="height: 100%;">
      <b-tab title="Profile" :active="tab===null">
        <EmailForm :currentEmail="profile.email" :origin="profile.origin"></EmailForm>
        <PersonalInformationForm
          :currentFirstname="profile.firstname"
          :currentLastname="profile.lastname"
          :origin="profile.origin">
        </PersonalInformationForm>
        <ProfilePictureForm></ProfilePictureForm>
      </b-tab>
      <b-tab title="Password" v-if="profile.origin === 'sql'">
        <PasswordForm></PasswordForm>
      </b-tab>
      <b-tab title="Access Tokens" :active="tab==='pat'">
        <AccessTokenList></AccessTokenList>
      </b-tab>
      <b-tab title="Registries" :active="tab==='registries'">
        <RegistryList></RegistryList>
      </b-tab>
      <b-tab title="Use Share Token" :active="tab==='share_token'">
        <UseShareTokenView></UseShareTokenView>
      </b-tab>
    </b-tabs>
  </div>

  <div v-else
       class="text-center col-12 col-xl-10"
       style="padding-top:10px; padding-bottom:10px;"
  >
    <b-spinner class="align-middle"></b-spinner>
    <strong style="margin-left:10px;">Loading...</strong>
  </div>
</template>

<script>
import EmailForm from './EmailForm.vue'
import PersonalInformationForm from './PersonalInformationForm.vue'
import ProfilePictureForm from './ProfilePictureForm.vue'
import PasswordForm from './PasswordForm.vue'
import AccessTokenList from './AccessTokenList.vue'
import RegistryList from './RegistryList.vue'
import UseShareTokenView from "./UseShareTokenView";


export default {
  name: 'SettingsView',
  components: {
    PasswordForm, AccessTokenList, ProfilePictureForm, EmailForm, PersonalInformationForm, RegistryList, UseShareTokenView
  },
  props: {
    tab: {
      type: String,
      default: null
    },
  },
  data() {
    return {
      loading: true,
    }
  },
  computed: {
    profile() {
      return this.$store.getters['users/getProfile']()
    }
  },
  mounted() {
    this.$store.dispatch('users/loadProfile').then(() => {
      this.loading = false
    })
    this.updateBreadcrumb()
  },
  methods: {
    updateBreadcrumb() {
      let breadcumbList = [
        {text: 'Profile', to: {name: 'profile'}},
        {text: 'Settings', to: {name: 'settings'}},
      ]
      this.$emit('breadcrumbChange', breadcumbList)
    },
  }
}

</script>

<style scoped>

.nav-pills .nav-link.active {
  color: var(--lade-color-white-hover) !important;
  background-color: var(--lade-color-grey) !important;
}

.nav-link.hover {
  background-color: var(--lade-color-dark-grey) !important;
}

a {
  color: var(--lade-color-dark) !important;
}

a.hover {
  color: var(--lade-color-white-hover);
}

.btn-primary {
  background-color: var(--lade-color-grey) !important;
  border-color: var(--lade-color-grey) !important;
}

.btn-primary:hover {
  background-color: var(--lade-color-dark-grey) !important;
  border-color: var(--lade-color-dark-grey) !important;
}
</style>
