import Vue from 'vue'
import Vuex from 'vuex'
import topologies from './modules/topologies'
import users from './modules/users'
import groups from './modules/groups'
import rules from './modules/rules'
import roles from "./modules/roles";
import token from './modules/token'
import about from './modules/about'
import actions from './modules/actions'
import scenarios from './modules/scenarios'
import bundles from './modules/bundles'
import bookings from './modules/bookings'
import files from './modules/files'
import project_pools from './modules/project_pools'
import projects from './modules/projects'
import workzones from './modules/workzones'
import categories from './modules/categories'
import search from './modules/search'
import workflows from './modules/workflows'
import events from './modules/events'
import uploads from './modules/uploads'
import configs from './modules/configs'
import accessTokens from './modules/accessTokens'
import userApps from './modules/userApps'
import favorites from './modules/favorites'
import reviews from './modules/reviews'
import questions from './modules/questions'
import notifications from "./modules/notifications"
import share from './modules/share'
import sharetoken from './modules/sharetoken'
import plugins from './modules/plugins'
import license from './modules/license'
import assets from "@/store/modules/assets";

import createLogger from 'vuex/dist/logger'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    token, topologies, users, groups, rules, actions, scenarios, about, files,
    bundles, categories, project_pools, projects, workzones, search, workflows, events, uploads, bookings, configs,
    accessTokens, userApps, favorites, reviews, questions, notifications, roles, plugins,
    share, sharetoken, license, assets
  },
  strict: debug,
  plugins: [createLogger()]
})
