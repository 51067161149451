<template>
    <b-modal ref="_reviewForm" title="Your bundle review"
        @show="reset"
        @hidden="reset"
        @ok="onOk">
        <form ref="form" @submit.stop.prevent="onSubmit">
            <b-form-group id="input-group-1" label="Rating:" label-for="input-1"
                :state="rateState"
                invalid-feedback="Rate this bundle">
                <b-form-rating
                    id="input-1"
                    v-model="rate"
                    required inline no-border variant="warning" size="sm">
                </b-form-rating>
            </b-form-group>

            <b-form-group id="input-group-2" label="Comment:" label-for="input-2"
                description="Please write your review following community rules."
                :state="commentState"
                invalid-feedback="Comment is required">
                <editor id="input-2" ref="editor" initialEditType="wysiwyg" />
            </b-form-group>
        </form>
    </b-modal>
</template>

<script>
export default {
    name: 'BundleReviewForm',
    props: {
        bundleIdentifier: String,
    },
    data: function() {
        return {
            rate: 0,
            show: true,
            rateState: true,
            commentState: true,
        }
    },
    methods: {
        checkFormValidity() {
            // const valid = this.$refs.form.checkValidity()
            this.commentState = (this.getComment().length > 0)
            this.rateState = (this.rate > 0)
            return this.commentState && this.rateState
        },
        onOk(evt) {
            // Prevent modal from closing
            evt.preventDefault()
            // Trigger submit handler
            this.onSubmit(evt)
        },
        onSubmit(evt) {
            // Exit when the form isn't valid
            if (!this.checkFormValidity()) {
                return
            }
            let review = {
                rate: this.rate,
                comment: this.getComment(),
            }
            this.$store.dispatch('reviews/add', {bundleIdentifier: this.bundleIdentifier, review})

            // Hide the modal manually
            this.$nextTick(() => {
                this.$refs._reviewForm.hide()
            })

            this.$store.dispatch('reviews/loadBundle', {bundleIdentifier: this.bundleIdentifier})
        },
        reset() {
            // Reset our form values
            this.rate = 0
        },
        openModal() {
            this.$refs._reviewForm.show()
        },
        getComment() {
            return this.$refs.editor.invoke('getMarkdown')
        },
    }
}
</script>
