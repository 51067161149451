 import Vue from 'vue'
import Config from '../../Config'
import {setDictValue, build_resource_list_params} from '@/common'
import {print_human_readable_size} from '@/filters'
import {field_tag, field_created_at, field_actions} from './fields'

const state = {
  list: [],
  limit: 15,
  skip: 0,
  query: null,
  label: null,
  orderBy: null,
  desc: null,
  load: null,
  isFavorites: false,
  pagination: {
    page: 1,
    total_pages: 0,
    total: 0,
  },
  _lookup: {},
  fields: [
    {"key": "name", "sortable": true},
    field_tag,
    {
      "key": "content_size",
      "label": "Size",
      "formatter": print_human_readable_size,
      "sortable": true
    },
    field_created_at,
    field_actions

  ],
  filters: {
    isEditable: (entry) => true,
    isDeletable: (entry) => true,
    isExportable: (entry) => true,
    isDownloadable: (entry) => false,
  },
  requestId: 0,
  loading : false
}

const getters = {
  get: (state) => (identifier) =>
    state._lookup[identifier],
  getAll: (state) => () =>
    state.list,
  getPage: (state) => () =>
    state.pagination.page,
  getTotalPages: (state) => () =>
    state.pagination.total_pages,
  getTotal: (state) => () =>
    state.pagination.total,
  getSkip: (state) => () =>
    state.skip,
  getLimit: (state) => () =>
    state.limit,
  getOrderBy: (state) => () =>
    state.orderBy,
  getQuery: (state) => () =>
    state.query,
  getLabels: (state) => () => {
    if (state.label) {
      return state.label.split(',')
    }
    else {
      return null
    }
  },
  getDesc: (state) => () =>
    state.desc,
  isFavorites: (state) => () =>
    state.isFavorites,
  getPagination: (state) => () =>
    state.pagination,
  getFields: (state) => () =>
    state.fields,
  getFilters: (state) => () =>
    state.filters,
  getTopology: (state) => (bundleIdentifier, identifier) =>
    state._lookup[bundleIdentifier].topologies.find(topology => topology['identifier'] === identifier),
  getAction: (state) => (bundleIdentifier, identifier) =>
    state._lookup[bundleIdentifier].actions.find(action => action['identifier'] === identifier),
  getScenario: (state) => (bundleIdentifier, identifier) =>
    state._lookup[bundleIdentifier].scenarios.find(scenario => scenario['identifier'] === identifier),
  getFile: (state) => (bundleIdentifier, identifier) =>
    state._lookup[bundleIdentifier].files.find(file => file['identifier'] === identifier),
  getLoading: (state) => () => state.loading,
}

const actions = {
  toggle({dispatch, commit}, item) {
    commit('setDetails', {item, details: !item._showDetails})
  },
  loadAll({dispatch, commit}, {favorites=false, ...resource_list_params}) {
    let params = build_resource_list_params(resource_list_params)

    if (favorites) {
      params['favorite'] = true
    }
    commit('setRequestId', state.requestId + 1)
    let currentRequestId = state.requestId;
    commit('setLoading', true)
    return Vue.http.get(Config.API_BUNDLES_URL, {params}).then((response) => {
      if(currentRequestId === state.requestId) {
        let bundles = response.data
        commit('setAll', {bundles, params})
        commit('setRequestId', 0)
        commit('setLoading', false)
      }
    })
  },
  loadGroup({dispatch, commit}, {groupIdentifier, favorites=false, ...resource_list_params}) {
    let params = build_resource_list_params(resource_list_params)

    if (favorites) {
      params['favorite'] = true
    }
    commit('setRequestId', state.requestId + 1)
    let currentRequestId = state.requestId;
    commit('setLoading', true)
    return Vue.http.get(Config.API_GROUPS_URL + '/' + groupIdentifier + '/bundles', {params}).then((response) => {
      if(currentRequestId === state.requestId) {
        let bundles = response.data
        state.groupIdentifier = groupIdentifier
        commit('setAll', {bundles, params})
        commit('setRequestId', 0)
        commit('setLoading', false)
      }
    })
  },
  load({dispatch, commit}, identifier) {
    return Vue.http.get(Config.API_BUNDLES_URL + '/' + identifier).then((response) => {
      let bundle = response.data
      commit('set', {identifier, bundle})
    })
  },
  add({dispatch, commit}, {group_identifier, data}) {
    let formData = new FormData()
    if (data['icon']) {
      formData.append('icon', data['icon'])
      delete data['icon']
    }
    formData.append('data', JSON.stringify(data))

    return Vue.http.post(Config.API_GROUPS_URL + '/' + group_identifier + '/bundles', formData).then((response) => {
      let bundle = response.data
      return dispatch('load', bundle.identifier)
    })
  },
  update({dispatch, commit}, {identifier, group_identifier, data}) {

    let formData = new FormData()
    if (data['icon']) {
      formData.append('icon', data['icon'])
      delete data['icon']
    }
    formData.append('data', JSON.stringify(data))
    return Vue.http.put(Config.API_GROUPS_URL + '/' + group_identifier + '/bundles/' + identifier, formData).then((response) => {
      dispatch('load', identifier).then(() => {
        let bundle = response.data
        commit('set', {identifier, bundle})
      })
    })
  },
  delete({dispatch, commit}, identifier) {
    let bundle = state._lookup[identifier]
    console.log(identifier)
    return Vue.http.delete(Config.API_GROUPS_URL + '/' + bundle.group_identifier + '/bundles/' + bundle.identifier).then((response) => {
      return commit('delete', identifier)
    })
  },
  search({dispatch, commit}, {search}) {
    return dispatch('search/bundle', {search}, {root: true}).then((resources) => {
      commit('setAll', {resources, })
    })
  },
  deleteTopology({dispatch, commit}, {bundle_identifier, identifier}) {
    return Vue.http.delete(Config.API_BUNDLES_URL + '/' + bundle_identifier + '/topologies/' + identifier).then((response) => {
      return dispatch('load', bundle_identifier)
    })
  },
  deleteScenario({dispatch, commit}, {bundle_identifier, identifier}) {
    return Vue.http.delete(Config.API_BUNDLES_URL + '/' + bundle_identifier + '/scenarios/' + identifier).then((response) => {
      return dispatch('load', bundle_identifier)
    })
  },
  deleteAction({dispatch, commit}, {bundle_identifier, identifier}) {
    return Vue.http.delete(Config.API_BUNDLES_URL + '/' + bundle_identifier + '/actions/' + identifier).then((response) => {
      return dispatch('load', bundle_identifier)
    })
  },
  deleteFile({dispatch, commit}, {bundle_identifier}) {
    return Vue.http.delete(Config.API_BUNDLES_URL + '/' + bundle_identifier + '/files/' + identifier).then((response) => {
      return dispatch('load', bundle_identifier)
    })
  },
  export({dispatch, commit}, {bundle_identifier, resources, no_metadata = false}) {
    return Vue.http.post(Config.API_BUNDLES_URL + "/" + bundle_identifier + '/export', {
      'resources': resources,
      'flatten_path': true,
      'no_metadata': no_metadata,
    }).then((response) => {
      for (let file of response.data.files) {
        window.open(file.url, "_blank");
      }
    })
  },
  moveGroup({dispatch, commit}, {bundle_identifier, new_group_identifier}) {
    let bundle = state._lookup[bundle_identifier]
    return Vue.http.post(Config.API_GROUPS_URL + '/' + bundle.group_identifier + '/' + new_group_identifier +
      '/bundles/' + bundle_identifier + "/move", {}).then((response) => {
      commit('delete', bundle_identifier)
    })
  },
  getLabels({dispatch, commit}, {groupIdentifier=null, ...resource_list_params}) {
    let params = build_resource_list_params(resource_list_params)

    let bundles_url
    if (groupIdentifier !== null) {
      bundles_url = Config.API_GROUPS_URL + '/' + groupIdentifier + '/bundles'
    }
    else {
      bundles_url = Config.API_BUNDLES_URL
    }

    return Vue.http.get(bundles_url + '/labels', {params}).then((response) => {
      return response.data
    })
  }
}

const mutations = {
  delete(state, identifier) {
    Vue.delete(state.list, state.list.indexOf(state.list.find(item => item.identifier === identifier)))
  },
  setDetails(state, {item, details}) {
    Vue.set(item, '_showDetails', details)
  },
  setAll(state, {bundles, params}) {
    state.list.splice(0, state.list.length)
    state.limit = bundles.limit
    state.pagination.page = bundles.page
    state.pagination.total_pages = bundles.total_pages
    state.skip = bundles.skip
    state.pagination.total = bundles.total


    state.isFavorites = params['favorite'] || false
    state.label = params['label'] || null
    state.orderBy = params['order_by'] || null
    state.desc = params['desc'] || null
    state.load = params['load'] || null
    state.query = params['q'] || null
    for (let bundle of bundles.data) {
      state.list.push(bundle)
      setDictValue(state._lookup, bundle.identifier, bundle)
    }
  },
  set(state, {identifier, bundle}) {
    setDictValue(state._lookup, identifier, bundle)
  },
  setRequestId(state, id) {
    state.requestId = id
  },
  setLoading(state, value) {
    state.loading = value
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true
}
