<template>
  <b-card>
    <b-tabs class="topologySpec" lazy>
      <b-tab title="Hosts" active>
        <ResourceList
          resource="topologies"
          :dispatchActions="[]"
          :getter="{event:'topologies/getHostSpecs', parameters:identifier}"
          resourceListForm="HostSpecsForm"
          addEvent="add-host-spec-show"
          :addEventParameter="identifier"
          editEvent="edit-host-spec-show"
          :editEventParameter="{topologyIdentifier:identifier}"
          deleteEvent="delete-host-spec-show"
          :deleteEventParameter="{topologyIdentifier:identifier}"
          :fields="hostSpecsFields"
          :filters="hostSpecsFilters"
          subComponent="HostSpecsListForm"
          :subComponentParameter="{topologyIdentifier:identifier}"
          hideSearch
          hideRefresh
          sortBy="hostname"
          loadOnToggle/>
      </b-tab>
      <b-tab title="Networks">
        <ResourceList
          resource="network-specs"
          :dispatchActions="[]"
          resourceListForm="NetworkSpecsForm"
          :getter="{event:'topologies/getNetworkSpecs', parameters:identifier}"
          :fields="networkSpecsFields"
          :filters="networkSpecsFilters"
          addEvent="add-network-spec-show"
          :addEventParameter="identifier"
          editEvent="edit-network-spec-show"
          :editEventParameter="{topologyIdentifier:identifier}"
          deleteEvent="delete-network-spec-show"
          :deleteEventParameter="{topologyIdentifier:identifier}"
          hideSearch
          sortBy="name"
          hideRefresh/>
      </b-tab>
      <HostSpecsForm></HostSpecsForm>
      <NetworkSpecsForm></NetworkSpecsForm>
    </b-tabs>
  </b-card>
</template>

<script>
import NetworkSpecsForm from './NetworkSpecsForm'
import HostSpecsForm from './HostSpecsForm'

export default {
  name: "TopologySpecForm",
  props: {
    identifier: String
  },
  components: {
    NetworkSpecsForm,
    HostSpecsForm
  },
  data() {
    return {
      hostSpecsFields: this.$store.getters['topologies/getHostSpecsFields'](),
      hostSpecsFilters: this.$store.getters['topologies/getHostSpecsFilters'](),
      networkSpecsFields: this.$store.getters['topologies/getNetworkSpecsFields'](),
      networkSpecsFilters: this.$store.getters['topologies/getNetworkSpecsFilters']()
    }
  }
}
</script>
