<template>
  <b-card class="action-tile tile" no-body onClick="">
    <b-link style="text-decoration: none;"
            :to="{name: 'actionPage', params: {
                  identifier: action.identifier, bundleIdentifier: action.bundle_identifier}}">
<!--    <b-link style="text-decoration: none;"-->
<!--            :to="{name: 'actionPage', params: {-->
<!--                identifier: action.identifier, bundleIdentifier: action.bundle_identifier}}">-->

        <!-- action description-->
        <div style="padding-left: 30px; padding-right: 35px; margin-top: 10px;">
          <b-row class="action-title">
            <h3>
              <img style="height: 1.75rem; padding-right: 1rem;" v-if="action.icon && action.icon.url" :src="action.icon.url" alt="Action icon"/>
              {{action.name}}
            </h3>
            <h6><b-badge class="lade-label">{{action.qualifier}}</b-badge></h6>
          </b-row>
          <b-row class="card-row"><div><B>Tag: </B>{{action.alias}}:{{action.qualifier}}</div></b-row>
          <b-row>
            <small>
              Last update {{ action.created_at | print_date }}
            </small>
          </b-row>
          <b-row><div class="command"><B style="color: #3d3d3d">Command: </B><code>{{action.command}}</code></div></b-row>
          <b-row style="color: grey; height: 20px; overflow-y:hidden; text-overflow: ellipsis;">
            <vue-markdown>{{ description }}</vue-markdown>
          </b-row>
          <b-row class="labels">
            <b-badge class="label-badge lade-label" v-for="label in action.labels" :key="label">{{label}}</b-badge>
          </b-row>
        </div>
    </b-link>
  </b-card>
</template>

<script>

import {truncateWithEllipses} from "@/common";

export default {
  name: 'ActionTile',
  components: {
  },
  props: {
    resource: Object,
  },
  data: function() {
    return {
      isBusy: true,
      preview: null,
      action: this.resource,
    }
  },
  mounted: function() {
  },
  computed: {
    description: function() {
      const regex = /[#]+ .+/ig
      return truncateWithEllipses(this.action.description.replaceAll(regex, ""), 200)
    },
  },
  methods: {
  }
}
</script>

<style scoped>

.action-title {
  align-items: center;
  color: #2B2B2B
}

small {
  color: #2B2B2B
}

.action-title h2 {
  margin-right: 10px
}

.action-title h3 {
  margin-right: 10px;
  font-size: 1.75rem;
}

.labels {
  padding-top: 12px;
  padding-bottom: 2px;
}

.command {
  margin-top: 5px;
  margin-bottom: 5px;
  height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 98%;
  color: var(--lade-color-command);
}

.label-badge {
  font-size: 90%;
  text-align: center;
  vertical-align: center;
  padding: 5px;
}

.action-tile {
  width: 100%;
  height: 190px;
  margin: 10px auto 20px;
}

.action-tile:hover {
  box-shadow: 0 0 3px #333;
}

</style>
