<template>
  <div style="background: #333; padding-left:20px; padding-right:20px; width:100%; overflow: auto">
    <div style="width: 85vw; margin-left: auto; margin-right: auto;">
      <h1 class="hr" style="text-align:center; padding:20px;" v-bind:style="{ color: about.platform_color }">{{ about.platform_name }}</h1>
      <p style="color: white; margin: auto;">
        Please read the following license agreement carefully:
      </p>
      <div style="background-color:white; padding:25px; overflow-y: scroll; height:70vh;  ">
        <h3 class="hr" style="text-align:center; padding-bottom:20px; ">End User License Agreement</h3>

        <pre style="white-space: pre-wrap; margin:10px;	font-family: Verdana,sans-serif;">

  {{ about.eula }}

          </pre>

      </div>
      <b-form @submit.prevent="onSubmit" style="color:white; margin: auto; display: grid;" >
        <b-form-checkbox id="checkbox-1" style="margin-top:15px; " v-model="accepted" name="checkbox-1" switch size="lg" :disabled="loading">
          I have read and accept the End User License Agreement
        </b-form-checkbox>
        <hr style="border-color:white; width:100%;">
        <div style="float:right; margin-right:auto; margin-left: auto; margin-top: 5px;">
          <b-button type="submit" variant="primary" :disabled="!accepted" size="lg">Next</b-button>
          <b-button variant="danger" @click="cancel()" size="lg" style="margin-left: 20px;">Cancel</b-button>
        </div>
      </b-form>
    </div>
  </div>
</template>

<script>

import store from '../../store'

export default {
  name: 'AcceptEULA',
  created() {
    store.dispatch('token/getTokenInfos').then(() => {
      this.loading = false
      if (!store.getters['token/isEula']()) this.redirect()
    })
  },
  data() {
    return {
      password: "",
      confirmation: "",
      accepted: false,
      loading: true,
      about: store.getters["about/get"]()
    }
  },
  methods: {
    cancel() {
      this.$router.push({name: 'logout'})
    },
    redirect() {
      if (store.getters['token/isEula']()) {
        this.$router.push({name: 'eula', query: {rr: this.$route.query.rr, ar: this.$route.query.ar}})
      } else if (store.getters['token/isReset']()) {
        this.$router.push({name: 'reset', query: {rr: this.$route.query.rr, ar: this.$route.query.ar}})
      } else if (this.$route.query.ar) {
        let loc = this.$route.query.ar
        let currentHost = document.location.host
        let url = new URL(loc)
        let host = url.host
        if (url.hash.startsWith("#/login")) {
          url.hash = "";
        }
        if (host === currentHost) {
          document.location.replace(url.href)
        }
      } else if (this.$route.query.rr) {
        if (this.$route.query.rr.startsWith("/login")) {
          this.$router.push('/')
        } else {
          this.$router.push(this.$route.query.rr)
        }
      } else {
        document.location.replace(document.location.protocol + '//' + document.location.host)
      }
    },
    onSubmit() {
      store.dispatch('token/acceptEULA').then(async () => {
        await store.dispatch('token/getTokenInfos')
        this.redirect()
      }).catch((response) => {
        this.$eventBus.$emit('http-error', response)
      })
    }
  },
  components: {}
}
</script>

<style scoped>
hr {
  border: 1px solid var(--lade-color-dark-grey);
}
</style>

