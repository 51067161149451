<template>
  <div>
    <ResourceList
      id="roleTabList"
      ref="rolelist"
      resource="roles"
      editEvent="edit-role-show"
      deleteEvent="delete-role-show"
      addEvent="add-role-show"
      resource-list-form="RoleListForm"
      :addEventParameter="groupIdentifier"
      :fields="fields"
      :getter="getter"
      :dispatchActions="dispatchActions"
      subComponent="RuleList"
      :subComponentParameter='subComponentParameter'
      hideSearch
      load-on-toggle>
      <template v-slot:cell(name)="row">
        <font-awesome-icon :icon="['fa', 'lock']"
                           v-if="row.item.protected"
                           title="This role is protected"
        style="margin-right: 5px;"/>

        {{row.item.name}}

      </template>

      <template v-slot:customActions="actions">
        <font-awesome-icon
          class='fa-icon action-icon'
          @click.stop="cloneRole(actions.row.item)"
          :icon="['fas', 'clone']"
          title="Clone role"/>
      </template>

      </ResourceList>
  </div>
</template>

<script>
import store from '../../store'

export default {
  name: "RoleTab",
  props: {
    groupIdentifier: String
  },
  data() {
    return {
      fields: store.getters['roles/getFields']().filter(field => field.name !== "protected"),
      filters: store.getters['roles/getFilters'](),
      getter: {event: 'roles/getGroup', parameters: this.groupIdentifier},
      dispatchActions: [{event: 'roles/loadGroup', parameters: this.groupIdentifier}],
      subComponentParameter: {
        groupIdentifier: this.groupIdentifier
      }
    }
  },
  methods: {
    cloneRole(role){
      this.$eventBus.$emit("clone-role-show", role.identifier)
    },
    isEditable(entry) {
      if (entry.authz.permission <= 1) {
        return false;
      }
      return this.filters.isEditable(entry)
    },
    isDeletable(entry) {
      if (entry.authz.permission <= 1) {
        return false;
      }
      return this.filters.isDeletable(entry)
    },
  }
}
</script>

<style>

</style>
