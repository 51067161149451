<template>
  <b-card class="topology-tile tile" no-body onClick="">
    <b-link style="text-decoration: none;"
            :to="{name: 'topologyPage', params: {
                  identifier: topology.identifier, bundleIdentifier: topology.bundle_identifier}}">
      <b-row no-gutters>
        <!-- Topology preview-->
        <b-col class="col-preview" cols=3 v-if="preview == null"><b-spinner class="align-middle spinner"></b-spinner></b-col>
        <b-col class="col-preview" cols=3 v-else>
          <b-card-img class="preview-style" :top=true :src="preview"
                      alt="Topology preview"></b-card-img>
        </b-col>

        <!-- Topology description-->
        <b-col cols='9' style="padding-left: 30px; padding-right: 35px; margin-top: 10px;">
          <b-row no-gutters class="topology-title">

            <h3>
              <img style="height: 1.75rem; padding-right: 1rem;" v-if="topology.icon && topology.icon.url" :src="topology.icon.url" alt="Topology icon"/>
              {{topology.name}}
            </h3>
            <h6>
              <b-badge class="lade-label">{{topology.qualifier}}</b-badge>
            </h6>
          </b-row>
          <div class="card-row"><div><B>Tag: </B>{{topology.alias}}/{{topology.qualifier}}</div></div>
          <div>
            <small>
              Last update {{ topology.created_at | print_date }}
            </small>
          </div>
          <div style="color: grey; height: 60px; overflow-y:hidden; text-overflow: ellipsis;">
            <vue-markdown>{{ description }}</vue-markdown>
          </div>
          <div class="labels"><b-badge class="label-badge lade-label" v-for="label in topology.labels" :key="label">{{label}}</b-badge></div>
        </b-col>
        <div class="right-corner lade-label">
          <p>Size: {{ topology.size | print_human_readable_size }}</p>
        </div>
      </b-row>
    </b-link>
  </b-card>
</template>

<script>
import * as ladeWebUI from 'lade-webui'
import {truncateWithEllipses} from "@/common";

export default {
  name: 'TopologyTile',
  components: {
  },
  props: {
    resource: Object,
  },
  data: function() {
    return {
      isBusy: true,
      preview: null,
      topology: this.resource
    }
  },
  mounted: function() {
    this.generateTopologyPreview()
  },
  computed: {
    description: function() {
      const regex = /[#]+ .+/ig
      return truncateWithEllipses(this.topology.description.replaceAll(regex, ""), 150)
    }
  },
  methods: {
    generateTopologyPreview() {
      ladeWebUI.getPreview(this.topology, {}).then((result) => {
        if (result.element.children.length > 0) {
          this.preview = result.element.children[0].src
        }
        else {
          this.preview = require("@/assets/background_pattern_light.png")
        }
      })
    }
  }
}
</script>

<style scoped>

.topology-title {
  align-items: center;
  color: var(--lade-color-black);
}

small {
  color: var(--lade-color-dark-grey);
}

.topology-title h2 {
  margin-right: 10px
}

.topology-title h3 {
  margin-right: 10px;
  font-size: 1.75rem;
}

.spinner{
  position: absolute;
  height: 40px;
  width: 40px;
  top: 45%;
  left: 45%;
  margin-left: auto;
  margin-top: auto;
}

.labels {
  padding-top: 2px;
  padding-bottom: 2px;
}

.label-badge {
  font-size: 90%;
  text-align: center;
  vertical-align: center;
  padding: 5px;
}

.card-row {
  margin-top: 10px;
}

.topology-tile {
  width: 100%;
  height: 190px;
  margin: 10px auto 20px;
  border-radius: 10px 10px 10px 10px;
}

.preview-style {
  background-image: url("~@/assets/background_pattern_light.png");
  height: 170px;
  object-fit: scale-down;
  margin: 10px;
}
</style>
