<template>
  <div class="edit-user" v-if="profile !== undefined">
    <div class="nav-bar">
      <div class="user-title">
        <div class="icon-user">
          <Icon v-if="profile.icon !== undefined && profile.icon !== null"
               :icon="profile.icon"/>
          <img v-else
               src="@/assets/no-icon.png"
               alt="user doesn't have icon"/>
        </div>
        <div>
          <h3>{{ profile.name }}</h3>
          <p>created at <em>{{ created_date }}</em></p>
        </div>
      </div>
      <span class="separation"></span>
      <nav>
        <ul>
          <li :class="{'selected': tab === null}"
              @click.stop="tab = null">
            <font-awesome-icon :icon="['fas', 'id-card-clip']" />
            <p>Profile</p>
          </li>
          <li :class="{'selected': tab === 'sql'}"
              @click.stop="tab = 'sql'">
            <font-awesome-icon :icon="['fas', 'unlock']" />
            <p>Password</p>
          </li>
          <li :class="{'selected': tab === 'pat'}"
              @click.stop="tab = 'pat'">
            <font-awesome-icon :icon="['fas', 'key']" />
            <p>Access Token</p>
          </li>
          <li :class="{'selected': tab === 'registries'}"
              @click.stop="tab = 'registries'">
            <font-awesome-icon :icon="['fass', 'bookmark']" />
            <p>Registries</p>
          </li>
          <li :class="{'selected': tab === 'share_token'}"
              @click.stop="tab = 'share_token'">
            <font-awesome-icon :icon="['fass', 'share']" />
            <p>Use Share Token</p>
          </li>
        </ul>
      </nav>
    </div>
    <SettingsProfile v-if="tab === null && this.profile.created_at !== undefined" :profile="this.profile"/>
    <SettingsPassword v-if="tab === 'sql'" :profile="this.profile"/>
    <SettingsToken v-if="tab === 'pat'" :profile="this.profile"/>
    <SettingsRegistry v-if="tab === 'registries'" :profile="this.profile"/>
    <SettingsShareToken v-if="tab === 'share_token'" :profile="this.profile"/>
  </div>
</template>

<script>
import Icon from "@/components/form/Icon.vue";
import SettingsProfile from "@/components/profile/SettingsProfile.vue";
import SettingsPassword from "@/components/profile/SettingsPassword.vue";
import SettingsToken from "@/components/profile/SettingsToken.vue";
import SettingsRegistry from "@/components/profile/SettingsRegistry.vue";
import SettingsShareToken from "@/components/profile/SettingsShareToken.vue";

export  default {
  name: 'SettingsUser',
  components: {
    Icon,
    SettingsProfile,
    SettingsPassword,
    SettingsToken,
    SettingsRegistry,
    SettingsShareToken
  },
  props: {
    tab_ : {
      type: String,
      default: null
    }
  },
  data() {
    return {
      loading: false,
      tab: null
    }
  },
  computed: {
    created_date() {
      if(this.profile.created_at) {
        let date = this.profile.created_at
        let jj = date.slice(8,date.indexOf('T'))
        let mm = date.slice(5,7)
        let yyyy = date.slice(0,4)
        return jj +'/'+ mm +'/'+ yyyy
      }
      return ""
    },
    profile() {
      return this.$store.getters['users/getProfile']()
    }
  },
  mounted() {
    this.$store.dispatch('users/loadProfile').then(() => {
      this.loading = false
    })
    this.updateBreadcrumb()
    this.tab = this.tab_
  },
  methods: {
    updateBreadcrumb() {
      let breadcumbList = [
        {text: 'Profile', to: {name: 'profile'}},
        {text: 'Settings', to: {name: 'settings'}},
      ]
      this.$emit('breadcrumbChange', breadcumbList)
    },
  }
}
</script>

<style scoped>
.nav-bar {
  width: 20%;
  padding: 5px 10px;
  border-radius: 0 5px 5px 0;
  background-color: var(--lade-color-white-hover);
  margin-top: 3%;
  position: fixed;
  height: calc(94% - 154.45px);
  border: 1px solid var(--lade-color-white);
}

.user-title {
  width: 100%;
  margin-top: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.user-title h3 {
  transition: 0.3s;
  width: 100%;
}


.user-title div:last-child{
  width: max-content;
}

.user-title div:last-child p {
  font-size: 1.1em;
}
.user-title div:last-child h3,p {
  margin: 0 0;
  padding: 0 0;
}

.icon-user {
  margin-right: 10px;
}

.icon-user img {
  max-width: 100px;
  height: 100%;
  margin: 0 0 auto;
  object-fit: scale-down;
  border-radius: 10px 10px;
}

.separation{
  display: none;
}

.nav-bar nav{
  margin-top: 15px;
  border-top: solid 1px var(--lade-color-white);
  padding-top: 15px;
}

.nav-bar ul {
  display: inline-flex;
  flex-direction: column;
  margin: 0 0;
  padding: 0 10px;
  width: 100%;
}

.nav-bar ul li{
  list-style: none;
  text-align: left;
  font-size: 1.1em;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 4px 4px 4px 8px;
  cursor: pointer;
  border-radius: 7px 7px;
  transition: 0.2s;
  margin-bottom: 2px;
}
.nav-bar ul li:hover,
.nav-bar ul li.selected {
  background-color: var(--lade-color-white);
}

.nav-bar ul li img{
  width: 20px;
  height: 20px;
}

.nav-bar ul li p{
  margin: 4px 0 0 15px;
  border-bottom: solid 1px transparent;
  padding-bottom: 0;
  height: 20px;
  transition: 0.3s;
}

.nav-bar ul li:hover p{
  border-bottom-color: var(--lade-color-dark-grey);
}


@media screen and (max-width: 1060px) {
  .nav-bar {
    top: 142.39px;
    right: 15%;
    width: 70%;
    height: 7% !important;
    min-height: 47px;
    overflow: hidden;
    padding: 0 10px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    border-radius: 5px 5px 5px 5px;
  }

  .user-title {
    width: min-content;
    margin-top: 0;
    margin-left: 5px;
    margin-right: 0;
    padding: 0 0;
  }

  .user-title div p{
    display: none;
  }

  .icon-user{
    margin-right: 5px !important;
    padding: 0 0;
    width: 50px !important;
    height: 50px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  .user-title div:last-child {
    padding: 0 0;
    height: 100%;
  }

  span.separation {
    display: block;
    width: 1px;
    height: 60%;
    padding: 0 0;
    background-color: var(--lade-color-dark-grey);
  }

  .nav-bar nav {
    padding: 0 0;
    margin: 0 0 0 0;
    border: none;
    width: 80%;
  }

  .nav-bar nav ul {
    display: flex;
    align-items: center;
    justify-content: center;
    justify-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
  }

  .nav-bar nav li {
    margin: 0 15px 0 0;
    padding: 2px 2px;
    width: auto;
  }
  .nav-bar nav li p{
    margin: 2px 0 0 2px;
  }
}

@media screen and (max-width: 990px) {
  .nav-bar {
    top: 122.39px;
    width: 80%;
    left: 10%;  /* 2.5% */
    height: auto !important;
  }
}

@media screen and (max-width: 762px) {
  .nav-bar {
    top: 102.39px;
    width: 95%;
    left: 2.5%;
  }
}
</style>
