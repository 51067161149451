var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mx-2 mb-2"},[_c('b-button-group',{staticClass:"align-middle"},[_c('b-button',{class:[
        _vm.currentPage > 1 ? 'page-chevron-active' : 'page-chevron-deactivated',
        'page-chevron',
      ],attrs:{"disabled":_vm.currentPage <= 1},on:{"click":function($event){return _vm.previousPage()}}},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'chevron-left']}})],1),_c('b-dropdown',{attrs:{"text":`${_vm.skip + 1} - ${Math.min(_vm.skip + _vm.limit, _vm.total)} on ${_vm.total} elements`,"variant":"light"}},_vm._l((_vm.limitOptions),function(lim){return _c('b-dropdown-item',{key:lim,attrs:{"active":lim === _vm.limit},on:{"click":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"up",38,$event.key,["Up","ArrowUp"]))return null;return _vm.updateLimit(lim)}}},[_vm._v(" "+_vm._s(lim)+" elements ")])}),1),_c('b-button',{class:[
        _vm.currentPage < _vm.totalPages
          ? 'page-chevron-active'
          : 'page-chevron-deactivated',
        'page-chevron',
      ],attrs:{"disabled":_vm.currentPage >= _vm.totalPages},on:{"click":function($event){return _vm.nextPage()}}},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'chevron-right']}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }