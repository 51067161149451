<template>
  <b-tabs pills card vertical>
      <div v-for="(value, key) in config" :key="key" class="row">
        <InputText :ref="'config-'+key" :name="key" :value="value" required class="col-sm-8 col-md-11"/>
        <b-button class="col-sm-4 col-md-1" style="margin-bottom:16px;" variant="primary" @click.stop="updateValue(key)">
          Apply
        </b-button>
      </div>
  </b-tabs>
</template>

<script>
import InputText from '../form/InputText.vue'

export default {
  components: {
    InputText
  },
  name: "ConfigSectionForm",
  created () {
    this.$store.dispatch('configs/load')
  },
  data () {
    return {
      config: this.$store.getters['configs/get']()
    }
  },
  props: {
    identifier: String,
  },
  methods: {
    updateValue(key) {
      let value = this.$refs['config-' + key][0].getValue()
      this.$store.dispatch("configs/update", {service: this.identifier, key, value}).then(() => {
        this.$eventBus.$emit('success', 'The configuration has been applied, you must restart the impacted services to apply it.')
      })
    },
  }
}
</script>

<style scoped>
.tabs {
  float: none;
}
</style>
