<template>
  <div>
    <ImageForm v-for="image in images" :key="image.identifier"
               :hardwareType="hardware_type"
               :identifier="image.identifier" :hostIdentifier="identifier"
               :topologyIdentifier="parameters.topologyIdentifier"/>

    <div v-if="hardware_type==='EM_T'" style="padding-top:5px;">
      No disk in an external machine.
    </div>
    <div v-else-if="images.length === 0" style="padding-top:5px;">
      No disk attached to host.
    </div>
    <b-button @click="showDropzone=true" style="margin-top:5px;" v-if="canUpload">
      + Upload disk ({{ accept }})
    </b-button>
    <div v-if="showDropzone" style="padding-top:15px">
      <b-alert show variant="warning" v-if="hardware_type==='VM_T'">
        Uploading your own virtual machine disks using web interface is an advanced method,
        it is not suited for huge disks and you will need to fill additionnal information that could have been
        autodetected (OS, Backing).
        <br/>
        <br/>

        The simplest and recommended method to import virtual machines is to use the command line interface: <br/>
        <code> lade topology add_ovf {{ topology.tag }} {{ hostSpec.hostname }}
          &lt;{{ hostSpec.hostname }}.ovf&gt;</code>
      </b-alert>

      <b-alert show variant="info" v-if="hardware_type==='CO_T'">
        You can create an image from a docker container using the folloxing command: <br/>
        <code> docker save &lt;docker-image&gt; | gzip -c > {{ hostSpec.hostname }}.tar.gz </code>
        <br/> <br/>
        You can also import an image in this topology using the lade command line interface: <br/>
        <code> lade topology add_image {{ topology.tag }} {{ hostSpec.hostname }} {{ hostSpec.hostname }}.tar.gz </code>
      </b-alert>

      <InputText name="Disk deployed size" v-model="disk_size" v-if="hardware_type==='VM_T'" required/>
      <b-alert show variant="info" v-if="hardware_type==='VM_T'">
        You can find "Disk Deployed Size" information in the "ovf" file attached with the vmdk at the key "capacity":
        <pre>
&lt;Disk ovf:capacity=&quot;4294967296&quot; ovf:diskId=&quot;vmdisk2&quot; ovf:fileRef=&quot;file1&quot;
  ovf:format=&quot;http://www.vmware.com/interfaces/specifications/vmdk.html#streamOptimized&quot; /&gt; </pre>
      </b-alert>

      <font-awesome-icon
        class='fa-icon'
        :icon="['fas', 'times']"
        style="margin:15px; position:absolute; float:right; z-index:999999; height:20px; color:grey;"
        @click="showDropzone=false"/>
      <Dropzone
        :text="'Select or drop an Image ('+accept+')'"
        :accept="accept"
        disabledText="Please fill 'Disk deployed size' field."
        :disabled="hardware_type==='VM_T' && disk_size === 0"
        @input="handleImage"/>
    </div>
  </div>
</template>

<script>
import store from '../../store'
import Dropzone from '../form/Dropzone'
import Config from '../../Config'
import InputText from '../form/InputText'
import ImageForm from './ImageForm'

export default {
  name: "TopologySpecsListForm",
  props: {
    parameters: Object,
    identifier: String,
  },
  data() {
    var topology = store.getters['topologies/get'](this.parameters.topologyIdentifier)
    var hostSpec = store.getters['topologies/getHostSpec'](topology.identifier, this.identifier)
    var hardware_type = hostSpec.hardware_type
    return {
      hardware_type: hardware_type,
      accept: (hardware_type === "VM_T") ? ".vmdk" : ".tar.gz,.tar,.tgz",
      topology: topology,
      hostSpec: hostSpec,
      disk_size: 0,
      modalTitle: "Upload image for host " + hostSpec.hostname + " in topology " + topology.tag,
      images: store.getters['topologies/getHostSpecImages']({
        topologyIdentifier: topology.identifier,
        identifier: hostSpec.identifier
      }),
      showDropzone: false,
      deployedSizeInfo: false
    }
  },
  computed: {
    canUpload() {
      if (this.showDropzone) return false
      if (this.hostSpec.hardware_type === 'EM_T') return false
      if (this.hostSpec.hardware_type === 'CO_T' && this.hostSpec.images.length > 0) return false
      return true
    }
  },
  methods: {
    async handleImage(files) {
      for (let file of files) {
          let image_data = {}
          if (this.disk_size > 0) {
            image_data.disk_size = this.disk_size
          }
          try {
            await this.$store.dispatch("topologies/addImage", {
                file: file,
                bundleIdentifier: this.topology.bundle_identifier,
                topologyIdentifier: this.topology.identifier,
                hostIdentifier: this.identifier,
                data: image_data,
                onerror: (message) => {
                  this.$eventBus.$emit('error', message)
                },
                onfinished: () => {
                  this.$eventBus.$emit('success', "The file " + tag + " has been uploaded.")
                }
              }
            )
          } catch(error) {
            this.$eventBus.$emit('http-error', error)
          }
      }
    }
  },
  components: {
    Dropzone,
    InputText,
    ImageForm
  }
}
</script>
