<template>
    <div v-if="reviews && reviews.length">
        <b-card no-body
            style="margin-bottom: 10px; border-radius: 10px 10px"
            v-for="review in reviews" :key="review.identifier">
            <b-row>
                <b-col md="2" class="text-center" style="margin-top: 5px; margin-bottom: 5px;">
                    <b-row><b-col>
                        <b-avatar v-if="review.icon !== null" :src="review.icon.url" :text="review.username.charAt()"></b-avatar>
                    </b-col></b-row>
                    <b-row><b-col>
                        <b-card-text>{{review.username}}</b-card-text>
                    </b-col></b-row>
                    <b-row style="margin-top: 5px;"><b-col>
                        <b-card-sub-title>{{review.created_at | print_date}}</b-card-sub-title>
                    </b-col></b-row>
                </b-col>
                <b-col md="6">
                    <b-row><b-col>
                        <b-form-rating v-model="review.rate" readonly inline no-border variant="warning" size="sm"></b-form-rating>
                    </b-col></b-row>
                    <b-row><b-col>
                        <b-card-text><vue-markdown>{{review.comment}}</vue-markdown></b-card-text>
                    </b-col></b-row>
                </b-col>
                <b-col md="4"></b-col>
            </b-row>
        </b-card>
    </div>
    <div v-else>Still no reviews</div>
</template>

<script>
export default {
    name: 'ReviewList',
    props: {
        bundleIdentifier: String,
    },
    data: function() {
        return {
            reviews: this.$store.getters['reviews/getBundle'](this.bundleIdentifier),
        }
    },
    computed: {
        // reviews () { return this.$store.getters['reviews/getBundle'](this.bundleIdentifier) },
    },
    methods: {
    },
    mounted: function() {
        this.$store.dispatch('reviews/loadBundle', {bundleIdentifier: this.bundleIdentifier}).then(() => {
            this.reviews = this.$store.getters['reviews/getBundle'](this.bundleIdentifier)
        })
    }
}
</script>
