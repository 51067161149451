<template>
  <div style="display: flex;">
    <div class="sidebar">
      <slot name="sidebar"/>
    </div>
    <div class="panel">
      <slot name="panel"/>
    </div>
  </div>
</template>

<script>
export default {
  name: "PanelView",
}
</script>

<style>
.sidebar {
  height: 100%;
  width:350px;
}

.panel {
  width: 100%;
}
</style>
