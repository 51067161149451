<template>
    <div>
		<b-tabs card v-if="isEditable() && editBar">
      <b-tab title="Upload File" ref="createUserTab" active >
				<b-form-select v-model="label" :options="labelOptions" style="margin-bottom:10px;" >
					<b-form-select-option value="custom">
						Custom label
					</b-form-select-option>
				</b-form-select>
				<InputText v-if="label === 'custom'" name="Custom Label Name" v-model="customLabel" required />
				<Dropzone @input="handleFiles"></Dropzone>
      </b-tab>
		</b-tabs>
		<ResourceList
			resource="files"
			:searchFilters="{bundleIdentifier: bundleIdentifier}"
			addIcon="plus"
			editEvent="edit-file-show"
			deleteEvent="delete-file-show"
			exportEvent="export-file"
			downloadEvent="download-file"
			resourceListForm="FileListForm"
			sortBy="tag"
			autoLoad
 			paginate/>
    </div>
</template>

<script>
import Config from '../../Config'
import Dropzone from '../form/Dropzone'
import InputText from '../form/InputText'

export default {
    name: "FileTab",
    props: {
      bundleIdentifier: String,
      editBar: {
        default: true,
        type: Boolean
      }
	},
	data () {
		return {
			label:'doc',
			customLabel: "",
			bundle: this.$store.getters['bundles/get'](this.bundleIdentifier),
			files: [],
			labelOptions: [
				{ value: "doc", text: 'doc - Documentation' },
				{ value: "action", text: 'action - Can be used in actions' },
        { value: "iso", text: 'iso - Operating system images for installation'},
        {
					label: 'Icons',
					options: [
						{ value: "shape-icon", text: 'shape-icon - Shapes' },
						{ value: "host-icon", text: 'host-icon - Hosts' },
						{ value: "external-host-icon", text: 'external-host-icon - External hosts' },
					]
				}
			]
		}
	},
	components: {
		Dropzone, InputText
	},
	methods: {
    isEditable() {
      return !(this.bundle.authz && (this.bundle.authz.permission & 0x04) === 0);
    },
    async handleFiles(files) {
			for (let file of files) {
				let label = this.label==="custom"? this.customLabel: this.label
				let file_data = {
          labels: [label]
        }

        try {
          await this.$store.dispatch("files/add", {
              file: file,
              bundleIdentifier: this.bundleIdentifier,
              data: file_data,
              onerror: (message) => {
                this.$eventBus.$emit('error', message)
              },
              onfinished: () => {
                this.$eventBus.$emit('success', "The file " + tag + " has been uploaded.")
              }
            }
          )
        } catch(error) {
					this.$eventBus.$emit('http-error', error)
				}
			}
		}
	}
}
</script>

