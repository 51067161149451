<template>
  <div v-if="scenario && bundle" class="scenario-container">

    <ScenarioListForm
      editEvent="edit-scenario-show"
      deleteEvent="delete-scenario-show"/>

    <b-row no-gutters class="top" >
      <b-container>
      <b-col>
        <div class="page-title">Scenario from bundle <b-link style="color: inherit" :to="{name: 'bundlePage', params: {identifier: this.bundleIdentifier, tab: 5}}">{{ bundle.name }}</b-link></div>
        <b-row style="margin-top: 10px; text-align: left;">
          <b-col cols=2 v-if="scenarioLogo != null"><b-img fluid :src="scenarioLogo" alt="Scenario Icon"></b-img></b-col>
          <b-col :cols="(scenarioLogo != null)?'10':'12'" style="padding-left: 30px;">
            <b-row class="scenario-title">
              <h2>{{scenario.name}}</h2><h6><b-badge class="lade-label">{{scenario.qualifier}}</b-badge></h6>
<!--              <font-awesome-icon :icon="scenario.favorite?['fas', 'star']:['far', 'star']" @click.stop="toggleFavorite()" :color="scenario.favorite?'orange':'gray'" size="lg" style="cursor: pointer; margin-left: 10px;"/>-->
              <font-awesome-icon :icon="['fas', 'pencil-alt']"
                                 @click.stop="$eventBus.$emit('edit-scenario-show', scenario.identifier)"
                                 style="margin-left:5px; cursor: pointer; height:20px;"
                                 v-if="isEditable"/>
              <font-awesome-icon :icon="['fas', 'trash']"
                                 @click.stop="$eventBus.$emit('delete-scenario-show', scenario.identifier)"
                                 v-if="isDeletable"
                                 style="margin-left:10px; cursor: pointer; height:20px; color:indianred;"/>
            </b-row>
            <b-row>
              <div>Last update {{ scenario.created_at | print_date }}</div>
            </b-row>
            <b-row class="tag-row"><div><B>Tag:</B> {{scenario.alias}}:{{scenario.qualifier}}</div></b-row>
            <b-row class="size-row" v-if="category !==  null"><div><B>Category:</B> {{category.name}}</div></b-row>
            <b-row class="size-row" style="padding-bottom: 2px">
              <ContentLabels resource-type="scenarios" :content="scenario" :is-editable="isEditable"></ContentLabels>
            </b-row>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="col-12">
            <b-tabs class="scenario-tabs" content-class="mt-3" v-model="tabValue">
              <b-tab title="Preview" :title-link-class="'tab-title-class'">
                <template #title>
                  <font-awesome-icon class="tab-icon" :icon="['fa', 'sitemap']"/>
                  Preview
                </template>
              </b-tab>
              <b-tab :title-link-class="'tab-title-class'">
                <template #title>
                  <font-awesome-icon class="tab-icon" :icon="['fa', 'align-left']"/>
                  Description
                </template>
              </b-tab>
            </b-tabs>
          </b-col>
        </b-row>

      </b-col>
      </b-container>
    </b-row>
    <b-row no-gutters >
      <b-col md="1"></b-col>
      <b-col md="10" cols="12">
        <div v-if="tabValue===0">
          <div>

            <div v-if="isBusy"><b-spinner></b-spinner></div>
            <div v-else>
              <b-card-img class="preview" :style="getPreviewStyle" :top=true :src="preview"
                          alt="Scenario preview"></b-card-img>
            </div>
          </div>
        </div>
        <div class="tab-content" v-if="tabValue===1">
          <div v-if="!editDescription">
            <b-button class="button edit-tab-button" @click.stop="editDescription=true">
              <font-awesome-icon :icon="['fas', 'pencil-alt']" v-if="isEditable"/></b-button>
            <vue-markdown :source="scenario.description"></vue-markdown>
          </div>
          <div v-else>
            <form ref="form" @submit.stop.prevent="updateDescription()">
              <b-form-group id="form-description" label-for="description"
                            description="Please write your scenario description following community rules.">
                <editor height="600px" id="input-description" ref="descriptionEditor" initialEditType="wysiwyg" :initialValue="this.scenario.description" />
              </b-form-group>
              <b-button type="submit" class="button" variant="primary">Submit</b-button>
              <b-button variant="secondary" class="button" @click.stop="editDescription=false"
                        style="margin-left:10px;">Cancel</b-button>
            </form>
          </div>
        </div>

      </b-col>
      <b-col md="1"></b-col>
    </b-row>
  </div>

  <div class="text-center col-12 col-xl-10"
       style="padding-top:10px; padding-bottom:10px;"
       v-else>
    <b-spinner class="align-middle"></b-spinner>
    <strong style="margin-left:10px;">Loading...</strong>
  </div>
</template>

<script>

import * as ladeWebUI from "lade-webui";
import ContentLabels from '@/components/hub/ContentLabels.vue'
import ScenarioListForm from "@/components/scenarios/ScenarioListForm";

export default {
  name: 'ScenarioContent',
  props: {
    identifier: String,
    bundleIdentifier: String,
  },
  components: {
    ScenarioListForm, ContentLabels
  },
  data: function() {
      return {
        scenario: null,
        bundle: null,
        isBusy: true,
        preview: null,
        showInputLabel: false,
        newLabel: "",
        editDescription: false,
        tabValue: 0,
        category: null,
      }
  },
  mounted: function() {
    let loadPromise = this.$store.dispatch('scenarios/load', this.identifier).then( () => {
      this.scenario = this.$store.getters['scenarios/get'](this.identifier)
      this.generateScenarioPreview()
      if (this.scenario.category_identifier) {
        this.$store.dispatch('categories/load', this.scenario.category_identifier).then( () => {
          this.category = this.$store.getters['categories/get'](this.scenario.category_identifier)
        })
      }
    })
    let loadBundlePromise = this.$store.dispatch('bundles/load', this.bundleIdentifier).then( () => {
      this.bundle = this.$store.getters['bundles/get'](this.bundleIdentifier)
    })
    Promise.all([loadPromise, loadBundlePromise]).then( () => {
      this.updateBreadcrumb()
    })

  },
  computed: {
    scenarioLogo() {
      if (this.scenario.icon) {
        if (this.scenario.icon.url) {
          return this.scenario.icon.url
        }
      }

      return this.preview
    },
    isEditable() {
      return !(this.bundle.authz && (this.bundle.authz.permission & 0x04) === 0);
    },
    isDeletable() {
      return !(this.bundle.authz && (this.bundle.authz.permission & 0x08) === 0);
    },
    getPreviewStyle() {
      return {
        backgroundImage: 'url(' + require('@/assets/background_pattern_light.png') + ')',
        "display": "block",
        "margin-left": "auto",
        "margin-right": "auto",
      }
    },
  },
  methods: {
    generateScenarioPreview() {
      ladeWebUI.getPreview(this.scenario, {'darkMode': false}).then((result) => {
        this.isBusy = false
        if (result.element.children.length > 0) {
          this.preview = result.element.children[0].src
        }
      })
    },
    updateBreadcrumb() {
      let breadcumbList = [
        { text: 'Explore', to: {name: 'bundleGrid'} },
        { text: this.bundle.name,
          to: {
            name: 'bundlePage',
            params: {
              identifier: this.bundleIdentifier,
              reload: false,
              tab: 3,
              reloadScenarios: false,
            }
          }
        },
        //{ text: 'scenario', to: {}},
        { text: this.scenario.name },
      ]
      this.$emit('breadcrumbChange', breadcumbList)
    },
    toggleFavorite() {
      if (this.scenario.favorite === true) {
        this.$store.dispatch('favorites/delete', this.identifier)
        this.scenario.favorite = false
      } else {
        this.$store.dispatch('favorites/add', this.identifier)
        this.scenario.favorite = true
      }
    },
    addLabel() {
      let labels = [...this.scenario.labels]
      labels.push(this.newLabel)
      this.$store.dispatch('scenarios/update', {
        identifier: this.scenario.identifier,
        data: {labels: labels}}).then(() => {
        this.newLabel = ""
        this.showInputLabel = false
      })
    },
    cancelLabel() {
      this.newLabel = ""
      this.showInputLabel = false
    },
    deleteLabel(label) {
      let labels =  this.scenario.labels.filter(function(ele){
        return ele !== label;
      });
      this.$store.dispatch('scenarios/update', {identifier: this.scenario.identifier,
        data: {labels: labels}})
    },
    updateDescription() {
      let description = this.$refs.descriptionEditor.invoke('getMarkdown')
      this.$store.dispatch('scenarios/update', {
        identifier: this.identifier,
        data: {description: description}}).then(() => {
        this.editDescription = false

      })
    },
  }
}
</script>

<style scoped>
.scenario-title {
  align-items: center;
}

.scenario-title h2 {
  margin-right: 10px
}

.scenario-tabs >>> .tab-title-class {
  color: var(--lade-color-dark-grey);
  font-size: 1.2em;
  border-radius: 0px;
}

.scenario-tabs {
  margin-top: 20px;
  margin-bottom: -14px;
}

.bundle-tabs >>> .tab-title-class {
  color: var(--lade-color-dark);
}

.tag-row {
  padding-top: 10px;
}

.tab-content {
  background-color: #FFFFFF;
  border: 1px solid #e0e4e7;
  border-radius: 2px;
  padding: 10px;
  min-height: 60px;
}
</style>
