<template>
  <div>
    <b-modal ref="deleteFormModal" id="deleteFormModal" hide-footer v-bind:title="title">
      <form v-on:submit.prevent="onDeleteContent">
        <p>
          Do you really want to delete {{ resource }} {{ tag }} belonging to bundle {{ bundle.tag }} ?
        </p>
        <b-button type="submit" variant="primary">Confirm</b-button>
        <b-button variant="secondary" @click.stop="$refs.deleteFormModal.hide()"
                  style="margin-left:10px;">Cancel
        </b-button>
      </form>
    </b-modal>
    <b-modal ref="moveBundleModal" id="moveBundleModal" hide-footer v-bind:title="title">
      <form v-on:submit.prevent="onMoveBundle">
        <div class="form-group row">
          <label class="col-sm-3 col-form-label" for="currentGroup">Current Group</label>
          <div class="col-sm-9">
            <TypeAhead required prefix="Bundle" id="currentGroup" name="currentGroup" resourceType="group"
                       v-model="currentGroup" class="mb-2" @hit="currentGroupIdentifier=$event" disabled/>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-sm-3 col-form-label" for="newGroup">New Group</label>
          <div class="col-sm-9">
            <TypeAhead required prefix="Bundle" id="newGroup" name="newGroup" resourceType="group" v-model="newGroup"
                       class="mb-2" @hit="newGroupIdentifier=$event"/>
          </div>
        </div>
        <b-button type="submit" variant="primary">Confirm</b-button>
        <b-button variant="secondary" @click.stop="$refs.moveBundleModal.hide()"
                  style="margin-left:10px;">Cancel
        </b-button>
      </form>
    </b-modal>
  </div>
</template>


<script>
import store from '../../store'
import TypeAhead from '../form/TypeAhead'

export default {
  name: 'BundleEdit',
  components: {
    TypeAhead
  },
  created() {

    this.$eventBus.$off('move-bundle-show')
    this.$eventBus.$on('move-bundle-show', (bundleIdentifier) => {
      this.reset("Move bundle")
      this.bundleIdentifier = bundleIdentifier
      this.bundle = store.getters['bundles/get'](this.bundleIdentifier)
      this.currentGroup = this.bundle.group
      this.currentGroupIdentifier = this.bundle.group_identifier
      this.$refs.moveBundleModal.show()
    })

    this.$eventBus.$on('delete-topology-in-bundle-show', (bundleIdentifier, topologyIdentifier) => {
      this.reset("Remove topology")
      this.resource = 'topology'
      let topology = store.getters['bundles/getTopology'](bundleIdentifier, topologyIdentifier)
      this.bundleIdentifier = bundleIdentifier
      this.bundle = store.getters['bundles/get'](this.bundleIdentifier)
      this.resourceIdentifier = topologyIdentifier
      this.tag = topology.tag
      this.$refs.deleteFormModal.show()
    })

    this.$eventBus.$on('delete-action-in-bundle-show', (bundleIdentifier, actionIdentifier) => {
      this.reset("Remove action")
      this.resource = 'action'
      let action = store.getters['bundles/getAction'](bundleIdentifier, actionIdentifier)
      this.bundleIdentifier = bundleIdentifier
      this.bundle = store.getters['bundles/get'](this.bundleIdentifier)
      this.resourceIdentifier = actionIdentifier
      this.tag = action.tag
      this.$refs.deleteFormModal.show()
    })

    this.$eventBus.$on('delete-scenario-in-bundle-show', (bundleIdentifier, scenarioIdentifier) => {
      this.reset("Remove scenario")
      this.resource = 'scenario'
      let scenario = store.getters['bundles/getScenario'](bundleIdentifier, scenarioIdentifier)
      this.bundleIdentifier = bundleIdentifier
      this.bundle = store.getters['bundles/get'](this.bundleIdentifier)
      this.resourceIdentifier = scenarioIdentifier
      this.tag = scenario.tag
      this.$refs.deleteFormModal.show()
    })

    this.$eventBus.$on('delete-file-in-bundle-show', (bundleIdentifier, fileIdentifier) => {
      this.reset("Remove file")
      this.resource = 'file'
      let file = store.getters['bundles/getFile'](bundleIdentifier, fileIdentifier)
      this.bundleIdentifier = bundleIdentifier
      this.bundle = store.getters['bundles/get'](this.bundleIdentifier)
      this.resourceIdentifier = fileIdentifier
      this.tag = file.tag
      this.$refs.deleteFormModal.show()
    })

  },
  data() {
    return {
      title: "",
      resources: [],
      tag: "",
      bundle: {},
      currentGroup: null,
      currentGroupIdentifier: "",
      newGroup: null,
      newGroupIdentifier: "",
      resource: "",
      resourceIdentifier: "",
      licence: ""
    }
  },
  methods: {
    reset(title) {
      this.title = title
      this.resources = []
      this.tag = ""
      this.currentGroup = null
      this.currentGroupIdentifier = ""
      this.newGroup = null
      this.newGroupIdentifier = ""
      this.bundle = {}
      this.resource = ""
      this.resourceIdentifier = ""
      this.licence = ""
    },
    onMoveBundle() {
      if (this.currentGroupIdentifier === this.newGroupIdentifier) {
        this.$eventBus.$emit("error", "Cannot move in the same groups")
        return
      }
      store.dispatch('bundles/moveGroup', {
        bundle_identifier: this.bundleIdentifier,
        new_group_identifier: this.newGroupIdentifier
      }).then(() => {
        this.$refs.moveBundleModal.hide()
      }).catch((response) => {
        this.$eventBus.$emit('http-error', response)
      })

    },
    onDeleteContent() {
      switch (this.resource) {
        case 'topology':
          store.dispatch('bundles/deleteTopology', {
            bundle_identifier: this.bundleIdentifier,
            identifier: this.resourceIdentifier
          }).then(() => {
            this.$refs.deleteFormModal.hide()
          }).catch((response) => {
            this.$eventBus.$emit('http-error', response)
          })
          break;
        case 'action':
          store.dispatch('bundles/deleteAction', {
            bundle_identifier: this.bundleIdentifier,
            identifier: this.resourceIdentifier
          }).then(() => {
            this.$refs.deleteFormModal.hide()
          }).catch((response) => {
            this.$eventBus.$emit('http-error', response)
          })
          break;
        case 'scenario':
          store.dispatch('bundles/deleteScenario', {
            bundle_identifier: this.bundleIdentifier,
            identifier: this.resourceIdentifier
          }).then(() => {
            this.$refs.deleteFormModal.hide()
          }).catch((response) => {
            this.$eventBus.$emit('http-error', response)
          })
          break;
        case 'file':
          store.dispatch('bundles/deleteFile', {
            bundle_identifier: this.bundleIdentifier,
            identifier: this.resourceIdentifier
          }).then(() => {
            this.$refs.deleteFormModal.hide()
          }).catch((response) => {
            this.$eventBus.$emit('http-error', response)
          })
          break;
      }
    }
  }
}

</script>

<style scoped>

</style>
