
function getPageFromNotification(notification)
{
  let resource = notification.resource
  console.log(notification)

  if (resource === null || resource === undefined) {
    console.log("no page associated")
    return {}
  }

  switch (resource.resource_type) {
    case 'QUESTION':
      return {name: 'questionPage', params: {identifier: resource.identifier,
          bundleIdentifier: resource.bundle_identifier}}
    case 'BUNDLE':
      return {name: 'bundlePage', params: {identifier: resource.identifier}}
    case 'GROUP':
      return {name: 'groupBundleGrid', params: {identifier: resource.identifier}}
    case 'USER':
      return {name: 'profile'}
    case 'WORKZONE':
      console.log("Go Workzone")
      return {}
  }
  console.log("No redirection to resource " + resource.resource_type)
  return {}


}

export {
  getPageFromNotification
}
