import {print_human_readable_size} from '@/filters'

const state = {
  list: [],
  _lookup: {},
  fields: [
    {
      key: 'status',
      sortable: true,
    },
    {
      "key": "description",
      sortable: true,

    },
    {
      "key": "message",
      sortable: true,
    },
    {
      "key": "uploaded",
      "formatter": print_human_readable_size,
      "sortable": true
    }
  ],
  filters: {
    isEditable: (entry) => false,
    isExportable: (entry) => false,
    isDeletable: (entry) => false,
    isDownloadable: (entry) => false
  }
}

const getters = {
  getAll: (state) => () =>
    state.list,
  getFields: (state) => () =>
    state.fields,
  getFilters: (state) => () =>
    state.filters
}

const actions = {
  clean({dispatch, commit}) {
    let promises = []
    for (let upload of state.list) {
      if (upload.status === "running")
        promises.push(dispatch("cancel", upload.identifier))
    }
    return Promise.all(promises)
  },
  cancel({dispatch, commit}, identifier) {
    var promises = [
      state._lookup[identifier].request.abort(),
      state._lookup[identifier].onerror()
    ]
    return Promise.all(promises).then(() => {
      commit('setStatus', {identifier, status: "cancelled"})
    })
  },
  add({dispatch, commit}, {identifier, description, data, url, onerror, onfinished}) {
    var request = new XMLHttpRequest();

    request.open('POST', url, true)
    request.addEventListener("progress", function (e) {
      let uploaded = e.position || e.loaded, total = e.totalSize || e.total;
      let progress = Math.floor(uploaded / total * 1000) / 10
      commit('progress', {identifier, progress, uploaded})
    }, false);

    request.addEventListener("onerror", function (e) {
      let message = "Unknown error: please check the logs."
      commit('setStatus', {identifier, status: "failed", message})
      onerror(message)
    }, false)

    request.addEventListener("load", function (e) {
      commit('progress', {identifier, progress: 100, uploaded: e.total})
      if (this.status >= 300) {
        let message = "An error occurred with file upload."
        try {
          message = JSON.parse(this.responseText).message
        } catch (error) {
          if (this.status === 413) {
            message = "File entity is too large"
          }
        }

        commit('setStatus', {identifier, status: "failed", message})
        state._lookup[identifier].onerror(message)
      } else {
        commit('setStatus', {identifier, status: "succeeded", message: "Upload Finished."})
        state._lookup[identifier].onfinished()
      }
    }, false)

    request.send(data)

    commit('add', {
      identifier,
      data: {
        url,
        identifier,
        description,
        message: "",
        progress: 0,
        total: data.size,
        uploaded: 0,
        status: "running",
        onerror: onerror,
        onfinished: onfinished,
        request
      }
    })
  }
}

const mutations = {
  add(state, {identifier, data}) {
    state.list.push(data)
    state._lookup[identifier] = data
  },
  progress(state, {identifier, progress, uploaded}) {
    state._lookup[identifier].progress = progress
    state._lookup[identifier].uploaded = uploaded
  },
  setStatus(state, {identifier, status, message = null}) {
    state._lookup[identifier].status = status
    if (message != null) {
      state._lookup[identifier].message = message
    }
  },
  delete(state, identifier) {
    let value = state._lookup[identifier]
    state.list.delete(value)
    delete state._lookup[identifier]
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true
}






