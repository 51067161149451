<template>
  <b-card no-body style="margin: 20px;">
    <b-card-header>
      <b-row no-gutters>
        <b-col md="6">Registries</b-col>
        <b-col md="6" class="text-right">
          <b-button @click.stop="onAdd()">New registry</b-button>
          <RegistryApplicationForm ref="registryApplicationForm" title="New registry"></RegistryApplicationForm>
        </b-col>
      </b-row>
    </b-card-header>
    <b-card-body>

      Registry added to your profile cannot be shared to others users. You may used your personal account to connect to the distant hub or registry.<br/>
      To be able to pull or push content from CyberRange registry a personal access token will be saved to authenticate you to the registry. You can disable/revoke this token from the registry interface directly. <br/>
      All CyberRange can be used as a Registry.

    </b-card-body>
    <b-card-body>

      <b-table hover :items="registries" :fields="fields" :busy="loading">

        <template v-slot:table-busy>
          <div class="text-center">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>

        <!-- <template v-slot:cell(name)="row">
            <b-form-input :ref="nameCellRef(row)"
                :value="row.item.name"
                @change="onNameChange(row)"
                placeholder="Enter a name"
                autocomplete="off" :required="true"
            ></b-form-input>
        </template> -->
        <template v-slot:cell(name)="row">
          <b-spinner v-if="isCellUpdating(row)" class="align-middle"></b-spinner>
          <input v-else :ref="nameCellRef(row)" type="text"
                 :value="row.item.name"
                 @change="onNameChange(row)"
                 placeholder="Enter a name"
                 autocomplete="off" required
                 class="form-control"/>
        </template>

        <template v-slot:cell(created_at)="row">{{ new Date(row.item.created_at).toLocaleString() }}</template>

        <template v-slot:cell(expiration)="row">{{ new Date(row.item.expiration).toLocaleString() }}</template>

        <template v-slot:cell(status)="row">
          <b-form-checkbox :checked="isEnabled(row.item.status)" @change="onStatusChange(row.item)" name="status-button"
                           switch></b-form-checkbox>
        </template>

        <template v-slot:cell(edit)="row">
          <b-button variant="danger" size="sm" @click="onDelete(row.item)" class="mr-2">Revoke</b-button>
        </template>

      </b-table>
    </b-card-body>
  </b-card>
</template>

<script>
import RegistryApplicationForm from './RegistryApplicationForm.vue'

export default {
  name: 'RegistryList',
  components: {
    RegistryApplicationForm
  },
  data() {
    return {
      loading: false,
      fields: ['name', {key: 'created_at', label: 'Created'}, {key: 'data.url', label: 'Url'}, {
        key: 'data.insecure',
        label: 'Insecure'
      }, {key: 'edit', label: ''}],
      updatingApps: [],
    }
  },
  computed: {
    registries() {
      // Best to sort the list as it's not always returned by server in the same order, which is an issue when we update the list
      const registries = this.$store.getters['userApps/getAll']()
      return registries.slice().sort((a, b) => new Date(a.created_at) - new Date(b.created_at))
    },
  },
  mounted: function () {
    this.$eventBus.$on('new-registry', (response) => {
      this.$eventBus.$emit('http-success', response)
    })
    this.$eventBus.$on('new-user-app-error', (response) => {
      this.$eventBus.$emit('http-error', response)
    })

    this.$store.dispatch('userApps/loadAll')
  },
  methods: {
    nameCellRef(row) {
      return 'input_name_' + row.index
    },
    isCellUpdating(row) {
      return this.updatingApps.includes(row.item.identifier)
    },
    setCellUpdating(row, isUpdating) {
      if (isUpdating) {
        this.updatingApps.push(row.item.identifier)
      } else {
        this.updatingApps.splice(this.updatingApps.indexOf(row.item.identifier), 1)
      }
    },
    onAdd() {
      this.$refs['registryApplicationForm'].openModal()
    },
    onDelete(app) {
      this.$bvModal.msgBoxConfirm('Are you sure you want to delete this application ? This action cannot be undone.', {
        title: 'Delete registry app',
        okVariant: 'danger',
        hideHeaderClose: false,
        centered: true
      })
        .then(value => {
          if (value) {
            this.$store.dispatch('userApps/delete', app.identifier)
          }
        })
    },
    onNameChange(row) {
      let identifier = row.item.identifier
      let name = this.$refs[this.nameCellRef(row)].value
      // console.log('change name:')
      // console.log(row)
      // console.log(identifier)
      // console.log(name)
      if (name) {
        this.setCellUpdating(row, true)
        this.$store.dispatch('userApps/update', {identifier, data: {"name": name}}).then(() => {
          this.setCellUpdating(row, false)
        }).catch(() => {
          this.setCellUpdating(row, false)
        })
      }
    },
  },
}
</script>

<style scoped>
.command {
  background: var(--lade-color-dark-grey);
  color: var(--lade-color-white-hover);
  display: inline;
  font-size: small;
  margin: 10px;
  padding: 5px 10px 5px 0px;
  box-shadow: -16px 0 0 var(--lade-color-dark-grey);
}
</style>
