<template>
  <div>
    <b-row>

      <b-col md="2">

        <div class="filterpanel">
          <div class="filterSection">
            <div class="filterSectionHeader">
              <font-awesome-icon :icon="['fa', 'cubes']"/>
              SCOPE
            </div>
            <b-form-group>
              <b-form-radio-group stacked @change="scope=$event" v-model="scope">
                <b-form-radio name="scope" value="all">
                  All
                </b-form-radio>
                <b-form-radio name="scope" value="group">
                  <TypeAhead ref="groupSelector"
                             resourceType="group"
                             v-model="group"
                             @hit="groupIdentifier=$event"/>
                </b-form-radio>
                <b-form-radio name="scope" value="workzone">
                  <TypeAhead ref="workzoneSelector"
                             resourceType="workzone"
                             v-model="workzone"
                             @hit="workzoneIdentifier=$event"/>
                </b-form-radio>
                <b-form-radio name="scope" value="bundle">
                  <TypeAhead ref="bundleSelector"
                             resourceType="bundle"
                             v-model="bundle"
                             @hit="bundleIdentifier=$event"/>
                </b-form-radio>
              </b-form-radio-group>
            </b-form-group>
          </div>

          <div class="filterSection">
            <div class="filterSectionHeader">
              <font-awesome-icon style="margin-right: 5px" :icon="['fa', 'exclamation']"/>
              Level
            </div>
            <b-form-group>
              <b-form-radio-group stacked @change="level=$event" v-model="level">
                <b-form-radio name="level" value="high">
                  high
                </b-form-radio>
                <b-form-radio name="level" value="medium">
                  medium
                </b-form-radio>
                <b-form-radio name="level" value="low" default>
                  low
                </b-form-radio>
              </b-form-radio-group>
            </b-form-group>
          </div>

          <div class="filterSection">
            <div class="filterSectionHeader">
  <!--            <font-awesome-icon :icon="['fa', 'engine-warning']"/>-->
              Others
            </div>
            <b-form-group>
              <b-form-checkbox name="exclude-ack" v-model="excludeAcknowledged" default>
                exclude acknowledged
              </b-form-checkbox>
            </b-form-group>
          </div>

          <b-button variant="primary" @click="getNotifications" style="margin:20px">
            Apply Filter
          </b-button>

        </div>

      </b-col>
      <b-col md="8">


        <div class="text-center"
             style="padding-top:10px; padding-bottom:10px;"
             v-if="busy">
          <b-spinner class="align-middle"></b-spinner>
          <strong style="margin-left:10px;">Loading...</strong>
        </div>
        <div v-else class="notification-list">
          <div v-if="pagination.total > limit" class="pagination" >
            <div class="pagination-total-element">Total elements: {{ pagination.total }}</div>
            <b-pagination class="pagination-item" v-model="page" :total-rows="pagination.total" @input="getNotifications"
                          :per-page="limit" first-number last-number/>
            <b-select :options="limitOptions" v-model="limit"
                      class="pagination-limit-select" @change="getNotifications"/>
          </div>

          <ul>
            <li  v-for="notification in notifications" :key="notification.identifier" :style="notification.acknowledged?{}:{'background-color': '#e4e4e7'}">
              <div class="notification-block">

                <div class="notification" @click="goNotificationResource(notification)">
                  <div class="notification-message">
                    <span v-if="notification.level === 'high'"><font-awesome-icon style="color: red" class='fa-icon' :icon="['fas', 'exclamation-triangle']"/></span>
                    <span v-if="notification.level === 'medium'"><font-awesome-icon style="color: orange" class='fa-icon' :icon="['fas', 'exclamation-triangle']"/></span>
                    <span> {{ notification.message }} </span>
                  </div>
                  <div class="notification-date"> {{ notification.created_at | print_date }} </div>
                </div>
                <div v-if="!notification.acknowledged" class="notification-acknowledge">
                  <b-button @click="acknowledge(notification)"><font-awesome-icon class='fa-icon ack-icon' :icon="['fas', 'check-circle']"/></b-button>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>

import TypeAhead from '../../components/form/TypeAhead.vue'
import {getPageFromNotification} from "@/components/notifications/notification";

export default {
  name: 'NotificationList',
  components: {
    TypeAhead
  },
  props: {
  },
  data() {
    return {
      busy: false,
      // filters
      workzone: "",
      workzoneIdentifier: null,
      group: "",
      groupIdentifier: null,
      bundle: "",
      bundleIdentifier: null,
      scope: "all",
      level: null,
      notifications: this.$store.getters['notifications/getAll'](),
      excludeAcknowledged: false,
      //pagination
      pagination: this.$store.getters['notifications/getPagination'](),
      page: 1,
      limit: 25,
      limitOptions: [
        {value: 25, text: "25 elements per page"},
        {value: 50, text: "50 elements per page"},
        {value: 100, text: "100 elements per page"},
      ],
    }
  },
  mounted: function () {
    this.getNotifications()
  },
  computed: {
  },
  methods: {
    getNotifications() {
      this.busy = true
      if (this.excludeAcknowledged) {
        this.$store.commit('notifications/excludeAcknowledged')
      }
      else {
        this.$store.commit('notifications/includeAcknowledged')
      }

      if (this.level !== null) {
        this.$store.commit('notifications/setLevel', this.level)
      }

      let loadPromise = null
      if (this.scope === 'group') {
        loadPromise = this.$store.dispatch('notifications/loadGroup', {
          groupIdentifier: this.groupIdentifier,
          limit: this.limit
        })
      }
      else if (this.scope === 'workzone') {
        loadPromise = this.$store.dispatch('notifications/loadWorkzone', {
          workzoneIdentifier: this.workzoneIdentifier,
          limit: this.limit,
          page: this.page
        })
      }
      else if (this.scope === 'bundle') {
        loadPromise = this.$store.dispatch('notifications/loadBundle', {
          bundleIdentifier: this.bundleIdentifier,
          limit: this.limit,
          page: this.page
        })
      }
      else {
        loadPromise = this.$store.dispatch('notifications/loadAll', {
          limit: this.limit,
          page: this.page
        })
      }
      if (loadPromise !== null) {
        loadPromise.then(() =>  {
          this.busy = false
        })
      }
    },
    acknowledge(notification) {
      let identifier = notification.identifier
      this.$store.dispatch('notifications/acknowledge', identifier).then(() => {
        this.getNotifications()
      })
    },
    goNotificationResource(notification) {
      let page = getPageFromNotification(notification)
      if ('name' in page) {
        this.ShowPopup = false
        this.onShowPopupChanged()
        this.acknowledge(notification)
        this.$router.push(page)
      }
    },
    onShowPopupChanged () {
      this.$emit('showPopupChanged', this.showPopup)
    },
    onShowPropChanged(value) {
      this.ShowPopup = value
    },
  },
  watch: {
    'showPopup' : {
      handler: 'onShowPopupChanged'
    },
    'show': {
      handler: 'onShowPropChanged',
      immediate: true,
    }
  }
}

</script>

<!-- Absolute position to right side between top and footer bars. -->
<style scoped>

.notification-list {
  background-color: var(--lade-color-white-hover);
}

.notification-list ul {
  padding: 10px;
}

.notification-list li {
  list-style-type: none;
  border-bottom: 2px solid var(--lade-color-grey);
  cursor: pointer;
}

.notification-list  li:hover {
  background-color: var(--lade-color-white) !important;
}

.notification-block {
  padding: 10px 10px 10px 10px;
  display: flex;
}

.notification {
  width: 380px;
  color: var(--lade-color-dark-grey);
}

.notification-acknowledge {
  position: relative;
  color: var(--lade-color-white-hover);
  text-align: center;
  margin-left: auto;
}

.notification-message {
  font-size: 1.2rem;
}

.notification-date {
  font-size: 0.8rem;
  line-height: 0.8rem;
  margin-top: 2px;
  margin-left: 10px;
}

.notification-acknowledge button {
  height: 30px;
  width: 34px;
  color: var(--lade-color-dark-grey);
  background-color: var(--lade-color-white);
}

.notification-acknowledge button:hover {
  background-color: var(--lade-green-explore-1);
}

.ack-icon {
  margin: 0;
}

.pagination {
  padding: 10px 20px;
  display: flex;
  align-items: center;
}

.pagination-total-element {
  margin-right: auto;
}

.pagination-limit-select {
  margin-left: auto;
  width: 200px;
}

</style>
