import Vue from 'vue'
import Config from '../../Config'


const state = {
  infos: null,
  expires_time: null,
  hasUser: false,
  hasGroup: false,
  hasRole: false,
  hasBundle: false,
  hasTopology: false,
  hasAction: false,
  hasScenario: false,
  hasFile: false,
  hasWorkzone: false,
  hasWorkflow: false,
  hasBooking: false,
  hasConfig: false,
  hasLicense: false,
  resources: [],
  specs: {
    scopes: [],
    resources: [],
    permissions: {}
  },
}

const getters = {
  getSpecs: (state) => () =>
    state.specs,
  getPermission: (state, getters) => (resourceType, permissionSTR) => {
    for (let permission of state.specs.permissions[resourceType]){
      if (permission.name === permissionSTR) {
        return permission.value
      }
    }
    return -1
  },
  hasPermission: (state, getters) => (resourceType, permissionSTR) => {

    // Current permission not here (no access)
    if (state.resources[resourceType] === undefined)
      return false

    // Specs for this ressource specs has not been loaded
    if (state.specs.permissions[resourceType] === undefined)
      return false

    for (let permission of state.specs.permissions[resourceType]){
      if (permission.name === permissionSTR) {
        return ((state.resources[resourceType] & permission.value ) === permission.value)
      }
    }
    return false
  },
  isValid: (state) => () => {
    return !(state.infos == null || state.infos.expires_in == null)
  },
  isReset: (state) => () => {
    return state.infos.data.reset
  },
  isEula: (state) => () => {
    return state.infos.data.eula
  },
  getInfos: (state) => () => {
    return state.infos
  },
  hasUser: (state) => () => {
    return state.hasUser
  },
  hasGroup: (state) => () => {
    return state.hasGroup
  },
  hasRole: (state) => () => {
    return state.hasRole
  },
  hasBundle: (state) => () => {
    return state.hasBundle
  },
  hasTopology: (state) => () => {
    return state.hasTopology
  },
  hasAction: (state) => () => {
    return state.hasAction
  },
  hasScenario: (state) => () => {
    return state.hasScenario
  },
  hasWorkzone: (state) => () => {
    return state.hasWorkzone
  },
  hasWorkflow: (state) => () => {
    return state.hasWorkflow
  },
  hasFile: (state) => () => {
    return state.hasFile
  },
  hasConfig: (state) => () => {
    return state.hasConfig
  },
  hasLicense: (state) => () => {
    return state.hasLicense
  },
  expires_in: (state) => () => {
    if (state.expires_time === null) {
      return 0
    }
    return state.expires_time - Date.now()
  }
}

const actions = {
  issue({dispatch, commit}, data) {
    return Vue.http.post(Config.API_TOKEN_URL + '/issue', data).then((response) => {
      return dispatch('getTokenInfos')
    })
  },
  renew({dispatch, commit}, data) {
    return Vue.http.post(Config.API_TOKEN_URL + '/renew', data).then((response) => {
      return dispatch('getTokenInfos')
    })
  },
  logout({dispatch, commit}) {
    return Vue.http.post(Config.API_SESSIONS_URL + '/me/logout').then((response) => {
      commit('logout')
    })
  },
  loadSpecs({dispatch, commit}) {
    return Vue.http.get(Config.API_RULES_SPECS_URL).then((response) => {
      let specs = response.data
      return commit('setSpecs', specs)
    })
  },
  getTokenInfos({dispatch, commit}) {
    return Vue.http.get(Config.API_TOKEN_URL).then((response) => {
      let infos = response.data
      commit('setInfos', infos)

      // Load rule specs after token info to be able to interpret permission levels
      return dispatch("loadSpecs")
    })
  },
  resetpassword({dispatch, commit}, {data}) {
    return Vue.http.post(Config.API_RESET_URL, data)
  },
  acceptEULA({dispatch, commit}) {
    return Vue.http.put(Config.API_ACCEPT_EULA_URL)
  },
}

const mutations = {
  setInfos(state, infos) {
    state.infos = infos
    state.expires_time = Date.now() + (infos.expires_in * 1000)
    for (let resource of infos.resources) {
      Vue.set(state.resources, resource['resource'], resource['permission'])
      switch (resource['resource']) {
        case "USER":
          state.hasUser = true;
          break;
        case "GROUP":
          state.hasGroup = true;
          break;
        case "ROLE":
          state.hasRole = true;
          break;
        case "WORKZONE":
          state.hasWorkzone = true;
          break;
        case "BUNDLE":
          state.hasBundle = true;
          break;
        case "TOPOLOGY":
          state.hasTopology = true;
          break;
        case "ACTION":
          state.hasAction = true;
          break;
        case "SCENARIO":
          state.hasScenario = true;
          break;
        case "WORKFLOW":
          state.hasWorkflow = true;
          break;
        case "FILE":
          state.hasFile = true;
          break;
        case "BOOKING":
          state.hasBooking = true;
          break;
        case "CONFIG":
          state.hasConfig = true;
          break;
        case "LICENSE":
          state.hasLicense = true;
          break;
        default:
          break;
      }
    }
  },
  logout(state) {
    state.infos = null
    state.timestamp = null
    state.expires_time = null
  },
  setSpecs(state, specs) {
    for (let key in specs) {
      if(specs.hasOwnProperty(key))
        Vue.set(state.specs, key, specs[key])
    }
  }
}


export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true
}
