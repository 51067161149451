import Vue from 'vue'
import Config from '../../Config'
import {field_actions} from './fields'
import {print_date} from '@/filters'
import eventBus from './eventBus'

let LOAD_LIMIT = 40
let WORFKlOW_LOAD_LIMIT = 50

const state = {
  list: [],
  loadStatus: false,
  workflow_events: {},
  workflow_identifiers: {},
  workflow_events_load: {},
  isStreaming: false,
  fields: [
    {
      key: 'type',
      label: 'Type',
      sortable: true
    },
    {
      key: 'user',
      label: 'User',
      sortable: true
    },
    {
      key: 'date',
      label: 'Date',
      sortable: true,
      formatter: print_date,
    },
    {
      key: "group",
      label: 'Group',
      sortable: true
    },
    {
      key: "workzone",
      label: 'Workzone',
      sortable: true
    },
    {
      key: "workflow",
      label: 'Workflow',
      sortable: true
    },
    {
      key: 'message',
      label: 'Message',
      sortable: true
    },
    field_actions
  ],
  filters: {
    isEditable: (entry) => false,
    isExportable: (entry) => false,
    isDeletable: (entry) => false,
    isDownloadable: (entry) => false
  }
}

const getters = {
  getAll: (state) => () =>
    state.list,
  getFields: (state) => () =>
    state.fields,
  getFilters: (state) => () =>
    state.filters,
  getWorkflow: (state) => function (identifier) {
    if (state.workflow_events[identifier] === undefined)
      state.workflow_events[identifier] = []
    return state.workflow_events[identifier]
  },
  canLoad: (state) => () =>
    state.canLoad,
  canLoadWorkflow: (state) => function (identifier) {
    if (state.workflow_events_load[identifier] === undefined)
      state.workflow_events_load[identifier] = false
    return state.workflow_events_load[identifier]
  }
}

const actions = {
  loadAll({dispatch, commit}, {parameters = {}, append = false}) {
    if (append) {
      Object.assign(parameters, state.parameters)
      parameters["skip"] = state.list.length
    } else {
      commit('setParameters', Object.assign({}, parameters))
    }

    //parameters.meta = true
    return Vue.http.get(Config.API_EVENTS_URL + "?load=meta&limit=" + LOAD_LIMIT, {params: parameters}).then((response) => {
      let events = response.data
      commit('setAll', {events, append})

      if (!state.isStreaming) {

        eventBus.$on("event", (data) => {
          commit('set', data)
        })
        eventBus.stream()
        commit('setStream', true)
      }

      commit('setLoad', events.length === LOAD_LIMIT)
    })
  },
  loadWorkflow({dispatch, commit}, {identifier, workflow, parameters = {}, append = false, global = false}) {
    var url = ""

    if (append)
      parameters["skip"] = state.workflow_events[identifier].length

    if (workflow.workzone_identifier != null && !global)
      url = Config.API_WORKZONES_URL + "/" + workflow.workzone_identifier + "/workflows/" + identifier + "/events"
    else if (workflow.group_identifier != null && !global)
      url = Config.API_GROUPS_URL + "/" + workflow.group_identifier + "/workflows/" + identifier + "/events"
    else
      url = Config.API_WORKFLOWS_URL + "/" + identifier + "/events"


    return Vue.http.get(url + "?load=meta&level=trace&limit=" + WORFKlOW_LOAD_LIMIT, {params: parameters}).then((response) => {
      let events = response.data

      var canLoad = events.length === WORFKlOW_LOAD_LIMIT
      commit('setWorkflowLoad', {identifier, value: canLoad})

      commit('setWorkflow', {identifier: identifier, events, append})

      // If finished loading and workflow is running
      if (!canLoad && workflow.status === "running") {
        eventBus.$off("workflow-event-" + identifier)
        eventBus.$on("workflow-event-" + identifier, function (data) {
          if (!state.workflow_events_load[data.workflow])
            commit('setWorkflowEvent', {identifier: data.workflow, event: data})
        })
        eventBus.streamWorkflow(identifier)
      }
    }, response => {
      if (!global)
        dispatch("loadWorkflow", {identifier, workflow, parameters, append, global: true})
    })
  }
}

const mutations = {
  set(state, event) {
    state.list.push(event)
  },
  setAll(state, {events, append}) {
    if (!append)
      state.list.splice(0, state.list.length)
    for (let event of events)
      state.list.push(event)
  },
  setParameters(state, parameters) {
    state.parameters = parameters
  },
  setWorkflow(state, {identifier, events, append}) {
    if (!append) {
      if (state.workflow_events[identifier] !== undefined)
        state.workflow_events[identifier].splice(0, state.workflow_events[identifier].length)
      else
        state.workflow_events[identifier] = []
    }
    for (let event of events)
      state.workflow_events[identifier].unshift(event)
  },
  setWorkflowEvent(state, {identifier, event}) {
    if (state.workflow_events[identifier] !== undefined)
      state.workflow_events[identifier].push(event)
  },
  setStream(state, value) {
    state.isStreaming = value
  },
  setLoad(state, value) {
    state.canLoad = value
  },
  setWorkflowLoad(state, {identifier, value}) {
    state.workflow_events_load[identifier] = value
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true
}
