<template>
  <b-checkbox ref="el" type="text" v-model="result" @input="updateValue()"
              :id="fieldId" :aria-describedby="helpId" :disabled="disabled" switch class="m-2">
    {{ name }} <br/>
    <small :id="helpId" class="form-text text-muted">{{ help }}</small>
  </b-checkbox>
</template>

<script>
import {capitalize} from "@/filters"

export default {
  name: "InputCheckbox",
  props: {
    value: Boolean,
    prefix: String,
    name: String,
    disabled: Boolean,
    pattern: String,
    help: String,
    default: Boolean
  },
  data() {
    return {
      fieldId: this.prefix + capitalize(this.name),
      helpId: this.prefix + capitalize(this.name) + "Help",
      result: false
    }
  },
  mounted() {
    this.result = this.value || this.default
  },
  methods: {
    updateValue() {
      this.$emit('input', this.result)
    },
  }
}
</script>
