import Vue from 'vue'
import Config from '../../Config'
import {downloadFile, sanitizeTag} from '@/common'
import {field_tag, field_created_at, field_actions} from './fields'
import {setDictValue, build_resource_list_params} from '@/common'

const state = {
  list: [],
  pagination: {
    page: 1,
    total_pages: 0,
    total: 0,
  },
  filteredList: [],
  _lookup: {},
  fields: [
    {"key": "name", "sortable": true},
    field_tag,
    field_created_at,
    field_actions
  ],
  filters: {
    isEditable: (entry) => true,
    isExportable: (entry) => true,
    isDeletable: (entry) => true,
    isDownloadable: (entry) => false
  }
}

const getters = {
  get: (state) => (identifier) =>
    state._lookup[identifier],
  getBundle: (state) => (bundleIdentifier) =>
    state.list,
  getAll: (state) => () =>
    state.list,
  getFilters: (state) => () =>
    state.filters,
  getFields: (state) => () =>
    state.fields,
  getPagination: (state) => () =>
    state.pagination,
}

const actions = {
  loadAll({dispatch, commit}, {filters = {}, ...resource_list_params}) {
    let params = build_resource_list_params(resource_list_params)

    let url
    if (filters.bundleIdentifier !== undefined)
      url = Config.API_BUNDLES_URL + "/" + filters.bundleIdentifier + "/actions"
    else
      url = Config.API_ACTIONS_URL
    return Vue.http.get(url, {params}).then((response) => {
      let actions = response.data
      commit('setAll', actions)
    })
  },
  load({dispatch, commit}, identifier) {
    return Vue.http.get(Config.API_ACTIONS_URL + '/' + identifier).then((response) => {
      let action = response.data
      commit('set', {identifier, action})
    })
  },
  add({dispatch, commit}, {bundleIdentifier, data}) {
    return Vue.http.post(Config.API_BUNDLES_URL + "/" + bundleIdentifier + "/actions", data).then((response) => {
      let action = response.data
      return dispatch('load', action.identifier)
    })
  },
  update({dispatch, commit}, {identifier, data}) {
    let action = state._lookup[identifier]
    return Vue.http.put(Config.API_BUNDLES_URL + "/" + action.bundle_identifier + "/actions/" + identifier, data).then((response) => {
      return dispatch('load', identifier)
    })
  },
  delete({dispatch, commit}, identifier) {
    let action = state._lookup[identifier]
    return Vue.http.delete(Config.API_BUNDLES_URL + "/" + action.bundle_identifier + "/actions/" + action.identifier).then((response) => {
      return commit('delete', identifier)
    })
  },
  export({state, dispatch, commit}, {bundleIdentifier, identifier}) {
    let action = state._lookup[identifier]
    return Vue.http.get(Config.API_BUNDLES_URL + "/" + bundleIdentifier + "/actions/" + identifier + '/download', {responseType: 'blob'}).then((response) => {
      downloadFile(response, sanitizeTag(action.tag))
    })
  },
  filter({dispatch, commit}, search) {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
    this.timer = setTimeout(() => {
      commit('filter', search)
    }, 600);
  },
  search({dispatch, commit}, {search, filters = {}}) {
    return dispatch('search/action', {search, filters}, {root: true}).then((resources) => {
      commit('setAll', resources)
    })
  }
}


const mutations = {
  delete(state, identifier) {
    Vue.delete(state.list, state.list.indexOf(state.list.find(item => item.identifier === identifier)))
  },
  setAll(state, actions) {
    state._lookup = {}
    state.pagination.page = actions.page
    state.pagination.total_pages = actions.total_pages
    state.pagination.total = actions.total
    state.list.splice(0, state.list.length)

    for (let action of actions.data)
      state.list.push(action)

    for (let action of actions.data)
      state._lookup[action.identifier] = action
  },
  set(state, {identifier, action}) {
    if (state._lookup[action.identifier] === undefined)
      state.list.push(action)
    setDictValue(state._lookup, action.identifier, action)
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true
}
