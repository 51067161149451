<template>
  <div v-if="!isBusy">
    <category-tree-item
    class="item"
    :category="categoriesTree"
    ></category-tree-item>
  </div>
  <div class="text-center col-12 col-xl-10"
       style="padding-top:10px; padding-bottom:10px;"
       v-else>
    <b-spinner class="align-middle"></b-spinner>
    <strong style="margin-left:10px;">Loading...</strong>
  </div>
</template>

<script>

import CategoryTreeItem from "@/components/hub/categories/CategoryTreeItem";

export default {
  name: 'CategoryTree',
  components: {CategoryTreeItem},
  props: {
    bundleIdentifier: String,
    reload: {
      type: Boolean,
      default: false,
    }
  },
  data: function() {
    return {
      categories: {},
      categoriesTree: {
        "name": "root",
        "root": true,
        "children": []
      },
      categoriesMap: {},
      isBusy: true
    }
  },
  mounted: function() {
    this.loadCategories()
  },
  methods: {
    loadCategories() {
      this.isBusy = true
      this.$store.dispatch('categories/loadAll', {
        filters: {bundleIdentifier: this.bundleIdentifier},
      }).then(() => {
        this.categories = this.$store.getters['categories/getAll']()

        this.categories.forEach(category => {
          this.categoriesMap[category.identifier] = category
        })

        this.categories.forEach(category => {
          if (category.category_identifier === null) {
            this.categoriesTree["children"].push(this.buildTree(category))
          }
        })

        this.isBusy = false
      })
    },
    buildTree(category) {
      let tree = {
        ...category,
        children: []
      }
      if (category.children.length > 0) {
        category.children.forEach(child => tree.children.push(this.buildTree(this.categoriesMap[child])))
      }
      return tree
    }
  }
}
</script>

<style scoped>
ul {
  list-style-type: none;
}

li {
  list-style-type: none;
}
</style>
