import Vue from 'vue'
import Config from '../../Config'
import {downloadFile} from "@/common";

const state = {
  tree: {}
}

const getters = {
  tree: (state) => () => {
    return state.tree
  }
}

const actions = {
  load_tree({commit}) {
    return Vue.http.get(Config.API_ASSETS_URL + '/tree').then((response) => {
      let tree = response.data
      commit('set', tree)
    })
  },
  async downloadCLI({dispatch, getters}, {os='linux'}) {
    // await dispatch("load_tree")
    let response = await Vue.http.get(Config.API_ASSETS_URL + '/cli', {params: {os: os}})
    let version = this.getters['about/get']().version
    let filename = 'lade-' + version + (os === "windows"?'.exe':'').toString()
    return downloadFile(response, filename)
  },
}

const mutations = {
  set(state, tree) {
    for (let [key, val] of Object.entries(tree)) {
      Vue.set(state.tree, key, val)
    }
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true
}






