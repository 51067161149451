var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-group-bundle-grid"},[(_vm.group)?_c('div',{staticClass:"bg-white group",class:{'extended': _vm.viewmoreClicked}},[_c('div',{staticClass:"icon-description"},[(_vm.group.icon && _vm.group.icon.url)?_c('img',{staticClass:"group-icon img-fluid",attrs:{"src":_vm.group.icon.url,"alt":_vm.group.name}}):_c('div',{staticClass:"generated-icon",style:({'background-color': _vm.generateIconColor(_vm.group)})},[_c('div',{staticClass:"text-icon"},[_vm._v(" "+_vm._s(_vm.group.name[0].toUpperCase())+" ")])]),_c('div',{staticClass:"title-description"},[_c('h2',[_vm._v(_vm._s(_vm.group.name))]),_c('p',{staticClass:"createSince"},[_vm._v("Active since "+_vm._s(_vm._f("print_date")(_vm.group.created_at)))]),_c('span'),_c('div',{ref:"htmlDescription"},[_c('vue-markdown',{attrs:{"source":_vm.group.description}})],1)]),(_vm.isEditable)?_c('b-link',{attrs:{"to":{
            name: 'editGroup',
            params: {
              identifier: this.group.identifier,
              previousPage: this.currentPage
            }
          }}},[_c('button',{staticClass:"btn-edit-group"},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'pen']}})],1)]):_vm._e()],1),(_vm.viewMore)?_c('div',{ref:"rowViewMore",staticClass:"row-view-more"},[_c('div',{staticClass:"btn-view-more",on:{"click":function($event){$event.stopPropagation();_vm.viewmoreClicked = !_vm.viewmoreClicked}}},[_c('p',[_vm._v("View "),(!_vm.viewmoreClicked)?_c('span',[_vm._v("More")]):_c('span',[_vm._v("Less")])]),_c('img',{staticClass:"arrow-dropdown",attrs:{"src":require("@/assets/dropdown.png"),"alt":"image of dropdown button"}})])]):_vm._e()]):_vm._e(),(this.group !== undefined)?_c('BundleGrid',{attrs:{"group-identifier":this.group.identifier}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }