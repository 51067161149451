import Vue from 'vue'
import Config from '../../Config'
import {field_actions} from './fields'
import {setDictValue} from "@/common";

const state = {
  list: [],
  groupList: {},
  limit: 15,
  skip: 0,
  pagination: {
    page: 1,
    total_pages: 0,
    total: 0,
  },
  _lookup: {},
  fields: [
    {"key": "identifier"},
    {"key": "name"},
    field_actions
  ],
  filters: {
    isDeletable: (entry) => false,
    isEditable: (entry) => true,
    isExportable: (entry) => false,
    isDownloadable: (entry) => false
  }
}

const getters = {
  get: (state) => (identifier) =>
    state._lookup[identifier],
  getAll: (state) => () =>
    state.list,
  getFilters: (state) => () =>
    state.filters,
  getFields: (state) => () =>
    state.fields
}

const actions = {
  clear({dispatch, commit}) {
    commit('clear')
  },
  loadAll({dispatch, commit}) {
    return Vue.http.get(Config.API_PROJECT_POOLS_URL).then((response) => {
      let projects = response.data
      commit('setAll', projects)
    })
  },
  loadGroup({dispatch, commit}, groupIdentifier) {
    return dispatch("loadAll").then(() => {
      return Vue.http.get(Config.API_GROUPS_URL + '/' + groupIdentifier + "/project_pools").then((response) => {
        let projects = response.data
        commit('setAll', projects)
      })
    })
  },
  load({dispatch, commit}, identifier) {
    return Vue.http.get(Config.API_PROJECT_POOLS_URL + '/' + identifier).then((response) => {
      let project = response.data
      commit('set', {identifier, project})
    })
  },
  search({dispatch, commit}, {search, filters = {}, params = {}}) {
    return dispatch('search/project_pool', {search, filters}, {root: true}).then((resources) => {
      commit('setAll', resources)
    })
  },
  update({dispatch, commit}, {identifier, data}) {
    return Vue.http.put(Config.API_PROJECT_POOLS_URL + '/' + identifier, data).then((response) => {
      return dispatch('load', identifier)
    })
  },
  deleteFromState({dispatch, commit}, project) {
    commit('deleteFromState', project)
  }
}


const mutations = {
  setAll(state, projects) {
    state.list.splice(0, state.list.length)
    state.limit = projects.limit
    state.skip = projects.skip
    state.pagination.page = projects.page
    state.pagination.total_pages = projects.total_pages
    state.pagination.total = projects.total
    for (let project of projects.data) {
      state.list.push(project)
      setDictValue(state._lookup, project.identifier, project)
    }
  },
  set(state, {identifier, project}) {
    let projects = state.list
    state._lookup[project.identifier] = project
    let found = false
    for (let i = 0; i < projects.length; i++) {
      let c_project = projects[i];
      if (c_project.identifier === identifier) {
        Vue.set(state.list, i, project)
        found = true
        break
      }
    }
    if (!found) {
      state.list.push(project)
    }
  },
  clear(state) {
    state.list.splice(0, state.list.length)
    state.skip = 0
    state.pagination.page = 0
    state.pagination.total_pages = 0
    state.pagination.total = 0
  },
  deleteFromState(state, project) {
    state.list.splice(state.list.indexOf(project), 1)
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true
}
