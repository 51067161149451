<template>
  <div v-if="topology" class="topology-container">

    <TopologyListForm
      editEvent="edit-topology-show"
      deleteEvent="delete-topology-show"/>

    <b-row no-gutters class="top" >
      <b-container>
      <b-col>
        <div class="page-title">Topology from bundle <b-link style="color: inherit" :to="{name: 'bundlePage', params: {identifier: this.bundleIdentifier, tab: 5}}">{{ bundle.name }}</b-link></div>
        <b-row style="margin-top: 10px; text-align: left;">
          <b-col cols=2 v-if="topologyLogo != null"><b-img fluid :src="topologyLogo" alt="Topology Icon"></b-img></b-col>
          <b-col :cols="(topologyLogo != null)?'10':'12'" style="padding-left: 30px;">
            <b-row class="topology-title">
              <h2>{{topology.name}}</h2><h6><b-badge class="lade-label">{{topology.qualifier}}</b-badge></h6>
<!--              <font-awesome-icon :icon="topology.favorite?['fas', 'star']:['far', 'star']" @click.stop="toggleFavorite()" :color="topology.favorite?'orange':'gray'" size="lg" style="cursor: pointer; margin-left: 10px;"/>-->
              <font-awesome-icon :icon="['fas', 'pencil-alt']"
                                 @click.stop="$eventBus.$emit('edit-topology-show', topology.identifier)"
                                 style="margin-left:5px; cursor: pointer; height:20px;"
                                 v-if="isEditable"/>
              <font-awesome-icon :icon="['fas', 'trash']"
                                 @click.stop="$eventBus.$emit('delete-topology-show', topology.identifier)"
                                 v-if="isDeletable"
                                 style="margin-left:10px; cursor: pointer; height:20px; color:indianred;"/>
            </b-row>
            <b-row>
              <div>Last update {{ topology.created_at | print_date }}</div>
            </b-row>
            <b-row class="tag-row"><div><B>Tag:</B> {{topology.alias}}:{{topology.qualifier}}</div></b-row>
            <b-row class="size-row"><div><B>Size:</B> {{printableTopologySize}}</div></b-row>
            <b-row class="size-row" v-if="category !==  null"><div><B>Category:</B> {{category.name}}</div></b-row>
            <b-row style="padding-bottom: 2px">
              <ContentLabels resource-type="topologies" :content="topology" :is-editable="isEditable"></ContentLabels>
            </b-row>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-tabs class="topology-tabs" content-class="mt-3" v-model="tabValue">
              <b-tab title="Preview" :title-link-class="'tab-title-class'" :active="!isEmpty"
                     :disabled="isEmpty">
                <template #title>
                  <font-awesome-icon class="tab-icon" :icon="['fa', 'sitemap']"/>
                  Preview
                </template>
              </b-tab>
              <b-tab :title-link-class="'tab-title-class'" :active="isEmpty">
                <template #title>
                  <font-awesome-icon class="tab-icon" :icon="['fa', 'align-left']"/>
                  Description
                </template>
              </b-tab>
              <b-tab :title-link-class="'tab-title-class'">
                <template #title>
                  <font-awesome-icon class="tab-icon" :icon="['fa', 'desktop']"/>
                  Hosts <b-badge class="pt-1" variant="light">{{topology.hosts_specs.length}}</b-badge>
                </template>
              </b-tab>
              <b-tab :title-link-class="'tab-title-class'">
                <template #title>
                  <font-awesome-icon class="tab-icon" :icon="['fa', 'cloud']"/>
                  Networks <b-badge class="pt-1" variant="light">{{topology.networks_specs.length}}</b-badge>
                </template>
              </b-tab>
              <b-tab :title-link-class="'tab-title-class'">
                <template #title>
                  <font-awesome-icon class="tab-icon" :icon="['fa', 'shapes']"/>
                  Shapes <b-badge class="pt-1" variant="light">{{topology.shapes_specs.length}}</b-badge>
                </template>
              </b-tab>
            </b-tabs>
          </b-col>
        </b-row>

      </b-col>
      </b-container>
    </b-row>
    <b-row no-gutters >
      <b-col md="1"></b-col>
      <b-col md="10" cols="12">
        <div v-if="tabValue===0">
          <div>

            <div v-if="isBusy"><b-spinner></b-spinner></div>
            <div v-else>
              <b-card-img class="preview" :style="getPreviewStyle" :top=true :src="preview"
                          alt="Topology preview"></b-card-img>
            </div>
          </div>
        </div>
        <div class="tab-content" v-if="tabValue===1">
          <div v-if="!editDescription">
            <b-button class="button edit-tab-button" @click.stop="editDescription=true">
              <font-awesome-icon :icon="['fas', 'pencil-alt']" v-if="isEditable"/></b-button>
            <vue-markdown :source="topology.description"></vue-markdown>
          </div>
          <div v-else>
            <form ref="form" @submit.stop.prevent="updateDescription()">
              <b-form-group id="form-description" label-for="description"
                            description="Please write your topology description following community rules.">
                <editor height="600px" id="input-description" ref="descriptionEditor" initialEditType="wysiwyg" :initialValue="this.topology.description" />
              </b-form-group>
              <b-button type="submit" class="button" variant="primary">Submit</b-button>
              <b-button variant="secondary" class="button" @click.stop="editDescription=false"
                        style="margin-left:10px;">Cancel</b-button>
            </form>
          </div>
        </div>
        <div v-if="tabValue===2">

          <div  v-for="host in topology.hosts_specs" :key="host.identifier">
            <b-card class="resource-tile px-5" no-body onClick="">
              <b-row>
                <b-col class="card-col-icon" cols=2 v-if="host.icon.url">
                  <b-card-img class="card-icon" :top=true :src="host.icon.url" alt="Host logo"></b-card-img>
                </b-col>
                <b-col cols='10' style="padding-left: 30px; margin-top: 10px;">
                  <b-row class="resource-title">
                    <h3>{{host.hostname}}</h3>
                  </b-row>
                  <b-row class="card-row"><div><B>Type: </B>{{ printHardwareType(host) }}</div></b-row>
                  <b-row v-if="host.os !== null" class="card-row"><div><B>OS: </B>{{ host.os }}</div></b-row>
                  <b-row v-if="host.hardware_type !== 'EM_T'" class="card-row"><div><B>Size: </B> {{ printHostSize(host) }}</div></b-row>
                  <b-row class="card-row">
                    <b-badge class="card-badge lade-label">RAM: {{host.ram_mb}}</b-badge>
                    <b-badge class="card-badge lade-label" v-if="host.hardware_type === 'VM_T'">CPU: {{host.cpu_cores}}</b-badge>
                    <b-badge class="card-badge lade-label" v-if="host.hardware_type === 'VM_T' && host.agent">LADE Agent</b-badge>
                  </b-row>
                </b-col>
              </b-row>
            </b-card>
          </div>

        </div>
        <div v-if="tabValue===3">

          <div  v-for="network in topology.networks_specs" :key="network.identifier">
            <b-card class="resource-tile px-5" no-body onClick="">
              <b-row>
                <b-col class="card-col-icon" cols=2 v-if="networkLogo(network)">
                  <b-card-img class="card-icon" :top=true :src="networkLogo(network)" alt="Host logo"></b-card-img>
                </b-col>
                <b-col cols='10' style="padding-left: 30px; margin-top: 10px;">
                  <b-row class="resource-title">
                    <h3>{{network.name}}</h3>
                  </b-row>
                  <b-row class="card-row"><div><B>Type: </B>{{ network.network_type }}</div></b-row>
                  <b-row v-if="network.ip !== undefined" class="card-row"><div><B>Subnet: </B>{{ printNetworkSubnet(network) }}</div></b-row>
                  <b-row class="card-row">
                    <b-badge v-if="network.network_type === 'L2' && network.mtu !== null" class="card-badge lade-label">MTU: {{network.mtu}}</b-badge>
                    <b-badge v-if="network.network_type !== 'SPAN' && network.forged_transmits === true" class="card-badge lade-label">Forged transmits</b-badge>
                    <b-badge v-if="network.network_type !== 'SPAN' && network.promiscuous === true" class="card-badge lade-label">Promiscuous</b-badge>
                    <b-badge v-if="network.network_type !== 'SPAN' && network.mac_changes === true" class="card-badge lade-label">Mac changes</b-badge>
                  </b-row>
                </b-col>
              </b-row>
            </b-card>
          </div>

        </div>
        <div v-if="tabValue===4">

          <div  v-for="shape in topology.shapes_specs" :key="shape.identifier">
            <b-card class="resource-tile px-5" no-body onClick="">
              <b-row>
                <b-col class="card-col-icon" cols=2 v-if="shape.icon.url">
                  <b-card-img class="card-icon" :top=true :src="shape.icon.url" alt="Host logo"></b-card-img>
                </b-col>
                <b-col cols='10' style="padding-left: 30px; margin-top: 10px;">
                  <b-row class="resource-title">
                    <h3>{{shape.name}}</h3>
                  </b-row>
                  <b-row><ContentLabels resource-type="shapes" :content="shape"></ContentLabels></b-row>
                </b-col>
              </b-row>
            </b-card>
          </div>
        </div>
      </b-col>
      <b-col md="1"></b-col>
    </b-row>
  </div>

  <div class="text-center col-12 col-xl-10"
       style="padding-top:10px; padding-bottom:10px;"
       v-else>
    <b-spinner class="align-middle"></b-spinner>
    <strong style="margin-left:10px;">Loading...</strong>
  </div>
</template>

<script>

import * as ladeWebUI from "lade-webui";
import TopologyListForm from "@/components/topologies/TopologyListForm";
import ContentLabels from '@/components/hub/ContentLabels.vue'
import {print_human_readable_size, print_hardware_type} from "@/filters";

export default {
  name: 'TopologyContent',
  props: {
    identifier: String,
    bundleIdentifier: String,
  },
  components: {
    TopologyListForm, ContentLabels
  },
  data: function() {
      return {
        topology: null,
        bundle: null,
        isBusy: true,
        preview: null,
        showInputLabel: false,
        newLabel: "",
        editDescription: false,
        tabValue: 0,
        category: null,
      }
  },
  mounted: function() {
    let loadPromise = this.$store.dispatch('topologies/load', this.identifier).then( () => {
      this.topology = this.$store.getters['topologies/get'](this.identifier)
      this.generateTopologyPreview()
      if (this.topology.category_identifier) {
        this.$store.dispatch('categories/load', this.topology.category_identifier).then( () => {
          this.category = this.$store.getters['categories/get'](this.topology.category_identifier)
        })
      }
    })
    let loadBundlePromise = this.$store.dispatch('bundles/load', this.bundleIdentifier).then( () => {
      this.bundle = this.$store.getters['bundles/get'](this.bundleIdentifier)
    })
    Promise.all([loadPromise, loadBundlePromise]).then( () => {
      this.updateBreadcrumb()
    })

  },
  computed: {
    topologyLogo() {
      if (this.topology) {
        if (this.topology.icon && this.topology.icon.url) {
          return this.topology.icon.url
        }
      }

      return this.preview
    },
    isEditable() {
      return !(this.bundle.authz && (this.bundle.authz.permission & 0x04) === 0);
    },
    isDeletable() {
      return !(this.bundle.authz && (this.bundle.authz.permission & 0x08) === 0);
    },
    printableTopologySize() {
      return print_human_readable_size(this.topology.size)
    },
    isEmpty() {
      return !(this.topology.hosts_specs.length > 0 || this.topology.networks_specs.length > 0 || this.topology.shapes_specs.length > 0)
    },
    getPreviewStyle() {
      let style = {
        backgroundImage: 'url(' + require('@/assets/background_pattern_light.png') + ')',
        "display": "block",
        "margin-left": "auto",
        "margin-right": "auto"
      }
      if (this.topology.hosts_specs.length + this.topology.networks_specs.length + this.topology.shapes_specs.length === 1) {
        if (this.topology.networks_specs.length === 1) {
          style["width"] = "30%"
        }
        else {
          style["width"] = "20%"
        }

      }
      else if (this.topology.hosts_specs.length + this.topology.networks_specs.length + this.topology.shapes_specs.length <= 3){
        style["width"] = "60%"
      }
      else {
        style["width"] = "100%"
      }
      return style
    },
  },
  methods: {
    generateTopologyPreview() {
      ladeWebUI.getPreview(this.topology, {'darkMode': false}).then((result) => {
        this.isBusy = false
        if (result.element.children.length > 0) {
          this.preview = result.element.children[0].src
        }
      })
    },
    updateBreadcrumb() {
      let breadcumbList = [
        { text: 'Explore', to: {name: 'bundleGrid'} },
        { text: this.bundle.name,
          to: {
            name: 'bundlePage',
            params: {
              identifier: this.bundleIdentifier,
              reload: false,
              tab: 1,
              reloadTopologies: false,
            }
          }
        },
        //{ text: 'file', to: {}},
        {text: this.topology.name, to: {name: 'TopologyPage',params: {identifier: this.identifier, bundleIdentifier: this.bundleIdentifier}}}
      ]
      this.$emit('breadcrumbChange', breadcumbList)
    },
    toggleFavorite() {
      if (this.topology.favorite === true) {
        this.$store.dispatch('favorites/delete', this.identifier)
        this.topology.favorite = false
      } else {
        this.$store.dispatch('favorites/add', this.identifier)
        this.topology.favorite = true
      }
    },
    updateDescription() {
      let description = this.$refs.descriptionEditor.invoke('getMarkdown')
      this.$store.dispatch('topologies/update', {identifier: this.identifier,
        data: {description: description}}).then(() => {
        this.editDescription = false

      })
    },
    printHardwareType(host) {
      return print_hardware_type(host.hardware_type)
    },
    printHostSize(host) {
      return print_human_readable_size(host.size)
    },
    networkLogo(network) {
      if (network.icon && network.icon.url) {
        return network.icon.url
      }
      console.log(network.network_type)
      if (network.network_type === 'SPAN') {
        return require("@/assets/span.svg")
      }
      else if (network.network_type === 'REMOTE_SPAN') {
        return require("@/assets/remote_span.svg")
      }
      else {
        return require("@/assets/lan_hub.png")
      }

    },
    printNetworkSubnet(network) {
      if (network.mask === undefined) {
        return network.ip
      }
      let mask = network.mask
      let maskNodes = mask.match(/(\d+)/g);
      let cidr = 0;
      for(let i in maskNodes)
      {
        cidr += (((maskNodes[i] >>> 0).toString(2)).match(/1/g) || []).length;
      }
      return network.ip + "/" + cidr;
    }
  }
}
</script>

<style scoped>

.topology-title {
  align-items: center;
}

.topology-title h2 {
  margin-right: 10px
}

.topology-tabs >>> .tab-title-class {
  color: var(--lade-color-dark-grey);
  font-size: 1.2em;
  border-radius: 0px;
}

.topology-tabs {
  margin-top: 20px;
  margin-bottom: -14px;
}

.bundle-tabs >>> .tab-title-class {
  color: var(--lade-color-dark);
}

.tab-content {
  background-color: #FFFFFF;
  border: 1px solid #e0e4e7;
  border-radius: 2px;
  padding: 10px;
  min-height: 60px;
}

</style>
