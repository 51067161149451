<template>
  <div class="bg-black drop-shadow" :style="styleHeight">
    <b-container>
      <b-row>
        <b-col
          md="6"
          class="pr-0 order-2 order-md-1"
          style="margin-top: 10em; margin-bottom: 17em"
        >
          <svg
            width="300"
            height="375"
            class="svg d-none d-xl-block animation-show-up"
          >
            <rect x="0" y="0" width="125" height="125" style="fill: #dbedc4" />
            <rect
              x="125"
              y="125"
              width="125"
              height="125"
              style="fill: #c2e299"
            />
            <rect
              x="0"
              y="250"
              width="125"
              height="125"
              style="fill: #9ad056"
            />
          </svg>
          <div
            class="
              text-right text-white text-9xl
              tracking-wide
              d-none d-md-block
            "
          >
            Cyber
          </div>
          <div
            class="
              text-right text-white text-11xl
              tracking-wide
              font-weight-bold
              mt-4
              d-none d-md-block
            "
          >
            HUB
          </div>

          <div
            class="
              text-center text-white text-2xl
              tracking-widest
              mt-5
              animation-translate-right
              my-auto
              d-md-none
              bg-green
            "
          >
            Welcome to the community portal.
          </div>
        </b-col>

        <b-col
          md="6"
          class="bg-green pl-0 order-1 order-md-2 animation-drop-down"
          :style="styleHeight({ 'padding-top': '10em' })"
        >
          <div class="text-white tracking-wide">
            <div class="text-9xl mb-5 d-none d-md-block">Range</div>
            <div class="text-6xl m-4 d-md-none tracking-widest">
              CyberRange
              <span class="font-weight-bold text-9xl tracking-widest">HUB</span>
            </div>
          </div>

          <div
            class="
              text-center text-white text-2xl
              tracking-widest
              mt-5
              animation-translate-right
              my-auto
              d-none d-md-block
            "
          >
            Welcome to the community portal.
          </div>
          <slot name="content"></slot>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "Header",
  components: {},
  props: {
    isHeightScreen: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    styleHeight(style = {}) {
      if (this.isHeightScreen) {
        style["min-height"] = "100vh";
      }
      return style;
    },
  },
};
</script>

<style scoped>
.animation-drop-down {
  animation: drop-down 2s forwards;
}

.animation-show-up {
  animation: show-up 2s forwards;
}

.animation-translate-right {
  animation: translate-right 1s ease-in-out forwards;
}

@keyframes drop-down {
  from {
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
  }
  to {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
}

@keyframes show-up {
  from {
    clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0% 100%);
  }
  to {
    clip-path: polygon(0 100%, 100% 100%, 100% 0, 0 0);
  }
}

@keyframes translate-right {
  from {
    transform: translateX(-100px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
</style>