import Vue from 'vue'
import Config from '@/Config'
import { setDictValue, build_resource_list_params } from '@/common'

const state = {
  _lookup: {},
  _questionsPage: {},
  _lookupAnswers: {},
}

const getters = {
  get: (state) => (identifier) =>
      state._lookup[identifier],
  getQuestionsPage: (state) => (bundleIdentifier) =>
      state._questionsPage[bundleIdentifier],
  getAnswers: (state) => (identifier) => state._lookupAnswers[identifier],
}

function questionURL(question) {
    return '/' + question.bundle_identifier + '/questions/' + question.identifier
}

const actions = {
  loadBundleQuestions ({dispatch, commit}, bundleIdentifier) {
      return Vue.http.get(Config.API_BUNDLES_URL + "/" + bundleIdentifier + '/questions').then((response) => {
          commit('setQuestionsPage', {bundleIdentifier, questionsPage: response.data})
      })
  },
  update({dispatch, commit}, {bundleIdentifier, identifier, data}) {
    return Vue.http.put(Config.API_BUNDLES_URL + '/' + bundleIdentifier + '/questions/' + identifier, data).then((response) => {
        let question = response.data
        commit('set', {identifier, question})
      })
  },
  load({dispatch, commit}, {bundleIdentifier, identifier}) {
    return Vue.http.get(Config.API_BUNDLES_URL + '/' + bundleIdentifier + '/questions/' + identifier).then((response) => {
      let question = response.data
      commit('set', {identifier, question})
    })
  },
  delete({dispatch, commit}, {bundleIdentifier, identifier}) {
    return Vue.http.delete(Config.API_BUNDLES_URL + '/' + bundleIdentifier + '/questions/' + identifier).then((response) => {
      return commit('delete', identifier)
    })
  },
  add ({dispatch, commit}, {bundleIdentifier, question}) {
    return Vue.http.post(Config.API_BUNDLES_URL + '/' + bundleIdentifier + '/questions', question).then((response) => {
      let identifier = response.body.identifier
      return dispatch('load', {bundleIdentifier, identifier}).then(() => {
        return identifier
      })
    })
  },
  loadAnswers ({dispatch, commit}, {identifier, desc = false, ...resource_list_params}) {
    let params = build_resource_list_params(resource_list_params)
    if (desc !== null) {
      params['desc'] = desc
    }
    let question = state._lookup[identifier]
    if (question !== undefined) {
      return Vue.http.get(Config.API_BUNDLES_URL + questionURL(question) + '/answers', {params}).then((response) => {
        return commit('setAnswers', {identifier, answers: response.data})
      })
    }
  },
  addAnswer ({dispatch, commit}, {identifier, answer}) {
    let question = state._lookup[identifier]
    if (question !== undefined) {
      return Vue.http.post(Config.API_BUNDLES_URL + questionURL(question) + '/answers', {answer})
    }
  },
  deleteAnswer({dispatch, commit}, {identifier, answerIdentifier}) {
    let question = state._lookup[identifier]
    if (question !== undefined) {
      return Vue.http.delete(Config.API_BUNDLES_URL + questionURL(question) + '/answers/' + answerIdentifier, {answerIdentifier})
    }
  },
}

const mutations = {
  setQuestionsPage (state, {bundleIdentifier, questionsPage}) {
    // Fow now, we assume we just need questions for a single bundle at a time.
    setDictValue( state._questionsPage, bundleIdentifier, questionsPage )
    for (let question of questionsPage.data) {
      setDictValue( state._lookup, question.identifier, question )
    }
  },
  set(state, {identifier, question}) {
    setDictValue(state._lookup, identifier, question)
  },
  setAnswer (state, {identifier, answer}) {

  },
  setAnswers (state, {identifier, answers}) {
    // if (identifier in state._lookupAnswers) {
    //   state._lookupAnswers[identifier].splice(0, state._lookupAnswers[identifier].length)
    // }
    setDictValue( state._lookupAnswers, identifier, answers )
  },
  delete(state, identifier) {
    if (this._lookup !== undefined && identifier in this._lookup) {
      delete(this._lookup[identifier])
    }
  },
}

export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true
}
