<template>
  <b-container class="mb-5">
    <b-row class="d-flex justify-content-center">
      <slot name="image"></slot>
    </b-row>

    <b-row class="d-flex justify-content-center text-2xl text-green my-4">
      {{ title }}
    </b-row>

    <b-row class="text-blue">
      <ObjectiveTarget v-for="(target, index) in targets" :key="index" class="my-2">
        <template #target>
          <strong>{{ target.highlight }}</strong> {{ target.rest }}
        </template>
      </ObjectiveTarget>
    </b-row>
  </b-container>
</template>

<script>
import ObjectiveTarget from "@/components/hub/landing/ObjectiveTarget";

export default {
  name: "Objective",
  components: { ObjectiveTarget },
  props: {
    title: String,
    targets: Array,
  },
};
</script>
