import Vue from 'vue'
import Config from '../../Config'
import {setDictValue} from '@/common'
import {print_date} from "@/filters"
import {field_created_at, field_actions} from './fields'

const state = {
  calendar: [],
  availability: [],
  list: [],
  groupList: {},
  _lookup: {},
  fields: [
    {
      key: 'status',
      sortable: true,
    },
    {
      "key": "name",
      "sortable": true
    },
    {
      key: 'start',
      sortable: true,
      formatter: print_date,
    },
    {
      key: 'end',
      sortable: true,
      formatter: print_date,
    },
    field_created_at,
    field_actions
  ],
  filters: {
    isEditable: (entry) => true,
    isDeletable: (entry) => entry.status !== "running",
    isExportable: (entry) => true,
    isDownloadable: (entry) => false,
  }
}

const getters = {
  getFields: (state) => () =>
    state.fields,
  getFilters: (state) => () =>
    state.filters,
  getCalendar: (state) => () =>
    state.calendar,
  getAvailability: (state) => () =>
    state.availability,
  get: (state) => (identifier) =>
    state._lookup[identifier],
  getAll: (state) => () =>
    state.list,
  getGroup: (state) => (groupIdentifier) =>
    state.groupList[groupIdentifier]
}

const actions = {
  emptyCalendar({dispatch, commit}) {
    commit('setCalendar', {calendar: []})
  },
  loadCalendar({dispatch, commit}, group_identifier) {
    return Vue.http.post(Config.API_GROUPS_URL + "/" + group_identifier + "/bookings/calendar", {workzones_amount: 1}).then((response) => {
      let calendar = response.data
      commit('setCalendar', {calendar})
    })
  },
  loadAvailability({dispatch, commit}, {groupIdentifier, data = {}}) {
    return Vue.http.post(Config.API_GROUPS_URL + "/" + groupIdentifier + "/bookings/calendar", data).then((response) => {
      let calendar = response.data
      commit('setAvailability', {calendar})
    })
  },
  loadGroup({dispatch, commit}, group_identifier) {
    return Vue.http.get(Config.API_GROUPS_URL + "/" + group_identifier + "/bookings").then((response) => {
      let bookings = response.data
      commit('setGroup', {group_identifier, bookings})
    })
  },
  loadAll({dispatch, commit}) {
    return Vue.http.get(Config.API_BOOKINGS_URL).then((response) => {
      let bookings = response.data
      commit('setAll', bookings)
    })
  },
  load({dispatch, commit}, booking_identifier) {
    return Vue.http.get(Config.API_BOOKINGS_URL + "/" + booking_identifier).then((response) => {
      let booking = response.data
      commit('set', booking)
    })
  },
  add({dispatch, commit}, {group_identifier, data, approve}) {
    return Vue.http.post(Config.API_GROUPS_URL + '/' + group_identifier + '/bookings', data).then((response) => {
      return dispatch('load', response.data.identifier).then(() => {
        if (approve)
          return dispatch('approve', {identifier: response.data.identifier, group_identifier})
      })
    })
  },
  update({dispatch, commit}, {identifier, group_identifier, data, approve}) {
    return Vue.http.put(Config.API_GROUPS_URL + '/' + group_identifier + '/bookings/' + identifier, data).then((response) => {
      return dispatch('load', identifier).then(() => {
        if (approve)
          return dispatch('approve', {identifier, group_identifier})
      })
    })
  },
  delete({dispatch, commit}, {identifier, group_identifier}) {
    return Vue.http.delete(Config.API_GROUPS_URL + '/' + group_identifier + '/bookings/' + identifier).then((response) => {
      commit('delete', identifier)
      return Promise.all([
        dispatch('loadCalendar', group_identifier),
      ])
    })
  },
  cancel({dispatch, commit}, {identifier, group_identifier}) {
    return Vue.http.put(Config.API_GROUPS_URL + '/' + group_identifier + '/bookings/' + identifier + "/cancel").then((response) => {
      return Promise.all([
        dispatch('load', identifier),
        dispatch('loadCalendar', group_identifier),
      ])
    })
  },
  approve({dispatch, commit}, {identifier, group_identifier}) {
    return Vue.http.put(Config.API_GROUPS_URL + '/' + group_identifier + '/bookings/' + identifier + '/approve').then((response) => {
      return Promise.all([
        dispatch('loadCalendar', group_identifier),
        dispatch('load', identifier)
      ])
    })
  },
  prune({dispatch, commit}, groupIdentifier) {
    var url = ""
    if (groupIdentifier === "all")
      url = Config.API_BOOKINGS_URL + '/prune'
    else
      url = Config.API_GROUPS_URL + '/' + groupIdentifier + '/bookings/prune'

    return Vue.http.delete(url).then((response) => {
      return Promise.all([
        dispatch('loadGroup', groupIdentifier)
      ])
    })
  }
}

const mutations = {
  setCalendar(state, {calendar}) {
    state.calendar = calendar
  },
  setAvailability(state, {calendar}) {
    state.availability = calendar
  },
  setGroup(state, {group_identifier, bookings}) {
    if (state.groupList[group_identifier] !== undefined)
      state.groupList[group_identifier].splice(0, state.groupList[group_identifier].length)
    else
      state.groupList[group_identifier] = []

    for (let booking of bookings) {
      state.groupList[group_identifier].push(booking)
      setDictValue(state._lookup, booking.identifier, booking)
    }
  },
  setAll(state, bookings) {
    state.list.splice(0, state.list.length)
    for (let booking of bookings) {
      setDictValue(state._lookup, booking.identifier, booking)
      state.list.push(booking)
    }
  },
  set(state, booking) {
    setDictValue(state._lookup, booking.identifier, booking)
    let groupList = state.groupList[booking.group_identifier]
    if (groupList !== undefined && groupList.find(item => item.identifier === booking.identifier) === undefined)
      groupList.push(booking)
  },
  delete(state, identifier) {
    let booking = state._lookup[identifier]
    let groupList = state.groupList[booking.group_identifier]
    if (groupList !== undefined)
      Vue.delete(groupList, groupList.indexOf(groupList.find(item => item.identifier === identifier)))
    Vue.delete(state.list, state.list.indexOf(state.list.find(item => item.identifier === identifier)))
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true
}
