import Vue from 'vue'
import Config from '../../Config'
import {setDictValue, build_resource_list_params} from '@/common'
import {print_date, capitalize} from '@/filters'
import {field_actions} from './fields'

const state = {
  list: [],
  pagination: {
    page: 1,
    total_pages: 0,
    total: 0,
    skip: 0,
    limit: 0
  },
  groupPagination: [],
  profile: {},
  _lookup: {},
  fields: [
    {
      key: 'active',
      label: 'Status',
      sortable: true
    },
    {
      key: 'name',
      label: 'Name',
      sortable: true
    },
    {
      key: 'email',
      label: 'Email',
      sortable: true
    },
    {
      key: 'last_logged_in',
      label: 'Last login',
      formatter: print_date,
      sortable: true
    },
    field_actions
  ],
  filters: {
    isEditable: (entry) => true,
    isExportable: (entry) => false,
    isDeletable: (entry) => entry.name !== 'admin',
    isDownloadable: (entry) => false
  }
}


const getters = {
  get: (state) => (identifier) =>
    state._lookup[identifier],
  getAll: (state) => () =>
    state.list,
  getFields: (state) => () =>
    state.fields,
  getFilters: (state) => () =>
    state.filters,
  getGroup: (state) => (groupIdentifier) =>
    state.groupList[groupIdentifier],
  getProfile: (state) => () =>
    state.profile,
  hasGroup: (state) => (userIdentifier, groupIdentifier) => {
    let user = state._lookup[userIdentifier]
    for (let _group of user.groups) {
      if (_group.identifier === groupIdentifier) {
        return true;
      }
    }
    return false
  },
  hasRole: (state) => (userIdentifier, roleIdentifier) => {
    let user = state._lookup[userIdentifier]
    for (let _role of user.roles) {
      if (_role.identifier === roleIdentifier) {
        return true;
      }
    }
    return false
  },
  getPagination: (state) => () =>
    state.pagination,
  getGroupPagination: (state, groupIdentifier) => () =>
    state.groupPagination[groupIdentifier],
}

const actions = {
  loadProfile({dispatch, commit}) {
    return Vue.http.get(Config.API_PROFILE_URL).then((response) => {
      let profile = response.data
      commit('setProfile', profile)
    })
  },
  loadAll({dispatch, commit}, {filters = {}, ...resource_list_params}) {
    let params = build_resource_list_params(resource_list_params)

    let url
    if (filters.groupIdentifier !== undefined)
      url = Config.API_GROUPS_URL + "/" + filters.groupIdentifier + "/users"
    else
      url = Config.API_USERS_URL

    return Vue.http.get(url, {params}).then((response) => {
      let users = response.data
      commit('setAll', users)
    })
  },
  loadGroup({dispatch, commit}, {groupIdentifier, ...resource_list_params}) {
    let params = build_resource_list_params(resource_list_params)

    return Vue.http.get(Config.API_GROUPS_URL + "/" + groupIdentifier + "/users", {params}).then((response) => {
      let users = response.data
      commit('setAll', users)
    })
  },
  load({dispatch, commit}, identifier) {
    return Vue.http.get(Config.API_USERS_URL + '/' + identifier).then((response) => {
      let user = response.data
      commit('set', {identifier, user})
    })
  },
  add({dispatch, commit}, data) {
    let formData = new FormData()
    if (data['icon']) {
      formData.append('icon', data['icon'])
    }
    formData.append('data', JSON.stringify(data))
    return Vue.http.post(Config.API_USERS_URL, formData).then((response) => {
      return dispatch('load', response.data.identifier)
    })
  },
  addInGroup({dispatch, commit}, {data, groupIdentifier, groups}) {
    return Vue.http.post(Config.API_GROUPS_URL + '/' + groupIdentifier + "/users", data).then((response) => {
      if (groups !== undefined) {
        let promises = []
        let userIdentifier = response.data.identifier
        groups.forEach(function (group) {
          promises.push(Vue.http.put(Config.API_USERS_URL + '/' + userIdentifier + '/groups/' + group.identifier, {}))
        })
        return Promise.all(promises).then((response) => {
          return dispatch('load', userIdentifier)
        })
      }
      else {
        return dispatch('load', userIdentifier)
      }
    })
  },
  update({dispatch, commit}, {identifier, data}) {
    let formData = new FormData()
    if (data['icon']) {
      formData.append('icon', data['icon'])
      delete data.icon;
    }
    formData.append('data', JSON.stringify(data))
    return Vue.http.put(Config.API_USERS_URL + '/' + identifier, formData).then((response) => {
      return dispatch('load', identifier)
    })
  },
  delete({dispatch, commit}, identifier) {
    return Vue.http.delete(Config.API_USERS_URL + '/' + identifier).then((response) => {
      commit('delete', identifier)
    })
  },
  addGroup({dispatch, commit}, {userIdentifier, groupIdentifier}) {
    return Vue.http.put(Config.API_USERS_URL + '/' + userIdentifier + '/groups/' + groupIdentifier, {}).then((response) => {
      return dispatch('load', userIdentifier)
    })
  },
  deleteGroup({dispatch, commit}, {userIdentifier, groupIdentifier, delete_orphan=false}) {
    let params = {}
    if (delete_orphan) {
      params['delete_orphan'] = true
    }
    return Vue.http.delete(Config.API_USERS_URL + '/' + userIdentifier + '/groups/' + groupIdentifier, {params}).then((response) => {
      if (!delete_orphan) {
        return dispatch('load', userIdentifier)
      } else {
        commit('delete', userIdentifier)
      }
    })
  },
  addRole({dispatch, commit}, {userIdentifier, groupIdentifier, roleIdentifier}) {
    return Vue.http.put(Config.API_USERS_URL + '/' + userIdentifier
      + '/groups/' + groupIdentifier
      + '/roles/' + roleIdentifier, {}).then((response) => {
      return dispatch('load', userIdentifier)
    })
  },
  deleteRole({dispatch, commit}, {userIdentifier, groupIdentifier, roleIdentifier}) {
    return Vue.http.delete(Config.API_USERS_URL + '/' + userIdentifier
      + '/groups/' + groupIdentifier
      + '/roles/' + roleIdentifier).then((response) => {
      return dispatch('load', userIdentifier)
    })
  },
  search({dispatch, commit}, {search}) {
    return this.dispatch('search/user', {search}, {root: true}).then((resources) => {
      commit('setAll', resources)
    })
  },
  update_profile({dispatch, commit}, {data}) {
    let formData = new FormData()
    if (data['icon']) {
      formData.append('icon', data['icon'])
      delete data.icon;
    }
    formData.append('data', JSON.stringify(data))
    return Vue.http.put(Config.API_PROFILE_URL, formData).then((response) => {
      return dispatch('loadProfile')
    })
  },
  disconnect({dispatch, commit}, {userIdentifier}) {
    return Vue.http.delete(Config.API_USERS_URL + "/" + userIdentifier + "/sessions").then((resources) => {
      return dispatch('load', userIdentifier)
    })
  },
}

const mutations = {
  delete(state, identifier) {
    Vue.delete(state.list, state.list.indexOf(state.list.find(item => item.identifier === identifier)))
  },
  setAll(state, users) {
    state._lookup = {}
    state.pagination.page = users.page
    state.pagination.total_pages = users.total_pages
    state.pagination.total = users.total
    state.pagination.skip = users.skip
    state.pagination.limit = users.limit
    state.list.splice(0, state.list.length)

    for (let user of users.data) {
      state._lookup[user.identifier] = user
      state.list.push(user)
    }
  },
  set(state, {identifier, user}) {
    Vue.set(state._lookup, identifier, user)
    if (state.list.find(item => item.identifier === identifier) !== undefined) {
      state.list.splice(state.list.indexOf(state.list.find(item => item.identifier === identifier)), 1, user)
    } else {
      state.list.push(user)
    }
  },
  setProfile(state, profile) {
    setDictValue(state._lookup, profile.identifier, profile)
    state.profile = profile
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true
}
