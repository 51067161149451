<template>
  <b-row>
    <b-col lg="3" xl="2" class="d-flex justify-content-center fixed-right">
      <div class="mt-5 ml-md-5">
        <div class="d-sm-block d-md-flex d-lg-block">
          <div
            class="order-by mx-2 w-100"
            >
              <a
                  v-for="item in sortOptions"
                  :key="item.text"
                  @click.up="sort_change(item)"
                  :class="{'active' : isActiveSortOption(item)}"
              >
                {{item.text}}
              </a>
          </div>
        </div>
        <div class="d-sm-block d-md-flex d-lg-block">
          <div v-if="filters.length > 0" class="p-md-5 p-lg-2 mb-2">
            <b-row class="mb-2">
              <b-col cols=3>
                <span class="font-weight-bold"> Content </span>
              </b-col>
              <b-col>
                <b-link
                  v-if="selectedFilters.length < filters.length"
                  class="text-black"
                  @click="
                              selectedFilters = Array.from(
                                filters,
                                (item) => item.item
                              )
                            "
                >
                  Select All
                </b-link>
                <b-link
                  v-if="selectedFilters.length > 0"
                  class="text-black"
                  @click="selectedFilters = []"
                >
                  Unselect All
                </b-link>
              </b-col>
              <b-col cols=1 class="d-flex justify-content-end">
                <b-link v-b-toggle.collapse-content class="text-black text-lg">+/-</b-link>
              </b-col>
            </b-row>
            <b-collapse visible id="collapse-content">
              <b-list-group>
                <b-list-group-item
                  href="#"
                  v-for="(item, index) in filters"
                  :key="index"
                  @click="selectFilter(item)"
                >
                  <svg
                    v-if="selectedFilters.indexOf(item.item) !== -1"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-check-square-fill text-black"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm10.03 4.97a.75.75 0 0 1 .011 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.75.75 0 0 1 1.08-.022z"
                    />
                  </svg>
                  <svg
                    v-else
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-square"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"
                    />
                  </svg>
                  <span class="px-2 font-weight-bold">{{
                      item.name
                    }}</span>
                </b-list-group-item>
              </b-list-group>
            </b-collapse>
          </div>

          <div class="p-md-5 p-lg-2 mb-2">
            <b-row class="mb-2">
              <b-col cols=3>
                <span class="font-weight-bold"> Labels </span>
              </b-col>
              <b-col>
                <b-link
                  v-if="selectedLabels.length < filter_labels.length"
                  class="text-black"
                  @click="
                              selectedLabels = Array.from(
                                filter_labels,
                                (item) => item.item
                              );
                               custom_labels.forEach(
                                (_, index) =>
                                  (custom_labels[index].status = !!custom_labels[index].text)
                              );
                              get_bundles();
                            "
                >
                  Select All
                </b-link>
                <b-link
                  v-if="selectedLabels.length > 0"
                  class="text-black"
                  @click="
                              selectedLabels = [];
                              custom_labels.forEach(
                                (_, index) =>
                                  (custom_labels[index].status = false)
                              );
                              get_bundles();
                            "
                >
                  Unselect All
                </b-link>
              </b-col>
              <b-col cols=1 class="d-flex justify-content-end">
                <b-link v-b-toggle.collapse-labels class="text-black text-lg">+/-</b-link>
              </b-col>
            </b-row>
            <b-collapse visible id="collapse-labels">
              <b-list-group>
                <b-list-group-item
                  href="#"
                  v-for="(item, index) in filterLabels"
                  :key="index"
                  class="filter-label-custom"
                  @click="selectLabel(item)">
                  <svg
                    v-if="selectedLabels.indexOf(item.item) !== -1"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-check-square-fill text-black"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm10.03 4.97a.75.75 0 0 1 .011 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.75.75 0 0 1 1.08-.022z"
                    />
                  </svg>
                  <svg
                    v-else
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-square"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"
                    />
                  </svg>
                  <span class="px-2 font-weight-bold">{{
                      item.name
                    }}</span>
                </b-list-group-item>

                <b-list-group-item
                  href="#"
                  class="filter-label"
                  v-for="(item, index) in custom_labels"
                  :key="`${item}-${index}`"
                >
                  <svg
                    v-if="custom_labels[index].status"
                    @click="
                                custom_labels[index].status = false;
                                get_bundles();
                              "
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-check-square-fill text-black"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm10.03 4.97a.75.75 0 0 1 .011 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.75.75 0 0 1 1.08-.022z"
                    />
                  </svg>
                  <svg
                    v-else
                    @click="
                                custom_labels[index].status = true;
                                get_bundles();
                              "
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-square"
                    viewBox="0 0 16 16"
                  >
                    <path
                      d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"
                    />
                  </svg>
                  <b-form-input
                    class="w-75 filter-label-custom"
                    style="margin-left: 21px; margin-top: -21px"
                    size="sm"
                    aria-label="Enter label name"
                    v-model="custom_labels[index].text"
                    placeholder="Custom label..."
                    @input="update_custom_label(index)"
                  >
                  </b-form-input>
                </b-list-group-item>
              </b-list-group>
              <b-row v-if="showLabelsUntil < filter_labels.length">
                <b-col class="d-flex justify-content-center font-weigh-bold text-xl mb-1">
                  ...
                </b-col>
              </b-row>
              <b-row>
                <b-col class="d-flex justify-content-end">
                  <b-link v-if="showLabelsUntil > 3" class="text-black ml-2" @click="showLabelsUntil = 3">
                    Hide
                  </b-link>
                  <b-link v-if="showLabelsUntil < filter_labels.length" class="text-black ml-2" @click="showLabelsUntil = filter_labels.length">
                    Show all
                  </b-link>
                  <b-link v-if="showLabelsUntil < filter_labels.length" class="text-black ml-2" @click="showLabelsUntil += 3">
                    Show more ↓
                  </b-link>
                </b-col>
              </b-row>
            </b-collapse>
          </div>
        </div>
      </div>
    </b-col>

    <b-col lg="9" xl="10" offset-lg="3" offset-xl="2">
      <div v-if="waitMonted" class="text-center my-5">
        <b-row>
          <b-col class="d-flex justify-content-center">
            <b-spinner class="align-middle"></b-spinner>
          </b-col>
        </b-row>
      </div>
      <div v-else-if="loadingmore" class="text-center my-5">
        <b-row>
          <b-col class="d-flex justify-content-center">
            <b-spinner class="align-middle"></b-spinner>
          </b-col>
        </b-row>
      </div>

      <div v-else-if="bundles.length <= 0" class="grid">
        <div
            class="search-content"
            style="width: 100%"
            v-if="groupIdentifier!==undefined">
          <font-awesome-icon :icon="['fas', 'magnifying-glass']" />
          <input
              type="search"
              id="searchBundle"
              ref="searchBundle"
              class="input-search"
              v-model="searchBundle"
              :placeholder="searchPlaceholder"
              @keyup="filter"
              @mouseup="mouseupSearchEvent"
              style=" margin-top: 0; padding: 2px; height:20px; width: 100%"
          />
        </div>
        <b-row>
          <b-col class="d-flex justify-content-center">
            <div class="text-xl my-5">
              There are no results for this search in the Hub.
            </div>
          </b-col>
        </b-row>
      </div>

      <div v-else class="grid">
        <div
            class="search-content"
            style="width: 100%"
            v-if="groupIdentifier!==undefined">
          <font-awesome-icon :icon="['fas', 'magnifying-glass']" />
          <input
              type="search"
              id="searchBundle"
              ref="searchBundle"
              class="input-search"
              v-model="searchBundle"
              :placeholder="searchPlaceholder"
              @keyup="filter"
              @mouseup="mouseupSearchEvent"
              style=" margin-top: 0; padding: 2px; height:20px; width: 100%"
          />
        </div>
        <b-row class="d-flex justify-content-center">
          <b-col
            style="max-width: 22rem; max-height: 32.5rem"
            v-for="bundle in bundles"
            v-bind:key="bundle.identifier"
          >
            <b-card no-body class="tile mb-5">
              <b-link
                style="text-decoration: none"
                :to="{
                  name: 'bundlePage',
                  params: { identifier: bundle.identifier, previousPage: currentPage },
                }"
              >
                <div class="container-img">
                  <img
                    v-if="bundle.icon && bundle.icon.url"
                    class="logo p-2"
                    :src="bundle.icon.url"
                    alt="Bundle logo"
                  />
                  <img
                    v-else-if="
                                bundle.group_icon && bundle.group_icon.url
                              "
                    class="logo p-2"
                    :src="bundle.group_icon.url"
                    :alt="bundle.group"
                  />
                  <div v-else class="no-icon">
                    <div
                      class="generated-icon"
                      :style="{
                                  'background-color': generateIconColor(bundle),
                                }"
                    >
                      <div class="text-icon">
                        {{ bundle.name[0].toUpperCase() }}
                      </div>
                    </div>
                  </div>
                </div>

                <hr style="margin-top: 0; border-top: 1px solid rgba(0, 0, 0, 0.25);"/>

                <!--                        <div v-if="bundle.group_icon && bundle.group_icon.url" class="groups-logo-wrapper">-->
                <!--                          <img class="groups-logo" :src="bundle.group_icon.url" :alt="bundle.groups"/>-->
                <!--                        </div>-->

                <div class="card-body">
                  <b-card-title class="card-title">
                              <span
                                class="card-bundle-name"
                                :title="bundle.name"
                              >
                                {{ bundle.name }}
                              </span>
                    <h6>
                      <b-badge
                        style="margin-top: 7px; margin-bottom: 5px"
                        class="label-badge lade-label"
                      >
                        {{ bundle.qualifier }}
                      </b-badge>
                      <VisibilityBadge v-if="isTokenValid" class="ml-2" :visibility="bundle.visibility"/>
                    </h6>
                  </b-card-title>
                  <b-link
                    style="text-decoration: none"
                    :to="{
                                name: 'groupBundleGrid',
                                params: { identifier: bundle.group_identifier, previousPage: currentPage},
                              }"
                  >
                              <span class="group-logo-text">
                                {{ bundle.group }}
                              </span>
                  </b-link>

                  <!--                          <b-card-text class="card-description">-->
                  <!--                            <vue-markdown>{{truncateText(bundle.description)}}</vue-markdown>-->
                  <!--                          </b-card-text>-->
                  <div class="card-rate">
                    <span class="rate-number">{{ bundle.rate }}</span>
                    <b-form-rating
                      class="rate-stars"
                      v-if="bundle.rate"
                      v-model="bundle.rate"
                      readonly
                      inline
                      no-border
                      variant="warning"
                      size="sm"
                    ></b-form-rating>
                  </div>
                </div>

                <div class="card-labels">
                  <b-badge
                    class="label-badge lade-label"
                    style="margin: 3px"
                    v-for="label in bundle.labels"
                    :key="label"
                  >{{ label }}</b-badge
                  >
                </div>

                <b-card-footer class="card-bottom">
                  <div>
                    <small>
                      <font-awesome-icon
                        class="mr-2"
                        :icon="['fas', 'pen']"
                      />
                      {{ bundle.created_at | print_date }}
                    </small>
                  </div>
                  <div>
                    <small v-if="isTokenValid">
                      <i class="fa-solid fa-download"></i>
                      <font-awesome-icon
                        class="mr-2"
                        :icon="['fas', 'download']"
                      />
                      <span v-if="bundle.download">
                                  {{ bundle.download ? bundle.download : "-" }}
                                  {{
                          `${
                            bundle.download > 1
                              ? "downloads"
                              : "download"
                          }`
                        }}
                                </span>
                      <span v-else>No downloads</span>
                    </small>
                  </div>
                </b-card-footer>
              </b-link>
            </b-card>
          </b-col>
        </b-row>

        <b-row class="my-3">
          <b-col class="d-flex justify-content-center">
            <Pagination
              :page="page"
              :skip="skip"
              :limit="limit"
              :total="total"
              :totalPages="totalPages"
              @page-change="onPageChange"
              @updateLimit="updateLimit"
            />
          </b-col>
        </b-row>
      </div>
    </b-col>
  </b-row>
</template>

<script>

import { print_date } from "@/filters";
import { truncateWithEllipses, generateIconColor } from "@/common";
import Pagination from "@/components/Pagination.vue";
import VisibilityBadge from "@/components/VisibilityBadge.vue";
import store from "@/store";

export default {
  components: {
    VisibilityBadge,
    Pagination
  },
  props: {
    groupIdentifier: String,
    editors: Array,
    searchContent: String,
    reset: {
      type: Boolean,
      default: false
    },
    reload: {
      type: Boolean,
      default: true
    },
  },
  data: function () {
    let data = {
      filter_labels: [],
      custom_labels: [{ status: false, text: "" }],
      filter_editors: ["Airbus", "Airbus-community", "Stormshield"],
      sortOptions: [
        { value: { orderBy: "rate", desc: true }, text: "Most Popular" }, // Registry feature
        { value: { orderBy: "created_date", desc: true }, text: "Most Recent" },
        { value: { orderBy: "download", desc: true }, text: "Download" },
        { value: { orderBy: "name", desc: false }, text: "Alphabetical" },
      ],
      selectedLabels: [],
      selectedFilters: [],
      filters: [],
      //pagination
      skip: store.getters["bundles/getSkip"](),
      total: store.getters["bundles/getTotal"](),
      page: store.getters["bundles/getPage"](),
      totalPages: store.getters["bundles/getTotalPages"](),
      limit: 100,
      orderBy: "rate",
      desc: true,
      showLabelsUntil: 3,
      searchPlaceholder: "",
      searchBundle: "",
      typingEffect: {
        charIndex: 0,
        textArrayIndex: 0,
        newTextDelay: 2000,
        erasingDelay: 100,
        typingDelay: 200,
        textArrayBundles: ["Search for bundles..."],
      },
      waitMonted: true
    }
    this.$store.dispatch('token/getTokenInfos').then(() => {
      if (this.$store.getters["token/isValid"]()) {
        data['filters'].push({ item: "favorites", name: "Favorites" })
      }
    })
    return data
  },
  computed: {
    isTokenValid: () => store.getters["token/isValid"](),
    filterLabels() {
      return this.filter_labels.slice(0, this.showLabelsUntil)
    },
    bundles: function () {
      return this.$store.getters["bundles/getAll"]();
    },
    loadingmore: () => store.getters["bundles/getLoading"](),
    currentPage: function () {
      if ( this.groupIdentifier !== undefined ) {
        let group = this.$store.getters["groups/get"](this.groupIdentifier);
        return { text: group.name, to: { name: 'groupBundleGrid', params: { "identifier": group.identifier }}};
      }
      else {
        return {
          text: 'Explore',
          to: {
            name: 'bundleGrid',
            params: {
              reload: true,
              objectSearched: "bundles",
            }
          }
        };
      }
    }
  },
  watch: {
    $route(/*to, from*/) {
      this.get_bundles();
    },
    searchContent(){
      this.get_bundles()
    }
  },
  async mounted() {
    this.waitMonted = true
    await this.$store.dispatch("bundles/getLabels",{
      groupIdentifier: this.groupIdentifier,
      limit: 10,
      desc: true,
    })
      .then((result) => {
        if (result.total !== 0) {
          this.filter_labels = [];
          result.data.forEach((item) =>
            this.filter_labels.push({
              item: item,
              name: item,
            })
          );
        } else {
          // Set default list of labels
          this.filter_labels = [];
        }
      });

    if (this.reset) {
      this.page = 1;
      this.skip = 0;
    } else {
      this.page = this.$store.getters['bundles/getPage']()
      this.orderBy = this.$store.getters['bundles/getOrderBy']()
      this.desc = this.$store.getters['bundles/getDesc']()
      if (this.desc === null) {
        this.desc = true
      }
      if(this.orderBy === null) {
        this.orderBy = "rate"
      }
      this.limit = this.$store.getters['bundles/getLimit']()
      this.page = this.$store.getters['bundles/getPage']()
      this.skip = this.$store.getters['bundles/getSkip']()
      let labels = this.$store.getters['bundles/getLabels']()
      if (labels) {
        labels.forEach((label) => {
          this.selectedLabels.push(label)
        })
      }
      if (this.$store.getters["token/isValid"]()) {
        let isFavorites = this.$store.getters['bundles/isFavorites']()

        if (isFavorites === true) {
          this.selectedFilters.push("favorites");
        }
      }
    }
    this.waitMonted = false
    if(this.reload)
      this.get_bundles();

    if(this.groupBundleGrid !== null) this.typingEffectType();
  },
  methods: {
    typingEffectType() {
      if (this.typingEffect.charIndex < this.typingEffect.textArrayBundles[this.typingEffect.textArrayIndex].length) {
        this.searchPlaceholder = this.searchPlaceholder + this.typingEffect.textArrayBundles[this.typingEffect.textArrayIndex].charAt(this.typingEffect.charIndex);
        this.typingEffect.charIndex++;
        setTimeout(this.typingEffectType, this.typingEffect.typingDelay);
      } else {
        setTimeout(this.typingEffectErase, this.typingEffect.newTextDelay);
      }
    },
    typingEffectErase() {
      if (this.typingEffect.charIndex > 0) {
        this.searchPlaceholder = this.typingEffect.textArrayBundles[this.typingEffect.textArrayIndex].substring(0, this.typingEffect.charIndex - 1);
        this.typingEffect.charIndex--;
        setTimeout(this.typingEffectErase, this.typingEffect.erasingDelay);
      } else {
        this.typingEffect.textArrayIndex++;
        if (this.typingEffect.textArrayIndex >= this.typingEffect.textArrayBundles.length) this.typingEffect.textArrayIndex = 0;
        setTimeout(this.typingEffectType, this.typingEffect.typingDelay + 1100);
      }
    },
    mouseupSearchEvent(_) {
      let oldvalue = this.$refs.searchBundle.value;
      if (oldvalue === "") {
        return;
      }
      //// When this event is fired after clicking on the clear button
      //// the value is not cleared yet. We have to wait for it.
      setTimeout(() => {
        var newValue = this.searchBundle;
        if (newValue === "") {
        }
      }, 1);
    },
    get_bundles() {
      let load_favorites = false;
      if (this.selectedFilters.includes("favorites")) {
        load_favorites = true;
      }

      let search = this.searchContent;
      if(this.groupIdentifier !== undefined) search = this.searchBundle;

      let payload = {
        orderBy: this.orderBy,
        desc: this.desc,
        page: this.page,
        limit: this.limit,
        search: search,
        load: "meta",
        favorites: load_favorites,
      };

      let labels = [...this.selectedLabels];
      this.custom_labels.forEach((custom_label) => {
        if (custom_label.status && custom_label.text !== "") {
          labels.push(custom_label.text);
        }
      });

      payload["labels"] = labels.join(",");

      let loadType = "bundles/loadAll";
      if (this.groupIdentifier) {
        loadType = "bundles/loadGroup";
        payload["groupIdentifier"] = this.groupIdentifier;
      }

      this.$store
        .dispatch(loadType, payload)
        .then(() => {
          this.page = this.$store.getters["bundles/getPage"]();
          this.totalPages = this.$store.getters["bundles/getTotalPages"]();
          this.limit = this.$store.getters["bundles/getLimit"]();
          this.skip = this.$store.getters["bundles/getSkip"]();
          this.total = this.$store.getters["bundles/getTotal"]();
        })
        .catch((response) => {
          this.$eventBus.$emit("http-error", response);
        });
    },
    sort_change(item) {
      this.orderBy = item.value.orderBy;
      this.desc = item.value.desc;
      this.get_bundles();
    },
    isActiveSortOption(item) {
      if(typeof(this.desc) === "string")
        this.desc === "true" ? this.desc = true : this.desc= false
      return this.desc === item.value.desc && this.orderBy === item.value.orderBy
    },
    onPageChange(page) {
      this.page = page;
      this.get_bundles();
    },
    updateLimit(limit) {
      if (limit !== this.limit) {
        this.limit = limit;
        this.get_bundles();
      }
    },
    filter() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.get_bundles();
      }, 600);
    },
    truncateText(text) {
      //delete markdown titles
      const regex = /[#]+ .+/gi;
      return truncateWithEllipses(text.replaceAll(regex, " "), 500);
    },
    selectLabel(item) {
      if (this.selectedLabels.indexOf(item.item) === -1) {
        this.selectedLabels.push(item.item)
      }
      else {
        this.selectedLabels.splice(
          this.selectedLabels.indexOf(item.item),
          1)
      }
      this.page = 1;
      this.get_bundles()
    },
    selectFilter(item) {
      if (this.selectedFilters.indexOf(item.item) === -1) {
        this.selectedFilters.push(item.item)
      }
      else {
        this.selectedFilters.splice(
          this.selectedFilters.indexOf(item.item),
          1)
      }
      this.page = 1;
      this.get_bundles()
    },
    generateIconColor(bundle) {
      return generateIconColor(bundle.identifier);
    },
    update_custom_label(index) {
      if (this.custom_labels[index].text === "") {
        return;
      }

      if (this.custom_labels[this.custom_labels.length - 1].text !== "") {
        this.custom_labels.push({ status: false, text: "" });
      }
      let old_value = this.custom_labels[index].text;
      if (this.custom_labels[index].status) {
        setTimeout(() => {
          var newValue = this.custom_labels[index].text;
          if (newValue === old_value) {
            this.get_bundles();
          }
        }, 800);
      }
    },
  },
  filters: {
    print_date,
  },
}
</script>

<style scoped>
select:focus {
  outline: none;
}

select[multiple] {
  line-height: 25px;
  background: var(--lade-color-white);
}

.order-by {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  text-decoration: none;
  margin-bottom: 10px ;
  flex-direction: row;
}

.order-by a:nth-child(1){
  border-radius: 5px 0 0 0;
}

.order-by a:nth-child(2){
  border-radius: 0 5px 0 0;
}

.order-by a:nth-child(3){
  border-radius: 0 0 0 5px;
}

.order-by a:nth-child(4){
  border-radius: 0 0 5px 0;
}

.fixed-right {
  position:fixed;
}

@media screen and (max-width: 960px) {
  .fixed-right {
    position:relative;
  }
}

@media screen and (max-width: 1458px) {
  .order-by {
    display: flex;
    flex-direction: column;
  }
  .order-by a {
    width: 100%;
  }
  .order-by a:nth-child(1){
    border-radius: 5px 5px 0 0;
  }

  .order-by a:nth-child(2){
    border-radius: 0 0 0 0;
  }

  .order-by a:nth-child(3){
    border-radius: 0 0 0 0;
  }

  .order-by a:nth-child(4){
    border-radius: 0 0 5px 5px;
  }

}

@media screen and (max-width: 1176px) {
  .order-by {
    display: flex;
    flex-direction: row;
  }
  .order-by a {
    width: 50%;
  }
  .order-by a:nth-child(1){
    border-radius: 5px 0 0 0;
  }

  .order-by a:nth-child(2){
    border-radius: 0 5px 0 0;
  }

  .order-by a:nth-child(3){
    border-radius: 0 0 0 5px;
  }

  .order-by a:nth-child(4){
    border-radius: 0 0 5px 0;
  }

}

.filter-label:first-child {
  margin-top: 0;
}

/*
.filter-label {
  margin-top: 10px;
}

.filter-label-custom {
  margin-top: 5px;
}
*/

.grid {
  margin-top: 40px;
}

.search-content {
  width: 50% !important;
  top: -10px;
}

.search-content .input-search{
  padding: 20px 20px 20px 35px !important;
  box-shadow: rgba(0, 0, 0, 0.15) 0 2px 8px;
}

.search-content svg {
  left: 10px;
  top: 10px;
}

.search-content .input-search:focus{
  padding-left: 20px !important;
}

.tile {
  width: 20rem;
  height: 31rem;
  //border-radius: 4px 4px;
}

.card-bundle-name:hover {
  text-decoration: underline;
}

.tile:hover .logo,
.tile:hover .generated-icon{
  transform: scale(1.3);
}

.card-bottom {
  position: absolute;
  width: 100%;
  bottom: 0;
  height: 60px;
  color: var(--lade-color-grey);
}

.label-badge {
  padding: 5px;
  height: 22px;
}

.checkbox-title {
  font-weight: 700;
  margin-bottom: 10px;
  font-size: 1.2rem;
  margin-top: 10px;
}

.checkbox {
  font-size: 1.2rem;
}

.editor-li {
  list-style-position: inside;
}

.editor-link {
  color: var(--lade-color-dark);
}

.container-img {
  overflow: hidden;
  border-radius: 10px 10px 0 0;
}

.generated-icon {
  padding-left: 0;
  height: 11.25rem;
  //border-radius: 4px 4px;

  transition: 0.5s ease-in;
}

.card-body {
  padding: 1.5rem 1.5rem 0.5rem;
}

.card-title {
  color: var(--lade-color-dark-grey);
  margin-top: 0.5rem;
  margin-bottom: 2px;
  font-weight: 700;
  line-height: 1.5rem;
  font-size: 1.2rem;
  overflow: hidden;
  white-space: nowrap;
  text-align: left;
//-webkit-mask-image: linear-gradient(to right, black 90%, transparent 100%);
  mask-image: linear-gradient(to right, black 90%, transparent 100%);
}

.card-rate {
  margin-top: 0.5rem;
  height: 2rem;
  line-height: 1.5rem;
  color: var(--lade-color-card-rate);
}

.rate-number {
  font-size: 1.1rem;
  margin-right: 2px;
}

.rate-stars {
  padding: 0;
}

.card-labels {
  padding-left: 1.25rem;
  height: 2rem;
  overflow: hidden;
}

.logo {
  height: 11.25rem;
  width: 100%;
  margin: 0 0 auto;
  object-fit: scale-down;
  border-radius: 10px 10px 0 0;
  background-color: var(--lade-color-white-hover);
  padding: 0 0 !important;
  transition: 1s cubic-bezier(0.155, 1.105, 0.295, 1.12);
}

.no-icon {
  height: 11.25rem;
}

.group-logo-text {
  text-align: left;
  line-height: 1.7rem;
  font-size: 1.1rem;
  text-decoration: none;
  color: var(--lade-color-dark-grey);
}

.group-logo-text:hover {
  text-decoration: underline;
}
</style>
