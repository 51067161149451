<template>
  <div style="background: #333; width:100%; padding-left:20px; padding-right:20px;">
    <h4 class="hr" style="text-align:center; color:white; padding:20px; ">Cookies Policy</h4>

    <div style="overflow-y: scroll; height:75vh; background-color:white; padding:40px;">

        <p>This website uses only functional cookies, necessary for authentication, security, and interface personalization.</p>

        <h5>WHAT ARE COOKIES?</h5>

        <p>Cookies are small files or amount of information that may be stored to, accessed and remove from your device when you access Airbus CyberRange interface.</p>

        <p>They are widely used in order to make websites work, or work more efficiently, as well as to provide information to the owners of the site.</p>

        <p>For instance, a “Cookie” may refer to “http cookie”, flash cookie” (used by some applications or website relying on Flash technology),
          local storage area of your internet browser, unique identifier calculated from your internet browser characteristics (also known as “browser fingerprinting”),
          or unique identifiers related to your device or your internet browser (device serial number, MAC address, Android ID, advertising ID, etc...)].</p>

        <p>Cookies allows us to recognize your device and store information about your preferences or past actions. We may use Cookies:
        <ul>
            <li>to record the preferences of our users,</li>
            <li>to enable us to optimize the design of our Website,</li>
            <li>to ease navigation, and increase the user-friendliness of our Website,</li>
            <li>to analyze the usage our Website, and/or to identify the most popular sections of our Website,</li>
            <li>to provide content that is more accurately suited to your needs, and, in doing so, improve our Website. Cookies can be used to determine whether there has been any contact between us and your device in the past,</li>
            <li>to facilitate secure online access so that you do not need to enter your user ID and password again when you access our Website</li>
        </ul>
        </p>

        <h5>WHICH COOKIES DO WE USE?</h5>

        <p>Cookies contain personal data. The table below aims to inform you about the type and purpose of each cookies, and the type of personal data processed inside, and that we may use on Airbus Cyberrange Gaming Module:</p>

          <table>
            <thead>
            <tr>
              <th scope="col">NAME OF COOKIE</th>
              <th scope="col">PURPOSE</th>
              <th scope="col">RETENTION PERIOD</th>
              <th scope="col">COOKIE TYPE</th>
              <th scope="col">MANDATORY</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <th scope="row">lade_session</th>
              <td>ID de session</td>
              <td>Session duration</td>
              <td>HTTP cookie</td>
              <td>yes</td>
            </tr>
            <tr>
              <th scope="row">dark-mode-enabled</th>
              <td>Dark mode setting</td>
              <td></td>
              <td>Local storage</td>
              <td>no</td>
            </tr>
            <tr>
              <th scope="row">display-performance</th>
              <td>Define animations quality</td>
              <td></td>
              <td>Local storage</td>
              <td>no</td>
            </tr>
            <tr>
              <th scope="row">lade-version</th>
              <td>LADE version</td>
              <td>Session duration</td>
              <td>Local storage</td>
              <td>no</td>
            </tr>
            <tr>
              <th scope="row">last-workzone</th>
              <td>Redirect user in last workzone visited from previous session</td>
              <td></td>
              <td>Local storage</td>
              <td>no</td>
            </tr>
            <tr>
              <th scope="row">dashboard_impressions-1</th>
              <td>Grafana dashboard impression tracking</td>
              <td>Session duration</td>
              <td>Local storage</td>
              <td>no</td>
            </tr>
            <tr>
              <th scope="row">grafana.*</th>
              <td>grafana dashboard display preferences</td>
              <td>Session duration</td>
              <td>Local storage</td>
              <td>no</td>
            </tr>
            </tbody>
          </table>


        <h5>HOW CAN YOU DISABLE OR DELETE COOKIES?</h5>

        <p>When the Cookies we use are strictly necessary for technical reasons, they are marked as “mandatory” on the table above. Those Cookies do not require consent from you. However, if you choose not to accept Cookies that are strictly necessary for the provision of our services provided by our Website, it may result in a reduced availability of such services.</p>

        <p>To find out more about cookies, including how to see what cookies have been set and how to manage and delete them, visit <a href="www.aboutcookies.org">www.aboutcookies.org</a> or <a href="www.allaboutcookies.org">www.allaboutcookies.org</a>.</p>
    </div>
  </div>
</template>

<script>

export default {
  name: 'CookiesPolicy',
}
</script>

<style scoped>

table {
  border-collapse: collapse;
  border: 2px solid rgb(140 140 140);
  font-family: sans-serif;
  font-size: 0.8rem;
  letter-spacing: 1px;
  margin-bottom: 10px;
}

thead {
  background-color: rgb(228 240 245);
}

th,
td {
  border: 1px solid rgb(160 160 160);
  padding: 8px 10px;
}

td:last-of-type {
  text-align: center;
}

tbody > tr:nth-of-type(even) {
  background-color: rgb(237 238 242);
}


</style>
