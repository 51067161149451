<template>
  <div>

    <ResourceList
      id="ruleList"
      ref="ruleList"
      resource="rules"
      :fields="fields"
      :getter="getter"
      :dispatchActions="dispatchActions"
      addIcon="plus"
      :resource-list-form-parameters="{roleIdentifier:identifier}"
      :addEvent="addEvent"
      :add-event-parameter="{groupIdentifier, roleIdentifier:identifier}"
      :editEvent="editEvent"
      :deleteEvent="deleteEvent"
      hideSearch
      resource-list-form="RuleListForm"
      :hide-refresh="isProtected"
      :busy="busy">

      <template v-slot:customActions="actions">
        <div class="custom-control custom-switch rule-switch" v-if="isEditable(actions.row.item)">
          <input type="checkbox" class="custom-control-input" v-model="actions.row.item.enabled"
                 :id="'switch_'+actions.row.item.identifier" @click.prevent="toggleEnable(actions.row.item)">
          <label class="custom-control-label" :for="'switch_'+actions.row.item.identifier"></label>
        </div>
      </template>

    </ResourceList>
  </div>
</template>

<script>
import store from '../../store'

export default {
  name: "RuleList",
  props: {
    identifier: String,
    parameters: Object
  },
  data() {
    let protect = store.getters['roles/get'](this.identifier).protected
    return {
      busy: false,
      addEvent: protect?undefined:("add-rule-show-"+this.identifier),
      editEvent: protect?undefined:("edit-rule-show-"+this.identifier),
      deleteEvent: protect?undefined:("delete-rule-show"),
      fields: store.getters['rules/getFields'](),
      filters: store.getters['rules/getFilters'](),
      getter: { event: 'rules/getRole', parameters: this.identifier},
      groupIdentifier: this.parameters.groupIdentifier,
      isProtected: protect,
      dispatchActions: [{event: 'rules/loadRole',
        parameters: {
          groupIdentifier: this.parameters.groupIdentifier,
          roleIdentifier: this.identifier
        }
      }]
    }
  },
  methods: {
    isEditable(entry) {
      if (this.isProtected)
        return false
      if (entry.authz.permission === 0) {
        return false
      }
      return this.filters.isEditable(entry)
    },
    isDeletable(entry) {
      if (this.isProtected)
        return false
      if (entry.authz.permission === 0) {
        return false
      }
      return this.filters.isDeletable(entry)
    },
    toggleEnable(rule) {
      let data = {
        "enabled": !rule.enabled,
      }
      store.dispatch('rules/update', {
        identifier: rule.identifier,
        groupIdentifier: this.groupIdentifier,
        roleIdentifier: this.identifier,
        data
      }).catch((response) => {
        this.$eventBus.$emit('http-error', response)
      })
    }
  },
}
</script>

<style>
.rule-switch {
  float: left;
  cursor: pointer;
}

.rule-disabled {
  font-style: italic;
  color: var(--grey);
}

#ruleTabList > .table-responsive {
  min-height: 400px;
}
</style>
