<template>
  <div>
    <h6>{{ title }}&nbsp;&nbsp;
      <span title="Add">
				<font-awesome-icon @click="add()" :icon="['fas', 'plus']" v-if="!disabled"/>
			</span>
    </h6>
    <small>{{ help }}</small>
    <fieldset :class="classes" v-for="(value, index) in values" :key="index">
			<span :title="'Remove ' + name" style="position:absolute; right:2px; top:1px;z-index:10000;">
				<font-awesome-icon @click="remove(index)" :icon="['fas', 'times']" v-if="!disabled"/>
			</span>
      <component
        ref="children"
        v-bind:is="component"
        :prefix="component+''+index"
        :value="value"
        :name="name+' #'+(index+1)"
      ></component>
    </fieldset>
  </div>
</template>

<script>
import NicSpecForm from './topologies/NicSpecForm'
import InputText from './form/InputText'
import TextAreaForm from './form/TextAreaForm'

export default {
  name: 'DynamicForm',
  props: {
    component: String,
    title: String,
    name: String,
    help: String,
    disabled: {
      type: Boolean,
      default: false
    },
    minimal: {
      type: Boolean,
      default: false
    },
    resources: [String, Number, Object, Array]
  },
  data: () => {
    return {
      values: []
    }
  },
  mounted() {
    if (this.resources === null || this.resources === "" || this.resources === undefined) {
      this.values = []
    } else {
      this.values = JSON.parse(JSON.stringify(this.resources))
    }
  },
  computed: {
    classes() {
      if (this.minimal)
        return 'minimal'
      return 'normal'
    }
  },
  methods: {
    add() {
      if (this.minimal) {
        this.values.push("")
      } else {
        this.values.push({})
      }
    },
    remove(index) {
      this.values.splice(0, this.values.length)
      for (let value of this.getValues()) {
        this.values.push(value)
      }
      this.values.splice(index, 1)
    },
    getValues() {
      let values = []
      if (this.$refs['children'] !== undefined) {
        for (let ref of this.$refs['children']) {
          values.push(ref.getValue())
        }
      }
      return values
    }
  },
  components: {
    NicSpecForm, InputText, TextAreaForm
  }
}
</script>

<style scoped>

.normal {
  position: relative;
  border: 1px solid var(--lade-color-grey);
  border-radius: 5px;
  padding: 20px 10px 10px 10px;
  margin: 10px;
}

.minimal {
  position: relative;
  padding: 10px 20px 0 10px;
}
</style>
