<template>
  <div>
    <b-modal ref="viewFormModal" id="viewFormModal" hide-footer v-bind:title="title">
      <table style="width: 100%;">
        <tr v-for="key in printableKeys" :key="key">
          <td><strong>{{ key | capitalize }} </strong></td>
          <td style="padding-left: .25em;">{{ action[key] }}</td>
        </tr>
      </table>
    </b-modal>
    <b-modal size="lg" ref="addFormModal" id="addFormModal" hide-footer v-bind:title="title">
      <form v-on:submit.prevent="onAddEdit">
        <div class="card">
          <div class="card-header">
            Attributes
          </div>
          <div class="card-body">
            <InputText v-if="edit" prefix="Action" name="identifier" v-model="identifier" disabled></InputText>

            <div class="form-group row">
              <label class="col-sm-3 col-form-label" for="Bundle">Bundle</label>
              <div class="col-sm-9">
                <TypeAhead prefix="Action" id="Bundle" resourceType="bundle" v-model="bundle" class="mb-2"
                           @hit="bundleIdentifier=$event" :disabled="edit"/>
              </div>
            </div>

            <InputText prefix="Action" name="Alias" v-model="alias" required pattern="[a-zA-Z0-9\-_]{3,50}"></InputText>
            <InputText prefix="Action" name="Version" v-model="qualifier" placeholder='1.0.0'></InputText>

            <InputText prefix="Action" name="name" v-model="name"
                       help='Alias will be used if field is left empty'></InputText>

            <div class="form-group row">
              <label class="col-sm-3 col-form-label" for="Category">Category <i style="color: var(--lade-color-grey);"> -
                Optional </i></label>
              <div class="col-sm-9">
                <TypeAhead prefix="Action" resourceType="category" v-model="category" class="mb-2" id="Category"
                           @hit="categoryIdentifier=$event" :filters="{bundleIdentifier: bundleIdentifier}"
                           :disabled="bundleIdentifier === null"/>
              </div>
            </div>

            <InputText prefix="Action" name="command" v-model="command" required></InputText>
            <TextAreaForm ref="specs" prefix="Action" name="specs" :rows="5" v-model="specs"
                          :json="true"></TextAreaForm>
            <TextAreaForm ref="description" prefix="Action" name="description" :rows="2"
                          v-model="description"></TextAreaForm>
            <TextAreaForm ref="attributes" prefix="Action" name="attributes" :rows="5" v-model="attributes"
                          :json="true"></TextAreaForm>
            <button type="submit" class="btn btn-primary">Submit</button>
          </div>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import store from '../../store'
import InputText from '../form/InputText'
import TypeAhead from '../form/TypeAhead'
import TextAreaForm from '../form/TextAreaForm'
import {Tag} from '@/common'
import {print_tag} from '@/filters'

export default {
  name: 'ActionListForm',
  created() {

    this.$eventBus.$off('view-action-show')
    this.$eventBus.$on("view-action-show", (identifier) => {
      this.reset("Action")
      store.dispatch('actions/load', identifier).then((_) => {
        this.action = store.getters['actions/get'](identifier)
        this.printableKeys = ["tag", "identifier", "bundle", "name", "category", "description", "command", "attributes"]
        this.$refs.viewFormModal.show()
      })
    })

    this.$eventBus.$off('add-action-show')
    this.$eventBus.$on("add-action-show", (bundleIdentifier) => {
      this.reset("Add action")
      if (this.$refs.specs) this.$refs.specs.$emit('reset')
      if (this.$refs.attributes) this.$refs.attributes.$emit('reset')
      if (this.$refs.description) this.$refs.description.$emit('reset')
      if (bundleIdentifier !== undefined) {
        this.bundleIdentifier = bundleIdentifier
        this.bundle = store.getters['bundles/get'](bundleIdentifier).tag
      }
      this.$refs.addFormModal.show()
    })

    this.$eventBus.$off('edit-action-show')
    this.$eventBus.$on("edit-action-show", (identifier) => {
      store.dispatch('actions/load', identifier).then((values) => {
        this.reset("Edit action")
        let action = store.getters['actions/get'](identifier)
        this.identifier = action.identifier
        this.alias = action.alias
        this.qualifier = action.qualifier
        this.tag = action.tag
        this.name = action.name
        this.command = action.command
        if (action.description == null) {
          this.description = ""
        } else {
          this.description = action.description
        }
        this.specs = action.specs
        this.attributes = action.attributes
        this.categoryIdentifier = action.category_identifier
        this.bundleIdentifier = action.bundle_identifier
        this.edit = true

        if (this.$refs.specs) this.$refs.specs.$emit('reset', this.specs)
        if (this.$refs.attributes) this.$refs.attributes.$emit('reset', this.attributes)
        if (this.$refs.description) this.$refs.description.$emit('reset', this.description)

        let bundleIdentifier = this.bundleIdentifier
        let categoryIdentifier = this.categoryIdentifier
        let promises = [store.dispatch('bundles/load', bundleIdentifier)]
        if (categoryIdentifier != null) {
          promises.push(store.dispatch('categories/load', categoryIdentifier))
        }
        Promise.all(promises).then((values) => {
          this.bundle = store.getters['bundles/get'](bundleIdentifier).tag
          let category = store.getters['categories/get'](categoryIdentifier)
          this.category = category != null ? category.name : null
          this.$refs.addFormModal.show()
        })
      })
    })

    this.$eventBus.$off('delete-action-show')
    this.$eventBus.$on("delete-action-show", (identifier) => {
      let action = store.getters['actions/get'](identifier)
      this.$deleteModal({
        title: "Delete action",
        text: "Delete action " + print_tag(false, false, action) + " ?"
      }).then((result) => {
        if (result.value) {
          store.dispatch('actions/delete', identifier).then(() => {
            this.$eventBus.$emit('success', 'The action has been deleted')
          }).catch((response) => {
            this.$eventBus.$emit('http-error', response)
          })
        }
      });
    })

    this.$eventBus.$off('export-action')
    this.$eventBus.$on('export-action', (identifier) => {
      let action = store.getters['actions/get'](identifier)
      store.dispatch('bundles/export', {
        bundle_identifier: action.bundle_identifier,
        resources: [action.identifier]
      }).then(() => {
        this.$eventBus.$emit('success', 'The action has been exported')
      }).catch((response) => {
        this.$eventBus.$emit('http-error', response)
      })
    })
  },
  data() {
    return {
      identifier: null,
      action: null,
      printableKeys: {},
      alias: null,
      qualifier: null,
      tag: null,
      name: null,
      command: null,
      description: null,
      category: null,
      categoryIdentifier: null,
      specs: {},
      attributes: {},
      title: "",
      edit: false,
      bundle: null,
      bundleIdentifier: null
    }
  },
  computed: {
    actions: () => store.getters['actions/getAll']()
  },
  methods: {
    reset(title) {
      this.title = title
      this.edit = false
      this.identifier = null
      this.alias = null
      this.qualifier = null
      this.name = null
      this.description = null
      this.command = null
      this.category = null
      this.categoryIdentifier = null
      this.bundle = null
      this.bundleIdentifier = null
      this.specs = {}
      this.attributes = {}
    },
    onAddEdit() {

      let data = {
        "tag": new Tag(this.alias, this.qualifier).print(),
        "name": this.name,
        "command": this.command,
        "description": this.description,
        "category_identifier": this.categoryIdentifier,
        "specs": this.specs,
        "attributes": this.attributes
      }

      if (this.edit) {
        store.dispatch('actions/update', {
          bundleIdentifier: this.bundleIdentifier,
          identifier: this.identifier,
          data: data
        }).then(() => {
          this.$refs.addFormModal.hide()
          this.$eventBus.$emit('success', 'The action has been updated')
        }).catch((response) => {
          this.$eventBus.$emit('http-error', response)
        })
      } else {
        store.dispatch('actions/add', {bundleIdentifier: this.bundleIdentifier, data: data}).then(() => {
          this.$refs.addFormModal.hide()
          this.$eventBus.$emit('success', 'The action has been created')
        }).catch((response) => {
          this.$eventBus.$emit('http-error', response)
        })
      }
    },
    onDelete() {
      store.dispatch('actions/delete', {
        bundleIdentifier: this.bundleIdentifier,
        identifier: this.identifier
      }).then(() => {
        this.$refs.deleteFormModal.hide()
        this.$eventBus.$emit('success', 'The action has been deleted')
      }).catch((response) => {
        this.$eventBus.$emit('http-error', response)
      })
    }
  },
  components: {
    InputText, TextAreaForm, TypeAhead
  }
}
</script>

<style>

</style>
