<template>
  <div class="mx-2 mb-2">
    <b-button-group class="align-middle">
      <b-button
        :class="[
          currentPage > 1 ? 'page-chevron-active' : 'page-chevron-deactivated',
          'page-chevron',
        ]"
        :disabled="currentPage <= 1"
        @click="previousPage()"
      >
        <font-awesome-icon :icon="['fas', 'chevron-left']" />
      </b-button>

      <b-dropdown
        :text="`${skip + 1} - ${Math.min(skip + limit, total)} on ${total} elements`"
        variant="light"
      >
        <b-dropdown-item
          v-for="lim in limitOptions"
          :key="lim"
          @click.up="updateLimit(lim)"
          :active="lim === limit"
        >
          {{ lim }} elements
        </b-dropdown-item>
      </b-dropdown>

      <b-button
        :class="[
          currentPage < totalPages
            ? 'page-chevron-active'
            : 'page-chevron-deactivated',
          'page-chevron',
        ]"
        :disabled="currentPage >= totalPages"
        @click="nextPage()"
      >
        <font-awesome-icon :icon="['fas', 'chevron-right']" />
      </b-button>
    </b-button-group>
  </div>
</template>

<script>
export default {
  name: "Pagination",
  props: {
    page: Number,
    skip: Number,
    limit: Number,
    total: Number,
    totalPages: Number,
    limitOptions: {
      type: Array,
      default: () => {
        return [50, 75, 100];
      },
    },
  },
  data() {
    return {
      currentPage: 1,
    };
  },
  mounted() {
    this.currentPage = this.page;
  },
  methods: {
    previousPage() {
      if (this.currentPage > 1) {
        this.currentPage -= 1;
        this.$emit("page-change", this.currentPage);
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage += 1;
        this.$emit("page-change", this.currentPage);
      }
    },
    updateLimit(limit) {
      this.$emit("updateLimit", limit);
    },
  },
};
</script>

<style scoped>
.page-chevron {
  font-size: 1.1em;
  border: none !important;
  background-color: var(--lade-color-white) !important;
  color: var(--lade-color-grey);
}

.page-chevron-deactivated {
  background-color: var(--lade-color-white) !important;
  color: var(--lade-color-grey) !important;
}

.page-chevron-active:hover {
  background-color: var(--lade-green-explore-1) !important;
  color: var(--lade-color-grey);
}
</style>
