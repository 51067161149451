import Vue from 'vue'
import Config from '../../Config'
import {setDictValue, build_resource_list_params} from '@/common'
import {field_actions} from './fields'
import {print_date} from '@/filters'
import moment from "moment/moment";

const state = {
  list: [],
  _lookup: {},
  running: new Set(),
  eventsource: null,
  pagination: {
    page: 1,
    total_pages: 0,
    total: 0,
    limit: 25
  },
  fields: [
    {
      key: 'status',
      label: 'Status',
      sortable: false,
    },
    {
      key: 'name',
      label: 'Type',
      sortable: false
    },
    {
      key: 'username',
      label: 'User',
      sortable: false
    },
    {
      key: 'start_time',
      label: 'Start',
      sortable: false,
      formatter: print_date,
    },
    {
      key: 'complete_time',
      label: 'End',
      sortable: false,
      formatter: print_date,
    },
    {
      key: "resources",
      label: 'Scopes',
      sortable: false,
    },
    {
      key: 'description',
      label: 'Description',
      sortable: false
    },
    field_actions
  ],
  params: null,
  filters: {
    isEditable: (entry) => false,
    isExportable: (entry) => false,
    isDeletable: (entry) => false,
    isDownloadable: (entry) => false
  },
  lastTime: null
}

const getters = {
  get: (state) => (identifier) =>
    state._lookup[identifier],
  getAll: (state) => () =>
    state.list,
  getFields: (state) => () =>
    state.fields,
  getFilters: (state) => () =>
    state.filters,
  getEvents: (state) => (identifier) =>
    state._workflow_events[identifier],
  getPagination: (state) => () =>
    state.pagination
}


const actions = {
  toggle({dispatch, commit}, item) {
    commit('setDetails', {item, details: !item._showDetails})
  },
  cancel({dispatch, commit}, workflow) {
    var url = ""
    if (workflow.workzone_identifier != null)
      url = Config.API_WORKZONES_URL + "/" + workflow.workzone_identifier + "/workflows/" + workflow.identifier + "/control"
    else if (workflow.group_identifier != null)
      url = Config.API_GROUPS_URL + "/" + workflow.group_identifier + "/workflows/" + workflow.identifier + "/control"
    else
      url = Config.API_WORKFLOWS_URL + "/" + workflow.identifier
    return Vue.http.put(url, {"action": "cancel"})

  },
  load({dispatch, commit}, identifier) {
    return Vue.http.get(Config.API_WORKFLOWS_URL + "/" + identifier).then((response) => {
      let workflow = response.data
      commit('set', {identifier, workflow})
    })
  },
  update({dispatch, commit}) {
    var url = ""
    if (state.filters !== null && state.filters.workzone_identifier != null)
      url = Config.API_WORKZONES_URL + "/" + state.filters.workzone_identifier + "/workflows"
    else if (state.filters !== null && state.filters.group_identifier != null)
      url = Config.API_GROUPS_URL + "/" + state.filters.group_identifier + "/workflows"
    else
      url = Config.API_WORKFLOWS_URL

    let params_new = {
      start_time: moment(state.lastTime).format()
    }

    commit("setLastTime")

    Vue.http.get(url, {params: {...params_new, ...state.filters}}).then((response) => {
      let workflows = response.data
      commit('append', {workflows})
    })

    let params_running = {
      only: 'running'
    }

    Vue.http.get(url, {params: {...params_running, ...state.filters}}).then(async (response) => {
      let workflows = response.data
      let wasRunning = new Set([...state.running])
      await commit('updateRunning', {workflows})
      for (let identifier of wasRunning) {
        if (!state.running.has(identifier)) {
          dispatch("load", identifier)
        }

      }
    })
  },
  loadAll({dispatch, commit}, {filters = {}, ...resource_list_params}) {
    let params = build_resource_list_params(resource_list_params)

    // if (filters.workzone_identifier != null)
    //   url = Config.API_WORKZONES_URL + "/" + filters.workzone_identifier + "/workflows"
    // else if (filters.group_identifier != null)
    //   url = Config.API_GROUPS_URL + "/" + filters.group_identifier + "/workflows"
    // else
    //
    const url = Config.API_WORKFLOWS_URL;

    if (filters.workzone_identifier != null) {
      params["workzones"] = filters.workzone_identifier
    }
    if (filters.project_identifier != null) {
      params["projects"] = filters.project_identifier
    }
    if (filters.project_pool_identifier != null) {
      params["project_pools"] = filters.project_pool_identifier
    }
    if (filters.group_identifier != null) {
      params["groups"] = filters.group_identifier
    }

    commit("setLastTime")
    commit('setFilters', {filters})
    commit('setParams', {params})

    delete filters.workzone_identifier
    delete filters.group_identifier

    return Vue.http.get(url, {params: {...params, ...filters}}).then((response) => {
      let workflows = response.data
      commit('setAll', {workflows})
    })
  }
}

const mutations = {
  setLoad(state, value) {
    state.canLoad = value
  },
  setDetails(state, {item, details}) {
    Vue.set(item, '_showDetails', details)
  },
  setEvents(state, {identifier, events, append = false}) {

    if (!append) {
      if (state._workflow_events[identifier] === undefined)
        state._workflow_events[identifier] = []
      else
        state._workflow_events[identifier].splice(0, state._workflow_events[identifier])
    }

    for (let event of events)
      state._workflow_events[identifier].push(event)
  },
  setAll(state, {workflows}) {
    state._lookup = {}
    state.pagination.page = workflows.page
    state.pagination.total_pages = workflows.total_pages
    state.pagination.total = workflows.total
    state.pagination.limit = workflows.limit
    state.list.splice(0, state.list.length)
    state.running.clear()

    for (let workflow of workflows.data) {
      state.list.push(workflow)
      setDictValue(state._lookup, workflow.identifier, workflow)
      if (workflow.status === 'running') {
        state.running.add(workflow.identifier)
      }
    }
  },
  append(state, {workflows}) {
    for (let workflow of workflows.data.reverse()) {
      if (state._lookup[workflow.identifier] === undefined) {
        state.list.unshift(workflow)
        state.pagination.total += 1
      }
      if (workflow.status === 'running') {
        state.running.add(workflow.identifier)
      }
      setDictValue(state._lookup, workflow.identifier, workflow)
    }
    if (state.pagination.limit > 0) {
      state.list.slice(0, state.pagination.limit)
    }
  },
  updateRunning(state, {workflows}) {
    state.running.clear()
    // let final_status = ["succeeded", "failed", "cancelled"]
    for (let workflow of workflows.data) {
      state.running.add(workflow.identifier)
    }
  },
  set(state, {identifier, workflow}) {
    if (state._lookup[identifier] === undefined) {
      state.list.unshift(workflow)
      state.pagination.total++
    } else {
      workflow.progress = state._lookup[identifier].progress
    }
    setDictValue(state._lookup, identifier, workflow)
  },
  setStatus(state, {identifier, status}) {
    state._lookup[identifier].status = status
  },
  setFilters(state, filters) {
    state.filters = filters
  },
  setParams(state, params) {
    state.params = params
  },
  setLastTime(state) {
    state.lastTime = new Date().getTime()
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true
}
