<template>
  <b-row>
    <b-col lg="3" xl="2" class="d-flex justify-content-center container-menu fixed-right">
      <div class="mt-5 ml-md-5">
        <div class="d-sm-block d-md-flex d-lg-block">
          <div class="p-md-5 p-lg-2 mb-2">
            <b-row class="mb-2">
              <div class="order-by">
                  <a
                    v-for="item in sortOptions"
                    :key="item.text"
                    @click.up="updateSort(item)"
                    :class="{'active' : isActiveSortOption(item)}"
                  >
                    {{item.text}}
                  </a>
              </div>
            </b-row>
            <b-row v-if="isTokenValid">
              <a
                href="#"
                @click="
                  member = !member;
                  get_groups()
                "
                class="filter-member">
                <svg
                  v-if="member"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-check-square-fill text-white"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2zm10.03 4.97a.75.75 0 0 1 .011 1.05l-3.992 4.99a.75.75 0 0 1-1.08.02L4.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093 3.473-4.425a.75.75 0 0 1 1.08-.022z"
                  />
                </svg>
                <svg
                  v-else
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-square"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"
                  />
                </svg>
                <span class="px-2 font-weight-bold">I'm a member of</span>
                </a>
              </b-row>
          </div>
        </div>
      </div>
    </b-col>

    <b-col lg="9" xl="10" offset-lg="3" offset-xl="2">
      <div v-if="loadingmore" class="text-center my-5">
        <b-row>
          <b-col class="d-flex justify-content-center">
            <b-spinner class="align-middle"></b-spinner>
          </b-col>
        </b-row>
      </div>
      <div v-else-if="groups.length <= 0">
        <b-row>
          <b-col class="d-flex justify-content-center">
            <div class="text-xl my-5">
              There are no results for this search in the Hub.
            </div>
          </b-col>
        </b-row>
      </div>

      <div v-else class="container-groupList">
        <div class="groupList">
            <b-link
              v-for="group in groups"
              :key="group.identifier"
              @click="viewGroup(group.identifier)"
              class="tile">
              <div class="content">
                <button class="btn-edit-group"
                        v-if="isEditable(group)"
                        @click="viewEditGroup(group.identifier)">
                    <font-awesome-icon :icon="['fas', 'pen']"/>
                </button>
                <div class="container-img">
                  <img
                    v-if="group.icon && group.icon.url"
                    class="logo p-2"
                    :src="group.icon.url"
                    alt="Group logo"
                  />
                  <div v-else class="no-icon">
                    <div
                      class="generated-icon"
                      :style="{'background-color': generateIconColor(group)}">
                      <div class="text-icon">
                        {{ group.name[0].toUpperCase() }}
                      </div>
                    </div>
                  </div>
                </div>
<!--                <span class="vertical-hr></span>-->
                <div class="description">
                  <h5>{{ group.name }}</h5>
                  <VisibilityBadge v-if="isTokenValid" class="ml-2 visibility" :visibility="group.visibility"/><br/>
                  <span v-if="group.is_member"><em>member</em></span>
                </div>
                <div class="bottom-card">
                  <div class="card-labels">
                    <b-badge
                            class="label-badge lade-label"
                            style="margin: 3px"
                            v-for="label in group.labels"
                            :key="label"
                    >{{ label }}</b-badge
                    >
                  </div>
                  <small>
                    <p>
                      Created at
                      {{ group.created_at | print_date }}
                    </p>
                  </small>
                </div>
              </div>
            </b-link>
        </div>
        <div class="pagination">
          <Pagination
            :page="page"
            :skip="skip"
            :limit="limit"
            :total="total"
            :totalPages="totalPages"
            @page-change="onPageChange"
            @updateLimit="updateLimit"
          />
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import { generateIconColor } from "@/common";
import Pagination from "@/components/Pagination.vue";
import store from "@/store";
import Icon from "@/components/form/Icon.vue";
import { print_date } from "@/filters";
import VisibilityBadge from "@/components/VisibilityBadge.vue";

export default {
  components: {VisibilityBadge, Icon, Pagination},
  data: function () {
    let data = {
      member: false,
      tabValue: 0,
      filters: store.getters['groups/getFilters'](),
      skip: store.getters['groups/getSkip'](),
      total: store.getters['groups/getTotal'](),
      page: store.getters['groups/getPage'](),
      totalPages: store.getters['groups/getTotalPages'](),
      limit: 15,
      showLimitOptions: false,
      limitOptions: [10, 15, 25, 50],
      sortOptions: [
        {orderBy: 'created_date', desc: false, text: 'Newest'},
        {orderBy: 'created_date', desc: true, text: 'Oldest'},
        {orderBy: 'name', desc: true, text: 'Alphabetic'},
      ],
      showSortOptions: false,
      orderBy: 'created_date',
      desc: false
    }
    return data
  },
  props: {
    editors: Array,
    searchContent: String,
    reset: {
      type: Boolean,
      default: false
    },
    reloadGroups: {
      type: Boolean,
      default: true
    },
    reloadBundles: {
      type: Boolean,
      default: true
    },
  },
  computed: {
    isTokenValid: () => store.getters["token/isValid"](),
    groups: function () {
      return this.$store.getters["groups/getAll"]();
    },
    loadingmore: () => store.getters["groups/getLoading"](),
    currentPage: function () {
      return {
        text: 'Explore',
        to: {
          name: 'bundleGrid',
          params: {
            reload: true,
            objectSearched: "groups",
            reloadBundles: false
          }
        }
      };
    },
  },
  watch: {
    $route(/*to, from*/) {
      this.get_groups();
    },
    searchContent(){
      this.get_groups()
    }
  },
  mounted() {
    this.limit = 15
    // this.selectTab(this.$route.params.resource)

    if(this.reloadGroups) this.get_groups()
  },
  methods: {
    get_groups() {
      this.$store.dispatch('groups/loadAll',
        {
        orderBy: this.orderBy, desc: this.desc, labels: [], page: this.page,
        limit: this.limit, search: this.searchContent, load: "meta",
        member_of: this.member
      })
      .then(() => {
        // this.selectGroup(this.$route.params.identifier)
        this.page = this.$store.getters['groups/getPage']()
        this.total_pages = this.$store.getters['groups/getTotalPages']()
        this.limit = this.$store.getters['groups/getLimit']()
        this.skip = store.getters['groups/getSkip']()
        this.total = store.getters['groups/getTotal']()
      })
    },
    generateIconColor(group) {
      return generateIconColor(group.identifier);
    },
    next_page() {
      if (this.page < this.total_pages) {
        this.page += 1
        this.get_groups()
      }
    },
    previous_page() {
      if (this.page > 1) {
        this.page -= 1
        this.get_groups()
      }
    },
    isActiveSortOption(data) {
      return data.orderBy === this.orderBy && data.desc === this.desc
    },
    onPageChange(page) {
      this.page = page;
      this.get_bundles();
    },
    updateLimit(limit) {
      this.showLimitOptions = false
      if (limit !== this.limit) {
        this.limit = limit
        this.get_groups()
      }
    },
    updateSort(data) {
      this.showSortOptions = false
      if (!this.isActiveSortOption(data)) {
        this.orderBy = data.orderBy
        this.desc = data.desc
      }
      this.get_groups()
    },
    canAddGroup(){
      return this.$store.getters["token/hasPermission"]("GROUP", "CREATE")
    },
    viewGroup(identifier) {
      if(!this.clickEdit) {
        this.$router.push({
          name: 'groupBundleGrid',
          params: {
            identifier: identifier,
            previousPage: this.currentPage
          }
        })
      }
    },
    viewEditGroup(identifier) {
      this.clickEdit = true
      this.$router.push({
        name: 'editGroup',
        params: {
          identifier: identifier
        }
      })
    },
    isEditable(entry) {
      if (entry.authz && (entry.authz.permission & 0x04) === 0) {
        return false;
      }
      return this.filters.isEditable(entry)
    },
    filter() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.page = 1
        this.get_groups()
      }, 600);
    },
  },
  filters: {
    print_date,
  },
}
</script>

<style scoped>
.container-menu {
  margin-top: 0;
  height: 100%;
}

.order-by {
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.order-by a:first-child {
  border-radius: 5px 0 0 5px;
}

.order-by a:last-child {
  border-radius: 0 5px 5px 0;
}

.fixed-right {
  position:fixed;
}

@media screen and (max-width: 960px) {
  .fixed-right {
    position:relative;
  }
}

.filter-member {
  background-color: var(--lade-green-explore-3);
  color: var(--lade-color-white-hover);
  font-weight: bold;
  text-transform: uppercase;
  margin: 10px 0 0 0;
  padding: 0.75rem 1.25rem;
  display: block;
  position: relative;
  border: 1px solid var(--lade-color-white);
  text-align: inherit;
  text-decoration: none;
  width: 100% !important;
}

.container-groupList {
  width: 100%;
  margin: 20px 0 0 0;
  padding: 1% 2%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}

.groupList {
  height: 100% !important;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

 .groupList .tile {
  width: 26rem;
  height: 15rem;
  margin: 0 15px 15px 0;
  background-color: var(--lade-color-white-hover);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  text-decoration: none !important;
  color: var(--lade-color-dark);
  border: 1px solid var(--lade-color-white);
}

.groupList .tile .content {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  height: 100%;
  width: 100%;
  position: relative;
}

.groupList .btn-edit-group{
  text-decoration: none;
  border-radius: 50% 50%;
  background-color: var(--lade-color-white-hover);
  padding: 5px 5px 2px 5px;
  font-size: 1.1em;
  color: var(--lade-color-black);
  transition: 0.2s;
  position: absolute;
  top: 10px;
  right: 10px;
  border: solid 1px transparent;
}

.groupList .btn-edit-group:hover{
  transform: rotateZ(90deg);
  box-shadow: var(--lade-color-white) 0 2px 8px;
}

.tile .container-img {
  overflow: hidden;
  height: 100%;
  border-radius: 10px 0 0 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 10rem;
}

.no-icon {
  height: 100%;
}

.generated-icon {
  padding-left: 0;
  width: 10rem;
  height: 100%;
  transition: 0.5s ease-in;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center
}

.container-img .logo {
  width: 11.25rem;
  height: 100%;
  margin: 0 0 auto;
  object-fit: scale-down;
  border-radius: 10px 10px 0 0;
  padding: 0 12px !important;
  transition: 1s cubic-bezier(0.155, 1.105, 0.295, 1.12);
}

.tile:hover .logo,
.tile:hover .generated-icon{
  transform: scale(1.3);
}

.groupList .description {
  padding: 15px 10px 15px 10px;
  height: 100%;
  width: 16rem;
}

.groupList .description .visibility{
  margin-bottom: 6.5px;
}

.groupList .tile .description span{
  background-color: var(--lade-green-explore-3);
  color: var(--lade-color-white-hover);
  text-transform: uppercase;
  font-size: 0.9em;
  padding: 4px 6px 4px 6px;
  border-radius: 3px 3px;
  font-weight: bold;
}

.pagination-button {
  color: var(--lade-color-black) !important;
  background-color: Transparent !important;
  border: none !important;
  border-radius: 3px;
}

.pagination-button:hover {
  background-color: var(--lade-color-white) !important;
}

.pagination-button:focus {
  background-color: var(--lade-color-white) !important;
  border: none !important;
  box-shadow: none ! important;
}

.page-options {
  white-space: normal;
  position: absolute;
  bottom: 0;
  left: -62.5px;
  z-index: -1;
  list-style: none;
  overflow-y: auto;
  height: auto;
  max-height: 150px;
  border: 0 !important;
  background-color: var(--lade-color-white-hover) !important;
  border-radius: 5px 5px;
  width: 250px;
  box-shadow: var(--lade-color-white) 0 1px 4px;
  opacity: 0;
  transition: 0.2s ease-out;
}

.page-options button {
  background-color: var(--lade-color-white-hover) !important;
}

.pagination-text:hover .page-options {
  z-index: 101;
  opacity: 1;
  bottom: 30px;
}

.page-option {
  border: none !important;
  background-color: var(--lade-color-white-hover) !important;
}

.page-option:hover {
  background-color: var(--lade-color-white) !important;
}

.page-option:focus {
  box-shadow: none ! important;
}

.bottom-card {
  position: absolute;
  bottom: 0px;
  left: 10rem;
  width: calc(26rem - 10rem);
  padding: 2px 10px 10px 10px;
  background-color: var(--lade-color-white);
  border-radius: 0 0 10px 0;
  border-top: 1px solid var(--lade-color-white);
  border-left: 1px solid var(--lade-color-white);
}

.bottom-card p{
  margin: 0 0;
}

select {
  height: 100px;
  line-height: 25px;
  background-color: var(--lade-color-white);
}

select:focus {
  outline: none;
}

select[multiple] {
  height: 100px;
  line-height: 25px;
  background: var(--lade-color-white);
}

.pagination {
  white-space: nowrap;
  display: inline-block;
  margin: 0.350rem auto;
  position: relative;
}
</style>
