<template>
  <b-card no-body style="margin: 20px;">
    <b-card-header>
      <b-row no-gutters>
        <b-col md="6">Access tokens</b-col>
        <b-col md="6" class="text-right">
          <b-button @click.stop="onAdd()">New Access Token</b-button>
          <AccessTokenModal ref="accessTokenModal" title="New access token"></AccessTokenModal>
        </b-col>
      </b-row>
    </b-card-header>

    <b-card-body>

      You can generate a personal access token for each application you use that needs access to the CyberRange.

    </b-card-body>

    <b-card-body>

      <b-card v-if="newCreatedToken" no-body>
        <b-card-body>
          <b-card-title>New personal access token '{{ newCreatedToken.name }}'</b-card-title>
          <b-card-text>
            <code class="command">{{ newCreatedToken.token }}</code>
            <CopyButton :value="newCreatedToken.token" />
          </b-card-text>
          <b-card-text>Expire: {{ new Date(newCreatedToken.expiration) }}</b-card-text>
          <b-card-sub-title class="mb-2">Make sure you save it - you won't be able to access it again.
          </b-card-sub-title>
        </b-card-body>
      </b-card>

      <b-table hover :items="accessTokens" :fields="fields" :busy="loading">

        <template v-slot:table-busy>
          <div class="text-center">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>

        <!-- <template v-slot:cell(name)="row">
            <b-form-input :ref="nameCellRef(row)"
                :value="row.item.name"
                @change="onNameChange(row)"
                placeholder="Enter a name"
                autocomplete="off" :required="true"
            ></b-form-input>
        </template> -->
        <template v-slot:cell(name)="row">
          <b-spinner v-if="isCellUpdating(row)" class="align-middle"></b-spinner>
          <input v-else :ref="nameCellRef(row)" type="text"
                 :value="row.item.name"
                 @change="onNameChange(row)"
                 placeholder="Enter a name"
                 autocomplete="off" required
                 class="form-control"/>
        </template>

        <template v-slot:cell(created_at)="row">{{ new Date(row.item.created_at).toLocaleString() }}</template>

        <template v-slot:cell(expiration)="row">{{ new Date(row.item.expiration).toLocaleString() }}</template>

        <template v-slot:cell(status)="row">
          <b-form-checkbox :checked="isEnabled(row.item.status)" @change="onStatusChange(row.item)" name="status-button"
                           switch></b-form-checkbox>
        </template>

        <template v-slot:cell(edit)="row">
          <b-button variant="danger" size="sm" @click="onDelete(row.item)" class="mr-2">Revoke</b-button>
        </template>

      </b-table>
    </b-card-body>
  </b-card>
</template>

<script>
import AccessTokenModal from './AccessTokenModal.vue'
import CopyButton from '@/components/CopyButton.vue'

export default {
  name: 'AccessTokenList',
  components: {
    AccessTokenModal, CopyButton
  },
  data() {
    return {
      loading: false,
      fields: ['name', {key: 'created_at', label: 'Created'}, {key: 'expiration', label: 'Expires'}, {
        key: 'status',
        label: 'Enabled'
      }, {key: 'edit', label: ''}],
      newCreatedToken: null,
      updatingTokens: [],
    }
  },
  computed: {
    accessTokens() {
      // Best to sort the list as it's not always returned by server in the same order, which is an issue when we update the list
      const accessTokens = this.$store.getters['accessTokens/getAll']()
      return accessTokens.slice().sort((a, b) => new Date(a.created_at) - new Date(b.created_at))
    },
  },
  mounted: function () {
    this.$eventBus.$on('new-access-token', (token) => {
      this.newCreatedToken = token
    })
    this.$eventBus.$on('new-access-token-error', (response) => {
      this.newCreatedToken = null
      this.$eventBus.$emit('http-error', response)
    })

    this.$store.dispatch('accessTokens/loadAll')
  },
  methods: {
    nameCellRef(row) {
      return 'input_name_' + row.index
    },
    isCellUpdating(row) {
      return this.updatingTokens.includes(row.item.identifier)
    },
    setCellUpdating(row, isUpdating) {
      if (isUpdating) {
        this.updatingTokens.push(row.item.identifier)
      } else {
        this.updatingTokens.splice(this.updatingTokens.indexOf(row.item.identifier), 1)
      }
    },
    isEnabled(status) {
      return status.toUpperCase() === 'ENABLED'
    },
    onAdd() {
      this.newCreatedToken = null
      this.$refs['accessTokenModal'].openModal()
    },
    onDelete(token) {
      this.$bvModal.msgBoxConfirm('Are you sure you want to revoke this personal access token? This action cannot be undone.', {
        title: 'Delete access token',
        okVariant: 'danger',
        hideHeaderClose: false,
        centered: true
      })
        .then(value => {
          if (value) {
            this.$store.dispatch('accessTokens/delete', token.identifier)
            this.newCreatedToken = null
          }
        })
    },
    onStatusChange(token) {
      let status = this.isEnabled(token.status) ? 'disabled' : 'enabled'
      this.$store.dispatch('accessTokens/update', {identifier: token.identifier, data: {status}})
    },
    onNameChange(row) {
      let identifier = row.item.identifier
      let name = this.$refs[this.nameCellRef(row)].value
      // console.log('change name:')
      // console.log(row)
      // console.log(identifier)
      // console.log(name)
      if (name) {
        this.setCellUpdating(row, true)
        this.$store.dispatch('accessTokens/update', {identifier, data: {name}}).then(() => {
          this.setCellUpdating(row, false)
        }).catch(() => {
          this.setCellUpdating(row, false)
        })
      }
    },
  },
}
</script>

<style scoped>
.command {
  background: var(--lade-color-dark-grey);
  color: var(--lade-color-white-hover);
  display: inline;
  font-size: small;
  margin: 10px;
  padding: 5px 10px 5px 0px;
  box-shadow: -16px 0 0 var(--lade-color-dark-grey);
}
</style>
