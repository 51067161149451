<template>
  <div style="background: #333; width:100%; padding-left:20px; padding-right:20px;">
    <h4 class="hr" style="text-align:center; color:white; padding:20px; ">End User License Agreement</h4>

    <div style="overflow-y: scroll; height:75vh; background-color:white; padding:40px;">
				<pre style="white-space: pre-wrap;">

{{ eula }}

				</pre>
    </div>
  </div>
</template>

<script>
import store from '../../store'

export default {
  name: 'AcceptEULA',
  data() {
    return {
      eula: store.getters["about/get"]().eula
    }
  }
}
</script>

