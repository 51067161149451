<template>
  <b-card class="scenario-tile tile" no-body onClick="">
    <b-link style="text-decoration: none;"
            :to="{name: 'scenarioPage', params: {
                identifier: scenario.identifier, bundleIdentifier: scenario.bundle_identifier}}">
      <b-row no-gutters>
        <b-col class="col-preview" cols=3 v-if="preview == null"><b-spinner class="align-middle spinner"></b-spinner></b-col>
        <b-col class="col-preview" cols=3 v-else>
          <b-card-img class="preview-style" :top=true :src="preview"
                      alt="Scenario preview"></b-card-img>
        </b-col>

        <!-- scenario description-->
        <b-col cols='9' style="padding-left: 30px; padding-right: 35px; margin-top: 10px;">
          <b-row no-gutters class="scenario-title">
            <h3>
              <img style="height: 1.75rem; padding-right: 1rem;" v-if="scenario.icon && scenario.icon.url" :src="scenario.icon.url" alt="Scenario icon"/>
              {{scenario.name}}
            </h3>
            <h6>
              <b-badge class="lade-label">{{scenario.qualifier}}</b-badge>
            </h6>
          </b-row>
          <div class="card-row"><div><B>Tag: </B>{{scenario.alias}}:{{scenario.qualifier}}</div></div>
          <div>
            <small>
              Last update {{ scenario.created_at | print_date }}
            </small>
          </div>
          <div style="color: grey; height: 60px; overflow-y:hidden; text-overflow: ellipsis;">
            <vue-markdown>{{ description }}</vue-markdown>
          </div>
          <div class="labels"><b-badge class="label-badge lade-label" v-for="label in scenario.labels" :key="label">{{label}}</b-badge></div>
        </b-col>
      </b-row>
    </b-link>
  </b-card>
</template>

<script>
import * as ladeWebUI from 'lade-webui'
import {truncateWithEllipses} from "@/common";

export default {
  name: 'ScenarioTile',
  components: {
  },
  props: {
    resource: Object,
  },
  data: function() {
    return {
      isBusy: true,
      preview: null,
      scenario: this.resource
    }
  },
  mounted: function() {
    this.generateScenarioPreview()
  },
  computed: {
    description: function() {
      const regex = /[#]+ .+/ig
      return truncateWithEllipses(this.scenario.description.replaceAll(regex, ""), 150)
    }
  },
  methods: {
    generateScenarioPreview() {

      ladeWebUI.getPreview(this.scenario, {}).then((result) => {
        if (result.element.children.length > 0) {
          this.preview = result.element.children[0].src
        }
        else {
          this.preview = require("@/assets/background_pattern_light.png")
        }
      })
    }
  }
}
</script>

<style scoped>

.scenario-title {
  align-items: center;
  color: var(--lade-color-dark);
}

small {
  color: var(--lade-color-dark)
}

.scenario-title h2 {
  margin-right: 10px
}

.scenario-title h3 {
  margin-right: 10px;
  font-size: 1.75rem;
}

.spinner{
  position: absolute;
  height: 40px;
  width: 40px;
  top: 45%;
  left: 45%;
  margin-left: auto;
  margin-top: auto;
}

.labels {
  padding-top: 2px;
  padding-bottom: 2px;
}

.label-badge {
  font-size: 90%;
  text-align: center;
  vertical-align: center;
  padding: 5px;
}

.lade-label {
  color: var(--lade-color-grey);
  font-weight: 400;
  background-color: var(--lade-color-white);
  font-size: 90%;
  text-align: center;
  vertical-align: center;
}

.card-row {
  margin-top: 10px;
}

.scenario-tile {
  width: 100%;
  height: 190px;
  margin: 10px auto 20px;
}

.scenario-tile:hover {
  box-shadow: 0 0 3px var(--lade-color-dark-grey);
}

.preview-style {
  background-image: url("~@/assets/background_pattern_light.png");
  height: 170px;
  object-fit: scale-down;
  margin: 10px;
}

</style>
