<template>
  <div class="sectionEditProfile">
    <div class="content">
      <div class="head-content">
        <h2>Access Tokens </h2>
        <div class="menu-edit">
          <button
            v-if="!loading"
            @click.stop="onAdd"
            type="submit"
            class="btn btn-primary">
            New access token
          </button>
          <b-spinner v-else class="loading top"></b-spinner>
        </div>
        <AccessTokenModal ref="accessTokenModal" title="New access token"></AccessTokenModal>
      </div>

      <div class="edit">
        <p style="margin: 15px 0 5px 0; width: 100%">
          You can generate a personal access token for each application you use that needs access to the CyberRange.
        </p>
        <div class="group">
          <b-card v-if="newCreatedToken" no-body class="new-token">
            <b-card-body>
              <b-card-title>New personal access token '{{ newCreatedToken.name }}'</b-card-title>
              <b-card-text>
                <code class="command">{{ newCreatedToken.token }}</code>
                <CopyButton :value="newCreatedToken.token" />
              </b-card-text>
              <b-card-text>Expire: {{ new Date(newCreatedToken.expiration) }}</b-card-text>
              <b-card-sub-title class="mb-2">Make sure you save it - you won't be able to access it again.
              </b-card-sub-title>
            </b-card-body>
          </b-card>
          <b-table hover :items="accessTokens" :fields="fields" :busy="loading">

            <template v-slot:table-busy>
              <div class="text-center">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>

            <!-- <template v-slot:cell(name)="row">
                <b-form-input :ref="nameCellRef(row)"
                    :value="row.item.name"
                    @change="onNameChange(row)"
                    placeholder="Enter a name"
                    autocomplete="off" :required="true"
                ></b-form-input>
            </template> -->
            <template v-slot:cell(name)="row">
              <b-spinner v-if="isCellUpdating(row)" class="align-middle"></b-spinner>
              <input v-else :ref="nameCellRef(row)" type="text"
                     :value="row.item.name"
                     @change="onNameChange(row)"
                     placeholder="Enter a name"
                     autocomplete="off" required
                     class="form-control"/>
            </template>

            <template v-slot:cell(created_at)="row">{{ new Date(row.item.created_at).toLocaleString() }}</template>

            <template v-slot:cell(expiration)="row">{{ new Date(row.item.expiration).toLocaleString() }}</template>

            <template v-slot:cell(status)="row">
              <b-form-checkbox :checked="isEnabled(row.item.status)" @change="onStatusChange(row.item)" name="status-button"
                               switch></b-form-checkbox>
            </template>

            <template v-slot:cell(edit)="row">
              <b-button variant="danger" size="sm" @click="onDelete(row.item)" class="mr-2">Revoke</b-button>
            </template>

          </b-table>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import CopyButton from "@/components/CopyButton.vue";
import AccessTokenModal from "@/components/profile/AccessTokenModal.vue";

export  default {
  name: "SettingsToken",
  components: {AccessTokenModal, CopyButton},
  props: {
    profile: Object,
  },
  data() {
    return {
      loading: false,
      fields: ['name', {key: 'created_at', label: 'Created'}, {key: 'expiration', label: 'Expires'}, {
        key: 'status',
        label: 'Enabled'
      }, {key: 'edit', label: ''}],
      newCreatedToken: null,
      updatingTokens: [],
    }
  },
  computed: {
    accessTokens() {
      // Best to sort the list as it's not always returned by server in the same order, which is an issue when we update the list
      const accessTokens = this.$store.getters['accessTokens/getAll']()
      return accessTokens.slice().sort((a, b) => new Date(a.created_at) - new Date(b.created_at))
    },
  },
  mounted() {
    this.$eventBus.$on('new-access-token', (token) => {
      this.newCreatedToken = token
    })
    this.$eventBus.$on('new-access-token-error', (response) => {
      this.newCreatedToken = null
      this.$eventBus.$emit('http-error', response)
    })

    this.$store.dispatch('accessTokens/loadAll')
  },
  methods: {
    nameCellRef(row) {
      return 'input_name_' + row.index
    },
    isCellUpdating(row) {
      return this.updatingTokens.includes(row.item.identifier)
    },
    setCellUpdating(row, isUpdating) {
      if (isUpdating) {
        this.updatingTokens.push(row.item.identifier)
      } else {
        this.updatingTokens.splice(this.updatingTokens.indexOf(row.item.identifier), 1)
      }
    },
    isEnabled(status) {
      return status.toUpperCase() === 'ENABLED'
    },
    onAdd() {
      this.newCreatedToken = null
      this.$refs['accessTokenModal'].openModal()
    },
    async onDelete(token) {
      this.$bvModal.msgBoxConfirm('Are you sure you want to revoke this personal access token? This action cannot be undone.', {
        title: 'Delete access token',
        okVariant: 'danger',
        hideHeaderClose: false,
        centered: true
      }).then(async value => {
        if (value) {
          this.loading = true
          await this.$store.dispatch('accessTokens/delete', token.identifier)
          this.loading = false
          this.newCreatedToken = null
        }
      })
    },
    onStatusChange(token) {
      let status = this.isEnabled(token.status) ? 'disabled' : 'enabled'
      this.$store.dispatch('accessTokens/update', {identifier: token.identifier, data: {status}})
    },
    onNameChange(row) {
      let identifier = row.item.identifier
      let name = this.$refs[this.nameCellRef(row)].value
      if (name) {
        this.setCellUpdating(row, true)
        this.$store.dispatch('accessTokens/update', {identifier, data: {name}}).then(() => {
          this.setCellUpdating(row, false)
        }).catch(() => {
          this.setCellUpdating(row, false)
        })
      }
    },
  },
}
</script>

<style scoped>
.sectionEditProfile .content {
  width: 100%;
  position: relative;
  overflow-y: auto;
  border: 1px solid var(--lade-color-white);
}

.sectionEditProfile .head-content {
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 2;
  backdrop-filter: blur(10px);
  padding: 15px 15px;
  box-shadow: rgba(149, 157, 165, 0.2) 0 8px 24px;
  border-radius: 0 0 10px 10px;
}

.sectionEditProfile .head-content h2 {
  width: 100%;
  padding: 0 0;
  margin:  0 0;
}


.menu-edit {
  position: absolute;
  top: 15px;
  right: 20px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 140px;
  z-index: 1;
}

.loading{
  width: 20px;
  height: 20px;
  position: absolute;
}

.loading.top {
  right: 20px;
  top: 5px;
}

.edit {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow-y: auto;
  position: relative;
  width: 100%;
  padding: 15px 15px 105px 15px;
}

.group {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 20px 10px 15px 10px;
  border: solid 1px var(--lade-color-white);
  border-radius: 10px 10px;
  position: relative;
  margin-top: 10px;
}

.new-token {
  width: 100%;
  margin-bottom: 20px;
}

</style>
