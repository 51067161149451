<template>
  <div>
    <b-alert show variant="danger">
      <font-awesome-icon :icon="['fas', 'ban']" size="lg"/>
      You are not authorized to access this page.
    </b-alert>
  </div>
</template>

<script>
export default {
  name: "Forbidden"
}
</script>
