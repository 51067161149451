<template>
  <b-button
    class="mx-2 btn-sm"
    variant="outline-dark"
    v-clipboard:copy="value"
    v-clipboard:success="onCopied"
    v-b-tooltip.hover
    :title="tooltipTitle"
    v-on:mouseleave="resetTooltip()"
  >
    <font-awesome-icon :icon="['fa', 'paste']" />
  </b-button>
</template>

<script>
export default {
  name: "CopyButton",
  props: {
    value: String,
  },
  data() {
    return {
      tooltipTitle: "Copy",
    };
  },
  methods: {
    onCopied() {
      this.tooltipTitle = "Copied!";
      setTimeout(() => {
        this.resetTooltip();
      }, 2000);
    },
    resetTooltip() {
      this.tooltipTitle = "Copy";
    },
  },
};
</script>
