<template>
  <b-modal ref="_registryApplicationForm" :title="title" size="lg"
           @show="reset"
           @hidden="reset"
           @ok="onOk"
  >
    <form ref="form" @submit.stop.prevent="onSubmit">
      <b-form-group id="input-group-name" label-for="input-name"
                    :state="nameState"
                    invalid-feedback="Name is required">

        <label><strong>Name: </strong></label>
        <b-form-input
          id="input-name"
          v-model="name"
          required>
        </b-form-input>

      </b-form-group>
      <b-form-group id="input-group-url" label-for="input-url"
                    invalid-feedback="Utl is required">

        <label><strong>Url: </strong></label>
        <b-form-input
          id="input-url"
          v-model="url"
          required>
        </b-form-input>

        <b-form-checkbox id="input-2" v-model="insecure" name="insecure-checkbox" switch>
          Do not verify SSL
        </b-form-checkbox>
      </b-form-group>

      <b-form-group v-if="useCreds" id="input-group-creds" label-for="input-credentials"
                    invalid-feedback="Username/Password are required">
        <label><strong>Username: </strong></label>
        <b-form-input
          id="input-username"
          v-model="username"
          required>
        </b-form-input>
        <label><strong>Password: </strong></label>
        <b-form-input
          :type="passwordVisibility ? 'text' : 'password'"
          id="input-password"
          v-model="password"
          required>
        </b-form-input>

        <font-awesome-icon :icon="['fas', 'eye-slash']"
                           @click="passwordVisibility=true"
                           style="cursor: pointer; float: right; margin-right: 10px; margin-top: -22px; position: relative; z-index: 2;"
                           v-if="passwordVisibility===false"/>
        <font-awesome-icon :icon="['fas', 'eye']"
                           @click="passwordVisibility=false"
                           style="cursor: pointer; float: right; margin-right: 10px; margin-top: -22px; position: relative; z-index: 2;"
                           v-if="passwordVisibility===true"/>
      </b-form-group>
      <b-form-group v-else id="input-group-token" label-for="input-token"
                    invalid-feedback="Token is required">


        <label><strong>Personal Access Token: </strong></label>
        <b-form-input
          :type="tokenVisibility ? 'text' : 'password'"
          id="input-token"
          v-model="token"
          required>
        </b-form-input>

        <font-awesome-icon :icon="['fas', 'eye-slash']"
                           @click="tokenVisibility=true"
                           style="cursor: pointer; float: right; margin-right: 10px; margin-top: -22px; position: relative; z-index: 2;"
                           v-if="tokenVisibility===false"/>
        <font-awesome-icon :icon="['fas', 'eye']"
                           @click="tokenVisibility=false"
                           style="cursor: pointer; float: right; margin-right: 10px; margin-top: -22px; position: relative; z-index: 2;"
                           v-if="tokenVisibility===true"/>
      </b-form-group>
      <div v-if="useCreds">Password will be used to create a personal access token and won't be store in database. Click <b-link @click="useCreds=false">here</b-link> if you want to copy a personal access token. </div>
      <div v-else><b-link @click="useCreds=true">Use username/password</b-link> </div>

    </form>
  </b-modal>
</template>

<script>

export default {
  name: 'RegistryApplicationForm',
  components: {},
  props: {
    title: String,
  },
  data() {
    return {
      name: '',
      nameState: true,
      insecure: false,
      url: null,
      token: null,
      username: null,
      password: null,
      tokenVisibility: false,
      passwordVisibility: false,
      useCreds: true
    }
  },
  methods: {
    expirationISO() {
      return this.expiration ? this.expiration.toISOString() : null
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity()
      // this.nameState = this.name.length > 0
      this.nameState = valid
      return valid
    },
    onOk(evt) {
      // Prevent modal from closing
      evt.preventDefault()
      // Trigger submit handler
      this.onSubmit(evt)
    },
    onSubmit(evt) {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return
      }
      let payload = {
        name: $.trim(this.name),
        url: $.trim(this.url),
        insecure: this.insecure
      }
      if (this.useCreds) {
        payload['username'] = $.trim(this.username)
        payload['password'] = this.password
      } else {
        payload['token'] = $.trim(this.token)
      }
      this.$store.dispatch('userApps/add_registry', payload)

      // Hide the modal manually
      this.$nextTick(() => {
        this.$refs._registryApplicationForm.hide()
      })
    },
    reset() {
      // Reset our form values
      this.name = ''
      this.enabled = false
      this.expiration = null
    },
    openModal() {
      this.$refs._registryApplicationForm.show()
    },
  }
}
</script>
