<template>
  <div style="display: inline-block;">
    <font-awesome-icon
      v-if="visibility==='private'"
      :icon="['fas', 'eye-slash']"
      title="Private: Only assigned people can access."/>
    <font-awesome-icon
      v-if="visibility==='internal'"
      :icon="['fas', 'shield-alt']"
      title="Internal: Connected users can access."/>
    <font-awesome-icon
      v-if="visibility==='public'"
      :icon="['fas', 'globe']"
      title="Public: Everyone can access, even without logging in."/>
  </div>
</template>

<script>
export default {
  name: "VisibilityBadge",
  props: {
    visibility: String,
  }
}

</script>

<style scoped>

</style>
