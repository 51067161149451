<template>
  <div class="labels">
    <div v-for="label in labels" :key="label">
      <b-badge class="lade-label label-badge" >{{label}}
        <font-awesome-icon v-if="isEditable" class="input-cancel-label" :icon="['fas', 'times']" @click="deleteLabel(label)"/>
      </b-badge>

    </div>
    <div v-if="isEditable">

      <div v-if="!showInputLabel">
        <b-badge class="lade-label label-badge" >
          <font-awesome-icon :icon="['fas', 'plus']" @click="showInputLabel=true" class="add-label"/>
        </b-badge>
      </div>
      <div v-else>
        <b-badge class="lade-label label-badge" >
          <input v-model="newLabel" autocomplete="off" type="text" class="input-label" v-on:keyup.enter="addLabel()"/>
          <font-awesome-icon :icon="['fas', 'check']" @click="addLabel()" class="input-cancel-label"/>
          <font-awesome-icon :icon="['fas', 'times']" @click="cancelLabel()" class="input-cancel-label"/>
        </b-badge>
      </div>
    </div>
  </div>
</template>


<script>
export default {
  name: 'ContentLabels',
  props: {
    resourceType: String,
    content: Object,
    isEditable: Boolean,
  },
  data: function() {
    return {
      showInputLabel: false,
      newLabel: "",
      labels: this.content.labels
    }
  },
  methods: {
    async addLabel() {
      let labels = [...this.content.labels]
      if (labels.includes(this.newLabel)) {
        this.$eventBus.$emit('error', "Label already exists")
        return
      }
      labels.push(this.newLabel)
      let data = {identifier: this.content.identifier, data: {labels: labels}}
      if (this.resourceType === 'bundles') {
        data['group_identifier'] = this.content.group_identifier
      }
      try {
        await this.$store.dispatch(this.resourceType + '/update', data)
        this.newLabel = ""
        this.showInputLabel = false
        this.labels = labels
      } catch (error) {
        this.$eventBus.$emit('http-error', error)
      }
    },
    cancelLabel() {
      this.newLabel = ""
      this.showInputLabel = false
    },
    async deleteLabel(label) {
      let labels =  this.content.labels.filter(function(ele){
        return ele !== label;
      });
      let data = {identifier: this.content.identifier, data: {labels: labels}}
      if (this.resourceType === 'bundles') {
        data['group_identifier'] = this.content.group_identifier
      }
      try {
        await this.$store.dispatch(this.resourceType + '/update', data)
        this.labels = labels
      } catch (error) {
        this.$eventBus.$emit('http-error', error)
      }
    },
  }
}
</script>


<style scoped>

.labels {
  padding-bottom: 2px;
  display: flex;
  flex-wrap: nowrap;
}

.label-badge {
  height: 18px;
  margin-right: 5px;
}

.input-label {
  background-color: unset;
  margin-top: -2px;
  margin-bottom: -2px;
  width: 100px;
  color: var(--lade-color-grey);
  border: 0;
  border-radius: 0.25rem;
}

.add-label {
  margin-top: -2px;
  margin-bottom: -2px;
  height: 14px;
  position: relative;
  color: var(--lade-color-grey);
  cursor: pointer;
}

.input-cancel-label {
  margin-bottom: -1px;
  height: 12px;
  position: relative;
  color: var(--lade-color-grey);
  cursor: pointer;
  margin-left: 2px;
}

.input-label:focus {
  background-color: unset;
  outline: none !important;
  box-shadow: none;
  border-color: var(--lade-color-grey);
  color: var(--lade-color-grey);
}

</style>
