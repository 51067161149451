let LADE_URI_BASE = document.location.origin

if (process.env.NODE_ENV === 'development') {
  if ("VUE_APP_LADE_BASE_URL" in process.env) {
    LADE_URI_BASE = process.env.VUE_APP_LADE_BASE_URL
  }
  else {
    LADE_URI_BASE = "https://beta.cyberrange.cloud"
  }
}

let API_URI_BASE = LADE_URI_BASE + "/api"

class Config {

  base = '/manager'

  get GRAFANA_URL () {
    return LADE_URI_BASE + "/admin/grafana"
  }

  get API_BASE_URL() {
    return API_URI_BASE
  }

  get API_PROFILE_URL() {
    return API_URI_BASE + "/me"
  }

  get API_RESET_URL() {
    return API_URI_BASE + "/reset"
  }

  get API_SESSIONS_URL() {
    return API_URI_BASE + "/sessions"
  }

  get API_USERS_URL() {
    return API_URI_BASE + "/users"
  }

  get API_GROUPS_URL() {
    return API_URI_BASE + "/groups"
  }

  get API_RULES_SPECS_URL() {
    return API_URI_BASE + "/rules-specs"
  }

  get API_TOKEN_URL() {
    return API_URI_BASE + "/token"
  }

  get API_ABOUT_URL() {
    return API_URI_BASE + "/about"
  }

  get API_TOPOLOGIES_URL() {
    return API_URI_BASE + "/topologies"
  }

  get API_ASSETS_URL() {
    return API_URI_BASE + "/assets"
  }

  get API_ACTIONS_URL() {
    return API_URI_BASE + "/actions"
  }

  get API_SCENARIOS_URL() {
    return API_URI_BASE + "/scenarios"
  }

  get API_FILES_URL() {
    return API_URI_BASE + "/files"
  }

  get API_BUNDLES_URL() {
    return API_URI_BASE + "/bundles"
  }

  get API_PROVIDERS_URL() {
    return API_URI_BASE + "/providers"
  }

  get API_CATEGORIES_URL() {
    return API_URI_BASE + "/categories"
  }

  get API_WORKFLOWS_URL() {
    return API_URI_BASE + "/workflows"
  }

  get API_HOSTS_URL() {
    return API_URI_BASE + "/hosts"
  }

  get API_NETWORKS_URL() {
    return API_URI_BASE + "/networks"
  }

  get API_EVENTS_URL() {
    return API_URI_BASE + "/events"
  }

  get API_STREAM_URL() {
    return API_URI_BASE + "/stream/events"
  }

  get API_WORKZONES_URL() {
    return API_URI_BASE + "/workzones"
  }

  get API_PROJECTS_URL() {
    return API_URI_BASE + "/projects"
  }

  get API_PROJECT_POOLS_URL() {
    return API_URI_BASE + "/project_pools"
  }

  get API_BOOKINGS_URL() {
    return API_URI_BASE + "/bookings"
  }

  get API_MEASUREMENTS_URL() {
    return API_URI_BASE + "/monitoring/measurements"
  }

  get API_CONFIG_URL() {
    return API_URI_BASE + "/config"
  }

  get API_LICENSE_URL() {
    return API_URI_BASE + "/license"
  }

  get API_GENERATE_REPORT_URL() {
    return API_URI_BASE + "/monitoring/report"
  }

  get API_DOWNLOAD_LAST_LOGS_URL() {
    return API_URI_BASE + "/monitoring/logs"
  }

  get API_ACCEPT_EULA_URL() {
    return API_URI_BASE + "/accept_eula"
  }

  get API_PERSONAL_ACCESS_TOKENS_URL() {
    return API_URI_BASE + "/me/personal_access_tokens"
  }

  get API_NOTIFICATIONS_URL() {
    return API_URI_BASE + "/notifications"
  }

  get DOC_URL() {
    return LADE_URI_BASE + "/static/doc/"
  }

  get API_PLUGINS_URL() {
    return API_URI_BASE + "/plugins"
  }

  get API_PUBLIC_PLUGINS_URL() {
    return API_URI_BASE + "/pub-plugins"
  }

  get SHARE_TOKENS_URL() {
    return API_URI_BASE + "/share_tokens"
  }
}

export default new Config()
