<template>
  <div class="sectionEditProfile">
    <div class="content">
      <div class="head-content">
        <h2>Profile</h2>
        <div class="menu-edit">
          <button
            v-if="!loading"
            @click.stop="init"
            class="btn button btn-secondary">
            Cancel
          </button>
          <button
            v-if="!loading"
            @click.stop="validatEditUser"
            type="submit"
            class="btn btn-primary">
            Submit
          </button>
          <b-spinner v-else class="loading top"></b-spinner>
        </div>
      </div>
      <div class="edit">
        <div class="border-content">
          <div class="group email">
            <div>
              <label class="label">EMAIL ADRESS</label>
              <input v-model="email"/>
            </div>
            <div :class="{'disabled': email === profile.email}">
              <label class="label">CONFIRM EMAIL ADRESS</label>
              <input v-model="confirmEmail" placeholder="Confirm email"/>
              <p class="error" v-if="errorEmail">
                Email mismatch
              </p>
            </div>
          </div>
          <div class="group personnal">
            <div>
              <label class="label">FIRSTNAME</label>
              <input v-model="firstname"/>
              <p class="error" v-if="errorFirstname">
                Firstname is required
              </p>
            </div>
            <div>
              <label class="label">LASTNAME</label>
              <input v-model="lastname">
              <p class="error" v-if="errorLastname">
                Lastname is required
              </p>
            </div>
          </div>
          <div class="group icon">
            <label class="label">PROFIL PHOTO</label>
            <div>
              <Icon v-if="profile.icon !== undefined && profile.icon !== null"
                    :icon="profile.icon"
                    style="float:left; margin-right: 30px;"/>
              <img v-else
                   src="@/assets/no-icon.png"
                   alt="user doesn't have icon"
                   style="width: 80px"/>
              <b-form-file ref="el"
                           :accept="accept"
                           type="file"
                           placeholder="Profile photo - Optional"
                           v-model="icon"
                           :disabled="false"
                           class="select-img"
                           style="width: 70%"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import Icon from "@/components/form/Icon.vue";
import InputImage from "@/components/form/InputImage.vue";

export  default {
  name: "SettingsProfile",
  components: {InputImage, Icon},
  props: {
    profile: Object,
    accept: {
      default: "image/*",
      type: String,
    },
  },
  data() {
    return {
      loading: false,
      email: "",
      confirmEmail: "",
      firstname: "",
      lastname: "",
      icon: null,
      errorEmail: false,
      errorFirstname: false,
      errorLastname: false
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.email = this.profile.email
      this.firstname = this.profile.firstname
      this.lastname = this.profile.lastname
      this.icon = null
      this.confirmEmail = ""
      this.errorEmail = false
      this.errorFirstname = false
      this.errorLastname = false
    },
    async validatEditUser() {
      if(this.email !== this.profile.email && this.email !== this.confirmEmail){
        this.errorEmail = true
        return
      }
      if(this.firstname === ""){
        this.errorFirstname = true
        return
      }
      if(this.lastname === ""){
        this.errorLastname = true
        return
      }
      let data = {
        "email": this.email,
        "firstname": this.firstname,
        "lastname": this.lastname
      }
      if(this.icon !== null) data['icon'] = this.icon

      this.loading = true
      await this.$store.dispatch('users/update_profile', {data}).then(() => {
        this.$eventBus.$emit('success', 'Your profile has been updated')
        this.init()
        this.$router.push({name: "profile"})
      }).catch((response) => {
        this.$eventBus.$emit('http-error', response)
      })
      this.loading = false
    }
  }
}
</script>

<style scoped>
.content .label{
  font-size: 0.9em;
  color: var(--lade-color-grey);
}

.menu-edit {
  position: absolute;
  top: 18px;
  right: 20px;
}

.loading{
  width: 20px;
  height: 20px;
  position: absolute;
}

.loading.top {
  right: 20px;
  top: 5px;
}

.edit {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow-y: auto;
  position: relative;
  width: 100%;
  padding: 15px 15px 105px 15px;
}

.border-content {
  border: solid 1px var(--lade-color-white);
  border-radius: 10px 10px;
  width: 100%;
  height: 100%;
}

.group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 20px 10px 15px 10px;
  position: relative;
  margin-top: 10px;
}

.group.icon {
  align-items: center;
}

.group.personnal,
.group.icon div{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.group div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
}

input {
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

.group.email p,
.group.email input {
  width: 60%;
}

.group.personnal input{
  width: 50%;
}


div.disabled {
  opacity: .5;
  pointer-events: none;
}

.group.email div:nth-child(2) {
  margin: 30px 0 10px 0;
}

.group.personnal div {
  margin-bottom: 10px;
}

.error {
  color: var(--lade-color-red);
  text-align: center;
  position: absolute;
  bottom: -32px;
}

.group.email .error {
  bottom: -34px;
}

</style>
