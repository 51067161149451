import Swal from 'sweetalert2'

const ModalPlugin = {
  install(Vue, options) {
    Vue.prototype.$deleteModal = function (parameters) {
      return Swal.fire({
        ...{
          title: "Delete",
          icon: 'warning',
          timer: 7000,
          timerProgressBar: true,
          confirmButtonColor: "indianred",
          confirmButtonText: "Yes",
          showCancelButton: true,
        }, ...parameters
      })
    }
    Vue.prototype.$warningModal = function (parameters) {
      return Swal.fire({
        ...{
          icon: 'warning',
          timerProgressBar: true,
          confirmButtonColor: "indianred",
          showCancelButton: true,
        }, ...parameters
      })
    }
    Vue.prototype.$modal = function (parameters) {
      return Swal.fire({
        ...{
        }, ...parameters
      })
    }
  }
}

export default ModalPlugin;
