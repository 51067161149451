import Vue from 'vue'
import Config from '../../Config'
import {build_resource_list_params, setDictValue} from '@/common'
import {field_created_at, field_actions} from './fields'

const state = {
  list: [],
  limit: 15,
  skip: 0,
  pagination: {
    page: 1,
    total_pages: 0,
    total: 0,
  },
  _lookup: {},
  filteredList: [],
  fields: [
    {"key": "name"},
    {"key": "description"},
    {"key": "origin"},
    {"name": "Storage Usage", "key": "content_size"},
    {"name": "Limit", "key": "limit_content_size"},
    field_created_at,
    field_actions
  ],
  filters: {
    isEditable: (entry) => true,
    isExportable: (entry) => false,
    isDeletable: (entry) => entry.name !== 'admin',
    isDownloadable: (entry) => false
  },
  requestId: 0,
  loading : false
}

const getters = {
  get: (state) => (identifier) =>
    state._lookup[identifier],
  getAll: (state) => () =>
    state.list,
  getFilters: (state) => () =>
    state.filters,
  getFields: (state) => () =>
    state.filters,
  filter({dispatch, commit}, search) {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
    this.timer = setTimeout(() => {
      commit('filter', search)
    }, 600);
  },
  getPagination: (state) => () =>
    state.pagination,
  getPage: (state) => () =>
    state.pagination.page,
  getTotalPages: (state) => () =>
    state.pagination.total_pages,
  getTotal: (state) => () =>
    state.pagination.total,
  getSkip: (state) => () =>
    state.skip,
  getLimit: (state) => () =>
    state.limit,
  getLoading: (state) => () => state.loading,
}

const actions = {
  loadAll({dispatch, commit}, {...resource_list_params}) {
    let params = build_resource_list_params(resource_list_params)

    commit('setRequestId', state.requestId + 1)
    let currentRequestId = state.requestId;
    commit('setLoading', true)
    return Vue.http.get(Config.API_GROUPS_URL, {params}).then((response) => {
      if(currentRequestId === state.requestId) {
        let groups = response.data
        commit('setAll', groups)
        commit('setRequestId', 0)
        commit('setLoading', false)
      }
    })
  },
  load({dispatch, commit}, identifier) {
    return Vue.http.get(Config.API_GROUPS_URL + '/' + identifier).then((response) => {
      let group = response.data
      commit('set', {identifier, group})
    })
  },
  load_by_name({dispatch, commit}, name) {
    let params = {name: name}
    return Vue.http.get(Config.API_GROUPS_URL, {params}).then((response) => {
      let group = null
      if ('data' in response.data) {
        if (response.data['data'].length === 0) {
          throw "Group " + name + " not found"
        }
        group = response.data['data'][0]
      } else {
        group = response.data
      }

      let group_identifier = group.identifier
      commit('set', {group_identifier, group})
      return group
    })
  },
  add({dispatch, commit}, data) {
    let formData = new FormData()
    if (data['icon']) {
      formData.append('icon', data['icon'])
      delete data['icon']
    }
    formData.append('data', JSON.stringify(data))
    return Vue.http.post(Config.API_GROUPS_URL, formData).then((response) => {
      return dispatch('loadAll', {})
    })
  },
  update({dispatch, commit}, {identifier, data}) {
    let formData = new FormData()
    if (data['icon']) {
      formData.append('icon', data['icon'])
      delete data['icon']
    }
    formData.append('data', JSON.stringify(data))

    return Vue.http.put(Config.API_GROUPS_URL + '/' + identifier, formData).then((response) => {
      return dispatch('load', identifier)
    })
  },
  delete({dispatch, commit}, identifier) {
    return Vue.http.delete(Config.API_GROUPS_URL + '/' + identifier).then((response) => {
      return dispatch('loadAll', {})
    })
  }
}

const mutations = {
  set(state, {identifier, group}) {
    setDictValue(state._lookup, identifier, group)
  },
  setAll(state, groups) {
    state.list.splice(0, state.list.length)
    state.limit = groups.limit
    state.skip = groups.skip
    state.pagination.page = groups.page
    state.pagination.total_pages = groups.total_pages
    state.pagination.total = groups.total
    for (let group of groups.data) {
      state.list.push(group)
      setDictValue(state._lookup, group.identifier, group)
    }
  },
  setRequestId(state, id) {
    state.requestId = id
  },
  setLoading(state, value) {
    state.loading = value
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true
}






