import Vue from 'vue'
import Config from '../../Config'

const state = {
  about: {
    version: null,
    platform_color: "",
    platform_name: ""
  }
}

const getters = {
  get: (state) => () => {
    return state.about
  }
}

const actions = {
  load({dispatch, commit}) {
    return Vue.http.get(Config.API_ABOUT_URL).then((response) => {
      let about = response.data
      commit('set', about)
    })
  }
}

const mutations = {
  set(state, about) {
    for (let [key, val] of Object.entries(about)) {
      Vue.set(state.about, key, val)
    }
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true
}






