import Vue from 'vue'
import Config from '../../Config'
import {setDictValue} from '@/common'
import {field_actions, field_created_at} from './fields'
import {print_boolean, print_permission} from '@/filters'

const state = {
  groupList: {},
  _lookup: {},
  fields: [
    {
      "key": "name",
      "name": "name",
      "sortable": true
    },
    {
      "key": "description",
      "name": "description",
      "sortable": false,
    },
    {
      "key": "protected",
      "name": "protected",
      "sortable": true
    },
    {
      "key": "default",
      "name": "default",
      "sortable": true,
      "formatter": print_boolean
    },
    field_created_at,
    field_actions
  ],
  filters: {
    isExportable: (entry) => false,
    isEditable: (entry) => !entry.protected && true,
    isDeletable: (entry) => !entry.protected && true,
    isDownloadable: (entry) => false
  }
}

state.fields[2].formatter = print_permission(state.specs)

const getters = {
  getGroup: (state) => (groupIdentifier) =>
    state.groupList[groupIdentifier],
  get: (state) => (identifier) =>
    state._lookup[identifier],
  getFilters: (state) => () =>
    state.filters,
  getFields: (state) => () =>
    state.fields
}

const actions = {
  loadGroup({dispatch, commit}, groupIdentifier) {
    return Vue.http.get(Config.API_GROUPS_URL + "/" + groupIdentifier + "/roles").then((response) => {
      let roles = response.data
      commit('setGroup', {groupIdentifier, roles})
      return groupIdentifier
    })
  },
  add({dispatch, commit}, {group_identifier, data}) {
    return Vue.http.post(Config.API_GROUPS_URL + '/' + group_identifier + "/roles", data).then((response) => {
      return dispatch("loadGroup", group_identifier)
    })
  },
  clone({dispatch, commit}, {groupIdentifier, roleIdentifier, data}) {
    return Vue.http.post(Config.API_GROUPS_URL + '/' + groupIdentifier + "/roles/" + roleIdentifier + "/clone", data).then((response) => {
      return dispatch("loadGroup", groupIdentifier)
    })
  },
  update({dispatch, commit}, {group_identifier, identifier, data}) {
    return Vue.http.put(Config.API_GROUPS_URL + "/" + group_identifier + "/roles/" + identifier, data).then((response) => {
      return dispatch('loadGroup', group_identifier)
    })
  },
  delete({dispatch, commit}, {groupIdentifier, identifier}) {
    return Vue.http.delete(Config.API_GROUPS_URL + '/' + groupIdentifier + '/roles/' + identifier).then((response) => {
      return dispatch("loadGroup", groupIdentifier)
    })
  },
  toggle({dispatch, commit}, item) {
    commit('setDetails', {item, details: !item._showDetails})
  },
  assign_roles({dispatch, commit}, {groupIdentifier, users, addRoles=[], deleteRoles=[]}) {

    let identifiers = []
    for (let user of users) {
      identifiers.push(user.identifier)
    }
    let add_roles = []
    for (let role of addRoles) {
      add_roles.push(role.identifier)
    }
    let delete_roles = []
    for (let role of deleteRoles) {
      delete_roles.push(role.identifier)
    }

    let data = {
      "users": identifiers,
      "add": add_roles,
      "delete": delete_roles
    }
    return Vue.http.put(Config.API_GROUPS_URL + '/' + groupIdentifier + "/roles-assign", data).then((response) => {
      return response.data
    })
  }
}

const mutations = {
  setDetails(state, {item, details}) {
    Vue.set(item, '_showDetails', details)
  },
  setGroup(state, {groupIdentifier, roles}) {
    if (state.groupList[groupIdentifier] !== undefined) {
      state.groupList[groupIdentifier].splice(0, state.groupList[groupIdentifier].length)
    } else {
      state.groupList[groupIdentifier] = []
    }
    for (let role of roles) {
      state.groupList[groupIdentifier].push(role)
      setDictValue(state._lookup, role.identifier, role)
    }
  },
  set(state, {identifier, role}) {
    setDictValue(state._lookup, identifier, role)
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true
}






