<template>
  <div class="text-center" id="resetPasswordPanel">
    <div style="margin: 0 auto;" class="col-lg-10">

      <h4 class="hr" style="color:white">A password change has been requested for you account</h4>
      <div class="container col-lg-3 col-md-4 col-sm-6">
        <form class="form-signin" @submit.prevent="onSubmit">
          <br/>
          <label for="password" style="float:left; color:white;">New password</label>
          <input ref="password" type="password" id="password" v-model="password" class="form-control"
                 placeholder="Password" required>
          <br/>
          <label for="confirmation" style="float:left; color:white;">New password confirmation</label>
          <input ref="confirmation" type="password" id="confirmation" v-model="confirmation" class="form-control"
                 placeholder="Confirmation" required>
          <br/>
          <button class="btn btn-primary btn-block bg-info" type="submit">Change password</button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import store from '../../store'

export default {
  name: 'ResetPasswordForm',
  async created() {
    await store.dispatch('token/getTokenInfos').then(() => {
      if (!store.getters['token/isReset']()) this.redirect()
    })
  },
  data() {
    return {
      password: "",
      confirmation: ""
    }
  },
  methods: {
    redirect() {
      if (store.getters['token/isEula']()) {
        this.$router.push({name: 'eula', query: {rr: this.$route.query.rr, ar: this.$route.query.ar}})
      } else if (store.getters['token/isReset']()) {
        this.$router.push({name: 'reset', query: {rr: this.$route.query.rr, ar: this.$route.query.ar}})
      } else if (this.$route.query.ar) {
        let loc = this.$route.query.ar
        let currentHost = document.location.host
        let url = new URL(loc)
        let host = url.host
        if (url.hash.startsWith("#/login")) {
          url.hash = "";
        }
        if (host === currentHost) {
          document.location.replace(url.href)
        }
      } else if (this.$route.query.rr) {
        if (this.$route.query.rr.startsWith("/login")) {
          this.$router.push('/')
        } else {
          this.$router.push(this.$route.query.rr)
        }
      } else {
        document.location.replace(document.location.protocol + '//' + document.location.host)
      }
    },
    onSubmit() {
      if (this.password !== this.confirmation) {
        this.$eventBus.$emit('error', 'Passwords mismatch')
        this.$refs.password.focus()
        return
      }

      let data = {
        password: this.password
      }

      store.dispatch('token/resetpassword', {data}).then(async () => {
        await store.dispatch('token/getTokenInfos')
        this.redirect()
      }).catch((response) => {
        this.$eventBus.$emit('http-error', response)
      })
    }
  },
  components: {}
}
</script>

<style scoped>
#resetPasswordPanel {
  width: 100%;
  min-height: 100%;
  display: flex;
  align-items: center;
  background: var(--lade-color-dark-grey);

}

hr {
  border: 1px solid var(--lade-color-white-hover);
}
</style>
