<template>
  <div class="roles">
    <div class="back" @click="$emit('CancelEditRoles')">
      <img src="../../../assets/back.png" alt="back to users"/>
      <p>Back to Users</p>
    </div>
    <ResourceList
      id="roleTabList"
      ref="rolelist"
      resource="roles"
      editEvent="edit-role-show"
      deleteEvent="delete-role-show"
      addEvent="add-role-show"
      resource-list-form="RoleListForm"
      :addEventParameter="groupIdentifier"
      :fields="fields"
      :getter="getter"
      :dispatchActions="dispatchActions"
      subComponent="RuleList"
      :subComponentParameter='subComponentParameter'
      hideSearch
      load-on-toggle>
      <template v-slot:cell(name)="row">
        <font-awesome-icon :icon="['fa', 'lock']"
                           v-if="row.item.protected"
                           title="This role is protected"
                           style="margin-right: 5px;"/>

        {{row.item.name}}

      </template>

      <template v-slot:customActions="actions">
        <font-awesome-icon
          class='fa-icon action-icon'
          @click.stop="cloneRole(actions.row.item)"
          :icon="['fas', 'clone']"
          title="Clone role"/>
      </template>

    </ResourceList>
  </div>
</template>

<script>
import store from '../../../store'

export default {
  name: "RoleTab",
  props: {
    groupIdentifier: String
  },
  data() {
    return {
      fields: store.getters['roles/getFields']().filter(field => field.name !== "protected"),
      filters: store.getters['roles/getFilters'](),
      getter: {event: 'roles/getGroup', parameters: this.groupIdentifier},
      dispatchActions: [{event: 'roles/loadGroup', parameters: this.groupIdentifier}],
      subComponentParameter: {
        groupIdentifier: this.groupIdentifier
      }
    }
  },
  methods: {
    cloneRole(role){
      this.$eventBus.$emit("clone-role-show", role.identifier)
    },
    isEditable(entry) {
      if (entry.authz.permission <= 1) {
        return false;
      }
      return this.filters.isEditable(entry)
    },
    isDeletable(entry) {
      if (entry.authz.permission <= 1) {
        return false;
      }
      return this.filters.isDeletable(entry)
    },
  }
}
</script>

<style scoped>
.roles {
  width: 100%;
}

.back {
  margin-bottom: 10px;
  cursor: pointer;
  border-radius: 5px 5px;
  padding: 5px 5px;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  width: 35px;
  overflow-x: hidden;
  white-space: nowrap;
  transition: 0.5s;
}

.back:hover {
  background-color: var(--lade-color-white);
  width: 120px;
}

.back img {
  width: 25px;
  margin-right: 5px;
}

.back p {
  margin: 0 0;
  height: 18px;
  transition: 0.1s;
}

</style>

