<template>
  <div>
    <b-row align-h="end">
      <b-col cols="5" class="mx-5 px-2 d-flex justify-content-center my-auto">
        <Objective
          class="mx-5"
          title="Share Easily"
          :targets="[
            {
              highlight: 'Save time',
              rest: 'by using shared content from the hub',
            },
            {
              highlight: 'Share easily',
              rest: 'your expertise with other entities, organizations and personel',
            },
            {
              highlight: 'Export and import easily',
              rest: 'from and to another CyberRange',
            },
          ]"
        >
          <template #image>
            <img src="@/assets/registry/collaborate.png" />
          </template>
        </Objective>
        <img
          src="@/assets/registry/bundle-composition.png"
          height="300"
          class="my-5 mx-5"
        />
      </b-col>

      <b-col cols="6" class="my-auto text-blue bg-green-brighter w-100 p-5">
        <div class="text-3xl mt-5 font-weight-bold">Bundles.</div>
        <div class="text-6xl mb-5 text-green font-weight-bold">Easy share.</div>
        <div class="my-5 text-lg">
          A bundle is a set of topologies (including your virtual machines and
          containers), actions, scenarios and files. <br />
          This is the format to import and export content in your CyberRange.
        </div>
        <div class="m-5 text-right">
          <b-link
            class="text-lg font-weight-bold text-blue"
            :to="{ name: 'bundleGrid' }"
          >
            Start exploring now ➜
          </b-link>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Objective from "@/components/hub/landing/Objective";

export default {
  name: "Community",
  components: { Objective },
};
</script>
