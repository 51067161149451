<template>
  <div>
    <div>
      <b-button
        block
        variant="primary"
        @click="acknowledgeAll"
        v-if="not_acknowledged > 0"
      >
        <span class="font-weight-bold">Acknowledge all</span>
        <font-awesome-icon class="fa-icon" :icon="['fas', 'check-double']" />
      </b-button>
    </div>

    <div class="my-2">
      <b-link @click="goNotificationsPage">See All</b-link>
    </div>

    <div id="notification">
      <b-alert
        class="my-2"
        v-if="notifications.length === 0"
        show
        variant="dark"
      >
        We don't have any notifications for you right now
      </b-alert>

      <a
        v-for="notification in notifications"
        :key="notification.identifier"
        @click="goNotificationResource(notification)"
      >
        <b-alert
          class="my-2"
          show
          fade
          :dismissible="!notification.acknowledged"
          @dismissed="acknowledge(notification)"
          :variant="
            notification.level in levelToVariant
              ? levelToVariant[notification.level]
              : notificationTypeToVariant(notification.type)
          "
        >
          <b-row class="text-left">
            <b-col cols="2" class="my-auto">
              <font-awesome-icon
                v-if="
                  notification.level === 'high' ||
                  notification.level === 'medium'
                "
                class="fa-icon"
                :icon="['fas', 'exclamation-triangle']"
                size="lg"
              />
              <font-awesome-icon
                v-else-if="notificationTypeToIcon(notification.type)"
                class="fa-icon"
                :icon="['fas', notificationTypeToIcon(notification.type)]"
                size="lg"
              />
            </b-col>
            <b-col class="text-right">
              {{ notification.created_at | print_date }}
            </b-col>
          </b-row>

          <hr />

          <p style="word-wrap: break-word">{{ notification.message }}</p>
        </b-alert>
      </a>
    </div>
  </div>
</template>

<script>
import notifications from "@/store/modules/notifications";
import { getPageFromNotification } from "@/components/notifications/notification";

export default {
  name: "NotificationBar",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      notifications: this.$store.getters["notifications/getPopupList"](),
      not_acknowledged:
        this.$store.getters["notifications/getNumberNotAcknowledged"](),
      limit: 10,
      ShowPopup: false,
      levelToVariant: {
        high: "danger",
        medium: "warning",
      },
    };
  },
  mounted: function () {
    this.getNotifications();
    // get first page notifications to update beginning of the list
    window.setInterval(() => {
      this.getNotifications();
    }, 30000); // refresh notifications every 30 seconds
  },
  computed: {},
  methods: {
    getNotifications() {
      this.$store
        .dispatch("notifications/loadPopup", {
          exclude_acknowledge: true,
          limit: this.limit,
        })
        .then(() => {
          this.not_acknowledged =
            this.$store.getters["notifications/getNumberNotAcknowledged"]();
        });
    },
    acknowledge(notification) {
      let identifier = notification.identifier;
      this.$store.dispatch("notifications/acknowledge", identifier).then(() => {
        this.getNotifications();
      });
    },
    acknowledgeAll() {
      this.$store.dispatch("notifications/acknowledgeAll").then(() => {
        this.getNotifications();
        this.closePopup();
      });
    },
    goNotificationResource(notification) {
      let page = getPageFromNotification(notification);
      if ("name" in page) {
        this.closePopup();
        this.acknowledge(notification);
        this.$router.push(page);
      }
    },
    closePopup() {
      this.ShowPopup = false;
      this.onShowPopupChanged();
    },
    onShowPopupChanged() {
      this.$emit("showPopupChanged", this.showPopup);
    },
    onShowPropChanged(value) {
      this.ShowPopup = value;
    },
    goNotificationsPage(s) {
      this.closePopup();
      this.$router.push({ name: "notifications" });
    },
    notificationTypeToIcon(notification_type) {
      switch (notification_type) {
        case "new_bundle":
          return "shapes";
        case "bundle":
          return "shapes";
        case "bundle_question":
          return "comment";
        case "booking_approved":
          return "check";
        case "password_expiration":
          return "key";
        case "group_storage_alert":
          return "box";
        case "workzone_consumption_alert":
          return "exclamation";
        default:
          return "info";
      }
    },
    notificationTypeToVariant(notification_type) {
      switch (notification_type) {
        case "new_bundle":
          return "success";
        case "bundle_question":
          return "success";
        case "booking_approved":
          return "success";
        case "password_expiration":
          return "warning";
        case "group_storage_alert":
          return "warning";
        case "workzone_consumption_alert":
          return "warning";
        default:
          return "primary";
      }
    },
  },
  watch: {
    showPopup: {
      handler: "onShowPopupChanged",
    },
    show: {
      handler: "onShowPropChanged",
      immediate: true,
    },
  },
};
</script>

<style scoped>
#notification:hover {
  cursor: pointer;
}
</style>
