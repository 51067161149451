<template>
  <div id="workflow-view">
    <div id="workflow-sidebar">
      <div class="filterpanel">
        <div class="filterSection">
          <div class="filterSectionHeader">
            <font-awesome-icon :icon="['fa', 'calendar']"/>
            TIME
          </div>

          <b-form-radio-group stacked @change="time=$event" v-model="time">
            <b-form-radio name="scope" value="stream">
              Real time
            </b-form-radio>
            <b-form-radio name="scope" value="pick">
              Select range
            </b-form-radio>
          </b-form-radio-group>

          <DatePicker
            mode='range'
            v-model="rangeDates"
            :max-date="new Date()"
            @dayclick="time='pick'"
            is-inline
            is-range/>

          <b-form-group style="padding-top:10px">
            <b-row>
              <b-col>
                <label><strong>Start time: {{ moment(rangeDates.start).format("DD/MM") }}</strong></label>
                <b-form-timepicker v-model="startTime" locale="en" @input="time='pick'"/>
              </b-col>
              <b-col>
                <label><strong>End time: {{ moment(rangeDates.end).format("DD/MM") }}</strong></label>
                <b-form-timepicker v-model="endTime" locale="en" @input="time='pick'"/>
              </b-col>
            </b-row>
          </b-form-group>

        </div>

        <div class="filterSection">
          <div class="filterSectionHeader">
            <font-awesome-icon :icon="['fa', 'cubes']"/>
            SCOPE
          </div>
          <b-form-group>
            <b-form-radio-group stacked @change="scope=$event" v-model="scope">
              <b-form-radio name="scope" value="all">
                All
              </b-form-radio>
              <b-form-radio name="scope" value="GROUP">
                <TypeAhead ref="groupSelector"
                           resourceType="GROUP"
                           v-model="group"
                           @hit="resourceIdentifier=$event"/>
              </b-form-radio>
              <b-form-radio name="scope" value="PROJECT_POOL">
                <TypeAhead ref="projectPoolSelector"
                           resourceType="PROJECT_POOL"
                           v-model="project_pool"
                           @hit="resourceIdentifier=$event"/>
              </b-form-radio>
              <b-form-radio name="scope" value="PROJECT">
                <TypeAhead ref="projectSelector"
                           resourceType="PROJECT"
                           v-model="project"
                           @hit="resourceIdentifier=$event"/>
              </b-form-radio>
              <b-form-radio name="scope" value="WORKZONE">
                <TypeAhead ref="workzoneSelector"
                           resourceType="WORKZONE"
                           v-model="workzone"
                           @hit="resourceIdentifier=$event"/>
              </b-form-radio>
            </b-form-radio-group>
          </b-form-group>

        </div>
        <div class="filterSection">
          <div class="filterSectionHeader">
            <font-awesome-icon :icon="['fa', 'user']"/>
            USER
          </div>
          <b-form-group>
            <b-form-radio-group stacked @change="userfilter=$event" v-model="userfilter">
              <b-form-radio name="userfilter" value="all" default>
                All
              </b-form-radio>
              <b-form-radio name="userfilter" value="typeahead">
                <TypeAhead ref="userSelector"
                           resourceType="user"
                           v-model="user"
                           @hit="userIdentifier=$event"/>
              </b-form-radio>
              <b-form-radio name="userfilter" value="string">
                <input type="text"
                       v-model="username"
                       placeholder="Type Username"
                       class="form-control"/>
              </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </div>


        <b-button variant="primary" @click="applyFilter" style="margin:20px">
          Apply Filter
        </b-button>
      </div>
    </div>

    <div id="workflow-panel">
      <div id="eventlist">
        <WorkflowList
          v-if="tabValue === 0"
          ref="WorkflowList"
          :searchFilters="searchFilters"
          :busy="busy">

          <template v-slot:cell(resources)="row">
            <div :v-if="row.item.resources != null" class="link">
              <span v-if="resource.is_scope" v-for="resource in row.item.resources" :key="resource.resource_identifier">
                <a @click="scope=resource.resource_type;
                resourceIdentifier=resource.resource_identifier;
                resourceName=resource.resource_name; applyFilter()"
                    class="filterlink">
                  {{ capitalize(resource.resource_type) }} {{ resource.resource_name }}
                </a>
              </span>
<!--            <div :v-if="row.item.project_identifier != null" class="link">-->
<!--              <a @click="scope='project'; projectIdentifier=row.item.project_identifier; applyFilter()"-->
<!--                 class="filterlink">-->
<!--                {{ print_project(row.item.project_identifier) }}-->
<!--              </a>-->
<!--              <span class="badge">-->
<!--								{{ row.item.project_identifier }}-->
<!--							</span>-->
            </div>
          </template>

          <template v-slot:cell(username)="row">
            <a @click="userfilter='string'; username=row.item.username; applyFilter()" class="filterlink">
              {{ row.item.username }}
            </a>
          </template>
        </WorkflowList>
      </div>
    </div>
  </div>
</template>

<script>
import store from '../../store'
import TypeAhead from '../../components/form/TypeAhead.vue'
import WorkflowList from './WorkflowList.vue'
// import eventBus from '../../store/modules/eventBus'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import moment from 'moment'
import {capitalize} from '@/filters'

export default {
  name: "WorkflowView",
  components: {
    TypeAhead, WorkflowList, DatePicker
  },
  data() {
    var start = new Date(moment().subtract(2, 'day').format())
    var end = new Date(moment().format())
    return {
      workzone: "",
      project_pool: "",
      project: "",
      group: "",
      user: "",
      resourceIdentifier: null,
      resourceName: null,
      userIdentifier: null,
      username: "",
      groupSelected: false,
      workzoneSelected: false,
      scope: "all",
      userfilter: "all",
      time: "stream",
      tabValue: 0,
      busy: false,
      rangeDates: {
        start: start,
        end: end
      },
      startTime: moment(start).set('minutes', 0).format('HH:mm'),
      endTime: moment(end).format('HH:mm'),
      searchFilters: {}
    }
  },
  // beforeDestroy: function () {
  //   eventBus.closeStream()
  // },
  mounted() {
    // get first page notifications to update beginning of the list
    window.setInterval(() => {
      store.dispatch("workflows/update")
    }, 5000);
  },
  computed: {
    startDateTime() {
      return moment(moment(this.rangeDates.start).format('YYYY-MM-DD') + 'T' + this.startTime).local().format()
    },
    endDateTime() {
      return moment(moment(this.rangeDates.end).format('YYYY-MM-DD') + 'T' + this.endTime).local().format()
    }
  },
  methods: {
    moment(value) {
      return moment(value)
    },
    capitalize(value) {
      return capitalize(value)
    },
    print_user: function (user_identifier) {
      let group = this.$store.getters['users/get'](user_identifier)
      if (!group) return ''
      return group.name
    },
    async applyFilter() {
      this.busy = true

      // Clear all filters
      delete this.searchFilters.resources
      delete this.searchFilters.start_time
      delete this.searchFilters.end_time
      delete this.searchFilters.user

      // Update the fields
      if (this.scope === "GROUP" && !this.group) {
        if (this.resourceName) {
          this.group = this.resourceName
        }
        else {
          try {
            await this.$store.dispatch('groups/load', this.resourceIdentifier)
            this.group = this.$store.getters['groups/get']([this.resourceIdentifier]).name
          } catch (e) {
            this.group = this.resourceIdentifier
          }
        }
        this.$refs.groupSelector.refresh(this.group)
      }
      else if (this.scope === "WORKZONE" && !this.workzone) {
        if (this.resourceName) {
          this.workzone = this.resourceName
        }
        else {
          try {
            await this.$store.dispatch('workzones/load', this.resourceIdentifier)
            this.workzone = this.$store.getters['workzones/get']([this.resourceIdentifier]).alias
          } catch (e) {
            this.workzone = this.resourceIdentifier
          }
        }
        this.$refs.workzoneSelector.refresh(this.workzone)
      }
      else if (this.scope === "PROJECT" && !this.project) {
        if (this.resourceName) {
          this.project = this.resourceName
        }
        else {
          try {
            await this.$store.dispatch('projects/load', this.resourceIdentifier)
            this.project = this.$store.getters['projects/get']([this.resourceIdentifier]).alias
          } catch (e) {
            this.project = this.resourceIdentifier
          }
        }
        this.$refs.projectSelector.refresh(this.project)
      }
      else if (this.scope === "PROJECT_POOL" && !this.project_pool) {
        if (this.resourceName) {
          this.project_pool = this.resourceName
        }
        else {
          try {
            await this.$store.dispatch('project_pools/load', this.resourceIdentifier)
            this.project_pool = this.$store.getters['project_pools/get']([this.resourceIdentifier]).alias
          } catch (e) {
            this.project_pool = this.resourceIdentifier
          }
        }
        this.$refs.projectPoolSelector.refresh(this.project_pool)
      }

      if (this.time !== "stream") {
        this.searchFilters.start_time = this.startDateTime
        this.searchFilters.end_time = this.endDateTime
      }

      // Scope
      if (this.scope !== 'all' && this.resourceIdentifier)
        this.searchFilters.resources = this.resourceIdentifier

      // User
      if (this.userfilter === "typeahead")
        this.searchFilters.user = this.print_user(this.userIdentifier)

      else if (this.userfilter === "string" && this.username !== "")
        this.searchFilters.user = this.username

      this.$refs["WorkflowList"].refresh().then(() => {
        this.busy = false
      })
    }
  }
}
</script>

<style>
.custom-control {
  z-index: auto !important;
}

.filterlink {
  color: var(--lade-navy) !important;
}

.filterlink:hover {
  color: var(--lade-color-black) !important;
}

.filterSection {
  padding: 20px;
}

.filterSection:hover {
  background-color: var(--lade-color-white);
}

.filterSectionHeader {
  margin-bottom: 10px;
  font-weight: bold;
  color: var(--lade-color-grey);
}

/* The sidebar menu */
#workflow-sidebar {
  display: flex;
  height: 100%;
  width: 300px;
}
#workflow-panel {
  display: flex;
  width: calc(100vw - 300px);
}
#workflow-view{
  display: flex;
  background-color: #ffffff;
}

</style>
