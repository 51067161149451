var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-row',{attrs:{"align-h":"end"}},[_c('b-col',{staticClass:"mx-5 px-2 d-flex justify-content-center my-auto",attrs:{"cols":"5"}},[_c('Objective',{staticClass:"mx-5",attrs:{"title":"Share Easily","targets":[
          {
            highlight: 'Save time',
            rest: 'by using shared content from the hub',
          },
          {
            highlight: 'Share easily',
            rest: 'your expertise with other entities, organizations and personel',
          },
          {
            highlight: 'Export and import easily',
            rest: 'from and to another CyberRange',
          },
        ]},scopedSlots:_vm._u([{key:"image",fn:function(){return [_c('img',{attrs:{"src":require("@/assets/registry/collaborate.png")}})]},proxy:true}])}),_c('img',{staticClass:"my-5 mx-5",attrs:{"src":require("@/assets/registry/bundle-composition.png"),"height":"300"}})],1),_c('b-col',{staticClass:"my-auto text-blue bg-green-brighter w-100 p-5",attrs:{"cols":"6"}},[_c('div',{staticClass:"text-3xl mt-5 font-weight-bold"},[_vm._v("Bundles.")]),_c('div',{staticClass:"text-6xl mb-5 text-green font-weight-bold"},[_vm._v("Easy share.")]),_c('div',{staticClass:"my-5 text-lg"},[_vm._v(" A bundle is a set of topologies (including your virtual machines and containers), actions, scenarios and files. "),_c('br'),_vm._v(" This is the format to import and export content in your CyberRange. ")]),_c('div',{staticClass:"m-5 text-right"},[_c('b-link',{staticClass:"text-lg font-weight-bold text-blue",attrs:{"to":{ name: 'bundleGrid' }}},[_vm._v(" Start exploring now ➜ ")])],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }