<template>
  <div>
    <div v-if="show">
      <b-alert show variant="danger"
               v-if="hasBookingWrite && groupIdentifier !== 'all' && (workzones.length === 0 || users.length === 0)"
               style="margin:10px">
        <small v-if="workzones.length === 0">
          Group must access at least one workzone to create booking
        </small>
        <br/>
        <small v-if="users.length === 0">
          You must add users in the group to create booking
        </small>
      </b-alert>
      <b-tabs>
        <b-tab title="Calendar" active>
          <Calendar :groupIdentifier="groupIdentifier" style="max-width:1200px; height:600px; margin:auto"/>
        </b-tab>
        <b-tab title="List">

          <ResourceList
            resource="bookings"
            :addEvent="addEvent"
            :addEventParameter="groupIdentifier"
            deleteEvent="delete-booking-show"
            editEvent="edit-booking-show"
            hideSearch
            :getter="getter"
            :dispatchActions="dispatchActions">

            <template v-slot:customHeaderAction>
              <b-button v-if="hasBookingWrite && groupIdentifier !=='all'"
                        @click.stop="$eventBus.$emit('prune-booking-show', groupIdentifier)"
                        variant="danger"
                        style="border-top-left-radius:0; border-bottom-left-radius:0;">
                <font-awesome-icon :icon="['fas', 'trash']"/>
                Clean
              </b-button>
            </template>

            <template v-slot:cell(status)="row">
              <b-badge v-if="!row.item.approved" variant="warning" style="font-size:1em;">
                <font-awesome-icon :icon="['fa', 'times']"
                                   :title="row.item.status"/>
                <span style="padding-left:3px;"> {{ "Require Approval" }} </span>
              </b-badge>
              <b-badge v-else :variant="status_to_variant(row.item.status)" style="font-size:1em;">
                <b-spinner
                  v-if="row.item.status==='running'"
                  style="height:1em; width:1em; vertical-align:middle;"/>
                <font-awesome-icon :icon="['fa', 'calendar']"
                                   v-else-if="row.item.status==='scheduled'"
                                   :title="row.item.status"/>
                <font-awesome-icon :icon="['fa', 'check']"
                                   v-else-if="row.item.status==='succeeded'"
                                   :title="row.item.status"/>
                <font-awesome-icon :icon="['fa', 'exclamation-triangle']"
                                   v-else-if="row.item.status==='failed'"
                                   :title="row.item.status"/>
                <font-awesome-icon :icon="['fa', 'ban']"
                                   v-else-if="row.item.status==='cancelled'"
                                   :title="row.item.status"/>
                <span style="padding-left:3px;"> {{ row.item.status | capitalize }} </span>
              </b-badge>
            </template>
          </ResourceList>
        </b-tab>
      </b-tabs>
    </div>
    <BookingListForm/>
  </div>
</template>

<script>
import {status_to_variant} from '@/common'
import Calendar from "./Calendar.vue";
import BookingListForm from "./BookingListForm"

export default {
  name: "BookingTab",
  components: {
    Calendar,
    BookingListForm
  },
  props: {
    groupIdentifier: String,
  },
  created() {
    if (this.groupIdentifier === "all") {
      Promise.all([
        this.$store.dispatch("bookings/loadAll"),
        this.$store.dispatch("users/loadAll", {}),
        this.$store.dispatch("workzones/loadAll"),
        this.$store.dispatch("bookings/emptyCalendar")

      ]).then(() => {
        this.show = true
      })
    } else {
      Promise.all([
        this.$store.dispatch("bookings/loadGroup", this.groupIdentifier),
        this.$store.dispatch("bookings/loadCalendar", this.groupIdentifier),
        this.$store.dispatch("users/loadGroup", {groupIdentifier: this.groupIdentifier}),
        this.$store.dispatch("workzones/loadGroup", this.groupIdentifier)
      ]).then(() => {
        this.users = this.$store.getters['users/getGroup'](this.groupIdentifier)
        this.workzones = this.$store.getters['workzones/getGroup'](this.groupIdentifier)
        this.show = true
      })
    }

  },
  data() {
    return {
      show: false,
      users: [],
      workzones: [],
      addEvent: this.groupIdentifier === "all" ? null : 'add-booking-show',
      getter: {
        event: this.groupIdentifier === "all" ? 'bookings/getAll' : 'bookings/getGroup',
        parameters: this.groupIdentifier
      },
      dispatchActions: [{
        event: this.groupIdentifier === "all" ? 'bookings/loadAll' : 'bookings/loadGroup',
        parameters: this.groupIdentifier
      }],
      hasBookingWrite: (this.$store.getters['token/getInfos']().resources.filter(x => x.resource === "BOOKING")[0].permission === 'w')
    }
  },
  methods: {
    status_to_variant(value) {
      return status_to_variant(value)
    }
  }
};
</script>
