<template>
  <div class="d-flex  flex-column" style="height: 100vh">

    <Nav v-if="$route.path !== '/login' && $route.path !== '/reset' && $route.path !== '/eula'"></Nav>

    <div id="routerview" ref="routerView" class="w-100">
      <router-view style="height:inherit;"></router-view>
    </div>

    <footer class="page-footer container-fluid text-center w-100 lade-bar lade-footer">
      <div class="row">
        <div class="col-md-2 text-left"></div>
        <div class="col-md-8 text-center align-middle">
          <small>
            <span v-if="$route.path !== '/login' ">LADE v{{ about.version }} - </span>
            <span>Copyright &copy; 2016-{{ (new Date).getFullYear() }} by Airbus CyberSecurity SAS, All rights reserved.</span>
            <br/>
            <span><a href="#/cookies_policy">Ce site internet n'utilise que des cookies fonctionnels, nécessaires à l'authentification, la sécurité et la personnalisation d'interface. Pour plus d'informations concernant les cookies, vous pouvez consulter notre politique relative aux cookies en cliquant ici.</a></span>
          </small>
        </div>
        <div class="col-md-2 text-right" v-if="$route.path !== '/login'">
          <a href="#/legal" style="padding-right:10px;">Legal</a>
        </div>
      </div>
    </footer>

  </div>
</template>

<script>
import store from './store'
//import NavBar from './components/registry/NavBar.vue'
import Nav from "./components/hub/landing/Nav.vue";

export default {
  name: 'app',
  components: {
    //NavBar,
    Nav,
  },
  async created() {
    document.title = "CyberRange Hub";

    try {
      await store.dispatch('token/getTokenInfos')
      if (this.$route.query.ar) {
        let loc = this.$route.query.ar
        let currentHost = document.location.host
        let host = new URL(loc).host
        if (host === currentHost) {
          document.location.replace(loc)
        }
      } else if (this.$route.query.rr) {
        this.$router.push(this.$route.query.rr)
      } else if (this.$router.currentRoute.path === "/login") {
        this.$router.push('/')
      }
    } catch (error) {
      await this.$store.dispatch('token/logout')
    }

    await store.dispatch('about/load')
    this.$eventBus.$on('success', this.success)
    this.$eventBus.$on('info', this.info)
    this.$eventBus.$on('error', this.error)
    this.$eventBus.$on('http-error', this.httpError)
  },
  computed: {
    about: () => store.getters['about/get'](),
  },
  methods: {
    info(message) {
      this.$bvToast.toast(message, {
        title: 'Info',
        autoHideDelay: 5000,
        solid: true,
        appendToast: false,
        variant: 'info',
        toaster: 'b-toaster-top-right'
      })
    },
    success(message) {
      this.$bvToast.toast(message, {
        title: 'Success',
        autoHideDelay: 5000,
        solid: true,
        appendToast: false,
        variant: 'success',
        toaster: 'b-toaster-top-right'
      })
    },
    error(message) {
      this.$bvToast.toast(message, {
        title: 'Error',
        autoHideDelay: 5000,
        solid: true,
        appendToast: false,
        variant: 'danger',
        toaster: 'b-toaster-top-right'
      })
    },
    httpError(response) {
      //console.error(response)
      /*if (typeof error.data == "object" && "message" in error.data) {
            this.error = error.data.message
            this.$refs.password.focus()
          } else {
            this.error = "An error occured, refer to logs"
          }*/

      if (typeof response.body == 'object' && "message" in response.body) {
        this.error(response.body["message"])
      } else {
        this.error("Got error code " + response.status)
      }
    }
  }
}
</script>

<style>

#routerview {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: #f7f7f8;
}

.lade-footer {
  position: relative;
  height: 40px;
  padding-top: 6px;
  padding-bottom: 4px;
  line-height: 1.2;
}

footer a {
  color: #DDD;
}

footer a:hover {
  color: #FFF;
  text-decoration: none;
}

</style>
