<template>
  <form v-on:submit.prevent="updateImage">
    <b-card button style="margin-top:2px">
      <template v-slot:header>
        <font-awesome-icon class='fa-icon' :icon="['fas', 'hdd']" alt="Size" style=" margin-right:10px;"/>
        Disk - <small> image </small> - {{ image.name }}
      </template>

      <span> Size in library - <small> compressed </small>: {{ image.size | print_human_readable_size }} </span>
      <hr/>
      <span> Checksum: {{ image.checksum }} </span>
      <hr/>
      <span v-if="hardwareType === 'VM_T'">
                <span v-if="editDiskSize" class="col-7">
                    <InputText name="Deployed size:" v-model="disk_size" pattern="[0-9]+" min="0" required/>
                </span>
                <span v-else>
                    Deployed size: {{ image.disk_size | print_human_readable_size }}
                    <font-awesome-icon class='fa-icon' :icon="['fas', 'pen']" style="margin-right:6px;"
                                       @click="editDiskSize = !editDiskSize"/>
                </span>
            </span>
      <hr/>
      <span>
                <TextAreaForm name="Backing:" v-model="backing" ref="backing" :rows="5" json/>
            </span>
      <template v-slot:footer>
        <b-button-group style="padding-top:3px; float:right;">

          <b-button type="submit" variant="primary">
            <font-awesome-icon class='fa-icon' :icon="['fas', 'pen']" style="margin-right:6px;"/>
            Update
          </b-button>

          <b-button variant="danger" @click="deleteImage()">
            <font-awesome-icon class='fa-icon' :icon="['fas', 'trash']" style="margin-right:6px;"/>
            Delete
          </b-button>
        </b-button-group>

      </template>
    </b-card>
  </form>
</template>

<script>
import store from '../../store'
import InputText from '../form/InputText'
import TextAreaForm from '../form/TextAreaForm'

export default {
  name: "ImageForm",
  props: {
    parameters: Object,
    identifier: String,
    topologyIdentifier: String,
    hostIdentifier: String,
    hardwareType: String
  },
  data() {
    var image = this.$store.getters['topologies/getHostSpecImage']({
      topologyIdentifier: this.topologyIdentifier,
      hostSpecIdentifier: this.hostIdentifier,
      identifier: this.identifier
    })
    return {
      image: image,
      backing: image.backing,
      editDiskSize: false,
      disk_size: image.disk_size
    }
  },
  methods: {
    deleteImage() {
      this.$deleteModal({
        text: "Delete image " + this.image.name + " ?"
      }).then((result) => {
        if (result.value) {
          store.dispatch('topologies/deleteImage', {
            topologyIdentifier: this.topologyIdentifier,
            hostIdentifier: this.hostIdentifier,
            identifier: this.identifier
          }).then(() => {
            this.$eventBus.$emit('success', 'The image has been deleted')
          }).catch((response) => {
            this.$eventBus.$emit('http-error', response)
          })
        }
      })
    },
    updateImage() {

      var data = {
        "backing": this.backing,
        "disk_size": this.disk_size
      }

      store.dispatch('topologies/updateImage', {
        topologyIdentifier: this.topologyIdentifier,
        hostIdentifier: this.hostIdentifier,
        identifier: this.identifier,
        data: data
      }).then(() => {
        this.$eventBus.$emit('success', 'The image has been updated')
      }).catch((response) => {
        this.$eventBus.$emit('http-error', response)
      })
    }
  },
  components: {
    TextAreaForm,
    InputText
  }
}
</script>
