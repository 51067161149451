<template>

  <b-form ref="form" @submit="onSubmit" style="margin: 20px;">
    <b-card header="Change email">
      <b-form-group label="Email address:"
                    :state="emailState"
                    invalid-feedback="Email is required"
      >
        <input type="email" v-model="email" class="form-control" autocomplete="off" :disabled="origin==='ldap'"
               placeholder="Enter email">
      </b-form-group>
      <b-form-group label="Confirm email:"
                    :state="confirmEmailState"
                    invalid-feedback="Email mismatch"
      >
        <input type="email" v-model="confirmEmail" class="form-control" autocomplete="off" :disabled="origin==='ldap'"
               placeholder="Confirm email">
      </b-form-group>
      <button type="submit" class="btn btn-primary">Submit</button>
    </b-card>
  </b-form>
</template>

<script>
export default {
  name: 'EmailForm',
  components: {},
  props: {
    currentEmail: String,
    origin: String,
  },
  data() {
    return {
      email: this.currentEmail,
      confirmEmail: '',
      emailState: true,
      confirmEmailState: true,
    }
  },
  methods: {
    reset() {
      this.email = this.currentEmail
      this.confirmEmail = ''
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity()
      this.emailState = this.email.length > 0
      this.confirmEmailState = (this.email === this.confirmEmail)
      return valid && this.emailState && this.confirmEmailState
    },
    onSubmit() {
      if (!this.checkFormValidity()) {
        return
      }

      let data = {
        "email": this.email,
      }

      this.$store.dispatch('users/update_profile', {data}).then(() => {
        this.$eventBus.$emit('success', 'Your email has been updated')
        this.reset()
        this.$router.push({name: "home"})
      }).catch((response) => {
        this.$eventBus.$emit('http-error', response)
      })
    },
  }
}
</script>
