import Vue from 'vue'
import Config from '@/Config'
import {setDictValue} from '@/common'

const state = {
  list: [],
  _lookup: {},
}

const getters = {
  get: (state) => (identifier) =>
    state._lookup[identifier],
  getAll: (state) => () =>
    state.list,
}

const actions = {
  loadAll({dispatch, commit}) {

    let params = {"limit": "-1"}

    return Vue.http.get(Config.API_PROFILE_URL + '/applications', {params}).then((response) => {
      commit('setAll', response.data)
    })
  },
  add_registry({dispatch, commit}, {name, url, token=null, username=null, password=null, insecure = false}) {
    let app_data = {
      "type": "registry",
      "name": name,
      "data": {
        "url": url,

        "insecure": insecure
      }
    }
    if (token !== null) {
      app_data.data['token'] = token
    } else if (username !== null && password !== null) {
      app_data.data['username'] = username
      app_data.data['password'] = password
    } else {
      throw "Missing authentication mechanism"
    }

    return dispatch('add', {data: app_data})
  },
  add({dispatch, commit}, {data}) {
    return Vue.http.post(Config.API_PROFILE_URL + '/applications', data).then((response) => {
      Vue.prototype.$eventBus.$emit('new-user-app', response.data)
      return dispatch('loadAll')
    }).catch((response) => {
      Vue.prototype.$eventBus.$emit('new-user-app-error', response)
    })
  },
  delete({dispatch, commit}, identifier) {
    return Vue.http.delete(Config.API_PROFILE_URL + '/applications/' + identifier).then((response) => {
      return commit('delete', identifier)
    })
  },
  update({dispatch, commit}, {identifier, data}) {
    return Vue.http.put(Config.API_PROFILE_URL + '/applications/' + identifier, data).then((response) => {
      return dispatch('loadAll')
    })
  },
}

const mutations = {
  delete(state, identifier) {
    Vue.delete(state.list, state.list.indexOf(state.list.find(item => item.identifier === identifier)))
    delete state._lookup[identifier]
  },
  setAll(state, applications) {
    state.list.splice(0, state.list.length)
    for (let app of applications.data) {
      state.list.push(app)
      setDictValue(state._lookup, app.identifier, app)
    }
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true
}
