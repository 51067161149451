<template>
  <div v-show="showAddEditRule" v-if="load">
  <h4> {{ title }}</h4>
    <b-row>
      <b-col cols="5">
        <h6 style="font-weight:bold;">1. Select resources</h6>
        <b-input-group>
          <template #prepend>
            <b-input-group-text>Scope&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b-input-group-text>
          </template>
          <b-form-select v-model="scopeType"
                         :options="scopeTypes"
                         @change="()=>permission=[]"
                         placeholder="Scope Type"
                         id="RuleScopeType"
                         class="b-md-4"/>
          <TypeAhead :resourceType="scopeType"
                     v-model="scope"
                     ref="scopeTypeAhead"
                     class="b-md-4"
                     id="RuleScopeType"
                     @hit="scopeIdentifier = $event"
                     :disabled="scopeType == null"/>
        </b-input-group>
        <b-input-group style="padding-top:10px;">
          <template #prepend>
            <b-input-group-text>Resource</b-input-group-text>
          </template>
          <b-form-select
            v-model="resourceType"
            :options="resourceTypes"
            @change="()=>permission=[]"
            placeholder="Resource Type"
            id="RuleResourceType"/>
        </b-input-group>
      </b-col>
      <b-col cols="5">
        <h6 style="font-weight:bold;">2. Set permissions</h6>
        <b-form-group>
          <b-container>
            <b-row>
              <b-col>
                <template v-for="option in basicPermissions">
                  <b-form-checkbox
                    v-model="permission"
                    :key="option.value"
                    :value="option.value"
                    name="RulePermission"
                    @change="updatePermission($event, option.value)"
                    :disabled="option.disabled">
                    {{ option.text }}
                    <font-awesome-icon
                      :title="option.description"
                      class="fa-icon"
                      :icon="['fas', 'info-circle']"
                      style="color: silver; margin-left:0.3em;">
                    </font-awesome-icon>
                  </b-form-checkbox>
                </template>
              </b-col>
              <b-col>
                <template v-for="option in advancedPermissions">
                  <b-form-checkbox
                    v-model="permission"
                    :key="option.value"
                    :value="option.value"
                    name="RulePermission"
                    @change="updatePermission($event, option.value)"
                    :disabled="option.disabled">
                    {{ option.text }}
                    <font-awesome-icon
                      :title="option.description"
                      class="fa-icon"
                      :icon="['fas', 'info-circle']"
                      style="color: silver; margin-left:0.3em;">
                    </font-awesome-icon>
                  </b-form-checkbox>
                </template>-
              </b-col>
            </b-row>
          </b-container>
        </b-form-group>
      </b-col>
      <b-col cols="2" align-self="center">
        <font-awesome-icon
          class="fa-icon action-icon"
          :icon="['fas', edit?'pen':'check']"
          @click.stop="addEditRule()"
          style="color: dodgerblue; margin-left:0.3em;"/>

        <font-awesome-icon
          class="fa-icon action-icon"
          :icon="['fas', 'times']"
          @click.stop="showAddEditRule=false"
          style="color: indianred; margin-left:0.8em;"/>
      </b-col>

    </b-row>
  </div>
</template>


<script>
import store from '../../store'
import TypeAhead from "@/components/form/TypeAhead";
import {capitalize} from "@/filters";

export default {
  name: 'RuleListForm',
  components: {
    TypeAhead
  },
  props: {
    parameters: Object
  },
  mounted() {
    console.log(this.parameters.roleIdentifier)
    this.$store.dispatch('token/loadSpecs').then(() => {
      this.load = true
    })
  },
  created() {
    this.$eventBus.$off('add-rule-show-'+this.parameters.roleIdentifier)
    this.$eventBus.$on('add-rule-show-'+this.parameters.roleIdentifier, ({groupIdentifier, roleIdentifier}) => {
      this.reset("Add rule")
      this.groupIdentifier = groupIdentifier
      this.roleIdentifier = roleIdentifier
      this.identifier = null
      this.edit = false
      this.showAddEditRule = true
    })

    this.$eventBus.$off('edit-rule-show-'+this.parameters.roleIdentifier)
    this.$eventBus.$on('edit-rule-show-'+this.parameters.roleIdentifier, (identifier) => {
      this.reset("Edit rule")
      let rule = store.getters['rules/get'](identifier)
      this.groupIdentifier = rule.group_identifier
      this.roleIdentifier = rule.role_identifier
      this.identifier = identifier
      this.resourceType = rule.rule_resource_type
      this.scopeType = rule.scope_type
      this.scopeIdentifier = rule.scope_identifier
      if (this.scopeIdentifier !== null && this.scopeType !== null){
        this.$refs.scopeTypeAhead.select(this.scopeIdentifier)
      }
      this.permissionFromInt(rule.permission).forEach((element)=> this.permission.push(element))
      this.updatePermission()
      this.edit = true
      this.showAddEditRule = true
    })

    this.$eventBus.$on("delete-rule-show", (identifier) => {
      let rule = store.getters['rules/get'](identifier)
      this.groupIdentifier = rule.group_identifier
      this.roleIdentifier = rule.role_identifier
      this.$deleteModal({
        title: "Delete rule",
        text: "Delete this rule ?"
      }).then((result) => {
        if (result.value) {
          store.dispatch('rules/deleteInGroup', {
            groupIdentifier: this.groupIdentifier,
            roleIdentifier: this.roleIdentifier,
            identifier
          }).catch((response) => {
            this.$eventBus.$emit('http-error', response)
          })
        }
      });
    })
  },
  data() {
    return {
      identifier: null,
      title: "",
      group: null,
      groupIdentifier: "",
      roleIdentifier: null,
      role: null,
      edit: false,
      scopeType: null,
      scope: null,
      scopeIdentifier: null,
      resourceType: null,
      permission: [],
      showAddEditRule: false,
      load:false // Wait specs to be loaded
    }
  },
  computed: {
    scopeTypes: function () {
      let options = []
      let types = this.specs.scopes
      options.push(...types.map(function (type) {
        return {"value": type.value, "text": capitalize(type.name)}
      }))
      return options
    },
    scopeResources: function () {
      return this.specs.scope_reduction
    },
    resourceTypes: function () {
      let options = [{"value": null, text: '*'}]
      if (this.scopeResources != null) {
        if (this.scopeType == null) {
          options.push(...this.scopeResources["*"].map(function (type) {
            return {"value": type.value, "text": type.name}
          }))
          return options
        }
        options.push(...this.scopeResources[this.scopeType].map(function (type) {
          return {"value": type.value, "text": type.name}
        }))
      }
      return options
    },
    permissions: function () {
      let permissions = this.specs.permissions
      let options = []

      if (this.resourceType != null) {
        options.push(...permissions[this.resourceType].map(function (option) {
          return {
            "value": option['value'],
            "text": option['name'],
            "group": option['group'],
            "disabled": false,
            "description": option['description'],
          }
        }))
      } else if (this.scopeType != null) {
        options.push(...permissions[this.scopeType].map(function (option) {
          return {
            "value": option['value'],
            "text": option['name'],
            "group": option['group'],
            "disabled": false,
            "description": option['description'],
          }
        }))
      } else {
        options.push(...permissions["*"].map(function (option) {
          return {
            "value": option['value'],
            "text": option['name'],
            "group": option['group'],
            "disabled": false,
            "description": option['description'],
          }
        }))
      }
      return options
    },
    basicPermissions: function () {
      return this.permissions.filter(permission => permission.group === 'basic')
    },
    advancedPermissions: function () {
      return this.permissions.filter(permission => permission.group !== 'basic')
    },
    specs: () => store.getters['token/getSpecs'](),
  },
  methods: {
    permissionFromInt(value) {
      let permission = []
      if (this.scopeType !== null){
        for (let rulePermission of this.permissions) {
          if ((BigInt(value) & BigInt(rulePermission.value)) === BigInt(rulePermission.value)) {
            this.permission.push(rulePermission.value)
          }
        }
      }
      return permission
    },
    updatePermission(checked, value) {
      let that = this
      // nextTick is important, it ensures this.permission is updated
      this.$nextTick().then(function () {
        for (let option1 of that.permissions) {
          let included = false
          for (let option2 of that.permissions) {
            if (option1.value !== option2.value && (option1.value & option2.value) === option1.value) {
              // option1 is included by option2
              if (that.permission.includes(option2.value)) {
                //option2 is checked
                included = true
                break
              }
            }
          }
          if (included) {
            if (!that.permission.includes(option1.value)) {
              that.permission.push(option1.value)
            }
            option1.disabled = true
          } else {
            option1.disabled = false
          }
          that.$forceUpdate()
        }
      })
    },
    reset(title) {
      this.title = title
      this.scopeType = null
      this.scope = null
      this.scopeIdentifier = null
      this.resourceType = null
      this.permission.splice(0, this.permission.length)
    },
    addEditRule() {
      let mask = BigInt(0)
      for (let perm of this.permission) {
        mask = BigInt(mask) | BigInt(perm)
      }

      mask = Number(mask)

      let data = {
        "scope_type": this.scopeType,
        "scope_identifier": this.scopeIdentifier,
        "rule_resource_type": this.resourceType,
        "permission": mask,
        "enabled": true
      }

      if (this.edit) {
        store.dispatch('rules/update', {
          groupIdentifier: this.groupIdentifier,
          roleIdentifier: this.roleIdentifier,
          identifier: this.identifier,
          data: data
        }).then(() => {
          this.$eventBus.$emit('success', 'The rule has been updated')
        }).catch((response) => {
          this.$eventBus.$emit('http-error', response)
        })
      } else {
        store.dispatch('rules/add', {
          groupIdentifier: this.groupIdentifier,
          roleIdentifier: this.roleIdentifier,
          data: data}).then(() => {
          this.$eventBus.$emit('success', 'The rule has been created')
        }).catch((response) => {
          this.$eventBus.$emit('http-error', response)
        })
      }
    }
  }
}

</script>

<style scoped>
.row {
  margin-right: 0;
  margin-left: 0;
  margin-bottom: 10px;
}
</style>
