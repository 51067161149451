<template>
  <div class="form-group">
    <label :for="fieldId">{{ name | capitalize }} <i v-if="!required" style="color: var(--lade-color-grey);"> - Optional </i></label>

    <textarea ref="el" type="text" v-on:input="updateValue()" autocomplete="off" :id="fieldId"
              :aria-describedby="helpId"
              :placeholder="placeholder" :disabled="disabled" :pattern="pattern"
              class="form-control" :rows="rows">
      </textarea>

    <small :id="helpId" class="form-text text-muted">{{help}}</small>
  </div>
</template>

<script>
import {capitalize} from '@/filters'

export default {
  name: "TextAreaForm",
  created() {
    this.$on('reset', (defaultValue) => {
      this.reset(defaultValue)
    })
  },
  mounted(){
    this.reset(this.value)
  },
  props: {
    value: [String, Number, Object, Array],
    prefix: String,
    name: String,
    required: Boolean,
    disabled: Boolean,
    pattern: String,
    help: String,
    rows: Number,
    json: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      fieldId: this.prefix + capitalize(this.name),
      helpId: this.prefix + capitalize(this.name) + "Help",
      placeholder: this.name,
      defaultValue: ""
    }
  },
  filters: {
    capitalize
  },
  methods: {
    updateValue() {
      this.$refs.el.setCustomValidity('')
      let value;
      try {
        value = this.getValue()
        this.$refs.el.setCustomValidity('')
      } catch (e) {
        this.$refs.el.setCustomValidity('Valid JSON is expected')
      }
      this.$emit('input', value)
    },
    reset(defaultValue) {

      if (defaultValue == null) {
        if (this.json) {
          defaultValue = {}
        } else {
          defaultValue = ""
        }
      }

      if (this.json) {
        this.$refs.el.value = JSON.stringify(defaultValue, null, 4)
      } else {
        this.$refs.el.value = defaultValue
      }

      this.$emit('input', defaultValue)
    },
    getValue() {
      let value;
      if (this.json) {
        value = JSON.parse(this.$refs.el.value)
      } else {
        value = this.$refs.el.value
      }
      return value
    },
    refresh(value) {
      this.$refs.el.value = value
    }
  }
}
</script>
