<template>
  <div>
    <b-modal size="lg" id="roleAddFormModal" hide-footer v-bind:title="title">
      <template v-slot:modal-title>
        {{title}}
      </template>
      <form v-on:submit.prevent="onAddEdit">
        <div class="card">
          <div class="card-header">
            Attributes
          </div>
          <div class="card-body">
            <InputText v-if="edit" prefix="Role" name="identifier" v-model="identifier" disabled></InputText>

            <InputText prefix="Role" name="name" v-model="name" required pattern="^[0-9a-zA-ZÀ-ÿ-._ ]+$"></InputText>

            <TextAreaForm ref="description" prefix="Role" name="description" :rows="2"
                          v-model="description"></TextAreaForm>

            <div class="form-group">
              <b-form-checkbox v-model="isDefault" name="check-button" switch>
                Default role: will be assigned automatically to all members of the group
              </b-form-checkbox>
            </div>

            <button type="submit" class="btn btn-primary">Submit</button>
          </div>
        </div>
      </form>
    </b-modal>
  </div>
</template>


<script>
import store from '../../store'
import TextAreaForm from '../form/TextAreaForm'
import InputText from '../form/InputText'

export default {
  name: 'RoleEdit',
  components: {
    InputText,
    TextAreaForm
  },
  created() {
    this.$eventBus.$off('add-role-show')
    this.$eventBus.$on("add-role-show", (groupIdentifier) => {
      this.reset("Add role")
      this.groupIdentifier = groupIdentifier
      this.edit = false
      this.$bvModal.show("roleAddFormModal")
    })

    this.$eventBus.$off('edit-role-show')
    this.$eventBus.$on("edit-role-show", (identifier) => {
      this.reset("Edit role")
      let role = store.getters['roles/get'](identifier)
      this.groupIdentifier = role.group_identifier
      this.identifier = identifier
      this.name = role.name
      this.description = role.description
      this.isDefault = role.default
      this.edit = true
      this.$bvModal.show("roleAddFormModal")
    })

    this.$eventBus.$off('delete-role-show')
    this.$eventBus.$on("delete-role-show", (identifier) => {
      let role = store.getters['roles/get'](identifier)
      this.$deleteModal({
        title: "Delete role",
        text: "Delete role " + role.name + " ?"
      }).then((result) => {
        if (result.value) {
          store.dispatch('roles/delete', {groupIdentifier:role.group_identifier, identifier}).then(() => {
            this.$eventBus.$emit('success', 'The role has been deleted')
          }).catch((response) => {
            this.$eventBus.$emit('http-error', response)
          })
        }
      });
    })

    this.$eventBus.$off('clone-role-show')
    this.$eventBus.$on("clone-role-show", (identifier) => {
      let role = store.getters['roles/get'](identifier)
      this.$modal({
        title: "Clone role " + role.name + " ?",
        input: "text",
        inputLabel: "Enter cloned role name:",
        inputPlaceholder: role.name + " - Copy",
        inputAttributes: {
          autocapitalize: 'off'
        },
        showCancelButton: true,
        confirmButtonText: 'Clone role',

      }).then((result) => {
        if (!result.isConfirmed) return
        let data = {}
        if (result.value !== "") data["name"] = result.value
        store.dispatch('roles/clone', {
          groupIdentifier:role.group_identifier,
          roleIdentifier: role.identifier,
          data
        }).then(() => {
          this.$eventBus.$emit('success', 'The role has been deleted')
        }).catch((response) => {
          this.$eventBus.$emit('http-error', response)
        })

      });
    })
  },
  data() {
    return {
      identifier: null,
      title: "",
      role: {},
      group: null,
      groupIdentifier: "",
      name: null,
      description: null,
      edit: false,
      isDefault: false
    }
  },
  methods: {
    reset(title) {
      this.identifier = null
      this.title = title
      this.resources = []
      this.group = null
      this.groupIdentifier = ""
      this.name = null
      this.description = null
      this.isDefault = false
    },
    onAddEdit() {
      let data = {
        "name": this.name,
        "default": this.isDefault
      }
      if (this.description){
        data["description"] = this.description
      }
      if (this.edit) {
        store.dispatch('roles/update', {
          group_identifier: this.groupIdentifier,
          identifier: this.identifier,
          data: data
        }).then(() => {
          this.$bvModal.hide("roleAddFormModal")
          this.$eventBus.$emit('success', 'The role has been updated')
        }).catch((response) => {
          this.$eventBus.$emit('http-error', response)
        })
      } else {
        store.dispatch('roles/add', {group_identifier: this.groupIdentifier, data: data}).then(() => {
          this.$bvModal.hide("roleAddFormModal")
          this.$eventBus.$emit('success', 'The role has been created')
        }).catch((response) => {
          this.$eventBus.$emit('http-error', response)
        })
      }
    }
  }
}

</script>

<style scoped>

</style>
