import { render, staticRenderFns } from "./GroupList.vue?vue&type=template&id=9c6108f4&scoped=true&xmlns=http%3A%2F%2Fwww.w3.org%2F1999%2Fhtml"
import script from "./GroupList.vue?vue&type=script&lang=js"
export * from "./GroupList.vue?vue&type=script&lang=js"
import style0 from "./GroupList.vue?vue&type=style&index=0&id=9c6108f4&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9c6108f4",
  null
  
)

export default component.exports