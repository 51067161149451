<template>
  <div v-if="load">
    <label> Basic Permissions:</label>
    <div style="display: flex" >
      <div v-for="option in basicPermissions" style="display: flex;
            padding-left:10px;">
        <b-form-checkbox
          v-model="permission"
          :key="option.value"
          :value="option.value"
          name="RulePermission"
          @change="updatePermission($event, option.value)"
          :disabled="option.disabled">
          {{ option.text }}
          <font-awesome-icon
            :title="option.description"
            class="fa-icon"
            :icon="['fas', 'info-circle']"
            style="color: silver; margin-left:0.3em;">
          </font-awesome-icon>
        </b-form-checkbox>
      </div>
    </div>
    <label style="padding-top: 20px;"> Advanced Permissions: </label>
    <div style="display: flex;">

      <div v-for="option in advancedPermissions" style="display: flex;
              padding-left:10px;">
        <b-form-checkbox
          v-model="permission"
          :key="option.value"
          :value="option.value"
          name="RulePermission"
          @change="updatePermission($event, option.value)"
          :disabled="option.disabled">
          {{ option.text }}
          <font-awesome-icon
            :title="option.description"
            class="fa-icon"
            :icon="['fas', 'info-circle']"
            style="color: silver; margin-left:0.3em;">
          </font-awesome-icon>
        </b-form-checkbox>
      </div>
    </div>
  </div>

</template>

<script>
import store from "../../store";

export default {
  name: "InputPermission",
  props: {
    resource: String,
    value: Number,
  },
  mounted() {
      this.$store.dispatch('token/loadSpecs').then(() => {
        this.load = true
        this.specs = store.getters['token/getSpecs']()
      })
  },
  data() {
    return {
      load: false, // Wait specs to be loaded
      specs: {},
      permission: []
    }
  },
  methods: {    updateValue() {
      this.$emit('input', this.getValue())
    },
    getValue() {
      return this.$refs.el.value
    },
    refresh(value) {
      this.$refs.el.value = value
    },
    updatePermission(checked, value) {
      let that = this
      // nextTick is important, it ensures this.permission is updated
      this.$nextTick().then(function () {
        for (let option1 of that.permissions) {
          let included = false
          for (let option2 of that.permissions) {
            if (option1.value !== option2.value && (option1.value & option2.value) === option1.value) {
              // option1 is included by option2
              if (that.permission.includes(option2.value)) {
                //option2 is checked
                included = true
                break
              }
            }
          }
          if (included) {
            if (!that.permission.includes(option1.value)) {
              that.permission.push(option1.value)
            }
            option1.disabled = true
          } else {
            option1.disabled = false
          }
          that.$forceUpdate()
        }
        let mask = BigInt(0)
        for (let perm of that.permission) {
          mask = BigInt(mask) | BigInt(perm)
        }
        that.$emit('input', Number(mask))
      })
    },
  },
  computed: {
    basicPermissions: function () {
      return this.permissions.filter(permission => permission.group === 'basic')
    },
    advancedPermissions: function () {
      return this.permissions.filter(permission => permission.group !== 'basic')
    },
    permissions: function () {
      if (this.specs.permissions === undefined)
        return []
      let options = []
      options.push(...this.specs.permissions[this.resource].map(function (option) {
        return {
          "value": option['value'],
          "text": option['name'],
          "group": option['group'],
          "description": option['description'],
        }
      }))
      return options
    }
  }
}
</script>

<style scoped>

</style>
