<template>
  <b-container>
    <b-row>
      <b-col cols="1">
        <svg
          width="25"
          height="25"
          fill="#9ad056"
          viewBox="0 0 16 16"
          class="my-2 svg"
        >
          <path
            d="M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z"
          />
        </svg>
      </b-col>
      <b-col class="text-lg">
        <slot name="target"></slot>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "ObjectiveTarget",
};
</script>
