<template>
  <div class="sectionEditProfile">
    <div class="content">
      <div class="head-content">
        <h2>Password</h2>
        <div class="menu-edit">
          <button
            v-if="!loading"
            @click.stop="init"
            class="btn button btn-secondary">
            Cancel
          </button>
          <button
            v-if="!loading"
            @click.stop="validatEditUser"
            type="submit"
            class="btn btn-primary">
            Submit
          </button>
          <b-spinner v-else class="loading top"></b-spinner>
        </div>
      </div>
      <div class="edit">
        <div class="group">
          <label class="label">CHANGE PASSWORD</label>
          <input type="password"
                 v-model="currentPassword"
                 placeholder="Current password"/>
          <input type="password"
                 v-model="newPassword"
                 placeholder="New Password"
                 :class="{'disabled': currentPassword.length === 0 }"/>
          <input type="password"
                 v-model="confirmPassword"
                 placeholder="Confirm new Password"
                 :class="{'disabled': currentPassword.length === 0 }"/>
          <p class="error" v-if="errorNew">
            Passwords mismatch
          </p>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
export  default {
  name: "SettingsPassword",
  props: {
    profile: Object,
  },
  data() {
    return {
      loading: false,
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
      errorNew: false,
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.currentPassword = ""
      this.newPassword = ""
      this.confirmPassword = ""
      this.errorNew = false
    },
    async validatEditUser() {
      if (this.newPassword === "" && this.confirmPassword === "") return

      if (this.newPassword !== this.confirmPassword) {
        this.errorNew = true
        return
      }

      let data = {
        "old_password": this.currentPassword,
        "new_password": this.newPassword
      }


      this.loading = true
      await this.$store.dispatch('users/update_profile', {"identifier": "me", "data": data}).then(() => {
        this.$eventBus.$emit('success', "Password has been updated")
        this.init()
        this.$router.push({name: "home"})
      }).catch((response) => {
        this.$eventBus.$emit('http-error', response)
      })
      this.loading = false
    }
  }
}
</script>

<style scoped>
.content .label{
  font-size: 0.9em;
  color: var(--lade-color-grey);
}

.menu-edit {
  position: absolute;
  top: 18px;
  right: 20px;
}

.loading{
  width: 20px;
  height: 20px;
  position: absolute;
}

.loading.top {
  right: 20px;
  top: 5px;
}

.edit {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow-y: auto;
  position: relative;
  width: 100%;
  padding: 15px 15px 105px 15px;
}

.group {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 20px 10px 15px 10px;
  border: solid 1px var(--lade-color-white);
  border-radius: 10px 10px;
  position: relative;
  margin-top: 10px;
}

input {
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

.group input {
  width: 60%;
  margin: 10px 0 15px 0;
}

.group input:nth-child(2),
.group input:nth-child(3) {
  margin-bottom: 50px;
}

input.disabled {
  opacity: .5;
  pointer-events: none;
}

.error {
  color: var(--lade-color-red);
  text-align: center;
  position: absolute;
  bottom: -10px;
}

</style>
