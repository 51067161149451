<template>
  <div>
    <div id="hub">
        <div class="navbar-explorer">
          <svg width="90" height="35">
            <rect
              x="0"
              y="0"
              width="15"
              height="35"
              style="fill: var(--lade-green-explore-1)"
            />
            <rect
              x="30"
              y="0"
              width="15"
              height="35"
              style="fill: var(--lade-green-explore-2)"
            />
            <rect
              x="60"
              y="0"
              width="15"
              height="35"
              style="fill: var(--lade-green-explore-3)"
            />
          </svg>
          <div class="search-content" style="width: 100%">
            <font-awesome-icon :icon="['fas', 'magnifying-glass']" />
            <div class="objet-searched" :class="{'active': selectObject}" v-on:mouseover="selectObject = true" v-on:mouseleave="selectObject = false">
              <div class="select">
                {{ objetSelected }}
                <font-awesome-icon :icon="['fas', 'caret-down']" class="img-dropdown"/>
              </div>
              <div class="options">
                <p @click="searchBundles()">
                  <font-awesome-icon :icon="['fas', 'cubes']" />
                  Bundles
                </p>
                <p @click="searchGroups()">
                  <font-awesome-icon :icon="['fas', 'people-group']" />
                  Groups
                </p>
              </div>
            </div>
            <input
              type="search"
              id="searchcontent"
              class="input-search"
              v-model="searchContent"
              :placeholder="searchPlaceholder"
              @keyup="filter"
              style=" margin-top: 0; padding: 2px; height:20px; width: 100%"/>
          </div>
        </div>
      <BundleGrid
        v-if="objetSelected === 'bundles'"
        :search-content="searchContent"
        :reset="reset"
        :reload="reloadB"
      />
      <GroupGrid
        v-if="objetSelected === 'groups'"
        :search-content="searchContent"
        :reset="reset"
        :reload-groups="reloadG"
      />
      </div>
    </div>

</template>

<script>
// @ is an alias to /src
import { print_date } from "@/filters";
import store from "@/store";
import VisibilityBadge from '@/components/VisibilityBadge'
import BundleGrid from "@/components/hub/BundleGrid.vue";
import GroupGrid from "@/components/hub/GroupGrid.vue";

export default {
  name: "Explore",
  components: {
    GroupGrid,
    VisibilityBadge,
    BundleGrid
  },
  props: {
    editors: Array,
    reset: {
      type: Boolean,
      default: false
    },
    reloadBundles: {
      type: Boolean,
      default: true
    },
    reloadGroups: {
      type: Boolean,
      default: true
    },
    objectSearched: {
      type: String,
      default: 'bundles',
    },
  },
  data: function () {
    let data = {
      reloadB: true,
      reloadG: true,
      objetSelected: '',
      selectObject: false,
      filter_labels: [],
      custom_labels: [{ status: false, text: "" }],
      filter_editors: ["Airbus", "Airbus-community", "Stormshield"],
      filter_groups: [],
      loadingmore: false,
      sortSelected: { orderBy: "rate", desc: true },
      sortOptions: [
        { value: { orderBy: "rate", desc: true }, text: "Most Popular" }, // Registry feature
        { value: { orderBy: "created_date", desc: true }, text: "Most Recent" },
        { value: { orderBy: "download", desc: true }, text: "Download" },
        { value: { orderBy: "name", desc: false }, text: "Alphabetical" },
      ],
      selectedLabels: [],
      selectedFilters: [],
      filters: [],
      searchContent: "",
      //pagination
      skip: store.getters["bundles/getSkip"](),
      total: store.getters["bundles/getTotal"](),
      page: store.getters["bundles/getPage"](),
      totalPages: store.getters["bundles/getTotalPages"](),
      limit: 100,
      orderBy: "rate",
      desc: true,
      searchPlaceholder: "",
      searchForGroup: "",
      showGroupsUntil: 3,
      showLabelsUntil: 3,
      typingEffect: {
        charIndex: 0,
        textArrayIndex: 0,
        newTextDelay: 2000,
        erasingDelay: 100,
        typingDelay: 200,
        textArrayBundles: ["Search for bundles...", "Starter Pack IT", "SIGEN", "Network", "Training"],
        textArrayGroups: ["Search for groups...", "Airbus"],
      }
    }

    this.$store.dispatch('token/getTokenInfos').then(() => {
      if (this.$store.getters["token/isValid"]()) {
        data['filters'].push({ item: "favorites", name: "Favorites" })
      }
    })

    return data
  },
  computed: {},
  mounted() {
    setTimeout(this.typingEffectType, this.typingEffect.newTextDelay + 250)
    this.objetSelected = this.objectSearched
    this.reloadB = this.reloadBundles
    this.reloadG = this.reloadGroups
  },
  methods: {
    // typing effect
    typingEffectType() {
      if(this.objetSelected === 'bundles'){
        if (this.typingEffect.charIndex < this.typingEffect.textArrayBundles[this.typingEffect.textArrayIndex].length) {
          this.searchPlaceholder = this.searchPlaceholder + this.typingEffect.textArrayBundles[this.typingEffect.textArrayIndex].charAt(this.typingEffect.charIndex);
          this.typingEffect.charIndex++;
          setTimeout(this.typingEffectType, this.typingEffect.typingDelay);
        }
        else {
          setTimeout(this.typingEffectErase, this.typingEffect.newTextDelay);
        }
      }else{
        if (this.typingEffect.charIndex < this.typingEffect.textArrayGroups[this.typingEffect.textArrayIndex].length) {
          this.searchPlaceholder = this.searchPlaceholder + this.typingEffect.textArrayGroups[this.typingEffect.textArrayIndex].charAt(this.typingEffect.charIndex);
          this.typingEffect.charIndex++;
          setTimeout(this.typingEffectType, this.typingEffect.typingDelay);
        }
        else {
          setTimeout(this.typingEffectErase, this.typingEffect.newTextDelay);
        }
      }
    },
    typingEffectErase() {
      if(this.objetSelected === 'bundles') {
        if (this.typingEffect.charIndex > 0) {
          this.searchPlaceholder = this.typingEffect.textArrayBundles[this.typingEffect.textArrayIndex].substring(0, this.typingEffect.charIndex - 1);
          this.typingEffect.charIndex--;
          setTimeout(this.typingEffectErase, this.typingEffect.erasingDelay);
        } else {
          this.typingEffect.textArrayIndex++;
          if (this.typingEffect.textArrayIndex >= this.typingEffect.textArrayBundles.length) this.typingEffect.textArrayIndex = 0;
          setTimeout(this.typingEffectType, this.typingEffect.typingDelay + 1100);
        }
      }else{
        if (this.typingEffect.charIndex > 0) {
          this.searchPlaceholder = this.typingEffect.textArrayGroups[this.typingEffect.textArrayIndex].substring(0, this.typingEffect.charIndex - 1);
          this.typingEffect.charIndex--;
          setTimeout(this.typingEffectErase, this.typingEffect.erasingDelay);
        } else {
          this.typingEffect.textArrayIndex++;
          if (this.typingEffect.textArrayIndex >= this.typingEffect.textArrayGroups.length) this.typingEffect.textArrayIndex = 0;
          setTimeout(this.typingEffectType, this.typingEffect.typingDelay + 1100);
        }
      }
    },
    // rest

    async filter() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
    },
    searchGroups(){
      this.objetSelected = "groups"
      this.selectObject = false
      setTimeout(() => {
        this.reloadB = false
      },500);
    },
    searchBundles() {
      this.objetSelected = "bundles"
      this.selectObject = false
      setTimeout(() => {
        this.reloadG = false
      },500);
    },
  },
  filters: {
    print_date,
  },

};
</script>

<style scoped>
.navbar-explorer {
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(10px);
  box-shadow: rgba(17, 17, 26, 0.1) 0 4px 16px, rgba(17, 17, 26, 0.1) 0 8px 24px, rgba(17, 17, 26, 0.1) 0 16px 56px;
}

/*
.explorer-navbar p{
  font-weight: 700;
  line-height: 2.25rem;
  margin: 0 0 0 30px;
  font-size: 2em;
}
*/

.navbar-explorer .search-content {
  width: 60% !important;
  margin: 0 0;
}

.search-content .select {
  position: absolute;
  width: 110px;
  height: 42px;
  top: 4px;
  left: -2px;
  z-index: 3;
  border: none;
  border-radius: 25px 4px 4px 25px;
  background-color: var(--lade-color-white);
  color:var(--lade-color-black);
  font-weight: 600;
  text-transform: uppercase;
  font-size: 1em;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  padding: 0 5px 0 10px;
  cursor: pointer;
}

.objet-searched.active .select {
  border-radius: 25px 5px 0 0;
}

.objet-searched .select .img-dropdown {
  position: initial !important;
  margin-bottom: 4px;
  transition: 0.4s ease-out;
}

.objet-searched.active .select .img-dropdown {
  transform: rotateZ(-180deg);
  margin-bottom: 0;
}

.objet-searched .options{
  height: 0;
  overflow-y: hidden;
  position: absolute;
  top: 45px;
  left: -2px;
  width: 110px;
  background-color: var(--lade-color-white);
  color: var(--lade-color-black);
  font-weight: 600;
  text-transform: uppercase;
  font-size: 1em;
  border-radius: 0 0 5px 5px;
  text-align: right;
  transition: all 0.3s ease-out;
  z-index: 3;
}

.objet-searched .options p{
  position: relative;
  margin: 0 0;
  padding: 5px 10px 2px 0;
  cursor: pointer;
  transition: 0.3s;
}

.objet-searched .options p:hover{
  background-color: var(--lade-green-explore-1);
}

.objet-searched .options p svg{
  position: absolute;
  left: 5px;
  top: 1px;
}

.objet-searched.active .options {
  height: 55px;
  top: 45px;
}

.navbar-explorer .search-content .input-search{
  padding: 20px 20px 20px 140px !important;
}

.navbar-explorer .search-content svg {
  left: 115px;
  top: 15px;
}

.navbar-explorer .search-content .input-search:focus{
  padding-left: 115px !important;
}
</style>
