<template>
  <div>
    <div>
      <b-tabs card v-model="shareTabIndex">

        <b-tab title="Share with user" ref="createUserTab" active>
          <p>
            You can grant access for this bundle to a user of the same platform.<br />
            If you cannot see the user in this list, you can create a share token and send it to him.
          </p>
            <label style="display: inline-flex">Share with user: </label>
            <TypeAhead prefix="existingUserForm"
                       resourceType="user"
                       v-model="existingUser"
                       class="mb-2"
                       id="existingUser"
                       ref="existingUser"
                       required
                       @hit="existingUserIdentifier = $event"
                       style="padding-left: 20px;"/>
        </b-tab>
        <b-tab title="Share with group" ref="addExistingTab">
          <p>
            You can grant access for this bundle to a group of the same platform.
          </p>
          <label style="display: inline-flex"> Share with group: </label>

          <TypeAhead prefix="existingGroupForm"
                     resourceType="group"
                     v-model="existingGroup"
                     class="mb-2"
                     id="existingGroup"
                     style="padding-left: 20px;"
                     ref="existingUser" required
                     @hit="existingGroupIdentifier = $event"/>
        </b-tab>
        <b-tab title="Create share token" ref="addExistingTab">
          <p>
          Share tokens can be sent to users of the same platform.<br />
          Once used, they will grant privileges associated with the token.
          </p>
          <b-col>
            <InputText name="Name" v-model="name" help="Name of the token"/>

            <InputDatetime name="Expiration date" v-model="expiration"
                           @input="expiration = $event"
                           help="Select expiration date (this token will not expire if date is not set)"/>

            <InputCheckbox name="Limit usage" v-model="limitUsage"
                           @input="limitUsage = $event"
                           help="Should be checked if you want to set a maximum number of time in which this token must be used."/>
            <div v-if="limitUsage">
              <InputText name="Max Usage count" placeholder="2" v-model="max_usage"
                         type="number" min="0" max="10000"
                         required
                         help="Number of time the share token can be used."/>

            </div>
          </b-col>
        </b-tab>
      </b-tabs>

      <InputPermission
        v-model="permission"
        resource="BUNDLE"
        style="padding-left:20px;padding-bottom: 10px;"/>

      <div style="margin-left: 10px; padding:10px;">
        <b-button variant="light" class="limit-button"  @click="share" >
          <font-awesome-icon :icon="['fas', 'link']"
                             style="color: dodgerblue; margin-right: 4px;"/>
          <span v-if="shareTabIndex === 0 || shareTabIndex === 1">Share Bundle </span>
          <span v-if="shareTabIndex === 2">Create Share Token</span>
        </b-button>
      </div>

    </div>

    <div v-if="shareTabIndex === 0 || shareTabIndex === 1">
      <ResourceList
        key="sharelist"
        resource="share"
        :searchFilters="{bundleIdentifier: bundleIdentifier}"
        :deleteEventParamete="bundleIdentifier"
        deleteEvent="delete-share-show"
        autoLoad />
    </div>
    <div v-if="shareTabIndex === 2">
      <ResourceList
        key="sharetokenlist"
        resource="sharetoken"
        viewEvent="show-sharetoken"
        :searchFilters="{bundleIdentifier: bundleIdentifier}"
        deleteEvent="delete-sharetoken-show"
        autoLoad
        paginate/>
    </div>
  </div>
</template>

<script>
import TypeAhead from "../form/TypeAhead";
import InputPermission from "../form/InputPermission";
import store from '../../store'
import InputCheckbox from "../form/InputCheckbox";
import InputText from "../form/InputText";
import InputDatetime from "../form/InputDatetime";

export default {
  name: "BundleShares",
  components: {
    TypeAhead, InputPermission, InputDatetime,InputCheckbox, InputText
  },
  mounted() {
    this.$eventBus.$on("delete-share-show", (identifier) => {
      console.log(identifier)
      let item =  store.getters['share/get'](identifier);

      this.$deleteModal({
        title: "Delete share",
        text: "Delete this share ?"
      }).then((result) => {
        if (result.value) {
          if (item.user_identifier !== undefined) {
            store.dispatch('share/deleteUser', {
              userIdentifier: item.user_identifier,
              bundleIdentifier: item.bundle_identifier
            }).catch((response) => {
              this.$eventBus.$emit('http-error', response)
            })
          }
          else if (item.group_identifier !== undefined) {
            store.dispatch('share/deleteGroup', {
              groupIdentifier: item.group_identifier,
              bundleIdentifier: item.bundle_identifier
            }).catch((response) => {
              this.$eventBus.$emit('http-error', response)
            })
          }
        }
      });
    })
    this.$eventBus.$on("show-sharetoken", (identifier) => {
      console.log(identifier)
      let sharetoken =  store.getters['sharetoken/get'](identifier);

      this.$modal({
        title: "Share Token",
        width: "800px",
        html: "The share token is: <b>" + sharetoken.token +"</b><br />" +
          "You can send it to another user of this platform to give him access to this bundle. <br/>" +
          "It can be activated on the <b>Manager interface > Profile > Use Share Token</b><br />"
      });
    })
    this.$eventBus.$on("delete-sharetoken-show", (identifier) => {
      this.$deleteModal({
        title: "Delete share",
        text: "Delete this share token?"
      }).then((result) => {
        if (result.value) {
          store.dispatch('sharetoken/delete', {
            bundleIdentifier: this.bundleIdentifier,
            identifier}).catch((response) => {
            this.$eventBus.$emit('http-error', response)
          })
        }
      });
    })
  },
  props: {
    bundleIdentifier: String,
  },
  methods: {
    share(data) {
      if (this.permission === 0){
        this.$eventBus.$emit('error', "You must select permission")
        return
      }
      if (this.shareTabIndex === 0 ) { // User
        if (this.existingUserIdentifier === ""){
          this.$eventBus.$emit('error', "You must select a user")
          return
        }
        store.dispatch('share/addUser', {
          bundleIdentifier: this.bundleIdentifier,
          userIdentifier: this.existingUserIdentifier,
          permission: this.permission
        }).catch((response) => {
          this.$eventBus.$emit('http-error', response)
        }).then((response) => {
          store.dispatch('share/loadAll',
            {filters: {bundleIdentifier: this.bundleIdentifier}})
        })
      }
      else if (this.shareTabIndex === 1 ){ // groups
        if (this.existingGroupIdentifier === ""){
          this.$eventBus.$emit('error', "You must select a groups")
          return
        }
        store.dispatch('share/addGroup', {
          bundleIdentifier: this.bundleIdentifier,
          groupIdentifier: this.existingGroupIdentifier,
          permission: this.permission
        }).catch((response) => {
          this.$eventBus.$emit('http-error', response)
        }).then((response) => {
          store.dispatch('share/loadAll',
            {filters: {bundleIdentifier: this.bundleIdentifier}})
        })
      }
      else if (this.shareTabIndex === 2) { // share token
        let max_usage = -1
        if (this.limitUsage) {
          max_usage = this.max_usage
        }
        let name = (this.name==="")? null:this.name
        store.dispatch('sharetoken/add', {
          bundleIdentifier: this.bundleIdentifier,
          data: {
            permission: this.permission,
            expiration: this.expiration,
            name,
            max_usage
          }
        }).catch((response) => {
          this.$eventBus.$emit('http-error', response)
        }).then((response) => {
          store.dispatch('sharetoken/loadAll',
            {filters: {bundleIdentifier: this.bundleIdentifier}}).then(() => {
            this.$eventBus.$emit('show-sharetoken', response.data.identifier)
          })
        })
      }
    }
  },
  data() {
    return {
      permission: Number(0),
      existingUser: null,
      existingUserIdentifier: "",
      existingGroup: null,
      existingGroupIdentifier: "",
      shareTabIndex: null,
      expiration: null,
      expires: null,
      limitUsage: null,
      max_usage: 2,
      name: ""
    }
  }
}
</script>

<style scoped>
.limit-button {
  border-radius: 40px;
}


</style>
