<template>
  <nav class="bg-black">
    <b-row class="mx-md-5">
      <b-col class="d-flex">
        <div>
          <AirbusLogo />
        </div>
        <div class="mx-5 my-auto text-right d-none d-lg-flex">
          <b-button
            :variant="this.$route.name === 'home' ? 'light' : 'dark'"
            :to="{ name: 'home' }"
            class="font-weight-bold m-2"
          >
            <font-awesome-icon
              class="mr-1"
              :icon="['fas', 'home']"
            />
            Home
          </b-button>

          <b-button
            :variant="this.$route.name === 'bundleGrid' ? 'light' : 'dark'"
            @click="redirectBundleGrid"
            class="font-weight-bold m-2"
          >
            <font-awesome-icon
              class="mr-1"
              :icon="['fas', 'search']"
            />
            Explore
          </b-button>
        </div>
      </b-col>

      <b-col class="my-auto text-right">

        <b-container v-if="!isTokenValid">
          <b-button
            variant="light"
            :to="{ name: 'login', query: {rr: currentRouteName}}"
            class="p-3 font-weight-bold m-2"
          >
            <font-awesome-icon :icon="['fas', 'user']" />
            Sign In
          </b-button>
        </b-container>

        <b-container v-else>
          <!-- MOBILE -->
          <b-col class="d-lg-none justify-content-end">
            <div class="my-2">
              <b-button v-b-toggle.nav-sidebar variant="light" class="pr-3">
                <!-- Menu collapse -->
                <font-awesome-icon class="fa-icon" :icon="['fas', 'bars']" />
              </b-button>
            </div>

            <b-sidebar
              id="nav-sidebar"
              title="Navigation"
              shadow
              backdrop
              right
              bg-variant="dark"
              text-variant="white"
              width="100%"
            >
              <b-button
                :variant="this.$route.name === 'home' ? 'light' : 'dark'"
                :to="{ name: 'home' }"
                class="font-weight-bold m-2"
                size="lg"
                block
              >
                <font-awesome-icon
                  class="mr-1"
                  :icon="['fas', 'home']"
                />
                Home
              </b-button>
              <b-button
                :variant="this.$route.name === 'bundleGrid' ? 'light' : 'dark'"
                @click="redirectBundleGrid"
                class="font-weight-bold m-2"
                size="lg"
                block
              >
                <font-awesome-icon
                  class="mr-1"
                  :icon="['fas', 'search']"
                />
                Explore
              </b-button>

              <b-button
                v-if="uploads"
                variant="dark"
                :to="{ name: 'upload' }"
                class="font-weight-bold m-2"
                size="lg"
                block
              >
                <font-awesome-icon class="fa-icon" :icon="['fas', 'upload']" />
                Uploads
                <b-badge v-if="uploading > 0" variant="light">
                  {{ uploading }}
                </b-badge>
              </b-button>

              <b-dropdown
                v-if="hasAdministration"
                variant="dark"
                class="m-2"
                size="lg"
                block
              >
                <template #button-content>
                  <span class="font-weight-bold">
                    <font-awesome-icon class="mr-1" :icon="['fas', 'wrench']"/>
                    Administration
                  </span>
                </template>
                <b-dropdown-item
                  v-if="hasGroup || hasUser"
                  :to="{ name: 'groups' }"
                >
                  Groups
                </b-dropdown-item>
                <b-dropdown-item v-if="hasWorkflow" :to="{ name: 'workflow' }">
                  Workflows</b-dropdown-item
                >
                <b-dropdown-item v-if="hasConfig" :to="{ name: 'config' }">
                  Configuration
                </b-dropdown-item>
              </b-dropdown>

              <div>
                <b-button
                  variant="dark"
                  class="font-weight-bold m-2"
                  size="lg"
                  block
                  v-b-toggle.sidebar-notifications
                >
                  <div>
                    <font-awesome-icon
                      v-if="notifications > 0"
                      class="fa-icon mr-2"
                      :icon="['fas', 'bell']"
                    />
                    Notifications
                    <b-badge v-if="notifications > 0" variant="danger" class="ml-2">
                      {{ notifications }}
                    </b-badge>
                  </div>
                </b-button>

                <b-sidebar
                  id="sidebar-notifications"
                  title="Notifications"
                  right
                  shadow
                  backdrop
                  width="100%"
                >
                  <div class="px-3 py-2">
                    <NotificationBar />
                  </div>
                </b-sidebar>
              </div>

              <b-dropdown variant="dark" class="m-2" size="lg" block>
                <template #button-content>
                  <span class="font-weight-bold pr-2">
                    <Icon
                      v-if="profile.icon && profile.icon.url !== undefined"
                      :icon="profile.icon"
                      style="width: 20px !important; height: 20px !important"
                    />
                    <font-awesome-icon
                      v-else
                      class="fa-icon"
                      :icon="['fas', 'user']"
                    />
                  </span>
                  {{ tokenInfos ? tokenInfos.username : "" }}
                </template>
                <b-dropdown-item :to="{ name: 'profile' }">
                  User settings</b-dropdown-item
                >
                <b-dropdown-item :to="{ name: 'logout' }">
                  Logout</b-dropdown-item
                >
              </b-dropdown>
            </b-sidebar>
          </b-col>

          <!-- DESKTOP -->

          <b-col class="d-none d-lg-flex justify-content-end">
            <b-button
              v-if="uploads"
              variant="dark"
              :to="{ name: 'upload' }"
              class="font-weight-bold m-2"
            >
              <font-awesome-icon class="fa-icon" :icon="['fas', 'upload']" />
              Uploads
              <b-badge v-if="uploading > 0" variant="light">
                {{ uploading }}
              </b-badge>
            </b-button>

            <b-dropdown v-if="hasAdministration" variant="dark" class="m-2">
              <template #button-content>
                <span class="font-weight-bold">
                 <font-awesome-icon class="mr-1" :icon="['fas', 'wrench']"/>
                 Administration
                </span>
              </template>
              <b-dropdown-item
                v-if="hasGroup || hasUser"
                :to="{ name: 'groups' }"
              >
                Groups
              </b-dropdown-item>
              <b-dropdown-item v-if="hasWorkflow" :to="{ name: 'workflow' }">
                Workflows</b-dropdown-item
              >
              <b-dropdown-item v-if="hasConfig" :to="{ name: 'config' }">
                Configuration
              </b-dropdown-item>
            </b-dropdown>

            <div>
              <b-button
                variant="dark"
                class="font-weight-bold m-2"
                v-b-toggle.sidebar-notifications
              >
                <div>
                  <font-awesome-icon
                    v-if="notifications > 0"
                    class="fa-icon mr-2"
                    :icon="['fas', 'bell']"
                  />
                  Notifications
                  <b-badge v-if="notifications > 0" variant="danger" class="ml-2">
                    {{ notifications }}
                  </b-badge>
                </div>
              </b-button>

              <b-sidebar
                id="sidebar-notifications"
                title="Notifications"
                right
                shadow
                backdrop
                width="25%"
              >
                <div class="px-3 py-2">
                  <NotificationBar />
                </div>
              </b-sidebar>
            </div>

            <b-dropdown variant="dark" class="m-2">
              <template #button-content>
                <span class="font-weight-bold pr-2">
                  <Icon
                    v-if="profile.icon && profile.icon.url !== undefined"
                    :icon="profile.icon"
                    style="width: 20px !important; height: 20px !important"
                  />
                  <font-awesome-icon
                    v-else
                    class="fa-icon"
                    :icon="['fas', 'user']"
                  />
                </span>
                {{ tokenInfos ? tokenInfos.username : "" }}
              </template>
              <b-dropdown-item :to="{ name: 'profile' }">
                User settings</b-dropdown-item
              >
              <b-dropdown-item :to="{ name: 'logout' }">
                Logout</b-dropdown-item
              >
            </b-dropdown>
          </b-col>
        </b-container>
      </b-col>
    </b-row>
  </nav>
</template>

<script>
import Icon from "@/components/form/Icon";
import store from "@/store";
import AirbusLogo from "@/components/hub/landing/AirbusLogo";
import NotificationBar from "@/components/notifications/NotificationBar";

import "@/assets/css/colors.css";

export default {
  name: "NavBar",
  components: {
    Icon,
    AirbusLogo,
    NotificationBar,
  },
  created() {
    window.addEventListener("beforeunload", this.beforeLeaving);
    if (this.isTokenValid) {
      this.$store.dispatch("users/loadProfile");
    }
  },
  data() {
    return {
      origin: document.location.origin,
    };
  },
  computed: {
    isTokenValid: () => store.getters["token/isValid"](),
    currentRouteName() {
      return this.$route.path;
    },
    uploading: () =>
      store.getters["uploads/getAll"]().filter(
        (upload) => upload.status === "running"
      ).length,
    notifications: () =>
      store.getters["notifications/getNumberNotAcknowledged"](),
    uploads: () => store.getters["uploads/getAll"]().length,
    about: () => store.getters["about/get"](),
    profile: () => store.getters["users/getProfile"](),

    tokenInfos: () => store.getters["token/getInfos"](),

    hasUser: function () {
      return this.$store.getters["token/hasUser"]();
    },
    hasGroup: function () {
      return this.$store.getters["token/hasGroup"]();
    },
    hasRule: function () {
      return this.$store.getters["token/hasRule"]();
    },

    hasWorkflow: function () {
      return this.$store.getters["token/hasWorkflow"]();
    },
    hasConfig: function () {
      return this.$store.getters["token/hasConfig"]();
    },
    hasAdministration: function () {
      return (
        this.hasConfig || this.hasWorkflow
      );
    },
  },
  methods: {
    beforeLeaving(event) {
      if (this.uploading > 0) {
        event.preventDefault();
        event.stopImmediatePropagation();
        event.stopPropagation();
        event.returnValue = "";

        this.$warningModal({
          title: "Pending Uploads",
          confirmButtonText: "Cancel uploads",
          cancelButtonText: "Do nothing",
        }).then((result) => {
          if (result.value) {
            store.dispatch("uploads/clean");
          }
        });
        return null;
      }
    },
    redirectBundleGrid() {
      if (this.$route.name === 'bundleGrid') {
        this.$router.go({name: 'bundleGrid', params: {reset: true}})
      }
      else {
        this.$router.push({name: 'bundleGrid', params: {reset: true}})
      }
    }
  },
};
</script>

<style>
.logo {
  height: 43px;
  vertical-align: middle;
  margin-left: -15px;
  margin-right: -12px;
  margin-top: -2px;
}

.navbar-icon {
  height: 13px;
  color: var(--lade-color-white);
}

.navbar-icon:hover {
  color: var(--lade-color-white-hover);
}

.airbusicon {
  fill: var(--lade-color-white);
}

.airbusicon:hover {
  fill: var(--lade-color-white-hover);
}

.navbar-dark .navbar-nav {
  padding-top: 1px;
}

.navbar-dark .navbar-nav .nav-link,
.dropdown-toggle span {
  color: var(--lade-color-white) !important;
}

.navbar-dark .navbar-nav .nav-link:hover,
.dropdown-toggle span:hover {
  color: var(--lade-color-white-hover) !important;
}

.mainnavbar {
  font-size: 13px;
  height: 40px;
}
</style>
