import Vue from 'vue'
import Config from '../../Config'
import {field_tag, field_created_at, field_actions, field_hardware_type} from './fields'
import {print_human_readable_size} from '@/filters'
import {setDictValue, build_resource_list_params} from '@/common';

function print_human_readable_size_MB(value) {
  return print_human_readable_size(value * 1024 * 1024)
}

const state = {
  list: [],
  filteredList: [],
  OSTypes: [],
  pagination: {
    page: 1,
    total_pages: 0,
    total: 0,
  },
  _lookup: {},
  _hsLookup: {},
  _nsLookup: {},
  fields: [
    {
      "key": "name",
      "sortable": true
    },
    field_tag,
    {
      "key": "size",
      "sortable": true,
      "formatter": print_human_readable_size
    },
    field_created_at,
    field_actions
  ],
  hostSpecsFields: [
    {
      "key": "hostname",
      "sortable": true
    },
    field_hardware_type,
    {
      "key": "os",
      "sortable": true
    },
    {
      "key": "size",
      "sortable": true,
      "formatter": print_human_readable_size
    },
    field_actions
  ],
  networkSpecsFields: [
    {
      "key": "name",
      "sortable": true
    },
    {
      "key": "network_type",
      "sortable": true
    },
    {
      "key": "status",
      "sortable": true
    },
    field_actions
  ],
  hostSpecsImagesFields: [
    {
      "key": "name",
      "sortable": true
    },
    {
      "key": "size",
      "name": "Library size",
      "formatter": print_human_readable_size,
      "sortable": true
    },
    {
      "key": "disk_size",
      "name": "Deployed Size",
      "formatter": print_human_readable_size,
      "sortable": true
    },
    field_created_at,
    field_actions
  ],
  filters: {
    isEditable: (entry) => true,
    isDeletable: (entry) => true,
    isExportable: (entry) => true,
    isDownloadable: (entry) => true
  },
  hostSpecsFilters: {
    isEditable: (entry) => true,
    isDeletable: (entry) => true,
    isExportable: (entry) => false,
    isDownloadable: (entry) => false
  },
  networkSpecsFilters: {
    isEditable: (entry) => true,
    isDeletable: (entry) => true,
    isExportable: (entry) => false,
    isDownloadable: (entry) => false
  },
  hostSpecsImagesFilters: {
    isEditable: (entry) => true,
    isDeletable: (entry) => true,
    isExportable: (entry) => false,
    isDownloadable: (entry) => false
  },
}

const getters = {
  get: (state) => (identifier) =>
    state._lookup[identifier],
  getBundle: (state) => (bundleIdentifier) =>
    state.list,
  getAll: (state) => () =>
    state.list,
  getFilters: (state) => () =>
    state.filters,
  getFields: (state) => () =>
    state.fields,
  getNics: (state) => ({topologyIdentifier, identifier}) => {
    for (let host_spec of state._hsLookup[topologyIdentifier]) {
      if (host_spec.identifier === identifier) {
        return host_spec.nics
      }
    }
  },
  getHostSoftware: (state) => ({topologyIdentifier, identifier}) => {
    for (let host_spec of state._hsLookup[topologyIdentifier]) {
      if (host_spec.identifier === identifier) {
        return host_spec.software
      }
    }
  },
  getHostSpec: (state) => (topologyIdentifier, identifier) => {
    for (let host_spec of state._hsLookup[topologyIdentifier]) {
      if (host_spec.identifier === identifier) {
        return host_spec
      }
    }
  },
  getHostSpecImages: (state) => ({topologyIdentifier, identifier}) => {
    for (let host_spec of state._hsLookup[topologyIdentifier]) {
      if (host_spec.identifier === identifier) {
        return host_spec.images
      }
    }
  },
  getHostSpecImage: (state) => ({topologyIdentifier, hostSpecIdentifier, identifier}) => {
    for (let host_spec of state._hsLookup[topologyIdentifier]) {
      if (host_spec.identifier === hostSpecIdentifier) {
        for (let image of host_spec.images) {
          if (image.identifier === identifier)
            return image
        }
      }
    }
  },
  getNetworkSpec: (state) => (topologyIdentifier, identifier) => {
    for (let host_spec of state._nsLookup[topologyIdentifier]) {
      if (host_spec.identifier === identifier) {
        return host_spec
      }
    }
  },

  getOSTypes: (state) => () =>
    state.OSTypes,
  getHostSpecs: (state) => (identifier) =>
    state._hsLookup[identifier],
  getHostSpecsFields: (state) => () =>
    state.hostSpecsFields,
  getHostSpecsFilters: (state) => () =>
    state.hostSpecsFilters,

  getHostSpecsImagesFilters: (state) => () =>
    state.hostSpecsImagesFilters,

  getNetworkSpecs: (state) => (identifier) =>
    state._nsLookup[identifier],
  getNetworkSpecsFields: (state) => () =>
    state.networkSpecsFields,
  getNetworkSpecsFilters: (state) => () =>
    state.networkSpecsFilters,

  getPagination: (state) => () =>
    state.pagination
}


function topologyURL(topology) {
  return "/bundles/" + topology.bundle_identifier + '/topologies/' + topology.identifier
}

const actions = {
  toggle({dispatch, commit}, item) {
    commit('setDetails', {item, details: !item._showDetails})
  },
  loadBundle({dispatch, commit}, bundleIdentifier) {
    return Vue.http.get(Config.API_BUNDLES_URL + "/" + bundleIdentifier + "/topologies").then((response) => {
      let topologies = response.data
      commit('setAll', topologies)
    })
  },
  loadAll({dispatch, commit}, {filters = {}, flatten = false, ...resource_list_params}) {
    let url
    let params = build_resource_list_params(resource_list_params)
    if (flatten === true) {
      params['flatten'] = "true"
    }

    if (filters.bundleIdentifier !== undefined)
      url = Config.API_BUNDLES_URL + "/" + filters.bundleIdentifier + "/topologies"
    else
      url = Config.API_TOPOLOGIES_URL
    return Vue.http.get(url, {params}).then((response) => {
      let topologies = response.data
      commit('setAll', topologies)
    })
  },
  load({dispatch, commit}, identifier) {
    return Vue.http.get(Config.API_TOPOLOGIES_URL + '/' + identifier + '?flatten=true').then((response) => {
      let topology = response.data
      commit('set', {identifier, topology})
    })
  },
  add({dispatch, commit}, {bundleIdentifier, data}) {
    return Vue.http.post(Config.API_BUNDLES_URL + "/" + bundleIdentifier + "/topologies", data).then((response) => {
      let topology = response.data
      return dispatch('load', topology.identifier)
    })
  },
  update({dispatch, commit}, {identifier, data}) {
    let topology = state._lookup[identifier]
    let formData = new FormData()
    if (data['icon']) {
      formData.append('icon', data['icon'])
      delete data['icon']
    }
    formData.append('data', JSON.stringify(data))
    return Vue.http.put(Config.API_BASE_URL + topologyURL(topology), formData).then((response) => {
      return dispatch('load', identifier)
    })
  },
  delete({dispatch, commit}, identifier) {
    let topology = state._lookup[identifier]
    return Vue.http.delete(Config.API_BASE_URL + topologyURL(topology)).then((response) => {
      return commit('delete', identifier)
    })
  },
  addNetwork({dispatch, commit}, {topologyIdentifier, data}) {
    let topology = state._lookup[topologyIdentifier]
    return Vue.http.post(Config.API_BASE_URL + topologyURL(topology) + '/networks', data).then((response) => {
      return dispatch('load', topologyIdentifier)
    })
  },
  updateNetwork({dispatch, commit}, {topologyIdentifier, identifier, data}) {
    let topology = state._lookup[topologyIdentifier]
    return Vue.http.put(Config.API_BASE_URL + topologyURL(topology) + '/networks/' + identifier, data).then((response) => {
      return dispatch('load', topologyIdentifier)
    })
  },
  deleteNetwork({dispatch, commit}, {topologyIdentifier, identifier}) {
    let topology = state._lookup[topologyIdentifier]
    return Vue.http.delete(Config.API_BASE_URL + topologyURL(topology) + '/networks/' + identifier).then((response) => {
      return dispatch('load', topologyIdentifier)
    })
  },
  addHost({dispatch, commit}, {topologyIdentifier, data}) {
    let topology = state._lookup[topologyIdentifier]

    let requestData = null
    if (data["icon"] !== undefined && data["icon"].constructor.name === "File") {
      requestData = new FormData()
      if (data['icon']) {
        requestData.append('icon', data['icon'])
      }
      requestData.append('data', JSON.stringify(data))
    } else {
      requestData = data
    }

    return Vue.http.post(Config.API_BASE_URL + topologyURL(topology) + '/hosts', requestData).then((response) => {
      return dispatch('load', topologyIdentifier)
    })
  },
  updateHost({dispatch, commit}, {topologyIdentifier, identifier, data}) {
    let topology = state._lookup[topologyIdentifier]

    let requestData = null
    if (data["icon"] !== undefined && data["icon"].constructor.name === "File") {
      requestData = new FormData()
      if (data['icon']) {
        requestData.append('icon', data['icon'])
      }
      requestData.append('data', JSON.stringify(data))
    } else {
      requestData = data
    }

    return Vue.http.put(Config.API_BASE_URL + topologyURL(topology) + '/hosts/' + identifier, requestData).then((response) => {
      return dispatch('load', topologyIdentifier)
    })
  },
  deleteHost({dispatch, commit}, {topologyIdentifier, identifier}) {
    let topology = state._lookup[topologyIdentifier]
    return Vue.http.delete(Config.API_BASE_URL + topologyURL(topology) + '/hosts/' + identifier).then((response) => {
      return dispatch('load', topologyIdentifier)
    })
  },
  filter({dispatch, commit}, search) {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
    this.timer = setTimeout(() => {
      commit('filter', search)
    }, 600);
  },
  search({dispatch, commit}, {search, filters = {}}) {
    return dispatch('search/topology', {search, filters}, {root: true}).then((resources) => {
      commit('setAll', resources)
    })
  },
  deleteImage({dispatch, commit}, {topologyIdentifier, hostIdentifier, identifier}) {
    let topology = state._lookup[topologyIdentifier]
    return Vue.http.delete(Config.API_BASE_URL + "/topologies/" + topologyIdentifier + '/hosts/' + hostIdentifier + "/images/" + identifier).then((response) => {
      return dispatch('load', topologyIdentifier)
    })
  },
  updateImage({dispatch, commit}, {topologyIdentifier, hostIdentifier, identifier, data}) {
    let topology = state._lookup[topologyIdentifier]
    return Vue.http.put(Config.API_BASE_URL + topologyURL(topology) + '/hosts/' + hostIdentifier + "/images/" + identifier, data).then((response) => {
      return dispatch('load', topologyIdentifier)
    })
  },
  async addImage({dispatch, commit, rootGetters}, {file, bundleIdentifier, topologyIdentifier, hostIdentifier,
                                                   data, onerror, onfinished}) {
    if (data.name === undefined || data.name === null) {
      data.name = file.name.replace(/\s/g, '_')
    }
    data.path = file.name.replace(/\s/g, '_')

    let response = await Vue.http.post(Config.API_BUNDLES_URL + '/' + bundleIdentifier
                                                              + '/topologies/' + topologyIdentifier
                                                              + '/hosts/' + hostIdentifier + '/images', data)
    let response_data = response.data
    dispatch("load", response_data.identifier)
    let url = response_data.files[0].url
    let workflow_id = response_data.workflow_id
    dispatch("uploads/add", {
      identifier: response.identifier,
      description: data.tag,
      data: file,
      url: url,
      onerror: async (message) => {
        await dispatch("workflows/load", workflow_id, {root:true})
        let workflow = rootGetters['workflows/get'](workflow_id)
        dispatch("workflows/cancel", workflow, {root:true})
        onerror(message)
      },
      onfinished: () => {
        dispatch("topologies/load", topologyIdentifier, {root:true})
        onfinished()
      }
    }, {root:true})
    return response_data.identifier
  },
}

const mutations = {
  delete(state, identifier) {
    Vue.delete(state.list, state.list.indexOf(state.list.find(item => item.identifier === identifier)))
  },
  setDetails(state, {item, details}) {
    Vue.set(item, '_showDetails', details)
  },
  setAll(state, topologies) {
    state._lookup = []
    state.pagination.page = topologies.page
    state.pagination.total_pages = topologies.total_pages
    state.pagination.total = topologies.total
    state.list.splice(0, state.list.length)
    for (let topology of topologies.data) {
      let identifier = topology.identifier
      setDictValue(state._lookup, identifier, topology)
      state.list.push(topology)
    }
  },
  set(state, {identifier, topology}) {
    if (state._lookup[topology.identifier] === undefined)
      state.list.push(topology)
    setDictValue(state._lookup, topology.identifier, topology)

    // Network Specs
    if (identifier in state._nsLookup)
      state._nsLookup[identifier].splice(0, state._nsLookup[identifier].length)
    else
      state._nsLookup[identifier] = []

    for (let spec of topology.networks_specs)
      state._nsLookup[identifier].push(spec)

    // Host Specs
    if (identifier in state._hsLookup)
      state._hsLookup[identifier].splice(0, state._hsLookup[identifier].length)
    else
      state._hsLookup[identifier] = []

    for (let spec of topology.hosts_specs)
      state._hsLookup[identifier].push(spec)

  }
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true
}
