import Vue from 'vue'
import Config from '../../Config'
import token from "@/store/modules/token";
import {field_actions, field_created_at} from "./fields";

const state = {
  list: [],
  filteredList: [],
  _lookup: {},
  fields: [
    {
      "key": "bundle_identifier",
      "name": "subject",
      formatter: (value = null, key = null, item = null) => {
        if (item.group === undefined) {
          return "USER / " + item.user.name
        }
        else if (item.user === undefined) {
          return "GROUP / " + item.group.name
        }
      }
    },
    {
      "key": "permission",
      "name": "permission",
      formatter: (value) => {
        let verbs = []
        if (token.state.specs.permissions.BUNDLE) {
          for (let permission of token.state.specs.permissions.BUNDLE) {
            if ((permission.value & value) === permission.value) {
              verbs.push(permission.name)
            }
          }
        }
        return verbs.join(', ')
      },
    },
    field_created_at,
    field_actions
  ],
  filters: {
    isEditable: (entry) => false,
    isExportable: (entry) => false,
    isDeletable: (entry) => true,
    isDownloadable: (entry) => false
  }
}

const getters = {
  getFilters: (state) => () =>
    state.filters,
  getAll: (state) => () =>
    state.list,
  get: (state) => (identifier) =>
    state._lookup[identifier],
  getFields: (state) => () =>
    state.fields
}

const actions = {
  loadAll({dispatch, commit}, {filters = {}}) {
    let url = Config.API_BUNDLES_URL + "/" + filters.bundleIdentifier + "/shares"
    return Vue.http.get(url, {}).then((response) => {
      let shares = response.data
      commit('setAll', shares)
    })
  },
  addUser({dispatch, commit}, {userIdentifier, bundleIdentifier, permission}) {
    let url = Config.API_BUNDLES_URL + "/" + bundleIdentifier + "/shares/users/"+userIdentifier+"?permission="+permission
    return Vue.http.put(url)
  },
  addGroup({dispatch, commit}, {groupIdentifier, bundleIdentifier, permission}) {
    let url = Config.API_BUNDLES_URL + "/" + bundleIdentifier + "/shares/groups/"+groupIdentifier+"?permission="+permission
    return Vue.http.put(url)
  },
  deleteUser({dispatch, commit}, {userIdentifier, bundleIdentifier}) {
    let url = Config.API_BUNDLES_URL + "/" + bundleIdentifier + "/shares/users/"+userIdentifier
    return Vue.http.delete(url).then((response) => {
      commit('delete', userIdentifier)
    })
  },
  deleteGroup({dispatch, commit}, {groupIdentifier, bundleIdentifier}) {
    let url = Config.API_BUNDLES_URL + "/" + bundleIdentifier + "/shares/groups/"+groupIdentifier
    return Vue.http.delete(url).then((response) => {
      commit('delete', groupIdentifier)
    })
  }
}

const mutations = {
  setAll(state, shares) {
    state._lookup = {}
    state.list.splice(0, state.list.length)

    for (let share of shares.group_shares) {
      share.identifier= share.group_identifier
      state._lookup[share.identifier] = share
      state.list.push(share)
    }

    for (let share of shares.user_shares) {
      share.identifier= share.user_identifier
      state._lookup[share.identifier] = share
      state.list.push(share)
    }
  },
  delete(state, identifier) {
    Vue.delete(state.list, state.list.indexOf(state.list.find(item => item.identifier === identifier)))
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true
}
