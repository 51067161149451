<template>
  <b-modal size="lg" ref="addFormModal" id="addFormModal" hide-footer v-bind:title="title">
    <form v-on:submit.prevent="onAddEdit">
      <div class="card">
        <div class="card-header">
          Attributes
        </div>
        <div class="card-body">
          <InputText v-if="edit" prefix="File" name="identifier" v-model="identifier" disabled></InputText>

          <div class="form-group row">
            <label class="col-sm-3 col-form-label" for="Bundle">Bundle</label>
            <div class="col-sm-9">
              <TypeAhead prefix="File" id="Bundle" resourceType="bundle" v-model="bundle" class="mb-2"
                         @hit="bundleIdentifier=$event" disabled />
            </div>
          </div>

          <InputImage v-if="!edit" prefix="File" name="File" required @input="(file) => {this.file = file}" accept="*" />

          <InputText prefix="File" name="Name" v-model="name" required v-if="edit"></InputText>

          <InputText prefix="File" name="Alias" v-model="alias" pattern="[a-zA-Z0-9\-\_]{3,50}"></InputText>

          <InputText prefix="File" name="Qualifier" v-model="qualifier"></InputText>

          <InputText prefix="File" name="Size" v-model="size" disabled v-if="edit"></InputText>

          <div class="form-group row">
            <label class="col-sm-3 col-form-label" for="Category">Category</label>
            <div class="col-sm-9">
              <TypeAhead prefix="File" resourceType="category" v-model="category" class="mb-2" id="Category"
                         @hit="categoryIdentifier=$event"
                         :filters="{bundleIdentifier: bundleIdentifier}" :disabled="bundleIdentifier === null"/>
            </div>
          </div>

          <TextAreaForm ref="description" prefix="File" name="description" :rows="2"
                        v-model="description"></TextAreaForm>
          <TextAreaForm ref="attributes" prefix="File" name="attributes" :rows="5" v-model="attributes"
                        :json="true" v-if="edit"></TextAreaForm>
          <button type="submit" class="btn btn-primary">Submit</button>
        </div>
      </div>
    </form>
  </b-modal>
</template>

<script>
import Config from "../../Config";
import store from '../../store'
import InputText from '../form/InputText'
import InputImage from '../form/InputImage'
import TypeAhead from '../form/TypeAhead'
import TextAreaForm from '../form/TextAreaForm'
import {Tag} from '@/common'
import {print_tag} from '@/filters'

export default {
  name: 'FileListForm',
  created() {

    this.$eventBus.$off("add-file-show")
    this.$eventBus.$on("add-file-show", () => {
      this.reset("Add file")
      if (this.$refs.attributes) this.$refs.attributes.$emit('reset')
      if (this.$refs.description) this.$refs.description.$emit('reset')
      this.bundleIdentifier = this.forcedBundleIdentifier;
      this.bundle = store.getters['bundles/get'](this.bundleIdentifier).tag
      this.$refs.addFormModal.show()
    })

    this.$eventBus.$off("edit-file-show")
    this.$eventBus.$on("edit-file-show", (identifier) => {
      this.reset("Edit file")
      let file = store.getters['files/get'](identifier)
      this.identifier = file.identifier
      this.alias = file.alias
      this.qualifier = file.qualifier
      this.tag = file.tag
      this.name = file.name
      this.description = file.description
      this.attributes = file.attributes
      this.size = file.size
      this.category = null
      this.categoryIdentifier = file.category_identifier
      this.edit = true
      this.bundleIdentifier = file.bundle_identifier

      if (this.$refs.attributes) this.$refs.attributes.$emit('reset', this.attributes)
      if (this.$refs.description) this.$refs.description.$emit('reset', this.description)

      let bundleIdentifier = this.bundleIdentifier
      let categoryIdentifier = this.categoryIdentifier
      let promises = []

      // Load bundle if not already loaded
      if (!store.getters['bundles/get'](bundleIdentifier))
        promises.push(store.dispatch('bundles/load', bundleIdentifier))

      // Load category if exists and not already loaded
      if (categoryIdentifier != null && !store.getters['categories/get'](categoryIdentifier))
        promises.push(store.dispatch('categories/loadAll', categoryIdentifier))

      Promise.all(promises).then((values) => {
        this.bundle = store.getters['bundles/get'](bundleIdentifier).tag
        let category = store.getters['categories/get'](categoryIdentifier)
        this.category = category != null ? category.name : null
        this.$refs.addFormModal.show()
      })
    })

    this.$eventBus.$off("delete-file-show")
    this.$eventBus.$on("delete-file-show", (identifier) => {
      let file = store.getters['files/get'](identifier)
      this.$deleteModal({
        title: "Delete file",
        text: "Delete file " + print_tag(false, false, file) + " ?"
      }).then((result) => {
        if (result.value) {
          store.dispatch('files/delete', identifier).then(() => {
            this.$eventBus.$emit('success', 'The file has been deleted')
          }).catch((response) => {
            this.$eventBus.$emit('http-error', response)
          })
        }
      });
    })

    this.$eventBus.$off('export-file')
    this.$eventBus.$on('export-file', (identifier) => {
      let file = store.getters['files/get'](identifier)
      store.dispatch('bundles/export', {
        bundle_identifier: file.bundle_identifier,
        resources: [file.identifier]
      }).then(() => {
        this.$eventBus.$emit('success', 'The file is being exported')
      }).catch((response) => {
        this.$eventBus.$emit('http-error', response)
      })
    })

    this.$eventBus.$off('download-file')
    this.$eventBus.$on('download-file', (identifier) => {
      let file = store.getters['files/get'](identifier)
      store.dispatch('bundles/export', {
        bundle_identifier: file.bundle_identifier,
        resources: [file.identifier],
        no_metadata: true
      }).then(() => {
        this.$eventBus.$emit('success', 'The file is being downloaded')
      }).catch((response) => {
        this.$eventBus.$emit('http-error', response)
      })
    })
  },
  data() {
    return {
      identifier: null,
      alias: null,
      qualifier: null,
      tag: null,
      name: null,
      description: null,
      attributes: {},
      category: null,
      categoryIdentifier: null,
      size: 0,
      title: "",
      edit: false,
      bundle: null,
      bundleIdentifier: null,
      file: null,
    }
  },
  computed: {},
  methods: {
    reset(title) {
      this.title = title
      this.edit = false
      this.identifier = null
      this.alias = null
      this.qualifier = null
      this.tag = null
      this.name = null
      this.description = null
      this.attributes = {}
      this.size = 0
      this.category = null
      this.categoryIdentifier = null
    },
    async onAddEdit() {

      let file_data = {
        "tag": new Tag(this.alias, this.qualifier).print(),
        "name": this.name,
        "description": this.description,
        "attributes": this.attributes,
        "category_identifier": this.categoryIdentifier
      }

      if (this.edit) {
        try {
          await store.dispatch('files/update', {
            bundleIdentifier: this.bundleIdentifier,
            identifier: this.identifier,
            data: file_data
          })
          this.$refs.addFormModal.hide()
          this.$eventBus.$emit('success', 'The file has been updated')
        } catch (response) {
          this.$eventBus.$emit('http-error', response)
        }
      } else {
        try {
          let file_identifier = await this.$store.dispatch("files/add", {
              file: this.file,
              bundleIdentifier: this.bundleIdentifier,
              data: file_data,
              onerror: (message) => {
                this.$eventBus.$emit('error', message)
              },
              onfinished: () => {
                this.$eventBus.$emit('success', "The file " + file_data.tag + " has been imported.")
              }
            }
          )
          await this.$store.dispatch("files/load", file_identifier);
          this.$refs.addFormModal.hide()
        } catch(error) {
          this.$eventBus.$emit('http-error', error)
        }
      }
    },
    onDelete() {
      store.dispatch('files/delete', {
        bundleIdentifier: this.bundleIdentifier,
        identifier: this.identifier
      }).then(() => {
        this.$refs.deleteFormModal.hide()
        this.$eventBus.$emit('success', 'The file has been deleted')
      }).catch((response) => {
        this.$eventBus.$emit('http-error', response)
      })
    }
  },
  components: {
    InputText, TextAreaForm, TypeAhead, InputImage
  },
  props: {
    forcedBundleIdentifier: String
  }
}

</script>

<style>
.resource-list {
  padding-top: 20px;
  padding-bottom: 20px;
}
</style>

