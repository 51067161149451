<template>
  <b-form @submit="onSubmit" style="margin: 20px;">
    <b-card header="Change profile picture">
      <Icon v-if="Boolean(profile)" :icon="profile.icon" style="float:left; margin-right: 30px;"/>
      <InputImage prefix="User" name="icon" @input="(file) => {icon=file}"/>
      <button type="submit" class="btn btn-primary">Submit</button>
    </b-card>
  </b-form>
</template>

<script>

import InputImage from '@/components/form/InputImage.vue'
import Icon from '../form/Icon'
import store from '../../store'

export default {
  name: 'ProfilePicture',
  components: {
    Icon, InputImage
  },
  created() {
    this.$store.dispatch('users/loadProfile')
  },
  computed: {
    profile: () => store.getters['users/getProfile']()
  },
  data() {
    return {
      icon: null,
    }
  },
  methods: {
    reset() {
      this.icon = null
    },
    onSubmit() {
      let data = {
        "icon": this.icon,
      }

      this.$store.dispatch('users/update_profile', {data}).then(() => {
        this.$eventBus.$emit('success', 'Your profile picture has been updated')
        this.reset()
        this.$router.push({name: "home"})
      }).catch((response) => {
        this.$eventBus.$emit('http-error', response)
      })
    },
  }
}
</script>
