<template>
  <div v-bind:id="resource">
    <component
      v-bind:is="resourceListForm"
      ref="resourceListForm"
      :parameters="resourceListFormParameters"
      :addEvent="addEvent"
      :editEvent="editEvent"
      :deleteEvent="deleteEvent"
      :viewEvent='viewEvent'
    ></component>

    <b-row v-if="paginate" align-h="between" style="padding-top: 10px; width:80%;">
      <p class="col-3" style="padding-top: 20px;padding-left: 20px;">Total elements: {{ pagination.total }}</p>
      <b-pagination v-model="page" :total-rows="pagination.total" @input="loadResources"
                    :per-page="limit" first-number last-number class="col-4"/>
      <b-select :options="limitOptions" v-if="showLimitOptions" v-model="limit"
                class="col-3" @change="loadResources"/>
    </b-row>

    <b-table
      show-empty
      :items="rList"
      :fields="rFields"
      :fixed="fixed"
      ref="resourcetable"
      responsive
      sort-icon-left
      :sticky-header='stickyHeader'
      :busy="isBusy || busy"
      :sortBy="sortBy"
      :sort-desc.sync="reverseSort"
      @row-clicked="toggle"
      :sort-compare="sortCompare"
      v-if="rList != null && rList !== undefined"
      :class="[{selectable:subComponent}]">

      <template v-slot:table-busy>
        <div class="text-center">
          <b-spinner class="align-middle"></b-spinner>
          <strong style="margin-left:10px;">Loading...</strong>
        </div>
      </template>

      <template v-slot:head(actions)>
        <div class="input-group">
          <div class="input-group-text input-group-prepend"
               v-if="!hideRefresh">
            <font-awesome-icon :icon="['fas', 'sync']"
                               @click="refreshResource()"
                               style="height: 10px; cursor: pointer;"
                               ref="refresh"/>
          </div>
          <div class="input-group-text input-group-append input-group-prepend"
               v-if="addEvent && canAddResource()"
               style="border-top-left-radius:0; border-bottom-left-radius:0;">

            <font-awesome-icon
              :icon="['fas', 'plus']"
              @click="addResource()"
              style="cursor: pointer;"/>
          </div>
          <input type="text" v-model="search"
                 placeholder="Search"
                 v-if="!hideSearch"
                 class="form-control input-group-append" @keyup="searchResources"
                 style="max-width:100px;"/>
          <slot name="customHeaderAction"/>
        </div>
      </template>

      <template v-slot:cell(actions)="row">
        <font-awesome-icon
          class='fa-icon action-icon'
          :icon="['fas', 'caret-up']"
          title="Directly download the resource" v-if="subComponent && row.item._showDetails"/>
        <font-awesome-icon
          class='fa-icon action-icon'
          :icon="['fas', 'caret-down']"
          title="Directly download the resource" v-else-if="subComponent"/>
        <font-awesome-icon
          class='fa-icon action-icon'
          v-if="downloadEvent && isDownloadable(row.item)"
          @click.stop="downloadResource(row.item.identifier)"
          :icon="['fas', 'file-download']"
          title="Directly download the resource"/>

        <font-awesome-icon
          class='fa-icon action-icon'
          v-if="exportEvent && isExportable(row.item)"
          @click.stop="exportResource(row.item.identifier)"
          :icon="['fas', 'download']"
          title="Export as bundle"/>

        <font-awesome-icon
          class='fa-icon action-icon'
          v-if="editEvent && isEditable(row.item)"
          @click.stop="editResource(row.item.identifier)"
          :icon="['fas', 'pencil-alt']"
          title="Edit resource"/>

        <font-awesome-icon
          class='fa-icon action-icon'
          v-if="viewEvent && !isEditable(row.item)"
          @click.stop="viewResource(row.item.identifier)"
          :icon="['fas', 'eye']"
          title="View resource"/>

        <font-awesome-icon
          class='fa-icon action-icon'
          v-if="deleteEvent && isDeletable(row.item)"
          @click.stop="deleteResource(row.item.identifier)"
          :icon="['fas', deleteIcon]"
          style="color: indianred;"
          title="Delete resource"/>

        <slot name="customActions" :row="row"/>
      </template>

      <template v-slot:row-details="row" ref="details">
        <div class="text-center text-primary"
             v-if="!loads[row.item.identifier]">
          <b-spinner class="align-middle"></b-spinner>
          <strong style="margin-left:10px;">Loading...</strong>
        </div>
        <component
          v-else
          :is="subComponent"
          :identifier="row.item.identifier"
          :parameters="subComponentParameter">
        </component>
      </template>

      <template v-slot:cell(name)="row">
        <div v-if="resource === 'bundles'" class="bundle-name">
          <input v-if="resource === 'bundles' && checkbox" type="checkbox" class="select-bundle" :value="row.item" @click="selectBundle($event, row.item)"/>
          <div class="row-name-bundle"  @click.stop="$emit('viewBundles', row.item.identifier)">
            <Icon v-if="showIcons" :icon="row.item.icon"
                  style="width:1.8em !important; height:1.8em !important; float:left; margin-right:10px;"/>
            <p>
              {{ row.item.name }}
              <span></span>
            </p>
          </div>
        </div>
        <div v-else style="vertical-align:middle;">
          <Icon v-if="showIcons" :icon="row.item.icon"
                style="width:1.8em !important; height:1.8em !important; float:left; margin-right:10px;"/>
          {{ row.item.name }}
        </div>
      </template>

      <template v-slot:cell(hostname)="row">
        <div style="vertical-align:middle;">
          <Icon v-if="showIcons" square :icon="row.item.icon"
                style="width:1.8em !important; height:1.8em !important; float:left; margin-right:10px;"/>
          {{ row.item.hostname }}
        </div>
      </template>

      <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
        <slot :name="slot" v-bind="scope"/>
      </template>

    </b-table>

    <hr/>
    <div class="text-center"
         style="padding-top:10px; padding-bottom:10px;"
         v-if="rList === null || rList === undefined">
      <b-spinner class="align-middle"></b-spinner>
      <strong style="margin-left:10px;">Loading...</strong>
    </div>

  </div>
</template>

<script>

import TopologyListForm from './topologies/TopologyListForm'
import ActionListForm from './actions/ActionListForm'
import ScenarioListForm from './scenarios/ScenarioListForm'
import FileListForm from './files/FileListForm'
import CategoryListForm from './categories/CategoryListForm'
import BundleListForm from './bundles/BundleListForm'
import ConfigSectionForm from './configuration/ConfigSectionForm.vue'
import TopologySpecsForm from './topologies/TopologySpecsForm'
import HostSpecsForm from './topologies/HostSpecsForm'
import NetworkSpecsForm from './topologies/NetworkSpecsForm'
import HostSpecsListForm from './topologies/HostSpecsListForm'
import RoleListForm from './groups/RoleListForm'
import RuleList from './rules/RuleList'
import Icon from './form/Icon'
import Workflow from './workflows/Workflow'
import RuleListForm from './rules/RuleListForm'

export default {
  name: "ResourceList",
  mounted() {
    this.loadResources().then(() => {
      if (this.resources === undefined) {
        if (this.getter === undefined) {
          this.rList = this.$store.getters[this.resource + '/getAll']({filters: this.searchFilters})
        } else {
          this.rList = this.$store.getters[this.getter.event](this.getter.parameters)
          }
      } else
        this.rList = this.resources
    })
  },
  props: {
    fixed: {type: Boolean, default: false},
    loadOnToggle: {type: Boolean, default: false},
    resource: String,
    resourceListForm: String,
    resourceListFormParameters: Object,
    dispatchActions: {
      type: Array[Object], default: function () {
        return []
      }
    },
    getter: Object,
    addIcon: String,
    viewEvent: String,
    addEvent: String,
    addEventParameter: [String, Object],
    editEvent: String,
    editEventParameter: Object,
    deleteEvent: String,
    deleteEventParameter: Object,
    deleteIcon: {type: String, default: "trash"},
    exportEvent: String,
    downloadEvent: String,
    subComponent: String,
    subComponentParameter: Object,
    hideSearch: Boolean,
    hideRefresh: Boolean,
    autoLoad: Boolean,
    fields: Array,
    filters: Object,
    stickyHeader: Boolean,
    sortBy: String,
    reverseSort: Boolean,
    sortCompare: Function,
    busy: Boolean,
    resources: Array[Object],
    showIcons: {type: Boolean, default: true},
    showSortOptions: {type: Boolean, default: true},
    showLimitOptions: {type: Boolean, default: true},
    searchFilters: {
      type: Object, default: () => {
      }
    },
    paginate: {type: Boolean, default: false},
    checkbox: Boolean,
  },
  data() {
    return {
      search: null,
      isBusy: true,
      loads: {},
      rList: null,
      pagination: {
        total: 0,
      },
      page: 1,
      limit: 25,
      limitOptions: [
        {value: 10, text: "10 elements per page"},
        {value: 15, text: "15 elements per page"},
        {value: 25, text: "25 elements per page"},
        {value: 50, text: "50 elements per page"}
      ],
      orderBy: 'created_date',
      desc: false
    }
  },
  computed: {
    rFields: function () {
      if (this.fields === undefined)
        return this.$store.getters[this.resource + '/getFields']()
      return this.fields
    },
    rFilters: function () {
      if (this.filters === undefined)
        return this.$store.getters[this.resource + '/getFilters']()
      return this.filters
    }
  },
  components: {
    TopologyListForm,
    ActionListForm,
    CategoryListForm,
    ScenarioListForm,
    TopologySpecsForm,
    BundleListForm,
    ConfigSectionForm,
    FileListForm,
    NetworkSpecsForm,
    RoleListForm,
    HostSpecsForm,
    Workflow,
    HostSpecsListForm,
    Icon,
    RuleList,
    RuleListForm
  },
  methods: {
    selectBundle(event, bundle) {
      if(event.srcElement.checked){
        this.$emit('selectBundle', bundle);
      }else{
        this.$emit('unselectBundle', bundle);
      }
    },
    canAddResource(){
      let resource = ""
      switch (this.resource) {
        case "topologies":
          resource = "TOPOLOGY"
          break
        default:
          resource = this.resource.slice(0, -1).toUpperCase()
      }
      return this.$store.getters["token/hasPermission"](resource, "CREATE")
    },
    isEditable(entry) {
      if (entry.authz && (entry.authz.permission & 0x04) === 0) {
        return false;
      }
      return this.rFilters.isEditable(entry)
    },

    isDeletable(entry) {
      if (entry.authz && (entry.authz.permission & 0x08) === 0) {
        return false;
      }
      return this.rFilters.isDeletable(entry)
    },

    isExportable(entry) {
      if (entry.authz && (entry.authz.permission & 0x20) === 0) {
        return false;
      }
      return this.rFilters.isExportable(entry)
    },

    isDownloadable(entry) {
      if (entry.authz && (entry.authz.permission & 0x20) === 0) {
        return false;
      }
      return this.rFilters.isDownloadable(entry)
    },

    addResource() {
      this.$eventBus.$emit(this.addEvent, this.addEventParameter)
    },

    loadResources() {
      this.isBusy = true
      var promises = []
      if (this.autoLoad) {
        promises.push(this.$store.dispatch(this.resource + '/loadAll', {
          filters: Object.assign({}, this.searchFilters),
          limit: this.limit,
          page: this.page,
          load: "meta",
          search: this.search
        }))
      }

      for (let action of this.dispatchActions)
        promises.push(this.$store.dispatch(action.event, action.parameters))

      return Promise.all(promises).then(() => {
        if (this.paginate) {
          this.pagination = this.$store.getters[this.resource + '/getPagination']()
        }
        this.isBusy = false
      })
    },
    refreshResource() {
      this.search = null
      return this.loadResources()
    },
    viewResource(identifier) {
      this.$eventBus.$emit(this.viewEvent, identifier)
    },
    editResource(identifier) {
      if (this.editEventParameter)
        this.$eventBus.$emit(this.editEvent, {...{identifier: identifier}, ...this.editEventParameter})
      else
        this.$eventBus.$emit(this.editEvent, identifier)
    },
    exportResource(identifier) {
      this.$eventBus.$emit(this.exportEvent, identifier)
    },
    downloadResource(identifier) {
      this.$eventBus.$emit(this.downloadEvent, identifier)
    },
    deleteResource(identifier) {
      console.log(this.deleteEvent)
      if (this.deleteEventParameter)
        this.$eventBus.$emit(this.deleteEvent, {...{identifier: identifier}, ...this.deleteEventParameter})
      else
        this.$eventBus.$emit(this.deleteEvent, identifier)
    },
    toggle(item) {
      if (this.subComponent !== undefined) {
        this.loads[item.identifier] = false
        this.$store.dispatch(this.resource + "/toggle", item).then(() => {
          if (item._showDetails) {
            if (!this.loadOnToggle) {
              this.$store.dispatch(this.resource + "/load", item.identifier).then(() => {
                this.loads[item.identifier] = true
                this.$forceUpdate();
              }).catch((response) => {
                this.$store.dispatch(this.resource + "/toggle", item)
                this.loads[item.identifier] = false
                this.$forceUpdate();
                this.$eventBus.$emit('http-error', response)
              })
            } else {
              this.loads[item.identifier] = true
              this.$forceUpdate();
            }
          }
        }).catch((response) => {
          this.$eventBus.$emit('http-error', response)
        })
      }
    },
    searchResources() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.loadResources()
      }, 500)
    }
  }
}
</script>

<style>
th {
  vertical-align: middle !important;
}

.action-icon {
  cursor: pointer;
  margin-left: 0.8em !important;
}

.fa-icon {
  margin-left: 4px;
  cursor: pointer;
}

.resource-list {
  padding-top: 20px;
  padding-bottom: 20px;
}

.b-table-sticky-header {
  max-height: unset !important;
}

.table-active {
  background-color: var(--lade-color-black) !important;
}

.selectable .b-table tbody tr:hover {
  background-color: var(--lade-color-white);
  cursor: pointer;
}

.b-table-has-details,
.b-table-details {
  background-color: var(--lade-color-white);
  border-left: solid var(--lade-navy) 4px;
}


.b-table tr {
  border-top: none !important;
  border-bottom: none !important;
  border-right: none !important;
  outline: none !important;
}


.bundle-name {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
}

.row-name-bundle{
  cursor: pointer;
  margin-left: 10px;
}

.row-name-bundle p{
  margin: 0px 0px;
  display: inline-block;
}

.row-name-bundle span{
  display: block;
  width: 0%;
  height: 1px;
  background-color: var(--lade-color-black);
  transition: 0.3s;
}

.row-name-bundle:hover span{
  width: 100%;
}
</style>
