<template>
  <img v-if="icon && icon.url" :src="icon.url" :class="{avatar: true, roundicon: square}" alt=""/>
</template>

<script>

export default {
  name: "Icon",
  props: {
    icon: Object,
    square: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style>
.avatar {
  vertical-align: middle;
  width: 64px;
  height: 64px;
  object-fit: scale-down;
}

.roundicon {
  border-radius: 50%;

}
</style>
