<template>
  <li>
    <div v-if="category.root !== true"
      class="category-name"
      @click="toggle"
      @dblclick="makeFolder">
      <font-awesome-icon :icon="['fas', 'folder-open']" size="lg" style="cursor: pointer; margin-left: 10px;"/>
      {{ category.name }}
    </div>
    <ul v-show="isOpen">
      <category-tree-item
        v-for="cat in category.children"
        :key="cat.identifier"
        :category="cat"
        @make-folder="$emit('make-folder', $event)"
        @add-item="$emit('add-item', $event)"
      ></category-tree-item>
<!--      <li class="add" @click="$emit('add-item', category)">+</li>-->
      <li class="resource" v-if="isBusy"><b-spinner class="align-middle"></b-spinner>
        <strong style="margin-left:10px;">Loading...</strong></li>
      <div v-if="!isBusy">
        <li class="resource" v-for="action in actions" :key="action.identifier">
          <b-link class="link"
                  :to="{name: 'actionPage', params: {
                  identifier: action.identifier, bundleIdentifier: action.bundle_identifier}}">
            <font-awesome-icon :icon="['fa', 'cog']" size="lg" style="cursor: pointer; margin-left: 10px;"/>
            {{ action.tag }}
          </b-link>
        </li>
        <li class="resource" v-for="file in files" :key="file.identifier">
          <b-link class="link"
                  :to="{name: 'filePage', params: {
                  identifier: file.identifier, bundleIdentifier: file.bundle_identifier}}">
            <font-awesome-icon :icon="['fa', 'file']" size="lg" style="cursor: pointer; margin-left: 10px;"/>
            {{ file.tag }}
          </b-link>
        </li>
        <li class="resource" v-for="scenario in scenarios" :key="scenario.identifier">
          <b-link class="link"
                  :to="{name: 'scenarioPage', params: {
                  identifier: scenario.identifier, bundleIdentifier: scenario.bundle_identifier}}">
            <font-awesome-icon :icon="['fa', 'cogs']" size="lg" style="cursor: pointer; margin-left: 10px;"/>
            {{ scenario.tag }}
          </b-link>
        </li>
        <li class="resource" v-for="topology in topologies" :key="topology.identifier">
          <b-link class="link"
                  :to="{name: 'topologyPage', params: {
                  identifier: topology.identifier, bundleIdentifier: topology.bundle_identifier}}">
            <font-awesome-icon :icon="['fa', 'sitemap']" size="lg" style="cursor: pointer; margin-left: 10px;"/>
            {{ topology.tag }}
          </b-link>
        </li>
      </div>
    </ul>
  </li>
</template>


<script>

export default {
  name: 'CategoryTreeItem',
  props: {
    category: Object,
  },
  data: function() {
    return {
      isOpen: false,
      topologies: null,
      scenarios: null,
      actions: null,
      files: null,
      isBusy: true,
    };
  },
  mounted() {
    if (this.category.root) {
      this.isOpen = true
      this.loadResources()
    }
  },
  computed: {
    hasChildren: function() {
      return this.category.children && this.category.children.length;
    },
    isEmpty: function () {
      return !(this.hasChildren) && (this.topologies === null || this.topologies.length === 0)
        && (this.scenarios === null || this.scenarios.length === 0) &&
        (this.files === null || this.files.length === 0) &&
        (this.actions === null || this.actions.length === 0)
    },
    getFontAwesomeIcon: function() {
      if (this.category.attributes.icon != null && this.category.attributes.icon.length > 0) {
        return this.category.attributes.icon
      }
      else {
        return ['fas', 'folder-open']
      }
    },
  },
  methods: {
    toggle: function() {
      this.isOpen = !this.isOpen;
      if (this.isOpen) {
        this.loadResources()
      }
    },
    makeFolder: function() {
      if (!this.hasChildren) {
        this.$emit("make-folder", this.category);
        this.isOpen = true;
      }
    },
    loadResources: function () {
      this.isBusy = true
      if (this.category.root) {
        this.isBusy = false
        return
      }
      let promises = []
      promises.push(this.loadTopologies())
      promises.push(this.loadActions())
      promises.push(this.loadScenarios())
      promises.push(this.loadFiles())
      Promise.all(promises).then(() => {
        this.isBusy = false
      })
    },
    loadTopologies: function () {
      return this.$store.dispatch('topologies/loadAll', {
        filters: {bundleIdentifier: this.bundleIdentifier},
        limit: -1,
        load: "meta",
        category: this.category.identifier
      }).then( () => {
        this.topologies = [...this.$store.getters['topologies/getAll']()]
      })
    },
    loadActions: function () {
      return this.$store.dispatch('actions/loadAll', {
        filters: {bundleIdentifier: this.bundleIdentifier},
        limit: -1,
        load: "meta",
        category: this.category.identifier
      }).then( () => {
        this.actions = [...this.$store.getters['actions/getAll']()]
      })
    },
    loadScenarios: function () {
      return this.$store.dispatch('scenarios/loadAll', {
        filters: {bundleIdentifier: this.bundleIdentifier},
        limit: -1,
        load: "meta",
        category: this.category.identifier
      }).then( () => {
        this.scenarios = [...this.$store.getters['scenarios/getAll']()]
      })
    },
    loadFiles: function () {
      return this.$store.dispatch('files/loadAll', {
        filters: {bundleIdentifier: this.bundleIdentifier},
        limit: -1,
        load: "meta",
        category: this.category.identifier
      }).then( () => {
        this.files = [...this.$store.getters['files/getAll']()]
      })
    }
  }
}
</script>


<style scoped>

.category-name {
  font-weight: 700;
}

.category-name:hover {
  background-color: var(--lade-color-white);
  cursor: pointer;
}

.add {
  margin-left: 10px;
  font-weight: 700;
}

ul {
  list-style-type: none;
  padding-left: 25px;
  border-radius: 10px 10px;
}


.link {
  text-decoration: none;
  color: var(--lade-color-dark);
}

</style>
