<template>
  <div>
    <InputText v-if="identifier != null && identifier.length > 0" :prefix="prefix" name="identifier"
               v-model="identifier" disabled></InputText>
    <InputText v-if="value.nic_type !== null" :prefix="prefix" name="Network name" placeholder="(ex: net-01)" v-model="value.network_name"
               :required="value.enabled"></InputText>

    <div v-if="value.nic_type !== null" class="form-group row">
      <label class="col-sm-3 col-form-label">Type </label>
      <div class="col-sm-9">
        <b-form-select v-model="value.nic_type" :options="NicTypes" default="bios"></b-form-select>
      </div>
    </div>

    <InputText v-if="value.nic_type !== 'other' && value.nic_type !== null" :prefix="prefix" name="IP Address" placeholder="(ex: 192.168.1.10)" v-model="value.ip"
               pattern="[0-9\.]{7,15}" :required="value.nic_type === 'static'"></InputText>
    <InputText v-if="value.nic_type !== 'other' && value.nic_type !== null" :prefix="prefix" name="Mask" placeholder="(ex: 255.255.255.0)" v-model="value.mask"
               pattern="[0-9\.]{7,15}" :required="value.nic_type === 'static'"></InputText>
    <InputText :prefix="prefix" name="MAC Address" help="MAC Address will be autogenerated if not set."
               placeholder="(ex: 00:56:50:e8:1d:b1)" v-model="value.mac"
               pattern="([0-9A-Fa-f]{2}[:-]){5}([0-9A-Fa-f]{2})" @input="updateMac()"/>
    <TextAreaForm :prefix="prefix" name="backing" ref="backing" :rows="3" v-model="value.backing" :json="true"/>
    <InputCheckbox :prefix="prefix" name="Enabled" v-model="value.enabled" @input="value.enabled = $event"/>
  </div>
</template>

<script>
import InputText from '../form/InputText'
import TextAreaForm from '../form/TextAreaForm'
import InputCheckbox from '../form/InputCheckbox'

export default {
  name: "NicSpecForm",
  props: {
    value: Object,
    prefix: String
  },
  computed: {
    identifier() {
      return this.value.identifier
    },
    computedValue() {
      return this.value
    }
  },
  data() {
    return {
      NicTypes: [
        {value: "static", text: "Static IPv4 address"},
        {value: "DHCP", text: 'DHCP'},
        {value: "other", text: "Manual Configuration"}
      ]
    }
  },
  methods: {
    getValue() {
      return this.value
    },
    updateMac() {
      if (this.value.mac === '') {
        this.value.mac = null
      }
    }
  },
  components: {
    InputText, TextAreaForm, InputCheckbox
  }
}
</script>
