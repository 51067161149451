<template>
  <div class="container-group-bundle-grid">
    <div v-if="group" class=" bg-white group" :class="{'extended': viewmoreClicked}">
      <div class="icon-description">
        <img  v-if="group.icon && group.icon.url" :src="group.icon.url" :alt="group.name" class="group-icon img-fluid"/>
        <div
          v-else
          class="generated-icon"
          :style="{'background-color': generateIconColor(group)}">
          <div class="text-icon">
              {{ group.name[0].toUpperCase() }}
          </div>
        </div>
        <div class="title-description">
          <h2>{{ group.name }}</h2>
          <p class="createSince">Active since {{ group.created_at | print_date }}</p>
          <span></span>
          <div ref="htmlDescription">
            <vue-markdown :source="group.description"></vue-markdown>
          </div>
        </div>
        <b-link
            v-if="isEditable"
            :to=" {
              name: 'editGroup',
              params: {
                identifier: this.group.identifier,
                previousPage: this.currentPage
              }
            }">
          <button class="btn-edit-group">
            <font-awesome-icon :icon="['fas', 'pen']"/>
          </button>
        </b-link>
      </div>
      <div v-if="viewMore" ref="rowViewMore" class="row-view-more">
        <div class="btn-view-more" @click.stop="viewmoreClicked = !viewmoreClicked">
          <p>View
            <span v-if="!viewmoreClicked">More</span>
            <span v-else>Less</span></p>
          <img src="@/assets/dropdown.png" class="arrow-dropdown" alt="image of dropdown button"/>
        </div>
      </div>
    </div>
    <BundleGrid
      v-if="this.group !== undefined"
      :group-identifier="this.group.identifier"
    />
  </div>
</template>

<script>
import { print_human_readable_size } from "@/filters";
import BundleGrid from "@/components/hub/BundleGrid.vue";
import Icon from "@/components/form/Icon";
import {generateIconColor} from "@/common";

export default {
  name: "GroupBundleGrid",
  components: {
    BundleGrid,
    Icon,
  },
  props: {
    identifier: String,
    previousPage: null,
    reloadBundles: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isEditable() {
      return !(this.group.authz && (this.group.authz.permission & 0x04) === 0);
    },
    currentPage: function () {
      if ( this.group.identifier !== undefined ) {
        let group = this.$store.getters["groups/get"](this.identifier);
        return { text: group.name, to: { name: 'groupBundleGrid', params: { "identifier": group.identifier }}};
      } else {
        return { text: 'Explore', to: { name: 'bundleGrid' } };
      }
    }
  },
  data: function () {
    return {
      viewmoreClicked: false,
      group: undefined,
      viewMore: false,
      chart_options: {
        legend: {
          display: false,
        },
        tooltips: {
          callbacks: {
            title: function (tooltipItem, data) {
              return data["labels"][tooltipItem[0]["index"]];
            },
            label: function (tooltipItem, data) {
              return print_human_readable_size(
                data["datasets"][0]["data"][tooltipItem["index"]]
              );
            },
          },
          backgroundColor: "#FFF",
          titleFontSize: 16,
          titleFontColor: "black",
          bodyFontColor: "black",
          bodyFontSize: 14,
          displayColors: false,
        },
      },
    };
  },
  updated() {
      this.viewMore = this.$refs.htmlDescription.offsetHeight > 85
  },
  mounted: function () {
    console.log(this.previousPage)
    let breadcumbList = []
    if (this.previousPage === undefined) {
      breadcumbList.push({text: 'Explore', to: {name: 'bundleGrid'}})
    } else if (this.previousPage.text === 'Explore' && this.previousPage.to.params.objectSearched === "groups") {
      this.previousPage.to.params.reloadGroups = false
      this.previousPage.to.params.reloadBundles = true
      breadcumbList.push(this.previousPage)
    } else {
      breadcumbList.push(this.previousPage)
    }
    breadcumbList.push({
      name: 'groupBundleGrid',
      params: {
        identifier: this.identifier,
      }
    })
    this.$emit('breadcrumbChange', breadcumbList)


    this.$store.dispatch("groups/load", this.identifier).then(() => {
      this.group = this.$store.getters["groups/get"](this.identifier);
    });
  },
  methods: {
    generateIconColor(group) {
        return generateIconColor(group.identifier);
    },
    editGroup (){
      this.$router.push({
        name: 'editGroup',
        params: {
          identifier: this.group.identifier
        }
      });
    },
  }
};
</script>


<style scoped>
::-webkit-scrollbar {
  display: none;
}

::-webkit-scrollbar-thumb {
  background-color: var(--lade-color-grey);    /* color of the scroll thumb */
  border-radius: 20px;       /* roundness of the scroll thumb */
}


.group{
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: content-box;
  max-height: 50vh;
  overflow-y: hidden;
  box-shadow: rgba(50, 50, 93, 0.25) 0 6px 12px -2px, rgba(0, 0, 0, 0.3) 0 3px 7px -3px;
  position: relative;
}


.icon-description {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 10px 5% 10px 2%;
}

.group-icon {
  max-height: 140px;
  object-fit: scale-down;
  margin: 0 15px 0 0;
}

.generated-icon {
  margin: 0 15px 0 0;
  height: 140px;
  width: 140px;
  padding-left: 0;
  transition: 0.5s ease-in;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center
}

.title-description{
  width: 100%;
  max-height: 140px;
  transition: .4s;
  overflow-y: auto;
}

.group.extended .title-description{
  width: 100%;
  max-height: 45vh;
}

.title-description h2{
  margin: 0 0;
}

.title-description .createSince{
  margin: 0 0 0;
}

.title-description span {
  display: block;
  width: 120px;
  height: 1px;
  background-color: var(--lade-color-dark);
  margin: 5px 5px;
}

.group .row-view-more {
  width: 100%;
  display: flex;
  align-items: center;
  background-color: var(--lade-color-white-hover);
  position: sticky;
  bottom: 0;
}

.btn-edit-group{
  text-decoration: none;
  border-radius: 50% 50%;
  background-color: var(--lade-color-white-hover);
  padding: 5px 5px 2px 5px;
  font-size: 1.1em;
  color: var(--lade-color-black);
  transition: 0.2s;
  border: solid 1px transparent;
}

.btn-edit-group:hover{
  transform: rotateZ(90deg);
  box-shadow: rgba(0, 0, 0, 0.15) 0 2px 8px;
}

.btn-view-more {
  margin: 0 auto;
  display: block;
  padding: 0 3px 0 3px;
  cursor: pointer;
  opacity: 1;
  pointer-events: all;
  height: 19px;
}

.btn-view-more p{
  border-bottom: solid 1px transparent;
  height: 17px;
  transition: 0.3s;
  margin: 0 0;
}

.btn-view-more:hover p{
  border-bottom: solid 1px var(--lade-color-black);
  height: 18px;
}

.btn-view-more img{
  width: 20px;
  position: relative;
  top: -17px;
  left: 47px;
  transition: 0.4s;
  opacity: 0;
}

.btn-view-more:hover img,
.group.extended .btn-view-more img{
  opacity: 1;
  left: 62px;
  top: -18px;
}

.group.extended .btn-view-more img{
  transform: rotateX(180deg);
}


@media screen and (max-width: 478px) {
  .group-icon {
    display: none;
  }
}

</style>
