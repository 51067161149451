<template>
  <div>
    <ResourceList
      resource="workflows"
      :resources="resources"
      ref="WorkflowList"
      :fields="fields"
      :searchFilters="searchFilters"
      hideSearch
      hideRefresh
      subComponent="Workflow"
      :busy="busy"
      paginate
      autoLoad
      :loadOnToggle="loadOnToggle">

      <template v-slot:cell(status)="row">
        <b-badge :variant="status_to_variant(row.item.status)" style="font-size:1em;">
          <b-spinner
            v-if="row.item.status==='running'"
            style="height:1em; width:1em; vertical-align:middle;"/>
          <font-awesome-icon :icon="['fa', 'calendar']"
                             v-else-if="row.item.status==='scheduled'"
                             :title="row.item.status"/>
          <font-awesome-icon :icon="['fa', 'check']"
                             v-else-if="row.item.status==='succeeded'"
                             :title="row.item.status"/>
          <font-awesome-icon :icon="['fa', 'exclamation-triangle']"
                             v-else-if="row.item.status==='failed'"
                             :title="row.item.status"/>
          <font-awesome-icon :icon="['fa', 'ban']"
                             v-else-if="row.item.status==='cancelled'"
                             :title="row.item.status"/>
          <span style="padding-left:3px;"> {{ row.item.status | capitalize }} </span>
        </b-badge>
      </template>

      <template v-for="(_, slot) of $scopedSlots" v-slot:[slot]="scope">
        <slot :name="slot" v-bind="scope"/>
      </template>

      <template v-slot:cell(actions)="row">
        <font-awesome-icon :icon="['fa', 'times']"
                           style="color:rgb(247, 64, 49)"
                           @click.stop="cancelWorkflow(row.item)"
                           v-if="row.item.status==='running' && !cancelIcons[row.item.identifier]"/>
        <font-awesome-icon icon="spinner" class="fa-spin"
                           v-if="row.item.status==='running' && cancelIcons[row.item.identifier]"/>
      </template>
    </ResourceList>

  </div>
</template>

<script>
import store from '../../store'
import {status_to_variant} from '@/common'

export default {
  name: "WorkflowList",
  props: {
    busy: Boolean,
    fields: Array,
    searchFilters: Object,
    loadOnToggle: {type: Boolean, default: true},
    resources: Array
  },
  data() {
    return {
      cancelIcons: {}
    }
  },
  methods: {
    cancelWorkflow(workflow) {
      this.cancelIcons[workflow.identifier] = true
      this.$forceUpdate()
      store.dispatch("workflows/cancel", workflow)
    },
    status_to_variant(value) {
      return status_to_variant(value)
    },
    refresh() {
      return this.$refs["WorkflowList"].loadResources()
    }
  }
}
</script>
