<template>
  <div>
    <b-modal ref="viewFormModal" id="viewFormModal" hide-footer v-bind:title="title">
      <table style="width: 100%;">
        <tr v-for="key in printableKeys" :key="key">
          <td><strong>{{ key | capitalize }} </strong></td>
          <td style="padding-left: .25em;">{{ bundle[key] }}</td>
        </tr>
        <tr>
          <td><strong>Content size</strong></td>
          <td style="padding-left: .25em;">
            {{ content_size_number | print_human_readable_size }}
            {{ content_size_unit | print_human_readable_size }}
          </td>
        </tr>
      </table>

    </b-modal>
    <b-modal size="lg" id="bundleAddFormModal" hide-footer v-bind:title="title">
      <template v-slot:modal-title>
				<Icon :icon="icon"/>
     			{{title}}
			</template>
      <form v-on:submit.prevent="onAddEdit">
        <div class="card">
          <div class="card-header">
            Attributes
          </div>
          <div class="card-body">
            <InputText v-if="edit" prefix="Bundle" name="identifier" v-model="identifier" disabled></InputText>

            <div class="form-group row">
              <label class="col-sm-3 col-form-label" for="Group">Group</label>
              <div class="col-sm-9">
                <TypeAhead prefix="Bundle" id="Group" name="group" resourceType="group" v-model="group" class="mb-2"
                           @hit="groupIdentifier=$event" :disabled="edit" required/>
              </div>
            </div>

            <InputText prefix="Bundle" name="alias" v-model="alias" required pattern="[a-zA-Z0-9\-_]{3,50}"></InputText>
            <InputText prefix="Bundle" name="version" v-model="qualifier" placeholder='version'></InputText>

            <InputText prefix="Bundle" name="name" v-model="name"></InputText>

            <InputImage prefix="Icon" name="icon" @input="(file) => {icon_updated = file}"/>

            <div class="form-group row">
              <label class="col-sm-3 col-form-label">Visibility</label>
              <div class="col-sm-9">
                <b-form-select v-model="visibility" value-field="id" text-field="name"
                               :options="visibilityOptions" class="mb-3" name="OS Type" prefix="HostSpec" required/>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-3 col-form-label">Exportability</label>
              <div class="col-sm-9">
                <b-form-select v-model="exportability" value-field="id" text-field="name"
                               :options="exportabilityOptions" class="mb-3" name="OS Type" prefix="HostSpec" required/>
              </div>
            </div>

            <TextAreaForm ref="description" prefix="Bundle" name="description" :rows="2"
                          v-model="description"></TextAreaForm>

            <TextAreaForm ref="license" prefix="Bundle" name="license" :rows="2" v-model="license"></TextAreaForm>

            <TextAreaForm ref="attributes" prefix="Bundle" name="attributes" :rows="5" v-model="attributes"
                          :json="true"></TextAreaForm>
            <button type="submit" class="btn btn-primary">Submit</button>
          </div>
        </div>
      </form>
    </b-modal>
    <BundleEdit></BundleEdit>
  </div>
</template>

<script>
import store from '../../store'
import InputText from '../form/InputText'
import TypeAhead from '../form/TypeAhead'
import TextAreaForm from '../form/TextAreaForm'
import InputImage from '../form/InputImage'
import Icon from '../form/Icon'
import BundleEdit from './BundleEdit'
import {Tag, units, refactor_number_with_unit} from '@/common'
import {print_tag} from '@/filters'

export default {
  name: 'BundleListForm',
  created() {

    this.$eventBus.$on("view-bundle-show", (identifier) => {
      this.reset("Bundle")
      let bundle = store.getters['bundles/get'](identifier)
      this.bundle = bundle
      this.content_size = bundle.content_size
      this.printableKeys = ["tag", "identifier", "group", "name", "description", "attributes"]
      this.$refs.viewFormModal.show()
    })

    this.$eventBus.$on("add-bundle-show", () => {
      this.reset("Add bundle")
      if (this.$refs.attributes) this.$refs.attributes.$emit('reset')
      if (this.$refs.description) this.$refs.description.$emit('reset')
      this.$bvModal.show("bundleAddFormModal")
    })

    this.$eventBus.$on("edit-bundle-show", (identifier) => {
      this.reset("Edit bundle")
      let bundle = store.getters['bundles/get'](identifier)
      this.identifier = bundle.identifier
      this.alias = bundle.alias
      this.qualifier = bundle.qualifier
      this.tag = bundle.tag
      this.topologies = bundle.ladeTopologies
      this.actions = bundle.ladeActions
      this.scenarios = bundle.ladeScenarios
      this.files = bundle.ladeFiles
      this.name = bundle.name
      this.group = bundle.group
      this.groupIdentifier = bundle.group_identifier
      this.description = bundle.description
      this.attributes = bundle.attributes
      this.edit = true
      this.license = bundle.license
      this.icon = bundle.icon
      this.visibility = bundle.visibility
      this.exportability = bundle.exportability
      this.content_size = bundle.content_size
      let resp = refactor_number_with_unit(this.content_size)
      this.content_size_number = resp.number.toFixed(2)
      this.content_size_unit = resp.unit

      if (this.$refs.attributes) this.$refs.attributes.$emit('reset', this.attributes)
      if (this.$refs.description) this.$refs.description.$emit('reset', this.description)
      this.$bvModal.show("bundleAddFormModal")
    })

    this.$eventBus.$off("delete-bundle-show")
    this.$eventBus.$on("delete-bundle-show", (identifier) => {
      let bundle = store.getters['bundles/get'](identifier)
      this.$deleteModal({
        title: "Delete bundle",
        text: "Delete bundle " + print_tag(false, false, bundle) + "? You will lose all associated data."
      }).then((result) => {
        if (result.value) {
          store.dispatch('bundles/delete', identifier).then(() => {
            this.$eventBus.$emit('success', 'The bundle will be deleted')
          }).catch((response) => {
            this.$eventBus.$emit('http-error', response)
          })
        }
      });
    })

    this.$eventBus.$off('export-bundle')
    this.$eventBus.$on('export-bundle', (identifier) => {
      let bundle = store.getters['bundles/get'](identifier)
      store.dispatch('bundles/export', {bundle_identifier: bundle.identifier, resources: null}).then(() => {
        this.$eventBus.$emit('success', 'The bundle has been exported')
      }).catch((response) => {
        this.$eventBus.$emit('http-error', response)
      })
    })
  },
  data() {
    return {
      identifier: null,
      bundle: {},
      printableKeys: [],
      alias: null,
      qualifier: null,
      group: null,
      tag: null,
      name: null,
      topologies: [],
      actions: [],
      scenarios: [],
      files: [],
      description: null,
      attributes: {},
      title: "",
      edit: false,
      keep_content: false,
      groupIdentifier: null,
      content_size: 0,
      content_size_number: 0,
      content_size_unit: units.Bytes,
      license: null,
      icon_updated: undefined,
      icon: {},
      visibility: "",
      visibilityOptions: [
        {id: "private", name: 'Private: Restricted'},
        {id: "internal", name: 'Internal: Visible to all connected users'},
        {id: "public", name: 'Public: Visible to all users, even disconnected'},
      ],
      exportability: "",
      exportabilityOptions: [
        {id: "private", name: 'Private: Restricted'},
        {id: "internal", name: 'Internal: All connected users can export this bundle'},
      ],
    }
  },
  methods: {
    reset(title) {
      this.title = title
      this.edit = false
      this.identifier = null
      this.alias = null
      this.qualifier = null
      this.group = null
      this.tag = null
      this.name = null
      this.description = null
      this.attributes = {}
      this.content_size = 0
      this.content_size_number = 0
      this.license = null
      this.icon = {}
      this.icon_updated = undefined
    },
    onAddEdit() {

      let data = {
        "tag": new Tag(this.alias, this.qualifier).print(),
        "name": this.name,
        "description": this.description,
        "attributes": this.attributes,
        "icon": this.icon_updated,
        "visibility": this.visibility,
        "exportability": this.exportability
      }

      if (this.license !== "" && this.license !== null)
         data.license= this.license

      if (this.edit) {
        store.dispatch('bundles/update', {
          identifier: this.identifier,
          group_identifier: this.groupIdentifier,
          data: data
        }).then(() => {
          this.$bvModal.hide("bundleAddFormModal")
          this.$eventBus.$emit('success', 'The bundle has been updated')
        }).catch((response) => {
          this.$eventBus.$emit('http-error', response)
        })
      } else {
        store.dispatch('bundles/add', {group_identifier: this.groupIdentifier, data: data}).then(() => {
          this.$bvModal.hide("bundleAddFormModal")
          store.dispatch('bundles/loadAll', {order_by: null, labels: []})
          this.$eventBus.$emit('success', 'The bundle has been created')
        }).catch((response) => {
          this.$eventBus.$emit('http-error', response)
        })
      }
    }
  },
  components: {
    InputText, TextAreaForm, BundleEdit, TypeAhead, InputImage, Icon
  }
}

</script>
