import Vue from 'vue'
import Config from '@/Config'
import {setDictValue, build_resource_list_params} from '@/common'

const state = {
  // one list for notification page and one for popup (this list can be used with different parameters)
  list: [],
  popupList: [],
  limit: 15,
  skip: 0,
  not_acknowledged: 0,
  pagination: {
    page: 1,
    total_pages: 0,
    total: 0,
  },
  _lookup: {},
  filters: {
  },
  exclude_acknowledged: false,
  level: null,
}


const getters = {
  get: (state) => (identifier) =>
    state._lookup[identifier],
  getAll: (state) => () =>
    state.list,
  getPopupList: (state) => () =>
    state.popupList,
  getPage: (state) => () =>
    state.pagination.page,
  getTotalPages: (state) => () =>
    state.pagination.total_pages,
  getTotal: (state) => () =>
    state.pagination.total,
  getSkip: (state) => () =>
    state.skip,
  getLimit: (state) => () =>
    state.limit,
  setLimit: (state) => (limit) =>
    state.limit = limit,
  getPagination: (state) => () =>
    state.pagination,
  getNumberNotAcknowledged: (state) => () =>
    state.not_acknowledged,
}


const actions = {
  acknowledge({dispatch, commit}, identifier) {
    return Vue.http.post(Config.API_NOTIFICATIONS_URL + '/' + identifier + '/acknowledge').then(() => {
      commit('setAcknowledged', identifier)
    })
  },
  acknowledgeAll({dispatch, commit}) {
    return Vue.http.post(Config.API_NOTIFICATIONS_URL + '/acknowledge')
  },
  loadPopup({dispatch, commit}, { exclude_acknowledged=true, ...resource_list_params }) {
    if (resource_list_params['desc'] === undefined) {
      resource_list_params['desc'] = true
    }

    let params = build_resource_list_params(resource_list_params)

    params['exclude_acknowledged'] = exclude_acknowledged

    return Vue.http.get(Config.API_NOTIFICATIONS_URL, {params}).then((response) => {
      let notifications = response.data
      commit('setPopupList', notifications)
    })
  },
  loadAll({dispatch, commit}, { ...resource_list_params }) {
    if (resource_list_params['desc'] === undefined) {
      //default behavior desc is true
      resource_list_params['desc'] = true
    }
    let params = build_resource_list_params(resource_list_params)

    params['exclude_acknowledged'] = state.exclude_acknowledged
    if (state.level !== null) {
      params['level'] = state.level
    }

    return Vue.http.get(Config.API_NOTIFICATIONS_URL, {params}).then((response) => {
      let notifications = response.data
      commit('resetList')
      commit('setAll', notifications)
    })
  },
  loadGroup({dispatch, commit}, { groupIdentifier, ...resource_list_params }) {
    let params = build_resource_list_params(resource_list_params)

    params['exclude_acknowledged'] = state.exclude_acknowledged

    return Vue.http.get(Config.API_GROUPS_URL + '/' + groupIdentifier + '/notifications', {params}).then((response) => {
      let notifications = response.data
      commit('resetList')
      commit('setAll', notifications)
    })
  },
  loadWorkzone({dispatch, commit}, { workzoneIdentifier, ...resource_list_params }) {
    let params = build_resource_list_params(resource_list_params)

    params['exclude_acknowledged'] = state.exclude_acknowledged

    return Vue.http.get(Config.API_WORKZONES_URL + '/' + workzoneIdentifier + '/notifications', {params}).then((response) => {
      let notifications = response.data
      commit('resetList')
      commit('setAll', notifications)
    })
  },
  loadBundle({dispatch, commit}, { bundleIdentifier, ...resource_list_params }) {
    let params = build_resource_list_params(resource_list_params)

    params['exclude_acknowledged'] = state.exclude_acknowledged

    return Vue.http.get(Config.API_BUNDLES_URL + '/' + bundleIdentifier + '/notifications', {params}).then((response) => {
      let notifications = response.data
      commit('resetList')
      commit('setAll', notifications)
    })
  },
  loadMore({dispatch, commit}) {
    let params = {
      exclude_acknowledged: state.exclude_acknowledged,
      limit: state.limit,
      skip: state.skip + state.limit
    }

    return Vue.http.get(Config.API_NOTIFICATIONS_URL, {params}).then((response) => {
      let notifications = response.data
      commit('append', notifications)
    })

  }
}

const mutations = {

  resetList(state) {
    state.list.splice(0, state.list.length)
    state._lookup = {}
  },
  setPopupList(state, notifications) {
    state.popupList.splice(0, state.popupList.length)
    for (let notification of notifications.data) {
      let lookup_notif = state._lookup[notification.identifier]
      if (lookup_notif !== undefined) {
        state.popupList.push(lookup_notif)
      }
      else {
        state.popupList.push(notification)
        setDictValue(state._lookup, notification.identifier, notification)
      }

    }
    state.not_acknowledged = notifications.not_acknowledged
  },
  setAll(state, notifications) {

    for (let notification of notifications.data) {
      let lookup_notif = state._lookup[notification.identifier]
      if (lookup_notif !== undefined) {
        state.list.push(lookup_notif)
      }
      else {
        state.list.push(notification)
        setDictValue(state._lookup, notification.identifier, notification)
      }
    }

    state.limit = notifications.limit
    state.pagination.page = notifications.page
    state.pagination.total_pages = notifications.total_pages
    state.pagination.total = notifications.total
    state.skip = notifications.skip
    if (state.skip > notifications.total) {
      state.skip = notifications.total - state.limit
    }
    state.not_acknowledged = notifications.not_acknowledged
  },
  excludeAcknowledged(state) {
    state.exclude_acknowledged = true
  },
  includeAcknowledged(state) {
    state.exclude_acknowledged = false
  },
  setLevel(state, value) {
    state.level = value
  },
  setAcknowledged(state, notification_identifier) {
    let notification = state._lookup[notification_identifier]
    if (notification === undefined) {
      return
    }
    notification.acknowledged = true
    state.not_acknowledged -= 1
  }
}


export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true
}
