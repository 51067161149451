<template>
  <div>
    <b-card
      header="Uploads">
      <b-card
        v-for="upload in uploads"
        :key="upload.identifier"
        bodyClass="row">

        <div class="col-2">
          <b-spinner
            variant="primary"
            v-if="upload.status==='running'"
            style="height:1em; width:1em;"
          />
          <font-awesome-icon :icon="['fa', 'check']"
                             style="color:rgb(118, 176, 73)"
                             v-if="upload.status==='succeeded'"
                             :title="upload.status"/>
          <font-awesome-icon :icon="['fa', 'exclamation-triangle']"
                             style="color:rgb(247, 64, 49)"
                             v-if="upload.status==='failed'"
                             :title="upload.status"/>
          <font-awesome-icon :icon="['fa', 'ban']"
                             style="color:rgb(247, 64, 49)"
                             v-if="upload.status==='cancelled'"
                             :title="upload.status"/>
          <span style="padding-left:6px;">
                        {{ upload.status | capitalize }}
                    </span>
        </div>
        <div class="col-5">
          {{ upload.description }}
        </div>
        <div class="col-5">
          <div v-if="!upload.message">
            {{ upload.uploaded | print_human_readable_size }} / {{ upload.total | print_human_readable_size }}
            <b-progress :value="upload.progress" max="100" show-progress animated
                        style="display:inline-block; width:90%"></b-progress>
            <font-awesome-icon :icon="['fa', 'times']"
                               style="color:rgb(247, 64, 49); float:right; display:inline-block"
                               @click.stop="cancel(upload.identifier)"
                               v-if="upload.status==='running' && !cancelIcons[upload.identifier]"/>
            <font-awesome-icon icon="spinner" class="fa-spin" style="float:right; display:inline-block"
                               v-if="upload.status==='running' && cancelIcons[upload.identifier]"/>
          </div>
          <div v-else>
            {{ upload.message }}
          </div>
        </div>
      </b-card>
    </b-card>
  </div>
</template>

<script>
import store from '../../store'

export default {
  name: "UploadList",
  data() {
    return {
      cancelIcons: {},
      uploads: store.getters["uploads/getAll"]()
    }
  },
  methods: {
    cancel(identifier) {
      this.cancelIcons[identifier] = true
      this.$forceUpdate()
      store.dispatch("uploads/cancel", identifier)
    }
  }
}
</script>
