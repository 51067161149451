<template>
  <div>
    <b-modal size="lg" id="groupAddFormModal" hide-footer>
      <template v-slot:modal-title>
        <Icon :icon="icon"/>
        {{ title }}
      </template>


      <form v-on:submit.prevent="onAddEditGroup" class="group-form">

        <InputText prefix="Group" name="Identifier" v-model="identifier" v-if="edit" disabled required></InputText>
        <InputText prefix="Group" name="Name" v-model="name" :disabled="isNameDisabled()" required pattern="^[0-9a-zA-ZÀ-ÿ\-\._ ]+$"></InputText>

        <InputImage prefix="Category" name="Icon" @input="(file) => {icon_updated = file}"/>

        <InputText prefix="Group" name="Description" v-model="description" :disabled="origin==='ldap'"></InputText>

        <div class="form-group row">
          <label class="col-sm-3 col-form-label">Visibility</label>
          <div class="col-sm-9">
            <b-form-select v-model="visibility" value-field="id" text-field="name"
                           :options="visibilityOptions" class="mb-3" name="OS Type" prefix="HostSpec" required/>
          </div>
        </div>

        <div class="form-group row">
          <label for="GroupLimit" class="col-sm-3">Size Limit</label>
          <div class="input-group col-sm-9">
            <div class="input-group-prepend">
              <div class="input-group-text">
                <input type="checkbox"
                       :checked="limit_content_size_number>=0" @click.stop="toggleLimit()">
              </div>
            </div>

            <input type="number" min='-1' step="any" v-model="limit_content_size_number"
                   :disabled="limit_content_size_number===-1"
                   autocomplete="off" id="GroupLimit" aria-describedby="GroupLimitHelp" class="form-control">
            <b-form-select v-model="limit_content_size_unit_selected" :disabled="limit_content_size_number===-1"
                           :options="unit_options" class="form-control" id="GroupLimitUnit"/>

          </div>
          <small id="GroupLimitHelp" style="padding:20px;">Max size of content owned by the group</small>
        </div>

        <b-alert v-if="limit_content_size_number===0" show variant="warning">
          The users of this group will not be able to manage content.
        </b-alert>

        <hr/>
        <h5>Projects</h5>
        <ResourceList
          resource="projects"
          deleteEvent="remove-project-from-group-list"
          :resources="projects"
          hideSearch hideRefresh>

          <template v-slot:customActions="actions">
            <font-awesome-icon
              class='fa-icon action-icon'
              @click.stop="removeProject(actions.row.item)"
              :icon="['fas', 'trash']"
              style="color: indianred;"
              title="Delete resource"/>
          </template>

        </ResourceList>

        <TypeAhead resourceType="project"
                   v-model="project" class="mb-2"
                   ref="projectInput"
                   @hit="addProject($event)"/>

        <hr/>

        <h5>Project Pools</h5>
        <ResourceList
          resource="project_pools"
          deleteEvent="remove-project-pool-from-group-list"
          :resources="project_pools"
          hideSearch hideRefresh>

          <template v-slot:customActions="actions">
            <font-awesome-icon
              class='fa-icon action-icon'
              @click.stop="removeProjectPool(actions.row.item)"
              :icon="['fas', 'trash']"
              style="color: indianred;"
              title="Delete resource"/>
          </template>

        </ResourceList>

        <TypeAhead resourceType="project_pool"
                   v-model="projectPool" class="mb-2"
                   ref="projectPoolInput"
                   @hit="addProjectPool($event)"/>
        <button type="submit" class="btn btn-primary">Submit</button>
      </form>
    </b-modal>
  </div>
</template>

<script>
import store from '../../store'
import InputText from '../form/InputText'
import InputImage from '../form/InputImage'
import Icon from '../form/Icon'

import {units, refactor_number_with_unit, refactor_unit_to_number} from '@/common'
import TypeAhead from "@/components/form/TypeAhead";

export default {
  name: 'GroupListForm',
  components: {TypeAhead, InputText, InputImage, Icon},
  created() {
    this.$eventBus.$on("add-group-show", () => {
      this.reset("Add groups")
      this.edit = false
      this.$bvModal.show("groupAddFormModal")
    })

    this.$eventBus.$on("edit-group-show", (identifier) => {
      this.reset("Edit groups")
      let group = store.getters['groups/get'](identifier)
      this.identifier = identifier
      this.name = group.name
      this.description = group.description
      this.edit = true
      this.origin = group.origin
      this.icon = group.icon
      this.visibility = group.visibility
      this.projects = store.getters['projects/getAll']()
      this.project_pools = store.getters['project_pools/getAll']()

      this.content_size = group.content_size
      let resp = refactor_number_with_unit(this.content_size)
      this.content_size_number = resp.number.toFixed(2)
      this.content_size_unit = resp.unit

      this.limit_content_size = group.limit_content_size
      if (this.limit_content_size > 0) {
        this.percent = ((this.content_size / this.limit_content_size) * 100).toFixed(0)
        let resp = refactor_number_with_unit(this.limit_content_size)
        this.limit_content_size_number = resp.number
        this.limit_content_size_unit_selected = resp.unit
      } else {
        this.percent = 0
        this.limit_content_size_number = this.limit_content_size
        this.limit_content_size_unit_selected = units.Bytes
      }
      this.edit = true
      this.$bvModal.show("groupAddFormModal")
    })
  },
  data() {
    return {
      edit: false,
      project:null,
      projectPool: null,
      title: "",
      identifier: "",
      name: "",
      description: "",
      origin: "",
      content_size: 0,
      limit_content_size: 0,
      percent: 0,
      content_size_number: 0,
      content_size_unit: units.Bytes,
      limit_content_size_number: 0,
      limit_content_size_unit_selected: units.Bytes,
      icon_updated: undefined,
      icon: {},
      projects: [],
      project_pools: [],
      visibility: "",
      visibilityOptions: [
        {id: "private", name: 'Private: Restricted'},
        {id: "internal", name: 'Internal: Visible to all connected users'},
        {id: "public", name: 'Public: Visible to all users, even disconnected'},
      ]
    }
  },
  computed: {
    groups: () => store.getters['groups/getAll'](),
    unit_options: () => Object.values(units)
  },
  methods: {
    removeProject(project){
      this.$store.dispatch("projects/deleteFromState", project)
    },
    addProject(event){
      // Events from TypeAhead can be null
      if (event === null) {
        return
      }

      // Check already in list
      for (let project of this.projects){
        if (project.identifier === event) {
          this.$eventBus.$emit('info', 'Project already in list')
          this.$refs.projectInput.clear()
          return
        }
      }

      this.$store.dispatch("projects/load", event).then(() => {
        this.$refs.projectInput.clear()
      })
    },
    removeProjectPool(project){
      this.$store.dispatch("project_pools/deleteFromState", project)
    },
    addProjectPool(event){
      // Events from TypeAhead can be null
      if (event === null) {
        return
      }

      // Check already in list
      for (let project of this.project_pools){
        if (project.identifier === event) {
          this.$eventBus.$emit('info', 'Project already in list')
          this.$refs.projectPoolInput.clear()
          return
        }
      }

      this.$store.dispatch("project_pools/load", event).then(() => {
        this.$refs.projectPoolInput.clear()
      })
    },
    reset(title) {
      this.title = title
      this.edit = false
      this.identifier = ""
      this.name = ""
      this.description = ""
      this.icon = {}
      this.projects = []
      this.icon_updated = undefined
      this.visibility = ""
    },
    onAddEditGroup() {

      let projects = []
      let all_projects = [...this.projects, ...this.project_pools]
      all_projects.forEach(project => {
        console.log(project)
        projects.push({"identifier": project['identifier']})
      })

      let data = {
        "name": this.name,
        "description": this.description,
        "limit_content_size": refactor_unit_to_number(this.limit_content_size_number, this.limit_content_size_unit_selected),
        "icon": this.icon_updated,
        "projects": projects,
        "visibility": this.visibility
      }
      console.log(data)
      if (this.edit) {
        store.dispatch('groups/update', {identifier: this.identifier, data: data}).then(() => {
          this.$bvModal.hide("groupAddFormModal")
          this.$eventBus.$emit('success', 'The groups has been updated')
        }).catch((response) => {
          this.$eventBus.$emit('http-error', response)
        })
      } else {
        store.dispatch('groups/add', data).then(() => {
          this.$bvModal.hide("groupAddFormModal")
          this.$eventBus.$emit('success', 'The groups has been created')
        }).catch((response) => {
          this.$eventBus.$emit('http-error', response)
        })
      }
    },
    isNameDisabled() {
      if (!this.edit)
        return false
      if (this.origin === 'ldap')
        return true
      return store.getters['groups/get'](this.identifier).name === 'admin';

    },
    toggleLimit() {
      if (this.limit_content_size_number >= 0) {
        this.limit_content_size_number = -1
      } else {
        this.limit_content_size_number = 0
      }
    }
  }
}
</script>

<style>
.group-form {
  margin: 10px;
}
</style>
