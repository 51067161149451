import Vue from 'vue'
import Config from '../../Config'
import {build_resource_list_params} from '@/common'
import token from "@/store/modules/token";
import {field_actions, field_created_at} from "./fields";

const state = {
  list: [],
  filteredList: [],
  _lookup: {},
  pagination: {
    page: 1,
    total_pages: 0,
    total: 0,
  },
  fields: [
    {
      "name": "name",
      "key": "name",
      "sortable": true
    },
    {
      "key": "permission",
      "name": "permission",
      formatter: (value) => {
        let verbs = []
        if (token.state.specs.permissions.BUNDLE) {
          for (let permission of token.state.specs.permissions.BUNDLE) {
            if ((permission.value & value) === permission.value) {
              verbs.push(permission.name)
            }
          }
        }
        return verbs.join(', ')
      },
    },
    {
      "name": "usage",
      "key": "usage",
      formatter: (value = null, key = null, item = null) => {
        if (item.max_usage === -1) {
          item.max_usage = "*"
        }
        return item.usage_count + " / " + item.max_usage
      }
    },
    {
      "name": "expiration",
      "key": "expiration",
      "sortable": true
    },
    field_created_at,
    field_actions
  ],
  filters: {
    isEditable: (entry) => false,
    isExportable: (entry) => false,
    isDeletable: (entry) => true,
    isDownloadable: (entry) => false
  }
}

const getters = {
  getFilters: (state) => () =>
    state.filters,
  getAll: (state) => () =>
    state.list,
  get: (state) => (identifier) =>
    state._lookup[identifier],
  getFields: (state) => () =>
    state.fields,
  getPagination: (state) => () =>
    state.pagination,
  getPage: (state) => () =>
    state.pagination.page,
  getTotalPages: (state) => () =>
    state.pagination.total_pages,
  getTotal: (state) => () =>
    state.pagination.total,
  getSkip: (state) => () =>
    state.skip,
  getLimit: (state) => () =>
    state.limit,
}

const actions = {
  loadAll({dispatch, commit}, {filters = {}, ...resource_list_params}) {
    let params = build_resource_list_params(resource_list_params)
    let url = Config.API_BUNDLES_URL + "/" + filters.bundleIdentifier + "/share_tokens"
    return Vue.http.get(url, {params}).then((response) => {
      let shares = response.data
      commit('setAll', shares)
    })
  },
  add({dispatch, commit}, {bundleIdentifier, data}) {
    let url = Config.API_BUNDLES_URL + "/" + bundleIdentifier + "/share_tokens"
    return Vue.http.post(url, data)
  },
  useToken({dispatch, commit}, {token}) {
    return Vue.http.post( Config.SHARE_TOKENS_URL + "/apply", {"token":token})
  },
  delete({dispatch, commit}, {bundleIdentifier, identifier}) {
    let url = Config.API_BUNDLES_URL + "/" + bundleIdentifier + "/share_tokens/" + identifier
    return Vue.http.delete(url).then((response) => {
      commit('delete', identifier)
    })
  }
}

const mutations = {
  setAll(state, share_tokens) {
    state._lookup = {}
    state.pagination.page = share_tokens.page
    state.pagination.total_pages = share_tokens.total_pages
    state.pagination.total = share_tokens.total
    state.list.splice(0, state.list.length)

    for (let share_token of share_tokens.data)
      state.list.push(share_token)

    for (let share_token of share_tokens.data)
      state._lookup[share_token.identifier] = share_token
  },
  delete(state, identifier) {
    Vue.delete(state.list, state.list.indexOf(state.list.find(item => item.identifier === identifier)))
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true
}
