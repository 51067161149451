<template xmlns="http://www.w3.org/1999/html">
    <div v-if="bundle" class="bundle-container">
      <BundleListForm
        addEvent="add-bundle-show"
        editEvent="edit-bundle-show"
        deleteEvent="delete-bundle-show"/>

      <div class="top">
        <form  v-on:submit.prevent="submitEditAttributes" class="container bundle-specs py-5">
          <div class="icon my-auto">
            <div  class="edit-attr-icon" v-if="editAttributes">
                <b-form-file ref="el"  :accept="accept" type="file" :id="imageId"
                              v-model="imageUpdated" :required="false" :disabled="false"/>
            </div>
            <b-img v-if="bundle.icon && bundle.icon.url" v-model="image" class="bundle-icon"
                   fluid :src="bundle.icon.url" alt="Bundle Icon">
            </b-img>
            <b-img v-else-if="bundle.group_icon && bundle.group_icon.url" v-model="image" class="bundle-icon"
                   fluid :src="bundle.group_icon.url" alt="bundle.group">
            </b-img>
            <div v-else class="no-icon">
              <div class="generated-icon" :style="{'background-color': iconColor}"><div class="text-icon">{{ bundle.name[0].toUpperCase()  }}</div></div>
            </div>
          </div>

          <div class="bundle-attributes mx-5">
            <div class="bundle-title">
                <h2 v-if="!editAttributes">{{bundle.name}}</h2>
                <input v-else class="bundle-title" v-model="inputName" pattern="^[a-zA-Z0-9\-/_ ]{3,50}"/>
                <h6>
                  <b-badge v-if="!editAttributes" class="lade-label">{{bundle.qualifier}}</b-badge>
                  <b-badge v-else class="lade-label">{{inputQualifier}}</b-badge>
                </h6>
                <VisibilityBadge v-if="isTokenValid && !editAttributes" class="ml-2" :visibility="bundle.visibility"/>
                <font-awesome-icon v-if="isTokenValid" :icon="bundle.favorite?['fas', 'star']:['far', 'star']" @click.stop="toggleFavorite()" :color="bundle.favorite?'orange':'gray'" size="lg" style="cursor: pointer; margin-left: 10px;"/>
                <font-awesome-icon :icon="['fas', 'pencil-alt']"
                    @click.stop="clickEditAttribut"
                    style="margin-left:5px; cursor: pointer; height:20px;"
                    v-if="isEditable && !editAttributes"/>
                <font-awesome-icon :icon="['fas', 'trash']"
                    @click.stop="$eventBus.$emit('delete-bundle-show', bundle.identifier)"
                    v-if="isDeletable"
                    style="margin-left:10px; cursor: pointer; height:20px; color: indianred;"/>

                <font-awesome-icon :icon="['fas', 'share']"
                  @click.stop="$bvModal.show('modal-share')"
                  v-if="isEditable"
                  style="margin-left:10px; cursor: pointer; height:20px;"/>

                <b-modal id="modal-share" title="Share" size="lg" body-class="p-0">
                  <BundleShare :bundleIdentifier="identifier"/>
                  <template #modal-footer>
                    <b-button @click="$bvModal.hide('modal-share')">Close</b-button>
                  </template>
                </b-modal>
            </div>
            <div class="group-name mt-3">
              <b>By
                <b-link v-if="group" style="color: #000000" @click="viewGroup">
                  {{bundle.group}}
                </b-link>
                <span v-else style="color: #000000">{{ bundle.group }}</span></b> • Last updated {{ bundle.created_at | print_date }}
            </div>
            <div class="tag-row text-xl">
              <div>
                <B>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-tag-fill" viewBox="0 0 16 16">
                    <path d="M2 1a1 1 0 0 0-1 1v4.586a1 1 0 0 0 .293.707l7 7a1 1 0 0 0 1.414 0l4.586-4.586a1 1 0 0 0 0-1.414l-7-7A1 1 0 0 0 6.586 1H2zm4 3.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                  </svg>
                </B>
                <b-badge v-if="!editAttributes" variant="light" class="mx-2">
                  {{bundle.tag}}
                </b-badge>
                <CopyButton v-if="!editAttributes" :value="bundle.tag" />
                <b-badge v-else variant="light" class="mx-2 tag">
                  {{bundle.group}}/bundle/ <input  v-model="inputAlias"  pattern="[a-zA-Z0-9\-_]{3,50}"/>:
                  <input  v-model="inputQualifier"/>
                </b-badge>
              </div>
            </div>
            <div v-if="editAttributes" class="mt-3 dropdown-edit-attr visibility">
              <label>Visibility:</label>
              <b-form-select v-model="visibility" value-field="id" text-field="name"
                             :options="visibilityOptions" class="mb-3" name="OS Type" prefix="HostSpec"/>
            </div>
            <div v-if="editAttributes" class="mt-3 dropdown-edit-attr">
              <label>Exportability:</label>
              <b-form-select v-model="exportability" value-field="id" text-field="name"
                             :options="exportabilityOptions" class="mb-3" name="OS Type" prefix="HostSpec"/>
            </div>
            <div class="size-row">
              <div>
                <B>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-hdd-fill" viewBox="0 0 16 16">
                    <path d="M0 10a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-1zm2.5 1a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1zm2 0a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1zM.91 7.204A2.993 2.993 0 0 1 2 7h12c.384 0 .752.072 1.09.204l-1.867-3.422A1.5 1.5 0 0 0 11.906 3H4.094a1.5 1.5 0 0 0-1.317.782L.91 7.204z"/>
                  </svg>
                </B>
                <span class="mx-2">{{ printableBundleSize }}</span>
              </div>
            </div>

            <div class="rate-row" v-if="bundle.rate">
                <b-form-rating v-model="bundle.rate" class="rate"
                               readonly inline no-border variant="warning" size="sm"></b-form-rating>
            </div>
            <ContentLabels resource-type="bundles" :content="bundle" :is-editable="isEditable"></ContentLabels>
             <nav class="nav-edit-attributes" v-if="editAttributes">
               <button class="btn button btn-secondary"
               @click.stop="cancelEditAttribut">
                 Cancel
               </button>
               <button type="submit" class="btn btn-primary">
                 Submit
               </button>
             </nav>
          </div>

          <div v-if="isExportable">
            <!-- <b-button variant="success" @click="exportBundle()">Download ({{bundle.content_size | print_human_readable_size}}) <i>(Experimental)</i></b-button> -->
            <bundle-download-modal v-if="bundle" class="download-button" ref="downloadModal" :bundle-tag="bundle.tag"></bundle-download-modal>
          </div>

        </form>
        <div class="tabs-bar">
          <b-tabs class="bundle-tabs" content-class="mt-3" v-model="tabValue" align="center" :title="isTokenValid?'':'You must be logged to access full description and read content of this bundle'">
            <b-tab :title-link-class="'tab-title-class'" :active="!tab">
              <template #title>
                <font-awesome-icon class="mr-2" :icon="['fas', 'pen']" size="xs"/> Description
              </template>
            </b-tab>
            <b-tab id="topologies-tab" :title-link-class="'tab-title-class'" :active="tab===1" :disabled="!isTokenValid">
              <template #title>
                <font-awesome-icon class="mr-2" :icon="['fa', 'sitemap']" size="xs"/> Topologies <b-badge class="pt-1" variant="light">{{ bundle.topologies.length  }}</b-badge>
              </template>
            </b-tab>
            <b-tab :title-link-class="'tab-title-class'" :active="tab===2" :disabled="!isTokenValid">
              <template #title>
                <font-awesome-icon class="mr-2" :icon="['fa', 'gear']" size="xs"/> Actions <b-badge class="pt-1" variant="light">{{ bundle.actions.length  }}</b-badge>
              </template>
            </b-tab>
            <b-tab :title-link-class="'tab-title-class'" :active="tab===3" :disabled="!isTokenValid">
              <template #title>
                <font-awesome-icon class="mr-2" :icon="['fas', 'gears']" size="xs"/> Scenarios <b-badge class="pt-1" variant="light">{{ bundle.scenarios.length  }}</b-badge>
              </template>
            </b-tab>
            <b-tab :title-link-class="'tab-title-class'" :active="tab===4" :disabled="!isTokenValid">
              <template #title>
                <font-awesome-icon class="mr-2" :icon="['fas', 'file']" size="xs"/> Files <b-badge class="pt-1" variant="light">{{ bundle.files.length  }}</b-badge>
              </template>
            </b-tab>
            <b-tab :title-link-class="'tab-title-class'" :active="tab===5" :disabled="!isTokenValid">
              <template #title>
                <font-awesome-icon class="mr-2" :icon="['fas', 'folder']" size="xs"/> Categories <b-badge class="pt-1" variant="light">{{ bundle.categories.length  }}</b-badge>
              </template>
            </b-tab>
            <b-tab :title-link-class="'tab-title-class'" :active="tab===6">
              <template #title>
                <font-awesome-icon class="mr-2" :icon="['fas', 'certificate']" size="xs"/> License
               </template>
            </b-tab>
            <b-tab :title-link-class="'tab-title-class'" :active="tab===7">
              <template #title>
                <font-awesome-icon class="mr-2" :icon="['fas', 'star']" size="xs"/> Reviews
               </template>
            </b-tab>
            <b-tab :title-link-class="'tab-title-class'" :active="tab===8" :disabled="!isTokenValid">
              <template #title>
                <font-awesome-icon class="mr-2" :icon="['fas', 'comment']" size="xs"/> Q&A
               </template>
            </b-tab>
          </b-tabs>
        </div>
      </div>
      <b-container class="tab-content-body pb-5">
        <div class="tabs-content">
          <div class="tab-content content-description" v-if="tabValue===0">
            <div v-if="!editDescription">
              <b-button v-if="isEditable" class="button edit-tab-button" @click.stop="editDescription=true">
                <font-awesome-icon :icon="['fas', 'pencil-alt']" /></b-button>
              <vue-markdown v-if="!isTokenValid" :source="bundle.description" class="public-description-overflow"></vue-markdown>
              <vue-markdown v-else :source="bundle.description"></vue-markdown>
            </div>
            <div v-else>
              <form ref="form" @submit.stop.prevent="updateDescription()">
                <b-form-group id="form-description" label-for="description"
                              description="Please write your bundle description following community rules."
                >
                  <editor height="600px" id="input-description" ref="descriptionEditor" initialEditType="wysiwyg" :initialValue="this.bundle.description" />
                </b-form-group>
                <div class="menu-edit-description"
                     v-if="!busy">
                  <button
                    class="button btn btn-secondary"
                    @click.stop="editDescription=false">
                    Cancel
                  </button>
                  <button
                    type="submit"
                    class="btn btn-primary"
                    style="margin-left: 10px">
                    Submit
                  </button>
                </div>
                <b-spinner v-else class="align-middle" style="margin: 0 auto; display: block;"></b-spinner>
              </form>
            </div>
          </div>
          <div v-else-if="tabValue===1">
            <div id="topologies">
              <ResourceTiles key="topologies"
                             resource="topologies"
                             :reload="loadTopologies"
                             @loaded="loadTopologies = false"
                             :bundleIdentifier="bundle.identifier"/>
            </div>
          </div>
          <div v-else-if="tabValue===2">
            <div id="actions">
              <ResourceTiles key="actions"
                             resource='actions'
                             :reload="loadActions"
                             @loaded="loadActions = false"
                             :bundleIdentifier="bundle.identifier"/>
            </div>
          </div>
          <div v-else-if="tabValue===3">
            <div id="scenarios">
              <ResourceTiles
                key="scenarios"
                resource='scenarios'
                :reload="loadScenarios"
                @loaded="loadScenarios = false"
                :bundleIdentifier="bundle.identifier"/>
            </div>
          </div>
          <div v-else-if="tabValue===4">
            <div id="files">
              <ResourceTiles key="files"
                             resource='files'
                             :reload="loadFiles"
                             @loaded="loadFiles = false"
                             :bundleIdentifier="bundle.identifier"/>
            </div>
<!--            <div class="tab-content">-->
<!--              <FileTab :bundleIdentifier="bundle.identifier" :editBar="false"></FileTab>-->
<!--            </div>-->
          </div>
          <div v-else-if="tabValue===5">
            <div class="tab-content category">
              <CategoryTree :bundleIdentifier="bundle.identifier"></CategoryTree>
            </div>
          </div>
          <div class="tab-content license" v-else-if="tabValue===6">
            <div v-if="!editLicense">
              <b-button v-if="isEditable" class="button edit-tab-button" @click.stop="editLicense=true">
                <font-awesome-icon :icon="['fas', 'pencil-alt']"/></b-button>
              <vue-markdown :source="bundle.license"></vue-markdown>
            </div>
            <div v-else>
              <form ref="form" @submit.stop.prevent="updateLicense()">
                <b-form-group id="form-license" label-for="license"
                              description="Please write your bundle license."
                >
                  <editor height="600px" id="input-license" ref="licenseEditor" initialEditType="wysiwyg" :initialValue="this.bundle.license" />
                </b-form-group>
                <div class="menu-edit-description"
                     v-if="!busy">
                  <button class="button btn btn-secondary"
                          @click.stop="editLicense=false"
                          style="margin-right:10px;">
                    Cancel
                  </button>
                  <button class="btn btn-primary"
                          type="submit">
                    Submit
                  </button>
                </div>
                <b-spinner v-else class="align-middle" style="margin: 0 auto; display: block;"></b-spinner>
              </form>
            </div>
          </div>
          <div v-else-if="tabValue===7">
            <b-button v-if="isTokenValid" variant="outline-secondary" @click.stop="$refs['reviewForm'].openModal()" style="margin-bottom: 10px">
              Start your review
            </b-button>
            <BundleReviewForm ref="reviewForm" id="reviewForm" :bundle-identifier="identifier"></BundleReviewForm>
            <ReviewList :bundle-identifier="identifier"></ReviewList>
          </div>
          <div v-else-if="tabValue===8">
            <b-button v-if="isTokenValid" variant="outline-secondary" :to="{name: 'questionForm', params: {bundleIdentifier: bundle.identifier}}"
                      style="margin-bottom: 10px">
              New question
            </b-button>
            <QuestionList :bundle-identifier="identifier"></QuestionList>
          </div>
        </div>
      </b-container>
    </div>

    <div class="text-center"
        style="padding-top:10px; padding-bottom:10px;"
        v-else>
        <b-spinner class="align-middle"></b-spinner>
        <strong style="margin-left:10px;">Loading...</strong>
    </div>
</template>

<script>
import BundleDownloadModal from '@/components/hub/BundleDownloadModal.vue'
import BundleReviewForm from '@/components/hub/reviews/BundleReviewForm.vue'
import CopyButton from '@/components/CopyButton.vue'
import ReviewList from '@/components/hub/reviews/ReviewList.vue'
import QuestionList from '@/components/hub/questions/QuestionList.vue'
import BundleListForm from '@/components/bundles/BundleListForm.vue'
import FileTab from "@/components/bundles/FileTab";
import ResourceTiles from "@/components/hub/ResourceTiles";
import CategoryTree from '@/components/hub/categories/CategoryTree'
import BundleShare from '@/components/shares/BundleShares'
import ContentLabels from '@/components/hub/ContentLabels.vue'
import VisibilityBadge from '@/components/VisibilityBadge'
import {capitalize, print_human_readable_size} from "@/filters";
import {generateIconColor, Tag} from '@/common';
import store from "@/store";
import InputImage from "@/components/form/InputImage.vue";
import InputText from "@/components/form/InputText.vue";

export default {
    name: 'BundleContent',
    components: {
      InputText,InputImage, BundleDownloadModal, ReviewList, BundleReviewForm,
      QuestionList, BundleListForm, FileTab, ResourceTiles, CategoryTree,
      CopyButton, BundleShare, VisibilityBadge, ContentLabels
    },
    props: {
      identifier: String,
      tab: {
        type: Number,
        default: 0
      },
      accept: {
        default: "image/*",
        type: String,
      },
      previousPage: null,
      reload: {
        type: Boolean,
        default: true
      },
      reloadTopologies: {
        type: Boolean,
        default: true
      },
      reloadActions: {
        type: Boolean,
        default: true
      },
      reloadScenarios: {
        type: Boolean,
        default: true
      },
      reloadFiles: {
        type: Boolean,
        default: true,
      }
    },
    data: function() {
      return {
        loadTopologies: this.reloadTopologies,
        loadActions: this.reloadActions,
        loadScenarios: this.reloadScenarios,
        loadFiles: this.reloadFiles,
        busy: false,
        bundle: undefined,
        group: undefined,
        tabValue: this.tab,
        favorites: this.$store.getters['favorites/getAll'](),
        showInputLabel: false,
        newLabel: "",
        editDescription: false,
        editAttributes: false,
        imgAccept: {
          default: "image/*",
          type: String,
        },
        imageId: "Icone",
        image: null,
        imageUpdated: undefined,
        visibility: "",
        visibilityOptions: [
          {id: "private", name: 'Private: Restricted'},
          {id: "internal", name: 'Internal: Visible to all connected users'},
          {id: "public", name: 'Public: Visible to all users, even disconnected'},
        ],
        exportability: "",
        exportabilityOptions: [
          {id: "private", name: "Private: Restricted"},
          {id: "internal", name: 'Internal: All connected users can export this bundle'},
        ],
        inputName:null,
        inputQualifier: null,
        inputAlias: null,
        editLicense: false,
      }
    },
    computed: {
      isTokenValid: () => store.getters["token/isValid"](),
      isEditable() {
        return !(this.bundle.authz && (this.bundle.authz.permission & 0x04) === 0);
      },
      isDeletable() {
        return !(this.bundle.authz && (this.bundle.authz.permission & 0x08) === 0);
      },
      isExportable() {
        return !(this.bundle.authz && (this.bundle.authz.permission & 0x20) === 0);
      },
      printableBundleSize() {
        return print_human_readable_size(this.bundle.content_size)
      },
      iconColor() {
        return generateIconColor(this.identifier)
      }
    },
    mounted: async function() {
      if(this.reload) {
        await this.$store.dispatch('bundles/load', this.identifier)
        this.bundle = this.$store.getters['bundles/get'](this.identifier)

        await this.$store.dispatch('groups/load', this.bundle.group_identifier)
        this.group = this.$store.getters['groups/get'](this.bundle.group_identifier)
      }else{
        this.bundle = this.$store.getters['bundles/get'](this.identifier)
        this.group = this.$store.getters['groups/get'](this.bundle.group_identifier)
      }

      this.updateBreadcrumb()
    },
    methods: {
      printCategory(categoryIdentfier) {
        let category = this.$store.getters['categories/get'](categoryIdentfier)
        if (category) {
          return category.alias + " / " + category.name
        }
      },
      exportBundle() {
        this.$store.dispatch('bundles/export', {
          bundle_identifier: this.bundle.identifier,
          resources: null
        }).then(() => {
          this.$eventBus.$emit('success', 'The bundle has been exported')
        }).catch((response) => {
          this.$eventBus.$emit('http-error', response)
        })
      },
      updateBreadcrumb() {
        let breadcumbList = []

        if (this.previousPage === null) {
          breadcumbList.push({text: 'Explore', to: {name: 'bundleGrid'}})
        } else if( this.previousPage.text === 'Explore') {
          this.previousPage.to.params.reloadBundles = false
          breadcumbList.push(this.previousPage)
        } else {
          breadcumbList.push(this.previousPage)
        }
        breadcumbList.push({
          text: this.bundle.name,
          to: {name: 'bundlePage', params: {identifier: this.bundle.identifier, tab: this.tabValue}}
        })
        if(this.previousPage.text === 'Group settings') {
          breadcumbList.push(this.previousPage)
        }

        this.$emit('breadcrumbChange', breadcumbList)
      },
      toggleFavorite() {
        if (this.bundle.favorite === true) {
          this.$store.dispatch('favorites/delete', this.identifier)
          this.bundle.favorite = false
        } else {
          this.$store.dispatch('favorites/add', this.identifier)
          this.bundle.favorite = true
        }
      },
      updateDescription() {
        this.busy = true;
        let description = this.$refs.descriptionEditor.invoke('getMarkdown')
        this.$store.dispatch('bundles/update', {
          identifier: this.bundle.identifier,
          group_identifier: this.bundle.group_identifier, data: {description: description}
        }).then(() => {
          this.busy = false;
          this.editDescription = false
        })
      },
      updateLicense() {
        this.busy = true
        let license = this.$refs.licenseEditor.invoke('getMarkdown')
        this.$store.dispatch('bundles/update', {
          identifier: this.bundle.identifier,
          group_identifier: this.bundle.group_identifier, data: {license: license}
        }).then(() => {
          this.editLicense = false
          this.busy = true
        })
      },
      clickEditAttribut() {
        this.editAttributes = true;
        this.inputName = this.bundle.name;
        this.inputQualifier = this.bundle.qualifier;
        this.visibility = this.bundle.visibility;
        this.exportability = this.bundle.exportability;
        this.inputAlias = this.bundle.alias;
        if(this.bundle.icon && this.bundle.icon.url){
          this.image = this.bundle.icon.url;
        }else if (this.bundle.group_icon && this.bundle.group_icon.url){
          this.image = this.bundle.group_icon.url;
        }
      },
      cancelEditAttribut() {
        this.editAttributes = false;
        this.image = null;
        this.imageUpdated = undefined;
        this.visibility = "";
        this.exportability = "";
        this.inputName = null;
        this.inputQualifier = null;
        this.inputAlias = null;
      },
      submitEditAttributes(){
        if (!this.editAttributes) {
          return
        }
        let data = {
          "tag": new Tag(this.inputAlias, this.inputQualifier).print(),
          "name": this.inputName,
          "visibility": this.visibility,
          "exportability": this.exportability
        };
        if(this.imageUpdated !== undefined){
          data.icon = this.imageUpdated;
        }
        store.dispatch('bundles/update', {
          identifier: this.identifier,
          group_identifier: this.bundle.group_identifier,
          data: data
        }).then(() => {
          this.$eventBus.$emit('success', 'The bundle has been updated')
        }).catch((response) => {
          this.$eventBus.$emit('http-error', response)
        });
        this.cancelEditAttribut();
      },
      viewGroup () {
        this.$router.push({
          name: 'groupBundleGrid',
          params: {
            identifier: this.group.identifier,
            previousPage: {
              text: this.bundle.name,
              to: {
                name: 'bundlePage',
                params: {
                  identifier: this.bundle.identifier,
                  previousPage: this.previousPage
                },
              },
            },
          }
        })
      },
    }
}
</script>


<style scoped>

.top {
  width: 100%;
}

.bundle-specs {
  width: 85%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 10px;
  text-align: left;
  display: flex;
  position: relative;
}

input:focus {
  outline: none;
}

.tab-content {
  position: relative;
  background-color: #FFFFFF;
  border: 1px solid #e0e4e7;
  border-radius: 2px;
  padding: 10px;
  min-height: 60px;
}

.tab-content.category,
.tab-content.license {
  border-radius: 10px 10px;
}

.rate {
  background-color: transparent;
  padding: 0;
}

/*bundle icon*/

.icon {
  width: 180px;
  margin-left: 10px;
  margin-right: 10px;
}

.bundle-icon {
  object-fit: scale-down;
}

.content-description {
  border-radius: 10px 10px 10px 10px;
}

.menu-edit-description {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 144px;
  margin: 0 auto;
}

.generated-icon {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.edit-attr-icon {
  width: 100%;
  height: auto;
  display: flex;
  align-items: end;
  margin-bottom: 5px;
  transition: 0.4s;
}

.edit-attr-icon input{
  cursor: pointer !important;
}

.edit-remove-icon button {
  display: block;
  margin: 6.5px auto 0px auto;
}

.bundle-attributes {
  margin-left: 10px;
  flex-wrap: wrap;
}

.bundle-title {
  display: flex;
  align-items: center;
  white-space: nowrap;
}
.bundle-title h2,
.edit-title{
  margin-right: 10px;
}

.dropdown-edit-attr {
  display: flex;
  align-items: center;
  margin: 0px 00px;
}

.dropdown-edit-attr label{
  white-space: nowrap;
  margin:2px 5px 0px 0px;
  width: 90px;
}

.dropdown-edit-attr select{
  margin: 0px 0px !important;
}

.tag-row {
  padding-top: 10px;
  display: flex;
}

.edit-attr.lade-label {
  width: 80px;
}

.rate-row {
  margin-top: 10px;
  margin-bottom: 10px;
}

.nav-edit-attributes{
  margin-top: 20px;
  width: 135px;
  display: flex;
  justify-content: space-between;
}

.download-button {
  position: absolute;
  right: 10px;
  top: 10px;
}

.bundle-tabs >>> .tab-title-class {
  color: var(--lade-color-dark-grey);
  font-size: 1.2em;
  border-radius: 0px;
}

.bundle-tabs >>> .tab-title-class.disabled{
  color: var(--lade-color-grey);
}

.bundle-tabs {
  width: 85%;
  margin: 20px auto -14px;
}

.tabs-content {
  width: 85%;
  margin-right: auto;
  margin-left: auto;
  position: relative;
}

@media screen and (max-width: 90rem) {
  .bundle-specs {
    width: 100%;
  }

  .bundle-tabs {
    width: 100%;
    margin-right: 10px;
    margin-left: 10px;
  }

  .tabs-content {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }

}

@media screen and (max-width: 60rem) {
  .bundle-specs {
    flex-wrap: wrap;
    justify-content: center;
  }
}

.public-description-overflow {
  white-space: nowrap;
  max-height: 512px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.disabled-tab {
  background: var(--lade-navy);
}

</style>
