<template>
  <div class="sectionEditGroup">
    <div class="content">
      <b-modal ref="moveBundleModal" id="moveBundleModal" hide-footer v-bind:title="'Move Bundles'">
        <form v-on:submit.prevent="moveBundles">
          <div class="form-group row">
            <label class="col-sm-3 col-form-label" for="currentGroup">Current Group</label>
            <div class="col-sm-9">
              <TypeAhead required prefix="Bundle" id="currentGroup" name="currentGroup" resourceType="group"
                         v-model="currentGroup" class="mb-2" @hit="currentGroupIdentifier=$event" disabled/>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-sm-3 col-form-label" for="newGroup">New Group</label>
            <div class="col-sm-9">
              <TypeAhead required prefix="Bundle" id="newGroup" name="newGroup" resourceType="group" v-model="newGroup"
                         class="mb-2" @hit="newGroupIdentifier=$event"/>
            </div>
          </div>
          <b-button type="submit" variant="primary">Confirm</b-button>
          <b-button variant="secondary" @click.stop="$refs.moveBundleModal.hide()"
                    style="margin-left:10px;">Cancel
          </b-button>
        </form>
      </b-modal>
      <div class="head-content">
        <h2>
          Bundles
        </h2>
      </div>
      <div class="edit">
        <div class="size">
          <div class="size-legend">
              <font-awesome-icon class='fa-icon' :icon="['fas', 'database']" alt="Size"/>
              <span v-if="group.content_size > 0">
                    {{ group.content_size | print_human_readable_size }}
                  </span>
              <span v-else-if="group.limit_content_size === 0">
                    Group cannot have content. <i>(limited)</i>
                  </span>
              <span v-else>
                    No Content
                  </span>
              <span v-if="group.limit_content_size > 0">/ {{
                  group.limit_content_size | print_human_readable_size
                  }}
                </span>
          </div>
          <CapacityChart :chart-data="capacitydata" :options="chart_size_options" class="capacity-chart"
                         v-if="(group.limit_content_size > 0) && (group.content_size >= 0)"/>
        </div>
        <nav class="actions-bundles">
          <div v-if="this.bundleSelected.length > 0" class="bundle-selected">
            <button class="move-bundle"
                    @click="modalMoveBundles">
              <font-awesome-icon
                :icon="['fas', 'share-square']"
                title="Move bundles selected"/>
              <p>Move bundles</p>
            </button>
            <button class="delete-bundle"
                    @click.stop="deleteBundles()">
              <font-awesome-icon
                :icon="['fas', 'trash']"
                style="color: indianred;"
                title="Delete bundles selected"/>
              <p>Delete bundles</p>
            </button>
            <span style="margin-left: 5px">|</span>
          </div>
          <button class="refresh-bundle"
                  @click="$store.dispatch('bundles/loadGroup', group.identity)">
            <font-awesome-icon
              :icon="['fas', 'sync']"
              title="Reload bundles"/>
              <p>Refresh</p>
          </button>
          <button class="add-bundle"
                  @click.stop="addBundle = !addBundle"
                  v-show="!addBundle">
            <font-awesome-icon
              :icon="['fas', 'plus']"
              title="Add bundle"/>
            <p>Add Bundle</p>
          </button>
        </nav>
        <ResourceList
          resource="bundles"
          :getter="getter"
          :dispatchActions="dispatchActions"
          addIcon="plus"
          ref="bundlelist"
          deleteEvent="delete-bundle-show"
          :addEvent="canAddBundle()?'add-bundle-event':undefined"
          resourceListForm="BundleListForm"
          hideSearch
          :checkbox=true
          :busy="busy"
          @viewBundles="viewBundle"
          @selectBundle="selectBundle"
          @unselectBundle="unselectBundle">

          <template v-slot:head(actions)>
            Actions
          </template>

          <template v-slot:customActions="actions">
  <!--          <font-awesome-icon-->
  <!--            class='fa-icon action-icon'-->
  <!--            :icon="['fas', 'eye']"-->
  <!--            title="View resource"-->
  <!--           @click.stop="viewBundle(actions.row.item.identifier)"/>-->
            <font-awesome-icon
              class='fa-icon action-icon'
              :icon="['fas', 'share-square']"
              title="Move bundle"
              v-if="isEditable(actions.row.item)"
              @click.stop="$eventBus.$emit('move-bundle-show', actions.row.item.identifier)"
              style="margin-left:0.8em;"/>
          </template>

          <template slot="top-row" v-if="addBundle">
            <td style="width:20%">
              <form method="GET" id="bundleForm" ref="bundleForm"></form>
              <input type="text" v-model="name" autocomplete="off" placeholder="Name"
                     required class="form-control" form="bundleForm"/>
            </td>
            <td>
              <div class="input-group">
                <input type="text" v-model="alias" autocomplete="off" placeholder="Alias"
                       required class="form-control" pattern="[a-zA-Z0-9\-_]*" form="bundleForm" minlength="3" maxlength="20"
                       title="Must be alphanumeric (dashes and underscores are authorized)"/>
                <div class="input-group-prepend input-group-append">
                  <span class="input-group-text">:</span>
                </div>
                <input type="text" v-model="qualifier" autocomplete="off" placeholder="Version"
                       required class="form-control" form="bundleForm"/>
              </div>
            </td>
            <td colspan="2">
            </td>
            <td style="vertical-align:middle;">

              <font-awesome-icon
                class='fa-icon action-icon'
                :icon="['fas', 'check']"
                @click.stop="addBundleInGroup()"
                style="color: dodgerblue; margin-left:0.3em;"/>

              <font-awesome-icon
                class='fa-icon action-icon'
                :icon="['fas', 'times']"
                @click.stop="addBundle=false"
                style="color: indianred; margin-left:0.8em;"/>

            </td>
          </template>
        </ResourceList>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import {Tag} from "@/common";
import TypeAhead from "@/components/form/TypeAhead.vue";
import CapacityChart from "@/components/groups/CapacityChart.vue";
import {print_human_readable_size} from "@/filters";
import {units} from "@/common";


export default {
  name: 'EditGroupBundles',
  components: {CapacityChart, TypeAhead},
  props: {
    group: Object,
    resourceListFormParameters: Object,
  },
  data() {
    return {
      addEvent: String,
      editEvent: String,
      content_size: 0,
      limit_content_size: 0,
      percent: 0,
      content_size_number: 0,
      content_size_unit: units.Bytes,
      limit_content_size_number: 0,
      limit_content_size_unit_selected: units.Bytes,
      chart_size_options: {
          legend: {
              display: false
          },
          tooltips: {
              callbacks: {
                  title: function (tooltipItem, data) {
                      return data['labels'][tooltipItem[0]['index']]
                  },
                  label: function (tooltipItem, data) {
                      return print_human_readable_size(data['datasets'][0]['data'][tooltipItem['index']])
                  },
              },
              backgroundColor: '#FFF',
              titleFontSize: 16,
              titleFontColor: 'black',
              bodyFontColor: 'black',
              bodyFontSize: 14,
              displayColors: false
          },
      },
      bundleSelected: [],
      currentGroup: null,
      currentGroupIdentifier: null,
      newGroup: null,
      newGroupIdentifier: "",
      name: null,
      alias: null,
      qualifier: null,
      addBundle: null,
      busy: false,
      filters: store.getters['bundles/getFilters'](),
      dispatchActions: [{
        event: 'bundles/loadGroup',
        parameters: {groupIdentifier: this.group.identifier, orderBy: 'created_date', limit: -1}
      }],
      getter: {event: 'bundles/getAll'}
    }
  },
  computed: {
    capacitydata() {
      return {
          datasets: [{
              data: [this.group.content_size,
                  Math.max(this.group.limit_content_size - this.group.content_size, 0)],
              backgroundColor: ['indianred', 'lightgreen']
          }],
          labels: ['Used', 'Free']
      }
    },
  },
  created() {
    this.$eventBus.$on("add-bundle-event", () => {
      this.addBundle = !this.addBundle
    })
  },
  methods: {
    selectBundle(bundle){
      this.bundleSelected.push(bundle);
    },
    unselectBundle(bundle) {
      let pos = 0;
      this.bundleSelected.forEach((item,index) => {
        if(item.identifier === bundle.identifier)
          pos = index
      })
      this.bundleSelected.splice(pos, 1);
    },
    canAddBundle() {
      let required = store.getters['token/getPermission']("BUNDLE", "CREATE")
      for (let permission of this.group.authz.resources_permissions){
        if (permission.resource === "BUNDLE"){
          return (permission.permission & required) === required
        }
      }
      return false
    },
    resetFields() {
      this.name = null
      this.alias = null
      this.qualifier = null
    },
    isEditable(entry) {
      if (entry.authz && (entry.authz.permission & 0x04) === 0) {
        return false;
      }
      return this.filters.isEditable(entry)
    },
    //TODO: add function isDeletable()
    async deleteBundles() {
      // this.$eventBus.$emit('delete-bundle-show', this.bundleSelected[0].identifier)
      this.$deleteModal({
        text: "Are you sure to delete selected bundles from groups " + this.group.name + "?"
      }).then(async (result) => {
        if(result.value) {
          let i = 0;
          while(i<this.bundleSelected.length) {
            await store.dispatch('bundles/delete', this.bundleSelected[i].identifier).then(() => {
              this.$eventBus.$emit('success', 'The bundle has been deleted');
            }).catch((response) => {
              this.$eventBus.$emit('http-error', response)
            })
            i++;
          }
          this.bundleSelected = []
        }
      })
    },
    modalMoveBundles () {
      this.currentGroup = this.group.name;
      this.currentGroupIdentifier = this.group.identifier;
      this.$refs.moveBundleModal.show();
    },
    async moveBundles () {
      if (this.currentGroupIdentifier === this.newGroupIdentifier) {
        this.$eventBus.$emit("error", "Cannot move in the same groups")
        return
      }
      let i = 0;
      while(i<this.bundleSelected.length) {
        await store.dispatch('bundles/moveGroup', {
          bundle_identifier: this.bundleSelected[i].identifier,
          new_group_identifier: this.newGroupIdentifier
        }).then(() => {
          this.$eventBus.$emit('success', 'The bundle has been moved');
        }).catch((response) => {
          this.$eventBus.$emit('http-error', response)
        })
        i++;
      }
      this.$refs.moveBundleModal.hide()
      this.bundleSelected = [];
    },
    addBundleInGroup() {
      if (!this.$refs.bundleForm.reportValidity()) {
        return
      }
      let data = {
        "tag": new Tag(this.alias, this.qualifier).print(),
        "name": this.name,
      }
      store.dispatch('bundles/add', {group_identifier: this.group.identifier, data: data}).then(() => {
        this.resetFields()
        this.$refs.bundlelist.loadResources()
      }).catch((response) => {
        this.$eventBus.$emit('http-error', response)
      })
    },
    exportBundle(bundle) {
      store.dispatch('bundles/export', {bundle_identifier: bundle.identifier, resources: null}).then(() => {
        this.$eventBus.$emit('success', 'The bundle has been exported')
      }).catch((response) => {
        this.$eventBus.$emit('http-error', response)
      })
    },
    currentPage: function () {
      return {text: 'Group settings', to: 'editGroup', identifier: this.group.identifier}
    },
    viewBundle (identifier){
      this.$router.push({
        name: "bundlePage",
        params: {
          identifier: identifier, previousPage: this.currentPage()
        },
      });
    }
  }
}
</script>

<style scoped>
::-webkit-scrollbar {
  width: 5px;               /* width of the entire scrollbar */
}

::-webkit-scrollbar-thumb {
  background-color: var(--lade-color-grey);    /* color of the scroll thumb */
  border-radius: 20px;       /* roundness of the scroll thumb */
}

.edit {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow-y: auto;
  position: relative;
  width: 100%;
  padding: 15px 15px 105px 15px;
}

.size{
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.capacity-chart {
  width: 80px;
  margin-left: 20px;
}

#bundles {
  width: 100%;
}

th:last-child .input-group{
  display: flex;
  justify-content: center;
}

nav.actions-bundles {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

nav .bundle-selected {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.move-bundle,
.delete-bundle,
.refresh-bundle,
.add-bundle{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 26px;
  overflow-x: hidden;
  flex-wrap: nowrap;
  border: solid 1px transparent;
  border-radius: 5px 5px;
  font-size: 1rem;
  background-color: transparent;
  transition: width 0.3s ease-out;
  padding: 4px 4px;
}

.refresh-bundle {
  margin-left: 5px;
}

.move-bundle svg,
.refresh-bundle svg {
  margin-right: 6px;
}

.delete-bundle svg,
.add-bundle svg{
  margin-right: 8px;
}

.move-bundle p,
.delete-bundle p ,
.refresh-bundle p,
.add-bundle p{
  margin: 0 0;
  white-space: nowrap;
}

.move-bundle:hover {
  width: 110px;
  background-color: var(--lade-background-notify);
  color: var(--lade-color-white-hover);
}

.refresh-bundle:hover {
  width: 75px;
  background-color: var(--lade-navy);
  color: var(--lade-color-white-hover);
}

.add-bundle:hover {
  width: 97px;
  background-color: var(--lade-green-explore-3);
  color: var(--lade-color-white-hover);
}

.delete-bundle:hover {
  width: 115px;
  color: var(--lade-color-white-hover);
  background-color: var(--lade-color-red);
}
</style>
