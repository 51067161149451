import Vue from 'vue'
import Config from '../../Config'

const state = {
  license: null,
  content: null,
  map: {}
}

const getters = {
  get: (state) => () => {
    return state.license
  },
  content: (state) => () => {
    return state.content
  }
}

const actions = {
  load({dispatch, commit}) {
    return Vue.http.get(Config.API_LICENSE_URL).then((response) => {
      let data = response.data
      commit('set', data)
    })
  },
  update({dispatch, commit}, value) {
    return Vue.http.post(Config.API_LICENSE_URL, {
      "license": value
    })
  }
}

const mutations = {
  set(state, data) {
    state.license = data.license
    state.content = data.content
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true
}






