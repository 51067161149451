<template>
  <form v-on:submit.prevent="onChangePassword">
    <div class="card" style="margin: 20px">
      <div class="card-header">
        Change password
      </div>
      <div class="card-body">
        <div class="form-group">
          <label for="UserChangePasswordCurrent">Current password</label>
          <input type="password" ref="oldPasswordField" autocomplete="off" v-model="password" class="form-control"
                 id="UserChangePasswordCurrent" aria-describedby="UserChangePasswordCurrentHelp" required>
          <small id="UserChangePasswordCurrentHelp" class="form-text text-muted"></small>
        </div>
        <div class="form-group">
          <label for="UserChangePasswordNew">New password</label>
          <input type="password" ref="newPasswordField" autocomplete="off" v-model="newPassword" class="form-control"
                 id="UserChangePasswordNew" aria-describedby="UserChangePasswordNewHelp" pattern=".{6,50}" required>
          <small id="UserChangePasswordNewHelp" class="form-text text-muted"></small>
        </div>
        <div class="form-group">
          <label for="UserChangePasswordNew2">Confirm new password</label>
          <input type="password" ref="newPasswordField2" autocomplete="off" v-model="newPassword2" class="form-control"
                 id="UserChangePasswordNew2" aria-describedby="UserChangePasswordNew2Help" pattern=".{6,50}" required>
          <small id="UserChangePasswordNew2Help" class="form-text text-muted"></small>
        </div>
        <button type="submit" class="btn btn-primary">Submit</button>
      </div>
    </div>
  </form>
</template>

<script>
export default {
  name: "ProfileView",
  computed: {
    tokenInfos: () => this.$store.getters['token/getInfos']()
  },
  data() {
    return {
      password: null,
      newPassword: null,
      newPassword2: null
    }
  },
  methods: {
    reset() {
      this.password = null
      this.newPassword = null
      this.newPassword2 = null
    },
    onChangePassword() {
      if (this.newPassword !== this.newPassword2) {
        this.$eventBus.$emit('error', "Passwords mismatch")
        this.$refs.newPasswordField.focus()
        return
      }

      let data = {
        "old_password": this.password,
        "new_password": this.newPassword
      }

      this.$store.dispatch('users/update_profile', {"identifier": "me", "data": data}).then(() => {
        this.$eventBus.$emit('success', "Password has been updated")
        this.reset()
        this.$router.push({name: "home"})
      }).catch((response) => {
        this.$eventBus.$emit('http-error', response)
      })
    }
  }
}
</script>
