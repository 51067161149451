<template>
  <FullCalendar
    :locales="allLocales"
    :locale="locale"
    ref="calendar"
    :header="header"
    :eventTextColor="eventTextColor"
    :eventLimit="eventLimit"
    :slotEventOverlap="slotEventOverlap"
    :timeFormat="timeFormat"
    :slotDuration="slotDuration"
    :options="calendarOptions"
    :selectable="workzones.length !== 0"
    :editable="workzones.length !== 0"
    nowIndicator="true"
    displayEventEnd="true"
    style="padding:10px;"/>
</template>

<script>
import {status_to_variant} from '@/common'
import moment from 'moment'
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from "@fullcalendar/timegrid";
import allLocales from '@fullcalendar/core/locales-all';


export default {
  name: "Calendar",
  components: {
    FullCalendar
  },
  data: function () {
    let language = navigator.language;

    // Default is "en"
    let locales = allLocales.filter((el) => el.code === language)
    if (locales.length === 0)
      language = "en"

    return {
      allLocales: allLocales,
      locale: language,
      header: {
        left: "prev,next today",
        center: "title",
        right: "dayGridMonth,timeGridWeek,timeGridDay"
      },
      calendarOptions: {
        dateClick: this.select,
        eventClick: this.handleEventClick,
        events:this.getEvents,
        plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
        initialView: 'dayGridMonth',
        headerToolbar: {
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay'
        },
      },
      eventTextColor: "white",
      eventLimit: 4,
      slotEventOverlap: false,
      timeFormat: "H(:mm)",
      slotDuration: "01:00:00",
      bookings: this.groupIdentifier === "all" ? this.$store.getters['bookings/getAll']() : this.$store.getters['bookings/getGroup'](this.groupIdentifier),
      calendar: this.$store.getters['bookings/getCalendar'](),
      users: this.groupIdentifier === "all" ? this.$store.getters['users/getAll']() : this.$store.getters['users/getGroup'](this.groupIdentifier),
      workzones: this.groupIdentifier === "all" ? this.$store.getters['workzones/getAll']() : this.$store.getters['workzones/getGroup'](this.groupIdentifier)
    };
  },
  props: {
    groupIdentifier: String,
  },
  computed: {
    hasBookingWrite() {
      return this.$store.getters['token/getInfos']().resources.filter(x => x.resource === "BOOKING")[0].permission === 'w'
    },
    hasBookingExecute() {
      return this.$store.getters['token/getInfos']().resources.filter(x => x.resource === "BOOKING")[0].permission === 'x'
    },
  },
  methods: {
    getEvents(fetchInfo, callback) {
      let events = []

      // Month view only accept full day for background
      events.push({
        id: 'past-dates',
        start: moment(0).format("YYYY-MM-DD"),
        end: moment().format("YYYY-MM-DD"),
        display: 'background',
        color: 'lightgrey'
      })

      // Remaining for day / week view
      events.push({
        id: 'past-days',
        start: moment().startOf('day').format(),
        end: moment().format(),
        display: 'background',
        color: 'lightgrey'
      })

      // highlight overbooked periods in red
      for (let overbooked of this.calendar) {
        let overbooked_event = {
          id: 'overbooked',
          start: moment(overbooked.start).format(),
          end: moment(overbooked.end).format(),
          display: 'background',
          backgroundColor: 'indianred'
        }
        events.push(overbooked_event)

        // If taking more thant a full day, display overbooked for calendar view
        let day_event = {...overbooked_event}
        day_event.start = moment(overbooked.start).startOf('day').format("YYYY-MM-DD")
        day_event.end = moment(overbooked.end).startOf('day').format("YYYY-MM-DD")
        if (day_event.start !== day_event.end)
          events.push(day_event)
      }

      // build booking events on calendar
      for (let booking of this.bookings) {
        let variant = this.booking_variant(booking)
        events.push({
          title: booking.name + (!booking.approved ? " (Need Approval)" : ""),
          start: moment(booking.start).format(),
          end: moment(booking.end).format(),
          id: booking.identifier,
          classNames: ['bg-' + variant, 'border-' + variant, 'pointer'],
          editable: false
        })

      }
      callback(events)
    },
    booking_variant(booking) {
      if (!booking.approved)
        return "warning"
      else
        return status_to_variant(booking.status)
    },
    select(arg) {
      if (this.groupIdentifier === "all") {
        this.$eventBus.$emit("info", "Select a groups to create a booking")
        return
      }
      let date = moment(arg.date)
      let now = moment()
      if (date >= now)
        this.$eventBus.$emit('add-booking-show', this.groupIdentifier, date)
    },
    handleEventClick(arg) {
      this.$store.dispatch("bookings/load", arg.event.id).then(() => {
        this.$eventBus.$emit('edit-booking-show', arg.event.id)
      })
    }
  }
}
</script>

