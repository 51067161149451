function sanitize(obj) {
  let sanitized = {}
  for (let [key, val] of Object.entries(obj)) {
    if (!(typeof val == 'string' && val.length === 0)) {
      sanitized[key] = val
    } else {
      sanitized[key] = null
    }
  }
  return sanitized
}

function downloadFile(response, defaultFilename) {
  let disposition = response.headers.get('content-disposition')
  console.log(disposition)
  let contentType = response.headers.get('content-type')
  if (!contentType)
    contentType = "application/octet-stream"
  let matches = /"([^"]*)"/.exec(disposition);
  let filename = (matches != null && matches[1] ? matches[1] : defaultFilename);
  let blob = new Blob([response.data], {type: contentType})

  let objectUrl = window.URL.createObjectURL(blob)
  let link = document.createElement('a')
  link.href = objectUrl
  link.download = filename
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

function sanitizeTag(tag) {
  return tag.replace(/[^a-zA-Z0-9\-_]/, '_')
}

class Tag {
  constructor(alias, qualifier=null, resource_type=null, owner=null) {
    this.alias = alias
    this.qualifier = qualifier
    this.resource_type = resource_type
    this.owner = owner
  }

  print(print_resource=false, print_owner=true) {
    let _tag = this.alias

    if (this.resource_type != null && print_resource) {
      _tag = this.resource_type + '/' + _tag
    }
    if (this.owner != null && print_owner) {
      _tag = this.owner + '/' + _tag
    }
    if (this.qualifier != null) {
      _tag = _tag + ':' + this.qualifier
    }

    return _tag
  }

}

function parseTag(tag, parse_owner=false) {
  let _tag = tag
  let owner = null
  let resource_type = null
  let qualifier = null

  if (parse_owner) {
    // owner is only present in bundle tag
    if (_tag.includes("/")) {
      const chars = tag.split('/')
      if (chars.length === 3) {
        resource_type = chars[1]
      }
      owner = chars[0]
      _tag = chars[chars.length - 1]
    }
  }
  else {
    const chars = _tag.split('/')
    _tag = chars[chars.length - 1]
    resource_type = chars[chars.length - 2]
  }

  if (_tag.includes(":")) {
     const chars = _tag.split(':')
     qualifier = chars[1]
     _tag = chars[0]
  }


  let alias = _tag

  return new Tag(alias, qualifier, resource_type, owner)
}

const units = {
  TB: 'TB',
  GB: 'GB',
  MB: 'MB',
  KB: 'KB',
  Bytes: 'Bytes',
  null: ''
}

function refactor_number_with_unit(number) {

  if (number < 0) {
    return {number: number, unit: units.null}
  }

  let log2 = Math.log2(number)
  if (log2 > 40) {
    return {number: (number / Math.pow(2, 40)), unit: units.TB}
  } else if (log2 > 30) {
    return {number: (number / Math.pow(2, 30)), unit: units.GB}
  } else if (log2 > 20) {
    return {number: (number / Math.pow(2, 20)), unit: units.MB}
  } else if (log2 > 10) {
    return {number: (number / Math.pow(2, 10)), unit: units.KB}
  } else {
    return {number: number, unit: units.Bytes}
  }
}

function refactor_unit_to_number(number, unit) {
  let value = number
  if (number < 0) {
    return -1
  }
  switch (unit) {
    case units.TB:
      value = value * Math.pow(2, 40)
      break
    case units.GB:
      value = value * Math.pow(2, 30)
      break
    case units.MB:
      value = value * Math.pow(2, 20)
      break
    case units.KB:
      value = value * Math.pow(2, 10)
      break
  }
  return value
}

function round_decimal(value, decimals) {
  if (value === 0) {
    return 0
  } else {
    return Math.round(value * 10 * decimals) / (10 * decimals)
  }
}

function setDictValue(dict, key, value) {
  if (dict[key] === undefined) {
    dict[key] = value
  } else {
    Object.assign(dict[key], value)
  }
}

function status_to_variant(status) {
  if (status === "succeeded")
    return "success"
  else if (status === "running")
    return "info"
  else if (status === "failed" || status === "cancelled")
    return "danger"
  return "primary"
}

function build_resource_list_params({
                                      orderBy = null,
                                      desc = null,
                                      page = null,
                                      limit = null,
                                      search = null,
                                      load = null,
                                      labels = [],
                                      category = null,
                                      member_of = null,
                                    }) {
  let params = {}
  if (orderBy) {
    params['order_by'] = orderBy
  }

  if (limit !== null) {
    params['limit'] = limit.toString()
  }

  if (page !== null) {
    params['page'] = page.toString()
  }

  if (desc !== null) {
    params['desc'] = desc.toString()
  }

  if (load != null) {
    params['load'] = load
  }

  if (category != null) {
    params['category'] = category
  }

  if (search) {
    params['q'] = search
  }

  if (labels.length > 1) {
    params['label'] = labels.toString()
  }

  if (member_of) {
    params['member_of'] = member_of
  }
  return params
}

function truncateWithEllipses(text, max)
{
  return text.substr(0,max-1)+(text.length>max?'...':'');
}

let iconColors = [
  '#5A8770',
  '#B2B7BB',
  '#6FA9AB',
  '#F5AF29',
  '#0088B9',
  '#F18636',
  '#D93A37',
  '#A6B12E',
  '#5C9BBC',
  '#F5888D',
  '#9A89B5',
  '#407887',
  '#9A89B5',
  '#5A8770',
  '#D33F33',
  '#A2B01F',
  '#F0B126',
  '#0087BF',
  '#F18636',
  '#0087BF',
  '#B2B7BB',
  '#72ACAE',
  '#9C8AB4',
  '#5A8770',
  '#EEB424',
  '#407887']

function generateIconColor(identifier) {
  let thenums = identifier.match(/\d+/)
  if (thenums > 0) {
    return iconColors[parseInt(thenums[0]) % iconColors.length]
  }
  else
    return iconColors[0]
}

export {
  sanitize, downloadFile, sanitizeTag, parseTag, Tag, units,
  refactor_number_with_unit, refactor_unit_to_number, round_decimal,
  setDictValue, status_to_variant, build_resource_list_params, truncateWithEllipses, iconColors, generateIconColor
}

