<template>
  <div class="form-group row" v-show="!hidden">
    <label class="col-sm-3 col-form-label" :for="fieldId">{{ name }} <i v-if="!required" style="color: var(--lade-color-grey);"> -
      Optional </i></label>
    <div class="col-sm-9">
      <div class="flex input-group">
        <DatePicker
          ref='datetimepicker'
          mode="datetime"
          @input="updateValue()"
          v-model="date">
          <template v-slot="{ inputValue, inputEvents }">
            <input
              class="py-2 px-4"
              :value="inputValue"
              v-on="inputEvents"/>
          </template>
        </DatePicker>
        <b-button class="py-2 px-4 input-group-text input-group-append"
                  @click="date = null"
                  :disabled="!date"
                  squared
                  v-if="!required">
          Clear
        </b-button>
      </div>
      <small :id="helpId" class="form-text text-muted">{{ help }}</small>
    </div>
  </div>
</template>

<script>
import {capitalize} from "@/filters"
import {DatePicker} from 'v-calendar'
import moment from "moment";

export default {
  name: "InputDatetime",
  components: {
    DatePicker
  },
  props: {
    value: String,
    prefix: String,
    name: String,
    required: Boolean,
    disabled: Boolean,
    pattern: String,
    help: String,
    hidden: Boolean,
    placeholder: String
  },
  data() {
    return {
      fieldId: this.prefix + capitalize(this.name),
      helpId: this.prefix + capitalize(this.name) + "Help",
      placeholder_: this.placeholder != null ? this.placeholder : capitalize(this.name),
      date: null
    }
  },
  methods: {
    updateValue() {
      this.$emit('input', this.getValue())
    },
    getValue() {
      if (this.date){
        return moment(this.date).format('YYYY-MM-DDTHH:mm')
      }
    },
  }
}
</script>
