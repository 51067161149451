<template>
  <b-container>
    <b-row>
      <b-col cols="8" md="2">
        <div class="p-0">
          <div class="bg-green text-center">
            <img src="@/assets/AIRBUS_WHITE.png" class="logo" />
          </div>
          <div class="text-center text-blue text-2xl tracking-wide py-2">
            CyberSecurity
          </div>
        </div>
      </b-col>
    </b-row>

    <b-row class="text-grey my-4">
      <b-col md="3" class="my-2">
        <div class="font-weight-bold">FRANCE</div>
        Metapole 1, boulevard Jean Moulin<br />
        CS 40001 / 78996 Elancourt Cedex<br />
        France
      </b-col>
      <b-col md="3" class="my-2">
        <div class="font-weight-bold">GERMANY</div>
        Willy-Messerschmitt-Str. 1<br />
        82024 Taufkirchen<br />
        Germany
      </b-col>
      <b-col md="3" class="my-2">
        <div class="font-weight-bold">UNITED KINGDOM</div>
        Quadrant House / Celtic Springs<br />
        Coedkernew / South Wales<br />
        NP10 8FZ / United Kingdom
      </b-col>
      <b-col md="3" class="my-2 p-2">
        <div>
          <a href="https://twitter.com/AirbusCyber" target="_blank">
            <svg
              class="svg"
              width="50"
              height="50"
              fill="#9ad056"
              viewBox="0 0 16 16"
              style="margin-left: 15px"
            >
              <path
                d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z"
              />
            </svg>
          </a>
        </div>
        <div>
          <a href="https://fr.linkedin.com/company/airbuscyber" target="_blank">
            <svg
              class="svg"
              width="50"
              height="50"
              fill="#9ad056"
              viewBox="0 0 16 16"
              style="margin-left: 100px"
            >
              <path
                d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z"
              />
            </svg>
          </a>
        </div>
      </b-col>
    </b-row>

    <b-row class="my-5">
      <b-col class="text-grey">
        This document is not contractual. Subject to change without notice. ©
        2022 Airbus CyberSecurity. AIRBUS, its logo and the product names are
        registered trademarks. All right reserved.
      </b-col>
      <b-col class="text-xl">
        <div class="text-blue font-weight-bold py-1">
          contact.cybersecurity@airbus.com
        </div>
        <div>
          <a
            href="https://www.airbus-cyber-security.com"
            target="_blank"
            class="text-blue font-weight-bold py-1"
          >
            www.airbus-cyber-security.com
          </a>
        </div>
      </b-col>
    </b-row>

    <b-container>
      <b-row>
        <b-col>
          <svg width="300" height="25" class="svg">
            <rect x="0" y="0" width="100" height="25" style="fill: #dbedc4" />
            <rect x="100" y="0" width="100" height="25" style="fill: #c2e299" />
            <rect x="200" y="0" width="100" height="25" style="fill: #9ad056" />
          </svg>
        </b-col>
      </b-row>
    </b-container>
  </b-container>
</template>

<script>
import AirbusLogo from "@/components/hub/landing/AirbusLogo";

export default {
  name: "Footer",
  components: { AirbusLogo },
};
</script>

<style scoped>
a:hover {
  background-color: var(--lade-green-explore-3);
}
</style>
