<template>
  <div class="dropzone w-100"
       :class="{ dropzonedisabled: disabled, dragging: dragging }">
    <input type="file"
           name="dropzoneFile"
           :disabled="disabled"
           @change="dragging=false; $emit('input', $event.target.files)"
           multiple
           @dragover="dragging=true"
           @dragend="dragging=false"
           @dragleave="dragging=false"
           :accept="accept"/>
    <label for="dropzoneFile" id="dropzoneFile">
      <span class="w-100" v-if="disabled">{{ disabledText }}</span>
      <span class="w-100" v-else-if="dragging">Drop file</span>
      <span class="w-100" v-else>{{ text }}</span>
    </label>
  </div>
</template>
<script>
export default {
  name: "Dropzone",
  props: {
    disabled: Boolean,
    accept: String,
    text: {type: String, default: "Select a file or drop one"},
    disabledText: {type: String, default: "Select a Label"}
  },
  data() {
    return {
      dragging: false
    }
  }
}
</script>

<style scoped>
.dropzone {
  border: 2px dashed lightgray;
  position: relative;
  display: inline-block;
  width: 100%;
  height: 200px;
  text-align: center;
}

.dragging {
  border-color: #777;
}

.dropzone input[type="file"] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  overflow: hidden;
  opacity: 0;
  border: 1px dashed var(--lade-color-white);
}

.dropzone label {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  overflow: hidden;
  cursor: pointer;
  border-radius: 4px;
  display: flex;
  align-items: center;
}

.dropzone span {
  display: block;
  font-size: 1.4rem;
  color: var(--lade-color-grey);
}

.dropzonedisabled {
  background-color: var(--lade-color-white);
}
</style>
