<template>
  <div>
    <b-row>
      <b-col md="4" class="px-2">
        <Objective
          title="Practical Training"
          :targets="[
            {
              highlight: 'Learn good practices',
              rest: 'in basic attack prevention to raise awareness with all audiences',
            },
            {
              highlight: 'Train your team',
              rest: 'with cyber skills or academic courses',
            },
            {
              highlight: 'Create operational exercises',
              rest: 'close to daily environments',
            },
            {
              highlight: 'Manage your company and your systems',
              rest: 'in a crisis scenario to test your process',
            },
          ]"
        >
          <template #image>
            <img src="@/assets/registry/consultancy.png" height="100" />
          </template>
        </Objective>
      </b-col>

      <b-col md="4" class="px-2">
        <Objective
          title="Operational Qualification"
          :targets="[
            {
              highlight: 'Evaluate the impact of integrating new equipment,',
              rest: 'new rules or applying a security patch on a system',
            },
            {
              highlight: 'Analyse the behaviour of cyber-attacks',
              rest: 'on our infrastructure without taking any risks',
            },
            {
              highlight: 'Ensure maximum security',
              rest: 'to avoid production shutdown during a cyber-attack',
            },
            {
              highlight: 'Reassure your customers',
              rest: 'about your cyber protection',
            },
            {
              highlight: 'Validate certifications',
              rest: 'and ensure secure connections',
            },
          ]"
        >
          <template #image>
            <img src="@/assets/registry/services.png" height="100" />
          </template>
        </Objective>
      </b-col>

      <b-col md="4" class="px-2">
        <Objective
          title="Production Testing"
          :targets="[
            {
              highlight: 'Launch a pre-production evaluation',
              rest: 'of the impact of adding the selected product with the need to recreate a hyper realistic environment',
            },
            {
              highlight: 'Evaluate your product',
              rest: 'before final selection',
            },
            {
              highlight: 'Recruit new people',
              rest: 'with exams',
            },
            {
              highlight: 'Explore new security equipment',
              rest: 'and procedures in a realistic environment',
            },
          ]"
        >
          <template #image>
            <img src="@/assets/registry/production.png" height="100" />
          </template>
        </Objective>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Objective from "@/components/hub/landing/Objective";

export default {
  name: "Presentation",
  components: { Objective },
};
</script>
