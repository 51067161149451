<template>
  <b-container></b-container>
</template>

<script>
import store from '../../store'

export default {
  name: "LogoutView",
  created() {
    store.dispatch('token/logout').then(() => {
      document.location.replace(document.location.origin)
    })
  }
}
</script>
