import Vue from 'vue'
import Config from '../../Config'
import {field_actions} from './fields'
import {setDictValue} from "@/common";

const state = {
  list: [],
  groupList: {},
  limit: 15,
  skip: 0,
  pagination: {
    page: 1,
    total_pages: 0,
    total: 0,
  },
  _lookup: {},
  fields: [
    {"key": "identifier"},
    {"key": "name"},
    field_actions
  ],
  filters: {
    isDeletable: (entry) => false,
    isEditable: (entry) => true,
    isExportable: (entry) => false,
    isDownloadable: (entry) => false
  }
}

const getters = {
  get: (state) => (identifier) =>
    state._lookup[identifier],
  getGroup: (state) => (groupIdentifier) =>
    state.groupList[groupIdentifier],
  getAll: (state) => () =>
    state.list,
  getFilters: (state) => () =>
    state.filters,
  getFields: (state) => () =>
    state.fields
}

const actions = {
  loadAll({dispatch, commit}) {
    return Vue.http.get(Config.API_WORKZONES_URL).then((response) => {
      let workzones = response.data
      commit('setAll', workzones)
    })
  },
  loadGroup({dispatch, commit}, groupIdentifier) {
    return dispatch("loadAll").then(() => {
      return Vue.http.get(Config.API_GROUPS_URL + '/' + groupIdentifier + "/workzones").then((response) => {
        let workzones = response.data
        commit('setGroup', {groupIdentifier, workzones})
      })
    })
  },
  load({dispatch, commit}, identifier) {
    return Vue.http.get(Config.API_WORKZONES_URL + '/' + identifier).then((response) => {
      let workzone = response.data
      commit('set', {identifier, workzone})
    })
  },
  search({dispatch, commit}, {search, filters = {}, params = {}}) {
    return dispatch('search/workzone', {search, filters}, {root: true}).then((resources) => {
      commit('setAll', resources)
    })
  },
  update({dispatch, commit}, {identifier, data}) {
    return Vue.http.put(Config.API_WORKZONES_URL + '/' + identifier, data).then((response) => {
      return dispatch('load', identifier)
    })
  }
}

const mutations = {
  setAll(state, workzones) {
    state.list.splice(0, state.list.length)
    state.limit = workzones.limit
    state.skip = workzones.skip
    state.pagination.page = workzones.page
    state.pagination.total_pages = workzones.total_pages
    state.pagination.total = workzones.total
    for (let workzone of workzones.data) {
      state.list.push(workzone)
      setDictValue(state._lookup, workzone.identifier, workzone)
    }
  },
  set(state, {identifier, workzone}) {
    let workzones = state.list
    state._lookup[workzone.identifier] = workzone
    for (let i = 0; i < workzones.length; i++) {
      let c_workzone = workzones[i];
      if (c_workzone.identifier === identifier) {
        Vue.set(state.list, i, workzone)
        break
      }
    }
  },
  setGroup(state, {groupIdentifier, workzones}) {
    if (state.groupList[groupIdentifier] !== undefined)
      state.groupList[groupIdentifier].splice(0, state.groupList[groupIdentifier].length)
    else
      state.groupList[groupIdentifier] = []

    for (let workzone of workzones)
      state.groupList[groupIdentifier].push(state._lookup[workzone])
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true
}
