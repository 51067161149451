<template>
  <div>
    <b-modal ref="viewFormModal" id="viewFormModal" hide-footer>

      <template v-slot:modal-title>
        <Icon :icon="icon"/>
        {{ title }}
      </template>

      <table style="width: 100%;">
        <tr v-for="key in printableKeys" :key="key">
          <td><strong>{{ key | capitalize }} </strong></td>
          <td style="padding-left: .25em;">{{ topology[key] }}</td>
        </tr>
      </table>
    </b-modal>
    <b-modal size="lg" ref="addFormModal" id="addFormModal" hide-footer>
      <template v-slot:modal-title>
        <Icon :icon="icon" style="margin-right:10px;"/>
        {{ title }}
      </template>
      <form v-on:submit.prevent="onAddEdit">
        <div class="card">
          <div class="card-header">
            Attributes
          </div>
          <div class="card-body">
            <InputText v-if="edit" prefix="Category" name="identifier" v-model="identifier" required
                       disabled></InputText>

            <div class="form-group row">
              <label class="col-sm-3 col-form-label" for="Bundle">Bundle</label>
              <div class="col-sm-9">
                <TypeAhead prefix="Category" id="Bundle" resourceType="bundle" v-model="bundle" class="mb-2"
                           @hit="bundleIdentifier=$event" :disabled="edit"/>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-sm-3 col-form-label" for="Bundle">Parent</label>
              <div class="col-sm-9">
                <TypeAhead prefix="Category" id="parentCategory" resourceType="category"
                           :value="parentCategory?parentCategory.alias:null" class="mb-2" @hit="parentIdentifier=$event"
                           :filters="{bundleIdentifier: bundleIdentifier}" :disabled="bundleIdentifier === null"/>
              </div>
            </div>
            <InputText prefix="Category" name="Alias" v-model="alias" required
                       pattern="[a-zA-Z0-9\-_]{3,50}"></InputText>
            <InputText prefix="Category" name="name" v-model="name"
                       help='Alias will be used if field is left empty'></InputText>
            <InputImage prefix="Category" name="icon" @input="(file) => {icon_updated = file}"/>
            <TextAreaForm ref="description" prefix="Category" name="description" :rows="2"
                          v-model="description"></TextAreaForm>
            <TextAreaForm ref="attributes" prefix="Category" name="attributes" :rows="5" v-model="attributes"
                          :json="true"></TextAreaForm>
            <button type="submit" class="btn btn-primary">Submit</button>
          </div>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import store from '../../store'
import InputText from '../form/InputText'
import TypeAhead from '../form/TypeAhead'
import TextAreaForm from '../form/TextAreaForm'
import {print_tag} from '@/filters'
import InputImage from '../form/InputImage'
import Icon from '../form/Icon'

export default {
  name: 'CategoryListForm',
  components: {InputImage, InputText, TextAreaForm, TypeAhead, Icon},
  created() {

    this.$eventBus.$off('view-category-show')
    this.$eventBus.$on("view-category-show", (identifier) => {
      this.reset("Category")
      this.printableKeys = ["tag", "identifier", "bundle", "name", "description", "attributes"]
      this.$refs.viewFormModal.show()
    })

    this.$eventBus.$off('add-category-show')
    this.$eventBus.$on("add-category-show", (bundleIdentifier) => {
      this.reset("Add category")
      if (this.$refs.specs) this.$refs.specs.$emit('reset')
      if (this.$refs.attributes) this.$refs.attributes.$emit('reset')
      if (this.$refs.description) this.$refs.description.$emit('reset')
      if (bundleIdentifier !== undefined) {
        this.bundleIdentifier = bundleIdentifier
        this.bundle = store.getters['bundles/get'](bundleIdentifier).tag
      }
      this.$refs.addFormModal.show()
    })

    this.$eventBus.$off('edit-category-show')
    this.$eventBus.$on("edit-category-show", (identifier) => {
      this.reset("Edit category")
      let category = store.getters['categories/get'](identifier)
      this.identifier = category.identifier
      this.alias = category.alias
      this.tag = category.tag
      this.name = category.name
      this.icon = category.icon
      this.parentIdentifier = category.category_identifier
      this.parentCategory = store.getters['categories/get'](this.parentIdentifier)
      if (category.description == null) {
        this.description = ""
      } else {
        this.description = category.description
      }
      this.attributes = category.attributes
      this.bundleIdentifier = category.bundle_identifier
      this.edit = true

      if (this.$refs.specs) this.$refs.specs.$emit('reset', this.specs)
      if (this.$refs.attributes) this.$refs.attributes.$emit('reset', this.attributes)
      if (this.$refs.description) this.$refs.description.$emit('reset', this.description)

      let bundleIdentifier = this.bundleIdentifier
      if (!store.getters['bundles/get'](bundleIdentifier)) {
        store.dispatch('bundles/load', bundleIdentifier).then((values) => {
          this.bundle = store.getters['bundles/get'](bundleIdentifier).tag
          this.$refs.addFormModal.show()
        })
      } else {
        this.bundle = store.getters['bundles/get'](bundleIdentifier).tag
        this.$refs.addFormModal.show()
      }
    })

    this.$eventBus.$off('delete-category-show')
    this.$eventBus.$on("delete-category-show", (identifier) => {
      let category = store.getters['categories/get'](identifier)
      this.$deleteModal({
        title: "Delete category",
        text: "Delete category " + print_tag(false, false, category) + " ?"
      }).then((result) => {
        if (result.value) {
          store.dispatch('categories/delete', identifier).then(() => {
            this.$eventBus.$emit('success', 'The category has been deleted')
          }).catch((response) => {
            this.$eventBus.$emit('http-error', response)
          })
        }
      });
    })
  },
  data() {
    return {
      identifier: null,
      category: null,
      printableKeys: {},
      alias: null,
      tag: null,
      name: null,
      description: null,
      attributes: {},
      title: "",
      edit: false,
      bundle: null,
      bundleIdentifier: null,
      icon: {},
      icon_updated: null,
      parentIdentifier: null,
      parentCategory: null
    }
  },
  methods: {
    reset(title) {
      this.title = title
      this.edit = false
      this.identifier = null
      this.alias = null
      this.name = null
      this.description = null
      this.bundle = null
      this.bundleIdentifier = null
      this.attributes = {}
      this.icon_updated = null
      this.icon = null
      this.parentIdentifier = null
      this.parentCategory = null
    },
    onAddEdit() {

      let data = {
        "alias": this.alias,
        "name": this.name,
        "command": this.command,
        "description": this.description,
        "attributes": this.attributes,
        "icon": this.icon_updated,
        "category_identifier": this.parentIdentifier
      }

      if (this.edit) {
        store.dispatch('categories/update', {
          bundleIdentifier: this.bundleIdentifier,
          identifier: this.identifier,
          data: data
        }).then(() => {
          this.$refs.addFormModal.hide()
          this.$eventBus.$emit('success', 'The category has been updated')
        }).catch((response) => {
          this.$eventBus.$emit('http-error', response)
        })
      } else {
        store.dispatch('categories/add', {bundleIdentifier: this.bundleIdentifier, data: data}).then(() => {
          this.$refs.addFormModal.hide()
          this.$eventBus.$emit('success', 'The category has been created')
        }).catch((response) => {
          this.$eventBus.$emit('http-error', response)
        })
      }
    },
    onDelete() {
      store.dispatch('categories/delete', {
        bundleIdentifier: this.bundleIdentifier,
        identifier: this.identifier
      }).then(() => {
        this.$refs.deleteFormModal.hide()
        this.$eventBus.$emit('success', 'The category has been deleted')
      }).catch((response) => {
        this.$eventBus.$emit('http-error', response)
      })
    }
  },
}
</script>

<style>

</style>
