<template>
  <div class="sectionEditGroup">
    <div class="content">
      <div class="head-content">
        <h2>
          Settings
        </h2>
        <div class="menu-edit top">
          <b-spinner v-if="loading" class="loading top"></b-spinner>
          <button
              v-if="!loading"
              @click.stop="clickResetEdit"
              class="btn button btn-secondary">
              Cancel
          </button>
          <button
              v-if="!loading"
              @click.stop="clickValidateEdit"
              type="submit"
              class="btn btn-primary">
              Submit
          </button>
        </div>
      </div>
      <div class="edit">
        <div class="name-id">
          <div class="edit-name">
            <label class="label">NAME</label>
            <input class="group-name" v-model="inputName" pattern="^[a-zA-Z0-9\-_ ]{3,50}"/>
          </div>
          <div class="visibility" v-if="this.group !== undefined">
            <div class="edit-visibility">
              <label class="label">VISIBILITY</label>
              <VisibilityBadge class="visibility-badge" :visibility="visibility"/>
              <b-form-select v-model="visibility" value-field="id" text-field="name"
                             :options="visibilityOptions" class="group-visibility" name="OS Type" prefix="HostSpec"/>
            </div>
          </div>
        </div>
        <div class="icon-size-visibility">
          <div class="icon-size" v-if="this.group !== undefined">
            <div class="edit-icon-group">
              <label class="label">ICON</label>
              <div class="icon-group">
                <img
                    v-if="group.icon && group.icon.url"
                    class="logo"
                    :src="group.icon.url"
                    alt="Group logo"
                />
                <div v-else class="no-icon">
                  <div
                      class="generated-icon"
                      :style="{'background-color': generateIconColor(group)}">
                    <div class="text-icon eg">
                      {{ group.name[0].toUpperCase() }}
                    </div>
                  </div>
                </div>
              </div>
              <b-form-file ref="el"  :accept="accept" type="file" :id="imageId"
                           v-model="image" :required="false" :disabled="false"  class="import-img"/>
            </div>
            <div class="size">
              <label class="label">SIZE</label>
              <div class="size-legend">
                <font-awesome-icon class='fa-icon' :icon="['fas', 'database']" alt="Size"/>
                <span v-if="group.content_size > 0">
                    {{ group.content_size | print_human_readable_size }}
                  </span>
                <span v-else-if="group.limit_content_size === 0">
                    Group cannot have content. <i>(limited)</i>
                  </span>
                <span v-else>
                    No Content
                  </span>
                <span v-if="group.limit_content_size > 0">/ {{
                    group.limit_content_size | print_human_readable_size
                  }}
                </span>
              </div>
              <CapacityChart :chart-data="capacitydata" :options="chart_size_options" class="capacity-chart"
                             v-if="(group.limit_content_size > 0) && (group.content_size >= 0) || (limitEdit > 0) && (group.content_size >= 0)"/>
              <div>
                <div class="edit-size">
                  <div>
                    <div>
                      <input type="checkbox"
                             :checked="limit_content_size_number>=0" @click.stop="toggleLimit()">
                    </div>
                  </div>
                  <input type="number" min='-1' step="any" v-model="limit_content_size_number"
                         :disabled="limit_content_size_number===-1"
                         autocomplete="off" id="GroupLimit" aria-describedby="GroupLimitHelp" class="form-control">
                  <b-form-select v-model="limit_content_size_unit_selected" :disabled="limit_content_size_number===-1"
                                 :options="unit_options" class="form-control" id="GroupLimitUnit"/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="description">
          <label class="label">DESCRIPTION</label>
          <div class="edit-description">
            <form v-if="this.updateDescription">
              <b-form-group description="Please write your group description following community rules.">
                <editor v-if="this.description === this.group.description" class="editor-description" height="600px" id="input-description"
                        ref="descriptionEditor" initialEditType="wysiwyg" :initialValue="this.description"/>
              </b-form-group>
            </form>
          </div>
          <div class="menu-edit bottom">
            <b-spinner v-if="loading" class="loading bottom"></b-spinner>
            <button
                v-if="!loading"
                @click.stop="clickResetEdit"
                class="btn button btn-secondary">
              Cancel
            </button>
            <button
                v-if="!loading"
                @click.stop="clickValidateEdit"
                type="submit"
                class="btn btn-primary">
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import VisibilityBadge from "@/components/VisibilityBadge.vue";
import CapacityChart from "@/components/groups/CapacityChart.vue";
import {print_human_readable_size} from "@/filters";
import store from "@/store";
import {generateIconColor, refactor_number_with_unit, refactor_unit_to_number, units} from "@/common";

export default {
  name:"editGroupBundleSettings",
  components: {CapacityChart, VisibilityBadge},
  props:{
    group: Object,
    accept: {
      default: "image/*",
      type: String,
    },
  },
  data: function (){
    return{
      loading: false,
      inputName: null,
      visibility: "",
      visibilityOptions: [
        {id: "private", name: 'Private: Restricted'},
        {id: "internal", name: 'Internal: Visible to all connected users'},
        {id: "public", name: 'Public: Visible to all users, even disconnected'},
      ],
      imageId: "Icone",
      image: undefined,
      content_size: 0,
      limit_content_size: 0,
      percent: 0,
      content_size_number: 0,
      content_size_unit: units.Bytes,
      limit_content_size_number: 0,
      limit_content_size_unit_selected: units.Bytes,
      description: null,
      updateDescription: false,
      chart_size_options: {
        legend: {
          display: false
        },
        tooltips: {
          callbacks: {
            title: function (tooltipItem, data) {
              return data['labels'][tooltipItem[0]['index']]
            },
            label: function (tooltipItem, data) {
              return print_human_readable_size(data['datasets'][0]['data'][tooltipItem['index']])
            },
          },
          backgroundColor: 'white',
          titleFontSize: 16,
          titleFontColor: 'black',
          bodyFontColor: 'black',
          bodyFontSize: 14,
          displayColors: false
        },
      },
    }
  },
  computed: {
    unit_options: () => Object.values(units),
    limitEdit() {
      return refactor_unit_to_number(this.limit_content_size_number, this.limit_content_size_unit_selected);
    },
    capacitydata() {
      if(this.limitEdit > 0) {
        return {
          datasets: [{
            data: [this.group.content_size,
              Math.max(this.limitEdit - this.group.content_size, 0)],
            backgroundColor: ['indianred', 'lightgreen']
          }],
          labels: ['Used', 'Free']
        }
      }else{
        return {
          datasets: [{
            data: [this.group.content_size,
              Math.max(this.group.limit_content_size - this.group.content_size, 0)],
            backgroundColor: ['indianred', 'lightgreen']
          }],
          labels: ['Used', 'Free']
        }
      }
    }
  },
  mounted: function () {
    this.clickResetEdit()
  },
  methods: {
    toggleLimit() {
      this.limit_content_size_number >= 0 ? this.limit_content_size_number = -1 : this.limit_content_size_number = 0
    },
    generateIconColor(group) {
      return generateIconColor(group.identifier);
    },
    async clickResetEdit() {
      this.updateDescription = false
      await this.$nextTick()
      this.description = this.group.description
      this.updateDescription = true
      this.inputName = this.group.name
      this.visibility = this.group.visibility
      this.image = null
      this.limit_content_size = this.group.limit_content_size
      let result = refactor_number_with_unit(this.group.limit_content_size)
      this.limit_content_size_unit_selected = result.unit
      this.limit_content_size_number = result.number

    },
    async clickValidateEdit() {
      let data = {};
      if(this.inputName !== this.group.name){
        data['name'] = this.inputName;
      }if(this.visibility !== this.group.visibility) {
        data['visibility'] = this.visibility;
      }if(this.image !== null) {
        data['icon'] = this.image;
      }if(this.limit_content_size_number !== 0) {
        data["limit_content_size"] = refactor_unit_to_number(this.limit_content_size_number, this.limit_content_size_unit_selected);
      }
      data['description'] = this.$refs.descriptionEditor.invoke('getMarkdown')
      this.loading = true;
      await store.dispatch('groups/update', {identifier: this.group.identifier, data: data}).then(() => {
        this.$eventBus.$emit('success', 'The groups has been updated')
      }).catch((response) => {
        this.$eventBus.$emit('http-error', response)
      })
      this.loading = false;
      this.clickResetEdit();
    }
  }
}
</script>

<style scoped>
.content .label{
  font-size: 0.9em;
  color: var(--lade-color-grey);
}

.edit {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow-y: auto;
  position: relative;
  width: 100%;
  padding: 15px 15px 105px 15px;
}

input {
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}

.name-id{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 20px 10px 15px 10px;
  border: solid 1px var(--lade-color-white);
  border-radius: 10px 10px;
  position: relative;
}

.edit-name {
  width: min-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
  margin: 0 0 0 27px;
}

.edit-name p,
.edit-name input {
  font-size: 1.3em;
  width: 250px;
}

.edit-name input {
  margin: 7px 0;
  height: 23px;
  display: block;
  padding: 7px 5px 7px 5px;
}

.menu-edit.top {
  position: absolute;
  top: 15px;
  right: 20px;
  border: none;
}

.menu-edit.bottom {
  position: absolute;
  bottom: 10px;
  right: 20px;
}

.menu-edit{
  position: relative;
}

.button:hover {
  color: var(--lade-color-grey);
  background-color: var(--lade-color-white);
  border-color: var(--lade-color-dark-grey);
}

.button:focus {
  color: var(--lade-color-white);
  background-color: var(--lade-color-grey);
  border-color: var(--lade-color-dark-grey);
  box-shadow: none;
}

.button:active {
  color: var(--lade-color-white) !important;
  background-color: var(--lade-color-grey) !important;
  border-color: var(--lade-color-dark-grey) !important;
  box-shadow: none !important;
}

.loading{
  width: 20px;
  height: 20px;
  position: absolute;
}

.loading.top {
  right: 20px;
  top: 5px;
}

.loading.bottom {
  right: 20px;
  bottom: 10px;
}

.label{
  position: absolute;
  top: -7px;
}

.visibility{
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  position: relative;
}

.visibility .label {
  position: absolute;
  top: -18px;
  left: 25px;
}

.edit-visibility {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.edit-visibility .visibility-badge{
  padding-right: 10px;
}

.edit-visibility select{
  width: 65%;
  display: block;
}

.edit-visibility p {
  margin-top: 10px;
}

.icon-size-visibility{
  width: 100%;
  margin-top: 15px;
  padding: 25px 10px 15px 10px;
  border: solid 1px var(--lade-color-white);
  border-radius: 10px 10px;
  position: relative;
}

.icon-size {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.icon-size .label{
  position: absolute;
  top: -15px;
}
.edit-icon-group .label{
  top: -17px;
}

.sectionEditGroup .edit-icon-group {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  position: relative;
}

.sectionEditGroup .edit-icon-group .icon-group{
  overflow: hidden;
  border-radius: 10px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.sectionEditGroup .edit-icon-group .icon-group .no-icon{
  width: 40%;
  max-width: 150px;
}

.generated-icon {
  /*padding-left: 0;*/
  width: 100% !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px 10px;
}

.icon-group .logo {
  width: 40%;
  max-width: 150px;
  height: max-content;
  margin: 0 0 auto;
  object-fit: scale-down;
  border-radius: 10px 10px;
}

.import-img {
  width: 55%;
}

.size {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  justify-content: space-between;
  height: 100%;
  position: relative;
}

.capacity-chart {
  width: 25%;
  text-align: center;
  margin-bottom: 2%;
}

.edit-size {
  z-index: 1;
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
}

.description {
  width: 100%;
  margin-top: 15px;
  padding: 50px 12px 12px 12px;
  border: solid 1px var(--lade-color-white);
  border-radius: 10px 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 0;
}

.description .label {
  position: absolute;
  top: 15px;
}

.edit-description {
  width: 100%;
}

.editor-description {
  width: 100% !important;
  position: relative;
}
</style>
