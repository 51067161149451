<template>
  <PanelView>
    <template v-slot:sidebar>
      <BundleListForm
        addEvent="add-bundle-show"
        editEvent="edit-bundle-show"
        deleteEvent="delete-bundle-show"/>
      <b-card no-body header="Bundles" style="height:100%;">
        <template v-slot:header>
          <div class="container" style="padding: 0;">
            <!-- title -->
            <div class=row style="display: flex; justify-content: space-evenly; flex-wrap: wrap">
              <div style="margin: 0.375rem 0; white-space: nowrap; font-size: 1.2em">
                Bundles
              </div>
            </div>
            <!-- search -->
            <div class=row>
              <div class="col" style="margin: 0.350rem auto;">
              <input type="text" v-model="search" placeholder="Search" class="search" @keyup="filter"
                     style=" margin-top: 0; padding: 2px; height:20px; width: 100%"/>

              </div>
            </div>
            <!-- pagination -->
            <div class="row">
              <div class="pagination">
                <b-button :class="[page > 1 ? 'page-chevron-active' : 'page-chevron-deactivated', 'page-chevron']"
                          @click="previous_page()">
                  <font-awesome-icon :icon="['fas', 'chevron-left']"/>
                </b-button>
                <span class="pagination-text">
                    <b-button class="pagination-button">
                    {{ skip + 1 }} - {{ Math.min(skip + limit, total) }} on {{ total }}
                    </b-button>
                    <div class="page-options">
                      <div style="text-align: center; color: #5f6368; font-weight: 500; user-select: none; ">
                        Order By
                      </div>
                      <div style="display: flex; justify-content: center; flex-wrap: wrap">
                        <b-button v-for="item in sortOptions"
                                  :key="item.text"
                                  @click.up="updateSort(item)"
                                  :class="[isActiveSortOption(item) ? 'page-option-active': '', 'page-option']">
                          {{ item.text }}
                        </b-button>
                      </div>
                      <div style="text-align: center; color: #5f6368; font-weight: 500; user-select: none; ">
                        Display up to
                      </div>
                      <div style="display: flex; justify-content: center; flex-wrap: wrap">
                        <b-button v-for="lim in limitOptions"
                                  :key="lim"
                                  @click.up="updateLimit(lim)"
                                  :class="[lim === limit? 'limit-selected': '', 'page-option']">
                          {{ lim }}
                        </b-button>
                      </div>
                    </div></span>
                <b-button :class="[page < total_pages ? 'page-chevron-active' : 'page-chevron-deactivated', 'page-chevron']"
                          @click="next_page()">
                  <font-awesome-icon :icon="['fas', 'chevron-right']" />
                </b-button>

                <b-button class="limit-button"
                          @click="search='' ; page=1 ; get_bundles()">
                  <font-awesome-icon :icon="['fas', 'sync']"/>
                </b-button>
                <b-button class="limit-button" v-if="canAddBundle()"
                          @click="$eventBus.$emit('add-bundle-show')">
                  <font-awesome-icon :icon="['fas', 'plus']"/>
                </b-button>

              </div>
            </div>
          </div>
        </template>
        <b-list-group flush class="bundlelist">
          <b-list-group-item button @click="selectBundle('all')" :active="currentBundleIdentifier==='all'">
            All content
          </b-list-group-item>
          <b-list-group-item button
                             v-for="bundle in bundles" :key="bundle.identifier" @click="selectBundle(bundle.identifier)"
                             :active="currentBundle && currentBundleIdentifier === bundle.identifier">
            {{ printTag(bundle.tag) }}
          </b-list-group-item>
        </b-list-group>
      </b-card>
    </template>

    <template v-slot:panel>
      <div v-if="showPanel" style="background-color: var(--lade-color-white-hover)">
        <div v-if="currentBundle && currentBundleIdentifier !== 'all'">
          <div class="bundle-title"><span style="font-size:1.4em;">Bundle {{
              currentBundle.alias
            }}:{{ currentBundle.qualifier }}</span>
            <b-link style="color: var(--lade-color-black)" :to="{
                name: 'bundlePage',
                params: { identifier: currentBundle.identifier,
                  previousPage: {
                    text: 'Bundles', to: {name: 'bundles', params: { identifier: currentBundle.identifier }}
                  }
                }
              }">
              <font-awesome-icon :icon="['fas', 'eye']" style="margin-left:5px; cursor: pointer; height:20px;"/>
            </b-link>


            <font-awesome-icon :icon="['fas', 'pencil-alt']"
                               @click.stop="$eventBus.$emit('edit-bundle-show', currentBundle.identifier)"
                               style="margin-left:5px; cursor: pointer; height:20px;"
                               v-if="isEditable(currentBundle)"/>

            <font-awesome-icon :icon="['fas', 'download']"
                               @click="$eventBus.$emit('export-bundle', currentBundle.identifier); search=''"
                               style="margin-left:5px; cursor: pointer; height:20px;"
                               v-if="isExportable(currentBundle)"/>

            <font-awesome-icon :icon="['fas', 'trash']"
                               @click.stop="$eventBus.$emit('delete-bundle-show', currentBundle.identifier)"
                               v-if="isDeletable(currentBundle)"
                               style="margin-left:10px; cursor: pointer; height:20px; color:indianred;"/>
          </div>
          <div class="bundle-description">
            <span class="bundleDescription"><vue-markdown :source="truncateText(currentBundle.description)"></vue-markdown></span>
            <span style="font-style:italic;"> Creation date: {{ currentBundle.created_at | print_date }} </span>
          </div>
          <div class="bundle-group" >
            Visibility:   <VisibilityBadge :visibility="currentBundle.visibility" />
            <br />
            Exportability:   <VisibilityBadge :visibility="currentBundle.exportability" />
            <br />
            <span> <font-awesome-icon :icon="['fas', 'users']"/> Group {{ currentBundle.group }}</span>
            <br />

            <font-awesome-icon class='fa-icon' :icon="['fas', 'hdd']" alt="Size"/>
            <span v-if="currentBundle.content_size > 0">
							{{ currentBundle.content_size | print_human_readable_size }}
						</span>
            <span v-else>
							No Content
						</span>
          </div>
        </div>
        <div v-else class="bundle-title">
          <span style="font-size:1.4em;">Content of all bundles </span>
        </div>
        <b-tabs class="viewtabs" ref="bundletabs" v-model="tabValue" @input="setRoute()">
          <b-tab title="Actions">
            <div v-if="tabValue===0">
              <ResourceList
                resource="actions"
                :searchFilters="searchFilter"
                :addEventParameter="bundleIdentifier"
                addIcon="plus"
                viewEvent="view-action-show"
                addEvent="add-action-show"
                editEvent="edit-action-show"
                deleteEvent="delete-action-show"
                exportEvent="export-action"
                sortBy="tag"
                resourceListForm="ActionListForm"
                autoLoad
                paginate/>
            </div>
          </b-tab>
          <b-tab title="Scenarios">
            <div v-if="tabValue===1">
              <ResourceList
                resource="scenarios"
                :searchFilters="searchFilter"
                :addEventParameter="bundleIdentifier"
                addIcon="plus"
                viewEvent="view-scenario-show"
                addEvent="add-scenario-show"
                editEvent="edit-scenario-show"
                deleteEvent="delete-scenario-show"
                exportEvent="export-scenario"
                sortBy="tag"
                resourceListForm="ScenarioListForm"
                autoLoad
                paginate/>
            </div>
          </b-tab>
          <b-tab title="Topologies">
            <div v-if="tabValue===2">
              <ResourceList
                resource="topologies"
                :searchFilters="searchFilter"
                :addEventParameter="bundleIdentifier"
                resourceListForm="TopologyListForm"
                viewEvent="view-topology-show"
                addEvent="add-topology-show"
                editEvent="edit-topology-show"
                exportEvent="export-topology"
                deleteEvent="delete-topology-show"
                downloadEvent="download-topology"
                sortBy="tag"
                subComponent="TopologySpecsForm"
                autoLoad
                paginate/>
            </div>
          </b-tab>
          <b-tab title="Files">
            <div v-if="tabValue===3">
              <ResourceList
                v-if="currentBundleIdentifier==='all'"
                resource="files"
                addIcon="plus"
                viewEvent="view-file-show"
                editEvent="edit-file-show"
                deleteEvent="delete-file-show"
                exportEvent="export-file"
                downloadEvent="download-file"
                sortBy="tag"
                resourceListForm="FileListForm"
                autoLoad
                paginate/>
              <FileTab v-else :bundleIdentifier="currentBundleIdentifier"></FileTab>
            </div>
          </b-tab>
          <b-tab title="Categories">
            <div v-if="tabValue===4">
              <ResourceList
                resource="categories"
                :searchFilters="searchFilter"
                :addEventParameter="bundleIdentifier"
                addIcon="plus"
                addEvent="add-category-show"
                viewEvent="view-category-show"
                editEvent="edit-category-show"
                deleteEvent="delete-category-show"
                sortBy="alias"
                resourceListForm="CategoryListForm"
                autoLoad>

                <template v-slot:cell(category_identifier)="row">
                  {{ printCategory(row.item.category_identifier) }}
                </template>

              </ResourceList>
            </div>
          </b-tab>
          <b-tab title="Share" v-if="currentBundleIdentifier!=='all'">
            <div v-if="tabValue===5">
              <BundleShare
                :bundleIdentifier="bundleIdentifier"/>
            </div>
          </b-tab>
        </b-tabs>
      </div>
    </template>
  </PanelView>
</template>

<script>
import Vue from 'vue'
import store from '../../store'
import BundleListForm from './BundleListForm.vue'
import PanelView from '../PanelView.vue'
import FileTab from './FileTab.vue'
import BundleShare from './../shares/BundleShares'

import {truncateWithEllipses, parseTag} from '@/common'
import VisibilityBadge from '../VisibilityBadge'
export default {
  name: "BundleList",
  components: {
    BundleListForm, PanelView, FileTab, VisibilityBadge, BundleShare
  },
  data: () => ({
    tabValue: 0,
    search: "",
    bundles: store.getters['bundles/getAll'](),
    filters: store.getters['bundles/getFilters'](),
    currentBundle: null,
    currentBundleIdentifier: null,
    bundleIdentifier: null,
    showPanel: false,
    searchFilter: {},
    resourceTabMapping: {
      0: 'actions',
      1: 'scenarios',
      2: 'topologies',
      3: 'files',
      4: 'categories',
      5: 'shares'
    },
    skip: store.getters['bundles/getSkip'](),
    total: store.getters['bundles/getTotal'](),
    page: store.getters['bundles/getPage'](),
    total_pages: store.getters['bundles/getTotalPages'](),
    limit: 15,
    showLimitOptions: false,
    limitOptions: [10, 15, 25, 50],
    sortOptions: [
      {orderBy: 'created_date', desc: false, text: 'Newest'},
      {orderBy: 'created_date', desc: true, text: 'Oldest'},
      {orderBy: 'download', desc: true, text: 'Download'},
      {orderBy: 'name', desc: true, text: 'Alphabetic'},
    ],
    showSortOptions: false,
    orderBy: 'created_date',
    desc: false
  }),
  mounted() {
    this.limit = 15
    this.selectTab(this.$route.params.resource)
    this.get_bundles()
  },
  methods: {
    canAddBundle(){
      return this.$store.getters["token/hasPermission"]("BUNDLE", "CREATE")
    },
    printCategory(categoryIdentfier) {
      let category = store.getters['categories/get'](categoryIdentfier)
      if (category) {
        return category.alias + " / " + category.name
      }
    },
    isEditable(entry) {
      if (entry.authz && (entry.authz.permission & 0x04) === 0) {
        return false;
      }
      return this.filters.isEditable(entry)
    },
    isDeletable(entry) {
      if (entry.authz && (entry.authz.permission & 0x08) === 0) {
        return false;
      }
      return this.filters.isDeletable(entry)
    },
    isExportable(entry) {
      if (entry.authz && (entry.authz.permission & 0x20) === 0) {
        return false;
      }
      return this.filters.isExportable(entry)
    },
    get_bundles() {
      this.$store.dispatch('bundles/loadAll', {
        orderBy: this.orderBy, desc: this.desc, labels: [], page: this.page,
        limit: this.limit, search: this.search, load: "meta"
      }).then(() => {
        this.selectBundle(this.$route.params.identifier)
        this.page = this.$store.getters['bundles/getPage']()
        this.total_pages = this.$store.getters['bundles/getTotalPages']()
        this.limit = this.$store.getters['bundles/getLimit']()
        this.skip = store.getters['bundles/getSkip']()
        this.total = store.getters['bundles/getTotal']()
      })
    },
    next_page() {
      if (this.page < this.total_pages) {
        this.page += 1
      }
      this.get_bundles()
    },
    previous_page() {
      if (this.page > 1) {
        this.page -= 1
      }
      this.get_bundles()
    },
    selectTab(tab) {
      switch (tab) {
        case "actions":
          this.tabValue = 0
          break;
        case "scenarios":
          this.tabValue = 1
          break;
        case "topologies":
          this.tabValue = 2
          break;
        case "files":
          this.tabValue = 3
          break;
        case "categories":
          this.tabValue = 4
          break;
        case "shares":
          this.tabValue = 5
          break;
        default:
          this.tabValue = 0
      }
    },
    setRoute() {
      let current_params = this.$router.history.current.params
      let bundle_identifier = this.currentBundleIdentifier
      let resource = this.resourceTabMapping[this.tabValue]
      if (current_params.identifier !== bundle_identifier || resource !== current_params.resource)
        this.$router.push({
          name: 'bundles', params: {
            identifier: bundle_identifier,
            resource: resource
          }
        })
    },
    selectBundle(bundle_identifier) {
      this.showPanel = false

      this.bundleIdentifier = bundle_identifier

      if (bundle_identifier === "all")
        this.bundleIdentifier = undefined

      //Set default to all
      if (!bundle_identifier)
        bundle_identifier = "all"

      this.currentBundleIdentifier = bundle_identifier
      this.setRoute()

      if (bundle_identifier !== "all") {
        this.searchFilter = {bundleIdentifier: bundle_identifier}
        store.dispatch('bundles/load', bundle_identifier).then(() => {
          this.currentBundle = store.getters['bundles/get'](bundle_identifier)
          this.showPanel = true
        })
      } else
        this.searchFilter = {}
      Vue.nextTick(() => {
        this.showPanel = true
      })

    },
    filter() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.page = 1
        this.get_bundles()
      }, 600);
    },
    updateSort(data) {
      this.showSortOptions = false
      if (!this.isActiveSortOption(data)) {
        this.orderBy = data.orderBy
        this.desc = data.desc
      }
      this.get_bundles()
    },
    isActiveSortOption(data) {
      return data.orderBy === this.orderBy && data.desc === this.desc
    },
    updateLimit(limit) {
      this.showLimitOptions = false
      if (limit !== this.limit) {
        this.limit = limit
        this.get_bundles()
      }
    },
    truncateText(text) {
      //delete markdown titles
      const regex = /[#]+ .+/ig
      return truncateWithEllipses(text.replaceAll(regex, " "), 500)
    },
    printTag(tag) {
      return parseTag(tag, true).print(false, true)
    }
  }
}
</script>

<style scoped>

.bundle-limit {
  padding-right: 30px;
  background-color: var(--lade-color-white-hover)
}

.groupactions {
  float: right
}

.bundle-title {
  padding-top: 10px;
  text-align: center;
  background-color: var(--lade-color-white-hover);
}

.bundle-group {
  font-size: 1.1em;
  margin-bottom: 2px;
  padding-left: 25px;
  padding-bottom: 10px;
}

.bundle-description {
  text-align: center;
  padding-left: 30px;
  padding-right: 30px;
  overflow-wrap: break-word;
}


.content {
  display: flex;
  flex-direction: column;
}

#bundle-selection {
  border: None;
  padding-right: 0;
}

.search {
  width: 100%;
}

.tabs {
  float: left;
  width: 100%;
}

.mainpanel {
  display: flex;
  height: 100%;
}

.action-icon {
  width: 13px;
  height: 13px;
  cursor: pointer;
}

.pagination-button {
  color: black !important;
  background-color: Transparent !important;
  border: none !important;
  border-radius: 3px;
}

.pagination-button:hover {
  background-color: rgba(28, 22, 22, 0.05) !important;
}

.pagination-button:focus {
  background-color: rgba(28, 22, 22, 0.05) !important;
  border: none !important;
  box-shadow: none ! important;
}

.page-options {
  white-space: normal;
  position: absolute;
  z-index: 101;
  list-style: none;
  overflow-y: auto;
  display: none;
  max-height: 150px;
  border: 0px !important;
  background-color: white !important;
  box-shadow: 0px 2px 4px black;
  border-radius: 3px;
  width: 250px;
}

.page-options button {
  background-color: white !important;
}

.pagination-text:hover .page-options {
  display: block;
}

.page-option {
  border: none !important;
  background-color: rgba(255, 255, 255, 0) !important;
}

.page-option:hover {
  background-color: rgba(28, 22, 22, 0.05) !important;
}

.page-option:focus {
  box-shadow: none ! important;
}

select {
  height: 100px;
  line-height: 25px;
  background: #f4f4f4;
}

select:focus {
  outline: none;
}

select[multiple] {
  height: 100px;
  line-height: 25px;
  background: #f4f4f4;
}

.bundle-description {
  color: grey;
  height: 100px;
  margin: 5px;
  overflow: hidden;
  -webkit-mask-image: linear-gradient(to bottom, black 60%, transparent 100%);
  mask-image: linear-gradient(to bottom, black 50%, transparent 100%);
}

.pagination {
  white-space: nowrap;
  display: inline-block;
  margin: 0.350rem auto;
  font-size: 1em
}

.viewtabs {
  background-color: var(--lade-color-white-hover);
}

</style>
