<template>
  <div
    class="d-flex text-white w-25"
    @click="returnToHome()"
    onselectstart="return false"
    style="cursor: pointer"
  >
    <div class="text-center">
      <img src="@/assets/AIRBUS_WHITE.png" class="logo d-none d-md-flex" />
      <img src="@/assets/AIRBUS_WHITE.png" class="logo-sm d-md-none" />
    </div>
    <div class="tracking-wide my-auto">
      <div class="text-left">CyberRange</div>
      <div
        class="text-2xl text-right font-weight-bold"
        style="margin-top: -7px"
      >
        HUB
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AirbusLogo",
  methods: {
    returnToHome() {
      if (this.$route.path === "/") return;
      this.$router.replace("/");
    },
  },
};
</script>

<style scoped>
.logo {
  height: 75px;
}

.logo-sm {
  height: 50px;
}
</style>
