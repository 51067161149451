<template>
  <b-modal size="lg" ref="addFormModal" id="addNetworkSpecModal" hide-footer v-bind:title="title">
    <form v-on:submit.prevent="onAddEdit">
      <div class="card">
        <div class="card-header">
          Attributes
        </div>
        <div class="card-body">
          <InputText v-if="edit" prefix="NetworkSpec" name="identifier" v-model="identifier" disabled/>
          <InputText prefix="NetworkSpec" name="Topology identifier" v-model="topologyIdentifier" :hidden="true"/>
          <InputText prefix="NetworkSpec" name="Topology tag" :value="topology.tag" disabled/>
          <InputText prefix="NetworkSpec" name="Network name" placeholder="(ex: net-01)" v-model="name" required
                     pattern="[a-zA-Z0-9\-_]{1,50}"/>

          <div class="form-group row">
            <label class="col-sm-3 col-form-label">Network type</label>
            <div class="col-sm-9">
              <b-form-select v-model="networkType" id="networktype" :options="networkTypeOptions" class="mb-3"
                             :disabled="edit"/>
            </div>
          </div>

          <InputText v-if="networkType === 'L3'" prefix="NetworkSpec" name="IP address" placeholder="(ex: 192.168.1.0)"
                     v-model="ip" pattern="[0-9\.]{7,15}"/>
          <InputText v-if="networkType === 'L3'" prefix="NetworkSpec" name="Mask" placeholder="(ex: 255.255.255.0)"
                     v-model="mask" pattern="[0-9\.]{7,15}"/>
          <InputText v-if="networkType === 'L2'" prefix="NetworkSpec" name="MTU" placeholder="1500" v-model="mtu"
                     type="number" min="1280" max="9000"
                     help="This parameter describes the size of the largest protocol data unit that can be communicated in a single network layer transaction."/>

          <InputCheckbox v-if="networkType !== 'SPAN'" prefix="NetworkSpec" name="Promiscuous" v-model="promiscuous"
                         @input="promiscuous= $event"
                         help="Allow connected hosts to see all the traffic on the network."/>
          <InputCheckbox v-if="networkType !== 'SPAN'" prefix="NetworkSpec" name="Forged Transmits"
                         v-model="forgedTransmits" @input="forgedTransmits= $event"
                         help="Allow connected hosts to send out frames with a source MAC address that is different from the MAC address registered in their configuration."/>
          <InputCheckbox v-if="networkType !== 'SPAN'" prefix="NetworkSpec" name="Mac Changes" v-model="macChanges"
                         @input="macChanges= $event"
                         help="Allow connected hosts to receive traffic originally intended for another host, by impersonating the other host\'s MAC address."/>

          <InputCheckbox v-if="networkType !== 'L2'" prefix="NetworkSpec" name="Enabled" v-model="enabled"
                         @input="enabled= $event"
                         help="When network is disabled, the hosts interfaces will still be up but no traffic will be transmitted."/>

          <TextAreaForm prefix="NetworkSpec" name="attributes" ref="attributes" v-model="attributes" :rows="5"
                        :json="true"/>
          <button type="submit" class="btn btn-primary">Submit</button>
        </div>
      </div>
    </form>
  </b-modal>
</template>

<script>
import store from '../../store'
import InputText from '../form/InputText'
import TextAreaForm from '../form/TextAreaForm'
import InputCheckbox from '../form/InputCheckbox'
import {print_tag} from '@/filters'

export default {
  name: 'NetworkSpecsForm',
  created() {
    this.$eventBus.$off('add-network-spec-show')
    this.$eventBus.$on('add-network-spec-show', (topologyIdentifier) => {
      this.reset('Add Network')
      this.topologyIdentifier = topologyIdentifier
      this.topology = store.getters['topologies/get'](this.topologyIdentifier)
      this.bundleIdentifier = this.topology.bundle_identifier
      if (this.$refs.attributes) this.$refs.attributes.$emit('reset')
      this.$refs.addFormModal.show()
    })

    this.$eventBus.$off('edit-network-spec-show')
    this.$eventBus.$on('edit-network-spec-show', ({topologyIdentifier, identifier}) => {
      this.reset('Edit Network')
      this.edit = true
      let networkSpec = store.getters['topologies/getNetworkSpec'](topologyIdentifier, identifier)
      this.topologyIdentifier = topologyIdentifier
      this.topology = store.getters['topologies/get'](this.topologyIdentifier)
      this.bundleIdentifier = this.topology.bundle_identifier
      this.identifier = identifier
      this.name = networkSpec.name
      this.ip = networkSpec.ip
      this.mask = networkSpec.mask
      this.attributes = networkSpec.attributes
      this.promiscuous = networkSpec.promiscuous
      this.macChanges = networkSpec.mac_changes
      this.forgedTransmits = networkSpec.forged_transmits
      this.mtu = networkSpec.mtu
      this.networkType = networkSpec.network_type
      this.enabled = (networkSpec.status === "enabled")
      if (this.$refs.attributes) this.$refs.attributes.$emit('reset', this.attributes)
      this.$refs.addFormModal.show()
    })

    this.$eventBus.$off('delete-network-spec-show')
    this.$eventBus.$on('delete-network-spec-show', ({topologyIdentifier, identifier}) => {
      let topology = store.getters['topologies/get'](topologyIdentifier)
      let networkSpec = store.getters['topologies/getNetworkSpec'](topologyIdentifier, identifier)
      this.$deleteModal({
        text: "Delete network " + networkSpec.name + " belonging to topology " + print_tag(false, false, topology) + " ?"
      }).then((result) => {
        if (result.value) {
          store.dispatch('topologies/deleteNetwork', {
            topologyIdentifier: topologyIdentifier,
            identifier: identifier
          }).then(() => {
            this.$eventBus.$emit('success', 'The network will be deleted')
          }).catch((response) => {
            this.$eventBus.$emit('http-error', response)
          })
        }
      })
    })
  },
  data() {
    return {
      identifier: "",
      title: "",
      edit: false,
      topology: {},
      topologyIdentifier: "",
      name: "",
      ip: "",
      mask: "",
      promiscuous: false,
      attributes: {},
      networkType: "L3",
      mtu: 1500,
      macChanges: false,
      forgedTransmits: false,
      enabled: true,
      networkTypeOptions: [
        {value: "L3", text: "Classic (L3)"},
        {value: "L2", text: "Segment (L2)"},
        {value: "SPAN", text: "Port mirroring session (SPAN)"}
      ],
    }
  },
  components: {
    InputText, TextAreaForm, InputCheckbox
  },
  methods: {
    reset(title) {
      this.title = title
      this.edit = false
      this.identifier = ""
      this.topologyIdentifier = ""
      this.ip = ""
      this.mask = ""
      this.name = ""
      this.attributes = {}
      this.promiscuous = false
      this.macChanges = false
      this.forgedTransmits = false
      this.bundleIdentifier = null
      this.enabled = true
      this.mtu = 1500
    },
    onAddEdit() {
      let data = {
        "name": this.name,
        "attributes": this.attributes,
        "status": this.enabled ? "enabled" : "disabled"
      }

      if (!this.edit) {
        data = {
          ...data, ...{
            "network_type": this.networkType,
          }
        }
      }

      if (this.networkType !== "SPAN") {
        data = {
          ...data, ...{
            "promiscuous": this.promiscuous,
            "forged_transmits": this.forgedTransmits,
            "mac_changes": this.macChanges
          }
        }
      }

      if (this.networkType === "L2") {
        data = {
          ...data, ...{
            "mtu": this.mtu,
            "status": "enabled"
          }
        }
      }

      if (this.networkType === "L3") {
        data = {
          ...data, ...{
            "ip": this.ip,
            "mask": this.mask,
          }
        }
      }

      if (this.edit) {
        store.dispatch('topologies/updateNetwork', {
          bundleIdentifier: this.bundleIdentifier,
          topologyIdentifier: this.topologyIdentifier,
          identifier: this.identifier,
          data: data
        }).then(() => {
          this.$refs.addFormModal.hide()
        }).catch((response) => {
          this.$eventBus.$emit('http-error', response)
        })
      } else {
        store.dispatch('topologies/addNetwork', {
          bundleIdentifier: this.bundleIdentifier,
          topologyIdentifier: this.topologyIdentifier,
          data: data
        }).then(() => {
          this.$refs.addFormModal.hide()
        }).catch((response) => {
          this.$eventBus.$emit('http-error', response)
        })
      }
    }
  }
}

</script>
