<template>
  <div class="question-page">
    <b-row no-gutters class="top">
      <b-col md="1"></b-col>
      <b-col md="10" cols="12">
        <div v-if="question">
          <div class="page-title">
            <b-badge class="status-badge" pill :variant="question.status==='open' ? 'success' : 'danger'">{{question.status}}</b-badge>
            <div class="date"> Opened {{question.created_at | print_date}} by</div>
            <div class="user" >
              <b-avatar class="user-icon" :src="question.icon && question.icon.url" :text="question.username[0]"></b-avatar>
              <div class="username">{{question.username | capitalize}}</div>
            </div>
            <div class="page-title-right">
              <b-button v-if="isTokenValid && question.status==='open'" class="button-close" variant="primary" @click="close()">Close</b-button>
              <b-button v-if="isTokenValid" class="button-delete" variant="primary" @click="deleteQuestion()">Delete</b-button>
            </div>

          </div>
          <div class="question-body">
            <b-button class="edit-tab-button" v-if="isTokenValid && profile.identifier === question.user_identifier"
                      :to="{name: 'questionForm', params: {bundleIdentifier: bundle.identifier, questionIdentifier: this.identifier}}">
              <font-awesome-icon :icon="['fas', 'pencil-alt']" /></b-button>
            <div class="question-title">{{ question.title }}</div>
            <div class="question-text">{{question.question}}</div>
          </div>

          <div v-if="answers !== null" class="answers">
            <div class="answers-title">{{ nbAnswers }} Answers</div>
            <li style="text-decoration: none"
                    v-for="answer in answers" :key="answer.identifier">
              <b-button class="trash-tab-button" v-if="isTokenValid && profile.identifier === answer.user_identifier" @click="deleteAnswer(answer)">
                <font-awesome-icon style="height: 10px" :icon="['fas', 'trash-alt']" /></b-button>
              <div class="answer-entry">
                <div class="answer-icon">
                  <b-avatar style="width: 100%; height: 100%;" :src="answer.icon && answer.icon.url"></b-avatar>
                </div>
                <div class="answer-content">
                  <div class="answer-header">
                    <div class="answer-header-info">
                      <span class="answer-username">{{ answer.username | capitalize }}</span>
                      <span class="answer-date"> - {{answer.created_at | print_date}}</span>
                    </div>
                  </div>
                  <div class="answer-text">
                    <vue-markdown>{{ answer.answer }}</vue-markdown>
                  </div>
                </div>
              </div>
            </li>
          </div>
          <AnswerForm v-if="isTokenValid && question.status==='open'" ref="answerForm"
                      :title="question.title" :questionIdentifier="question.identifier"></AnswerForm>
        </div>
        <div class="text-center col-12 col-xl-10"
             style="padding-top:10px; padding-bottom:10px;"
             v-else>
          <b-spinner class="align-middle"></b-spinner>
          <strong style="margin-left:10px;">Loading...</strong>
        </div>
      </b-col>
      <b-col md="1"></b-col>
    </b-row>
  </div>
</template>

<script>
import AnswerForm from './AnswerForm.vue'
import store from "@/store";

export default {
  name: 'QuestionView',
  components: {
      AnswerForm
  },
  props: {
      identifier: String,
      bundleIdentifier: String,
  },
  created() {
    this.$eventBus.$off('refresh-answers')
    this.$eventBus.$on("refresh-answers", () => {
      this.refreshAnswers()
    })
  },
  data: function() {
    return {
      question: null,
      answers: null,
      bundle: null,
      user: null,
      profile: null
    }
  },
  mounted: function() {
    this.refresh()
  },
  methods: {
    refresh() {
      // It might be necessary to load bundle questions here, in case user loads directly these page
      let question_promise = this.$store.dispatch('questions/load', {bundleIdentifier: this.bundleIdentifier,
        identifier: this.identifier})
      let bundle_promise = this.$store.dispatch('bundles/load', this.bundleIdentifier)

      Promise.all([question_promise, bundle_promise]).then(() => {
          this.question = this.$store.getters['questions/get'](this.identifier)
          if (this.isTokenValid) {
            this.$store.dispatch('users/load', this.question.user_identifier)
          }
          this.bundle = this.$store.getters['bundles/get'](this.bundleIdentifier)
          this.refreshAnswers()
          this.updateBreadcrumb()
        }
      )
      if (this.isTokenValid) {
        this.$store.dispatch('users/loadProfile').then(() => {
          this.profile = this.$store.getters['users/getProfile']()
        })
      }
    },
    updateBreadcrumb() {
      let breadcumbList = [
        { text: 'Explore', to: {name: 'bundleGrid'} },
        { text: this.bundle.name, to: {name: 'bundlePage', params: {identifier: this.bundleIdentifier, reload: false, tab: 8}} },
        //{ text: 'question', to: {}},
        { text: this.question.title },
      ]
      this.$emit('breadcrumbChange', breadcumbList)
    },
    refreshAnswers() {
      this.$store.dispatch('questions/loadAnswers', {identifier: this.identifier, page: this.page, desc: false}).then(() => {
        let answers_object = this.$store.getters['questions/getAnswers'](this.identifier)
        this.answers = answers_object.data
        this.page = answers_object.page
        this.nbAnswers = answers_object.total
        this.totalPages = answers_object.total_pages
      })
    },
    close() {
      this.$store.dispatch('questions/update',
        {
          bundleIdentifier: this.bundleIdentifier,
          identifier: this.identifier, data: {status: "closed"},
        })
    },
    deleteAnswer(answer) {
      this.$store.dispatch('questions/deleteAnswer',
        {
          identifier: this.identifier, answerIdentifier: answer.identifier,
        }).then(() => {
          this.refreshAnswers()
      })
    },
    deleteQuestion() {
      this.$store.dispatch('questions/delete',
        {
          bundleIdentifier: this.bundleIdentifier,
          identifier: this.identifier
        })
      this.$router.push({name: 'bundlePage', params: {identifier: this.bundleIdentifier, tab: 8}})
    }
  },
  computed: {
    isTokenValid: () => store.getters["token/isValid"](),
  },
  watch: {
    $route(to, from)
    {
      console.log(to)
      // react to route changes...
      if(to !== from){
        this.refresh()
      }
    }
  }
}
</script>

<style scoped>

.question-page {
}

.page-title {
  line-height: 1.3;
  font-size: 1.25em;
  padding-top: 15px;
  padding-bottom: 15px;
  border-bottom: solid 1px var(--lade-color-white);
  border-top: solid 1px var(--lade-color-white);
}

.page-title-right {
  display: inline;
  right: 0;
}

.button-close {
  right: 80px;
  position: absolute;
  color: var(--lade-color-buttonQuestion-close);
  border-color: var(--lade-color-buttonQuestion-close);
  background-color: inherit;
  font-weight: bold;
  font-size: 1em;
}

.button-close:hover, .button-close:focus, .button-close:active {
  color: var(--lade-color-buttonQuestion-close) !important;
  border-color: var(--lade-color-buttonQuestion-close) !important;
  background-color: var(--lade-color-white) !important;
  box-shadow: none !important;
}

.button-delete {
  right: 0;
  position: absolute;
  color: var(--lade-color-red);
  border-color: var(--lade-color-red);
  background-color: inherit;
  font-weight: bold;
  font-size: 1em;
}

.button-delete:hover, .button-delete:focus, .button-delete:active {
  color: var(--lade-color-red) !important;
  border-color: var(--lade-color-red) !important;
  background-color: var(--lade-color-white) !important;
  box-shadow: none !important;
}

.status-badge {
  margin-bottom: 0;
  margin-left: 10px;
  font-size: 1em;
  border-radius: 9px
}

.date {
  display: inline;
  margin-left: 5px;
  margin-right: 5px;
  font-weight: lighter;
}

.user {
  margin-left: 5px;
  display: inline;
  border: none;
  background-color: inherit;
  font-weight: bold;
}

.user-icon {
  margin-right: 5px;
}
.username {
  display: inline;
}

.question-body {
  padding: 10px 10px 15px;
  border-bottom: solid 1px var(--lade-color-white);
}

.question-title {
  font-weight: bold;
  font-size: 1.75em;
}

.question-text {
  font-size: 1.1em;
  margin-top: 10px;
}

.answers {
  margin: 20px 10px 10px;
}

li {
  list-style-type: none
}

.answers-title {
  margin: 1rem 0;
  line-height: 1.3;
  font-size: 1.25em;
  font-weight: 600;

}

.answer-entry {
  padding: 10px;
  border: 1px solid var(--lade-color-white);
  border-radius: 4px;
  margin: 16px 0;
}

.answer-icon {
  float: left;
  width: 40px;
  height: 40px;
  margin: 10px;
}

.answer-content {
  padding-left: 30px
}

.answer-header {
  display: flex;
  justify-content: space-between;
  padding-left: 10px;
  padding-top: 6px
}

.answer-header-info {
  min-width: 0;
  padding-bottom: 8px;
}

.answer-username {
  font-weight: 600;
  font-size: 1.1em;
}

.answer-date {
}

.answer-text {
  padding-left: 40px;
  padding-right: 10px;
  font-size: 1.1em;
}

.edit-tab-button {
  position: absolute;
  height: 33px;
  margin-top: 0;
  right: 10px;
  color: var(--lade-color-grey);
  font-weight: 400;
  background-color: var(--lade-color-white);
  border-color: var(--lade-color-white);
}

.edit-tab-button:hover {
  color: var(--lade-color-grey);
  background-color: var(--lade-color-white);
  border-color: var(--lade-color-dark-grey);
}

.edit-tab-button:active .edit-tab-button:focus {
  color: var(--lade-color-white) !important;
  background-color: var(--lade-color-grey) !important;
  border-color: var(--lade-color-dark-grey) !important;
  box-shadow: none !important;
}

.trash-tab-button {
  position: absolute;
  margin-top: 10px;
  right: 20px;
  color: var(--lade-color-red);
  border-color: var(--lade-color-red);
  background-color: inherit;
  padding: 4px 5px 5px;
}

.trash-tab-button:hover, .trash-tab-button:focus, .trash-tab-button:active {
  color: var(--lade-color-red) !important;
  border-color: var(--lade-color-red) !important;
  background-color: var(--lade-color-white) !important;
  box-shadow: none !important;
}

</style>
