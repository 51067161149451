<template>
  <div class="font-base">
    <header>
      <Header />
    </header>

    <main class="bg-white py-5">
      <b-container class="my-5">
        <b-row>
          <b-col>
            <Title name="CyberRange" />
          </b-col>
          <b-col>
            <Testimony />
          </b-col>
        </b-row>

        <Presentation class="my-5" />
      </b-container>

      <b-row class="d-flex justify-content-center my-5">
        <b-col class="d-flex justify-content-end">
          <img
            style="width: 75%; object-fit: scale-down"
            src="@/assets/registry/cr-environment.png"
            class="d-none d-md-flex"
          />
        </b-col>
        <b-col sm=12 md=6 class="d-flex justify-content-center my-auto">
          <div class="w-75">
            <b-embed
              class="pl-5"
              type="iframe"
              aspect="16by9"
              src="https://www.youtube.com/embed/37yPDcoD6Q8"
              allowfullscreen
            ></b-embed>
          </div>
        </b-col>
      </b-row>

      <b-container class="my-5">
        <Title name="Community" />
      </b-container>

      <Community class="my-5" />

      <b-container class="my-5">
        <Title name="Trust Hierarchy" />
      </b-container>

      <Trust class="my-5" />

      <b-container class="my-5">
        <Title name="Our Partners and Customers" />

        <Partners class="my-5" />
      </b-container>
    </main>

    <footer class="pt-5 bg-white">
      <Footer />
    </footer>
  </div>
</template>

<script>
import Community from "@/components/hub/landing/Community";
import Footer from "@/components/hub/landing/Footer";
import Header from "@/components/hub/landing/Header";
import Partners from "@/components/hub/landing/Partners";
import Presentation from "@/components/hub/landing/Presentation";
import Testimony from "@/components/hub/landing/Testimony";
import Title from "@/components/hub/landing/Title";
import Trust from "@/components/hub/landing/Trust";

export default {
  name: "Home",
  components: {
    Community,
    Footer,
    Header,
    Partners,
    Presentation,
    Testimony,
    Title,
    Trust,
  },
  props: {},
  data: function () {
    return {};
  },
  computed: {},
  mounted() {},
  methods: {},
};
</script>
