<template>
     <div class="new-answer">
       <b-avatar v-if="profile !== null" class="user-icon" :src="myIcon" :text="this.profile.username"></b-avatar>
        <form class="answer-form" ref="form" @submit.stop.prevent="submit">
          <b-form-group id="answer-form" label-for="answer-form"
              :state="state" description="Please write your question following community rules."
              invalid-feedback="Answer is required">
            <editor height="150px" class="editor" id="input-answer" ref="editor" initialEditType="wysiwyg" />

          </b-form-group>
          <div class="form-button">
            <b-button type="submit" class="button-submit" variant="primary">Submit</b-button>
          </div>
        </form>
    </div>
</template>

<script>
export default {
  name: 'AnswerForm',
  props: {
      questionIdentifier: String,
      title: String,
  },
  data: function() {
    return {
      state: true,
      profile: this.$store.getters['users/getProfile'](),
    }
  },
  computed: {
    myIcon() {
      if (this.profile.icon && this.profile.icon.url) {
        return this.profile.icon.url
      }
      return null
    }
  },
  methods: {
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity()
      this.state = this.getAnswer().length > 0
      return valid && this.state
    },
    submit(evt) {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
          return
      }
      this.$store.dispatch('questions/addAnswer',
        {   identifier: this.questionIdentifier, answer: this.getAnswer(),
        }).then(() =>  {
          this.$eventBus.$emit("refresh-answers")
          this.reset()
      })
    },
    reset() {
      this.$refs.editor.reset()
    },
    getAnswer() {
        return this.$refs.editor.invoke('getMarkdown')
    },
  }
}
</script>

<style scoped>

.user-icon {
  float: left;
  width: 40px;
  height: 40px;
  margin: 10px;
}

.new-answer {
  margin: 20px 10px 10px;
}

.editor {
}

.answer-form {
  padding-left: 70px;
  padding-right: 60px
}

.form-button {
  position: relative;
  margin-top: 20px;
  margin-bottom: 90px;
}

.button-submit {
  left: 0;
  position: absolute;
  color: var(--lade-color-green);
  border-color: var(--lade-color-green);
  background-color: inherit;
  font-weight: bold;
  font-size: 1em;
}

.button-submit:hover, .button-submit:focus, .button-submit:active {
  color: var(--lade-color-green) !important;
  border-color: var(--lade-color-green) !important;
  background-color: var(--lade-green-explore-1) !important;
  box-shadow: none !important;
}

</style>
