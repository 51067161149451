<template>
  <WorkflowList
    :searchFilters="{group_identifier: groupIdentifier}"
    :fields="fields"
    :busy="busy">

    <template v-slot:cell(resources)="row">
      <div :v-if="row.item.resources != null" class="link">
        <span v-if="resource.is_scope" v-for="resource in row.item.resources" :key="resource.resource_identifier">
          <a @click="scope=resource.resource_type;
          resourceIdentifier=resource.resource_identifier;
          resourceName=resource.resource_name; applyFilter()"
             class="filterlink">
            {{ capitalize(resource.resource_type) }} {{ resource.resource_name }}
          </a>
        </span>
      </div>
    </template>

  </WorkflowList>
</template>

<script>
import store from '../../store'
import WorkflowList from '../workflows/WorkflowList.vue'
import {capitalize} from '@/filters'
import moment from "moment/moment";

export default {
  name: "WorkflowTab",
  components: {
    WorkflowList
  },
  props: {
    groupIdentifier: String,
  },
  methods: {
    capitalize(value) {
      return capitalize(value)
    },
  },
  data() {
    return {
      busy: false,
      fields: store.getters['workflows/getFields']()

    }
  }
}
</script>
