import Vue from 'vue'
import Config from '../../Config'

const state = {
  fields: [
    {
      "key": "identifier",
      "sortable": true,
      "label": "Configuration Section"
    }
  ],
  map: {}
}

const getters = {
  get: (state) => () => {
    return state.map
  },
  getFields: (state) => () => {
    return state.fields
  },
}

const actions = {
  load({dispatch, commit}) {
    return Vue.http.get(Config.API_CONFIG_URL).then((response) => {
      let config = response.data
      commit('set', config)
    })
  },
  update({dispatch, commit}, {key, value}) {
    return Vue.http.post(Config.API_CONFIG_URL, {
      key,
      value
    })
  }
}

const mutations = {
  set(state, config) {
    for(let key of Object.keys(config)){
      Vue.set(state.map, key, config[key])
    }
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true
}






