<template>
  <div class="form-group row" v-show="!hidden">
    <label class="col-sm-3 col-form-label" :for="fieldId">{{ name }} <i v-if="!required" style="color:gray;"> -
      Optional </i></label>
    <div class="col-sm-9">
      <input ref="el" type="text" @input="updateValue()" :value="value"
             autocomplete="off" :id="fieldId" :aria-describedby="helpId"
             :placeholder="placeholder_" :required="required" :disabled="disabled"
             :pattern="pattern" class="form-control"/>
      <small :id="helpId" class="form-text text-muted">{{ help }}</small>
    </div>
  </div>
</template>

<script>
import {capitalize} from "@/filters"

export default {
  name: "InputText",
  props: {
    value: [String, Number, Object, Array, Boolean],
    prefix: String,
    name: String,
    required: Boolean,
    disabled: Boolean,
    pattern: String,
    help: String,
    hidden: Boolean,
    placeholder: String
  },
  data() {
    return {
      fieldId: this.prefix + capitalize(this.name),
      helpId: this.prefix + capitalize(this.name) + "Help",
      placeholder_: this.placeholder != null ? this.placeholder : capitalize(this.name)
    }
  },
  methods: {
    updateValue() {
      this.$emit('input', this.getValue())
    },
    getValue() {
      return this.$refs.el.value
    },
    refresh(value) {
      this.$refs.el.value = value
    }
  }
}
</script>
