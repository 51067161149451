import {print_date, print_tag, print_hardware_type} from '@/filters'

let field_tag = {
  "key": "tag",
  "sortable": true,
  "formatter": print_tag
}

let field_created_at = {
  key: 'created_at',
  label: 'Creation',
  formatter: print_date,
  sortable: true
}

let field_actions = {
  "key": "actions",
  "sortable": false
}

let field_hardware_type = {
  "key": "hardware_type",
  "sortable": true,
  "formatter": print_hardware_type
}

export {
  field_tag,
  field_created_at,
  field_actions,
  field_hardware_type
}
