<template>
  <div class="typeahead-container" style="position: relative;">
    <div class="input-group">
      <input
        ref="el"
        type="text"
        v-model="query"
        @keyup="updateQuery()"
        @focus="search(query)"
        @blur.stop="handleBlur()"
        class="form-control"
        :required="required"
        :placeholder="placeholder_real"
        :disabled="disabled"/>

      <div class="input-group-text input-group-append"
           style="border-top-left-radius:0; border-bottom-left-radius:0;"
           v-if="!disabled">

        <font-awesome-icon :icon="['fas', 'times']"
                           @click="clear()"
                           style="color: indianred; cursor: pointer; height:1em; width: 1em;"/>

      </div>
    </div>
    <div class="typeahead" v-if="focused">

      <div class="text-center text-primary"
           v-if="loading" style="margin:10px;">
        <b-spinner class="align-middle"></b-spinner>
        <strong style="margin-left:10px;">Loading...</strong>
      </div>

      <div class="text-center text-secondary"
           v-else-if="choices.length === 0" style="margin:10px;">
        <strong style="margin-left:10px;">No Result</strong>
      </div>

      <div class="typeahead-elem"
           v-else
           v-for="item in choices"
           :key="item.identifier"
           @click.stop="choose(item)">

        {{ item.tag }}<br/>
        <small v-if='item.name'>
          Name: {{ item.name }}
        </small>
      </div>
    </div>
  </div>

</template>

<script>

import {capitalize, print_tag} from '@/filters'

export default {
  name: "TypeAhead",
  mounted() {
    this.updateResource()
    if (this.value !== "" && this.value != null) {
      this.query = this.value
    } else {
      this.query = ""
    }
  },
  props: {
    value: {
      type: String,
      default: ""
    },
    help: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: ""
    },
    resourceType: String,
    delayTime: {
      type: Number,
      default: 500
    },
    placeholder: String,
    disabled: {
      type: Boolean,
      default: false
    },
    customResources: {
      type: Array,
      default: () => []
    },
    required: {
      type: Boolean,
      default: false
    },
    filters: {
      type: Object,
      default: () => {
      }
    }
  },
  data() {
    return {
      query: this.value,
      choices: [],
      resource: "",
      fieldId: capitalize(this.label),
      helpId: this.label,
      loading: false,
      focused: false,
    }
  },
  computed: {
    placeholder_real: function () {
      if (this.placeholder !== "" && this.placeholder != null) {
        return this.placeholder
      } else if (this.resourceType != null) {
        return "Select " + capitalize(this.resourceType)
      }
      return ""
    }
  },
  methods: {
    // Manage unfocus
    handleBlur() {
      setTimeout(() => {
        this.focused = false
        this.choices.splice(0, this.choices.length)
      }, 350);
    },
    clear() {
      this.query = ""
      this.choices = []
      this.$emit("hit", null)
      this.loading = false
      this.focused = false
    },
    updateResource() {
      switch (this.resourceType) {
        case 'BUNDLE':
          this.resource = 'bundle'
          break
        case 'GROUP':
          this.resource = 'group'
          break
        case 'WORKZONE':
          this.resource = 'workzone'
          break
        case 'ACTION':
          this.resource = 'action'
          break
        case 'SCENARIO':
          this.resource = 'scenario'
          break
        case 'TOPOLOGY':
          this.resource = 'topology'
          break
        case 'FILE':
          this.resource = 'file'
          break
        case 'USER':
          this.resource = 'user'
          break
        case 'RULE':
          this.resource = null
          break
        case 'HOST':
          this.resource = 'host'
          break
        case 'NETWORK':
          this.resource = 'network'
          break
        case 'EVENT':
          this.resource = null
          break
        case 'WORKFLOW':
          this.resource = null
          break
        case 'CATEGORY':
          this.resource = 'category'
          break
        case 'PROVIDER':
          this.resource = 'provider'
          break
        case 'PROJECT_POOL':
          this.resource = 'project_pool'
          break
        case 'PROJECT':
          this.resource = 'project'
          break
        case null:
          this.resource = null
          this.choices.splice(0, this.choices.length)
          this.query = ""
          break
        default:
          this.resource = this.resourceType
      }
    },
    updateQuery() {
      this.focused = true
      this.$emit("input", this.query)
      if (this.resource == null) {
        return
      }
      this.reloadSearch()
    },
    reloadSearch() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.search(this.query)
      }, this.delayTime);
    },
    getQuery() {
      return this.$refs.el.value
    },
    refresh(value) {
      this.$refs.el.value = value
    },
    search(query) {
      this.loading = true
      this.focused = true
      this.loading = true

      this.$store.dispatch("search/" + this.resource, {
        search: query,
        filters: this.filters,
        params: {limit: 5}
      }).then((resources) => {
        // Loading is also used to know if unfocus has been done
        if (this.loading) {
          this.choices.splice(0, this.choices.length)
          if (this.customResources.length > 0) {
            resources = this.customResources
          }
          for (let resource of resources) {
            this.choices.push(this.transformResource(resource))
          }
        }
        this.loading = false
      })
    },
    transformResource(resource){
      let _data = {
        'identifier': resource.identifier
      }
      if (resource.tag) {
        _data['tag'] = print_tag(null, null, resource)
        _data['name'] = resource.name
      } else if (this.resource === 'workzone') {
        _data['tag'] = resource.identifier
        _data['name'] = resource.name
      } else if (this.resource === 'project') {
        _data['tag'] = resource.project_pool + '/' + resource.alias
        _data['name'] = resource.name
      } else if (this.resource === 'project_pool') {
        _data['tag'] = resource.alias
        _data['name'] = resource.name
      } else if (this.resource === 'category') {
        _data['tag'] = resource.alias
        _data['name'] = resource.name
      } else if (this.resource === "bundle") {
        _data['tag'] = print_tag(null, null, resource)
        _data['name'] = resource.name
      } else {
        _data['tag'] = resource.name
      }
      return _data
    },
    choose(item) {
      this.query = item.tag
      this.$emit("hit", item.identifier)
      this.choices.splice(0, this.choices.length)
      this.focused = false
    },
    select(identifier) {
      let that = this
      this.$nextTick().then(function () {
        that.updateResource()
        that.$store.dispatch(that.resource+"s/load", identifier).then(() => {
          that.choose(that.transformResource(that.$store.getters[that.resource + 's/get'](identifier)))
        })
      })
    }
  },
  watch: {
    resourceType: function () {
      this.updateResource()
      this.query = ""
      this.choices.splice(0, this.choices.length)
      this.$emit("hit", null)
    }
  }
}
</script>

<style scoped>

.typeahead {
  position: absolute;
  top: 2.5em;
  z-index: 101;
  background-color: var(--lade-color-white-hover);
  list-style: none;
  left: 0;
  border-collapse: separate;
  width: 100%;
  border: solid var(--lade-color-white) 1px;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  border-top: none;
  overflow-y: auto;
  display: block;
  max-height: 150px;
}

.typeahead-elem {
  padding: .175rem .75rem;
  text-decoration: none;
  color: var(--lade-color-black);
  cursor: pointer;
  width: 100%;
  z-index: 101;
}

.typeahead-elem:hover {
  background-color: var(--lade-color-white);
}

</style>
