<template>
  <div class="sectionEditProfile">
    <div class="content">
      <div class="head-content">
        <h2>Use share token</h2>
        <div class="menu-edit">
          <button
            v-if="!loading"
            @click.stop="submit"
            type="submit"
            class="btn btn-primary">
            Submit
          </button>
          <b-spinner v-else class="loading top"></b-spinner>
        </div>
      </div>

      <div class="edit">
        <div class="group">
          <InputText class="input-text" name="Share Token" v-model="shareToken" required></InputText>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import InputText from "@/components/form/InputText.vue";
import store from "../../store";


export  default {
  name: "SettingsShareToken",
  components: {InputText},
  props: {
    profile: Object,
  },
  data() {
    return {
      loading: false,
      shareToken: "",
    }
  },

  methods: {
    async submit() {
      this.loading = true
      await store.dispatch('sharetoken/useToken',
        {token:this.shareToken}).then(()=> {
        this.loading = false
        this.$modal({
          text:"This token has been activated"
        })
      }).catch((response) => {
        this.loading = false
        this.$eventBus.$emit('http-error', response)
      })
    },
  },
}
</script>

<style scoped>

.menu-edit {
  position: absolute;
  top: 15px;
  right: 20px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 140px;
  z-index: 1;
}

.loading{
  width: 20px;
  height: 20px;
  position: absolute;
}

.loading.top {
  right: 20px;
  top: 5px;
}

.edit {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow-y: auto;
  position: relative;
  width: 100%;
  padding: 15px 15px 105px 15px;
}

.group {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 20px 10px 15px 10px;
  border: solid 1px var(--lade-color-white);
  border-radius: 10px 10px;
  position: relative;
  margin-top: 10px;
}

.input-text {
  width: 100%;
}
</style>
