<template>
  <div style="z-index: 101">
    <b-button class="button" id="collapse-button-cli" v-b-toggle.collapse-cli variant="dark">
      <div class="d-flex font-weight-bold">
      <font-awesome-icon
      class="mr-2 mt-1"
      :icon="['fas', 'download']"
      />
      Download</div></b-button>
    <b-collapse class="collapse" id="collapse-cli" size="lg">
        <b-card>
          <b-card-title style="font-size: 1.4rem;">Download to your CyberRange </b-card-title>
          <b-card-text>Follow the <a :href="registryDocUrl">documentation</a> </b-card-text>
            <b-card-title style="font-size: 1.4rem;">Download using LADE Command Line Interface</b-card-title>
            <b-card-text>
              <br />Click <b-link @click="downloadCLI()">here</b-link> to download the <b>LADE CLI</b> for your platform.
              Run the following command <b-link @click="windowsCommand=true">on Windows</b-link> or <b-link @click="windowsCommand=false">on Linux</b-link>.

            </b-card-text>
            <b-card-text>
              <div class="d-flex">
                <code class="command">{{downloadCommand}}</code>
                <CopyButton :value="downloadCommand" />
              </div>
            </b-card-text>
        </b-card>
    </b-collapse>
  </div>
</template>

<script>

import Config from "@/Config";

import CopyButton from '@/components/CopyButton.vue'

export default {
    name: 'BundleDownloadModal',
    components: {
      CopyButton
    },
    props: {
        bundleTag: String,
    },
    data: function() {
        return {
            windowsCommand: true
        }
    },
    computed: {
      downloadCommand () {
        return 'lade-' + this.version + (this.windowsCommand?'.exe':'').toString() + ' --base-url ' + location.origin + ' bundle download ' + this.bundleTag
      },
      registryDocUrl () {
        return Config.DOC_URL + '04-user_guide/02-content/07-registry.html'
      },
      version () {
        return this.$store.getters['about/get']().version
      }
    },
    methods: {
        downloadCLI() {
          let os = this.windowsCommand?`windows`:`linux`
          this.$store.dispatch("assets/downloadCLI", {os: os})

          // return Vue.http.get(Config.CLI_URL + filename, {responseType: 'blob'}).then((response) => {
          //   return downloadFile(response, filename)
          // })
        }
    }
}
</script>

<style scoped>
  .command {
      display: block;
      white-space: pre-wrap;
      background: var(--lade-color-white);
      color: var(--lade-color-grey);
      font-size: small;
      padding: 5px; padding-left:2px; padding-right:2px;
      box-shadow: 0 0 0 var(--lade-color-dark-grey);
      margin-left: 5px;
      margin-right: 5px;
      text-align: left;
  }
  /*#collapse-button-cli {*/
  /*    color: rgba(255,255,255,.8);*/
  /*    background-color: #5a6268;*/
  /*    border-color: #545b62;*/
  /*}*/

  .button {
    position: absolute;
    right: 0;
  }

  .collapse {
    margin-top: 35px;
  }

</style>
