import Vue from 'vue'
import Config from '../../Config'
import {downloadFile, sanitizeTag} from '@/common'
import {field_tag, field_created_at, field_actions} from './fields'
import {setDictValue, build_resource_list_params} from '@/common'

const state = {
  list: [],
  pagination: {
    page: 1,
    total_pages: 0,
    total: 0,
  },
  filteredList: [],
  _lookup: {},
  fields: [
    {
      "key": "name",
      "sortable": true
    },
    field_tag,
    field_created_at,
    field_actions,
  ],
  filters: {
    isEditable: (entry) => true,
    isDeletable: (entry) => true,
    isExportable: (entry) => true,
    isDownloadable: (entry) => false
  },
}

const getters = {
  get: (state) => (identifier) =>
    state._lookup[identifier],
  getBundle: (state) => (bundleIdentifier) =>
    state.list,
  getAll: (state) => () =>
    state.list,
  getFilters: (state) => () =>
    state.filters,
  getFields: (state) => () =>
    state.fields,
  getPagination: (state) => () =>
    state.pagination
}

const actions = {
  loadAll({dispatch, commit}, {filters = {}, ...resource_list_params}) {
    let params = build_resource_list_params(resource_list_params)

    var url
    if (filters.bundleIdentifier !== undefined)
      url = Config.API_BUNDLES_URL + "/" + filters.bundleIdentifier + "/scenarios"
    else
      url = Config.API_SCENARIOS_URL
    return Vue.http.get(url, {params}).then((response) => {
      let scenarios = response.data
      commit('setAll', scenarios)
    })
  },
  load({dispatch, commit}, identifier) {
    return Vue.http.get(Config.API_SCENARIOS_URL + '/' + identifier).then((response) => {
      let scenario = response.data
      commit('set', {identifier, scenario})
    })
  },
  add({dispatch, commit}, {bundleIdentifier, data}) {
    return Vue.http.post(Config.API_BUNDLES_URL + '/' + bundleIdentifier + '/scenarios', data).then((response) => {
      let scenario = response.data
      return dispatch('load', scenario.identifier)
    })
  },
  update({dispatch, commit}, {identifier, data}) {

    let scenario = state._lookup[identifier]

    return Vue.http.put(Config.API_BUNDLES_URL + '/' + scenario.bundle_identifier + '/scenarios/' + identifier, data).then((response) => {
      return dispatch('load', identifier)
    })
  },
  delete({dispatch, commit}, identifier) {
    let scenario = state._lookup[identifier]
    return Vue.http.delete(Config.API_BUNDLES_URL + '/' + scenario.bundle_identifier + '/scenarios/' + scenario.identifier).then((response) => {
      return commit('delete', identifier)
    })
  },
  export({state, dispatch, commit}, {bundleIdentifier, identifier}) {
    let scenario = state._lookup[identifier]
    return Vue.http.get(Config.API_BUNDLES_URL + '/' + bundleIdentifier + '/scenarios/' + identifier + '/download', {responseType: 'blob'}).then((response) => {
      downloadFile(response, sanitizeTag(scenario.tag))
    })
  },
  search({dispatch, commit}, {search, filters = {}}) {
    return dispatch('search/scenario', {search, filters}, {root: true}).then((resources) => {
      commit('setAll', resources)
    })
  },
}

const mutations = {
  delete(state, identifier) {
    Vue.delete(state.list, state.list.indexOf(state.list.find(item => item.identifier === identifier)))
  },
  setAll(state, scenarios) {
    state._lookup = {}
    state.pagination.page = scenarios.page
    state.pagination.total_pages = scenarios.total_pages
    state.pagination.total = scenarios.total
    state.list.splice(0, state.list.length)

    for (let scenario of scenarios.data)
      state.list.push(scenario)

    for (let scenario of scenarios.data)
      state._lookup[scenario.identifier] = scenario
  },
  set(state, {identifier, scenario}) {
    if (state._lookup[scenario.identifier] === undefined)
      state.list.push(scenario)
    setDictValue(state._lookup, scenario.identifier, scenario)
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true
}
