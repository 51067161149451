<template>
  <div>
    <div class="top-bar">
      <!--<div class="breadcrumb-bar">
        <b-breadcrumb class="breadcrumb" :items="breadcrumbList"></b-breadcrumb>
      </div>-->
      <b-container class="px-2 container-button">
        <b-button @click="redirectPreviousPage" variant="link" style="font-size: 1.2em; color: black;"><font-awesome-icon :icon="['fas', 'chevron-left']" /> {{ `Back (${lastPage.text})` }}</b-button>
      </b-container>
    </div>
    <div>
      <router-view v-on:breadcrumbChange="onBreadcrumbChange($event)" v-on:breadcrumbPush="onBreadcrumbPush($event)"></router-view>
    </div>
  </div>
</template>

<script>

export default {
    name: 'NavView',
    data: function () {
        return {
            breadcrumbList: [
              {text: 'Explore', to: {name: 'bundleGrid'}},
            ],
        }
    },
    computed: {
      lastPage() {
        if (this.breadcrumbList.length < 2) {
          return {text: 'Explore', to: {name: 'bundleGrid'}};
        }
        return this.breadcrumbList[this.breadcrumbList.length - 2];
      },
    },
    methods: {
      onBreadcrumbChange(event) {
        this.breadcrumbList = event
      },
      onBreadcrumbPush(event) {
        this.breadcrumbList.push(event)
      },
      async redirectPreviousPage() {

        await this.breadcrumbList.pop();
        let last_element = this.breadcrumbList[this.breadcrumbList.length - 1]
        if(last_element.to === 'editGroup'){
          this.$router.push({
            name: last_element.to,
            params: {
              identifier: last_element.identifier,
              bundle: true,
            }
          })
        }else if (last_element.to.name === 'bundleGrid' && last_element.text === 'Explore') {
          this.$router.push( last_element.to)
        }
        else if(last_element.to === 'groups' && last_element.text === 'Group List'){
          this.$router.push({
            name: last_element.to,
            params: {
              identifier: last_element.identifier,
              resource: last_element.resource,
            }
          })
        }
        else{
          this.$router.push(last_element.to);
        }
      }
    },
}
</script>

<style scoped>

.top-bar {
  width: 100%;
  -webkit-box-shadow: 0 1px 0 0 rgba(0, 0, 0, .05),
  0 2px 4px 0 rgba(0, 0, 0, .06);
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, .05),
  0 2px 4px 0 rgba(0, 0, 0, .06);
  background-color: var(--lade-color-white-hover);
}

.container-button {
  margin: 0 0 0 2%;
}

.breadcrumb-bar {
  width: 85%;
  margin-left: auto;
  margin-right: auto;
  overflow-y: hidden;
}

@media screen and (max-width: 60rem) {
  .breadcrumb-bar {
    width: 100%;
  }
}

.breadcrumb {
  background-color: var(--lade-color-white-hover);
  margin-bottom: 0;
  font-size: 1.1em;
  white-space: nowrap;
}

.breadcrumb >>> a {
  color: var(--lade-color-dark-grey);
}

</style>
