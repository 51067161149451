<template>
  <b-container>
    <b-row>
      <b-col class="text-xl text-blue">
        <b-row class="text-2xl font-weight-bold my-3 px-3" style="border-left: 5px solid #01215d">
          Airbus
        </b-row>
        <b-row class="mx-2">
          Developed and maintained by Airbus CyberSecurity - CyberRange team. These are our core bundles, included with
          the CyberRange license.
        </b-row>

        <b-row class="text-2xl font-weight-bold my-3 px-3" style="border-left: 5px solid #dbedc4">
          Airbus Community
        </b-row>
        <b-row class="mx-2">
          Developed by Airbus CyberSecurity and freely delivered to the community.
        </b-row>

        <b-row class="text-2xl font-weight-bold my-3 px-3" style="border-left: 5px solid #c2e299">
          Partners
        </b-row>
        <b-row class="mx-2">
          Developed and maintained by partners. Our partners are validated by Airbus CyberSecurity for the quality of
          their work.
        </b-row>

        <b-row class="text-2xl font-weight-bold my-3 px-3" style="border-left: 5px solid #9ad056">
          Public
        </b-row>
        <b-row class="mx-2">
          Other users of the CyberRange, no prior validation by Airbus CyberSecurity.
        </b-row>
      </b-col>
      <b-col class="d-none d-md-flex justify-content-center">
        <img src="@/assets/registry/trust-hierarchy.png" height=400 />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "Trust",
};
</script>
