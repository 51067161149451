import Vue from 'vue'
import Config from '@/Config'
import { setDictValue } from '../../common'

const state = {
	list: [],
    _lookup: {},
}

const getters = {
    get: (state) => (identifier) =>
        state._lookup[identifier],
    getAll: (state) => () =>
        state.list,
}

const actions = {
	toggle({dispatch, commit}, resource_identifier) {
        dispatch( (state._lookup[resource_identifier] == undefined) ? 'add' : 'delete', resource_identifier)
	},
    loadAll ({dispatch, commit}) {
        return Vue.http.get(Config.API_PROFILE_URL + '/favorites').then((response) => {
			commit('setAll', response.data)
		})
    },
    add ({dispatch, commit}, resource_identifier) {
        return Vue.http.post(Config.API_PROFILE_URL + '/favorites', {resource_identifier}).then((response) => {
			return dispatch('loadAll')
		})
	},
	delete({dispatch, commit}, resource_identifier) {
        return Vue.http.delete(Config.API_PROFILE_URL + '/favorites/' + resource_identifier).then((response) => {
            return commit('delete', resource_identifier)
		})
	},
}

const mutations = {
    delete (state, identifier) {
        Vue.delete(state.list, state.list.indexOf(state.list.find(item => item.identifier === identifier)))
        delete state._lookup[identifier]
    },
    setAll (state, favorites) {
        state.list.splice(0, state.list.length)
        for (let favorite of favorites.data) {
            state.list.push(favorite)
            setDictValue(state._lookup, favorite.identifier, favorite)
		}
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
    namespaced: true
  }
  