<template>
  <b-card class="file-tile tile" no-body onClick="">
    <b-link style="text-decoration: none;"
            :to="{name: 'filePage', params: {
                  identifier: file.identifier, bundleIdentifier: file.bundle_identifier}}">
        <div style="padding-left: 30px; padding-right: 35px; margin-top: 10px;">
          <b-row class="file-title">
            <h3>
              <img style="height: 1.75rem; padding-right: 1rem;" v-if="file.icon && file.icon.url" :src="file.icon.url" alt="File icon"/>
              {{file.name}}
            </h3>
            <h6><b-badge class="lade-label">{{file.qualifier}}</b-badge></h6>
          </b-row>
          <b-row class="card-row"><div><B>Tag: </B>{{file.alias}}:{{file.qualifier}}</div></b-row>
          <b-row>
            <small>
              Last update {{ file.created_at | print_date }}
            </small>
          </b-row>
<!--          <b-row class="card-row"><div><B>Size: </B>{{file.size}}</div></b-row>-->
          <b-row class="card-row" style="color: grey; height: 20px; overflow-y:hidden; text-overflow: ellipsis;">
            <vue-markdown>{{ description }}</vue-markdown>
          </b-row>
          <b-row class="labels">
            <b-badge class="label-badge lade-label" v-for="label in file.labels" :key="label">{{label}}</b-badge>
          </b-row>
        </div>
      <div class="right-corner lade-label">
        <p>Size: {{ file.size | print_human_readable_size }}</p>
      </div>
    </b-link>
  </b-card>
</template>

<script>

import {truncateWithEllipses} from "@/common";

export default {
  name: 'FileTile',
  components: {
  },
  props: {
    resource: Object,
  },
  data: function() {
    return {
      isBusy: true,
      preview: null,
      file: this.resource,
    }
  },
  mounted: function() {
  },
  computed: {
    description: function() {
      const regex = /[#]+ .+/ig
      return truncateWithEllipses(this.file.description.replaceAll(regex, ""), 150)
    }
  },
  methods: {
  }
}
</script>

<style scoped>

.file-title {
  align-items: center;
  color: var(--lade-color-dark);
}

small {
  color: var(--lade-color-dark);
}

.file-title h2 {
  margin-right: 10px
}

.file-title h3 {
  margin-right: 10px;
  font-size: 1.75rem;
}

.labels {
  padding-top: 12px;
  padding-bottom: 2px;
}

.label-badge {
  font-size: 90%;
  text-align: center;
  vertical-align: center;
  padding: 5px;
}

</style>
