<template>
  <b-modal size="lg" id="userEditFormModal" hide-footer>
    <template v-slot:modal-title>
      <Icon :icon="icon"/>
      {{ title }}
    </template>
    <form v-on:submit.prevent="onAddEditUser">
      <div class="card">
        <div class="card-header">
          Credentials
        </div>
        <div class="card-body">
          <div class="form-group" v-if="edit">
            <label for="UserIdentifier">Identifier</label>
            <input type="text" v-model="identifier" class="form-control" id="UserIdentifier"
                   aria-describedby="UserIdentifierHelp" disabled>
            <small id="UserIdentifierHelp" class="form-text text-muted"> Unique identifier of the user</small>
          </div>
          <div class="form-group" v-if="edit">
            <label>Creation date</label>
            <input type="text" class="form-control" :value="created_at | print_date" disabled>
          </div>
          <div class="form-group" v-if="edit">
            <label>Origin</label>
            <input type="text" class="form-control" :value="origin | capitalize" id="UserOrigin"
                   aria-describedby="UserOriginHelp" disabled>
            <small id="UserOriginHelp" class="form-text text-muted">Authentication provider</small>

          </div>
          <div class="form-group">
            <label for="UserUsername">Username</label>
            <input type="text" v-model="name" class="form-control" autocomplete="off"
                   id="UserUsername" aria-describedby="UserUsernameHelp" placeholder="Enter username"
                   required pattern="[0-9a-zA-ZÀ-ÿ-._ ]+" minlength="3" maxlength="20"
                   v-bind:disabled="isUsernameDisabled()">
            <small id="UserUsernameHelp" class="form-text text-muted">Must be alphanumeric (dashes and underscores are
              authorized) and 3-20 characters long</small>
          </div>
          <div class="form-group">
            <label for="UserEmail">Email</label>
            <input type="email" v-model="email" class="form-control" autocomplete="off" :disabled="origin==='ldap'"
                   id="UserEmail" placeholder="Enter email">
          </div>
          <div class="form-group">
            <label for="UserPassword">Password</label>
            <input type="password" ref="passwordField" v-model="password" class="form-control"
                   autocomplete="off" v-bind:class="getPasswordsClass()" id="UserPassword" :disabled="origin==='ldap'"
                   aria-describedby="UserPasswordHelp" placeholder="Enter password" minlength="6" maxlength="50">
            <small id="UserPasswordHelp" class="form-text text-muted"></small>
          </div>
          <div class="form-group">
            <label for="UserConfirmPassword">Confirm password</label>
            <input type="password" v-model="confirmPassword" class="form-control"
                   autocomplete="off" v-bind:class="getPasswordsClass()" id="UserConfirmPassword"
                   :disabled="origin==='ldap'"
                   aria-describedby="UserConfirmPasswordHelp" placeholder="Confirm the password" minlength="6"
                   maxlength="50">
            <small id="UserConfirmPasswordHelp" class="form-text text-muted"></small>
          </div>
          <div class="form-group">
            <b-form-checkbox v-model="reset" name="check-button" switch :disabled="origin==='ldap'">
              User will reset his password at next login
            </b-form-checkbox>
          </div>
          <InputImage prefix="User" name="icon" @input="(file) => {icon_updated=file}"/>
          <button type="submit" class="btn btn-primary" :disabled="origin==='ldap'">Submit</button>
        </div>
      </div>
    </form>
    <form>
      <div class="card">
        <div class="card-header">
          Groups
        </div>
        <div class="card-body">
          <table style="border: 0; margin-bottom: 10px">
            <tr  v-for="group in groups"
                 :key="group.identifier">
              <td style="padding-left: .75rem">
                {{ group.name }}
              </td>
              <td style="padding-left: 10px">
                <a v-if="!isGroupDisabled(group)" class="btn btn-danger"
                   style="height: 1.5rem; width: 1.5rem; margin: 0 auto; padding: 0; text-align: center; line-height: 1.5rem;"
                   :data-confirm="'Are you sure you want to remote' + name + 'from ' + group.name "
                   title="Remove user from group"
                   @click="deleteGroup(group)">
                  <font-awesome-icon :icon="['fa', 'times']" style=" cursor: pointer;"/>
                </a>
              </td>
            </tr>
          </table>
          <TypeAhead resourceType="group"
                     ref="scopeTypeAhead"
                     class="b-md-4"
                     id="RuleScopeType"
                     @hit="addGroup($event)"/>
        </div>
      </div>
      <b-card header="Manager roles for user" v-if="manageRoles">

        <div  v-if="showRoles">
          <b-card v-for="group in groups"
               :key="group.identifier"
                  :header="'Assign roles in groups '+group.name">

            <b-spinner v-if="waitRoles(group)" variant="primary"
                       small
                       style="margin-left: -1.25rem; margin-right: 0.25rem;"/>

            <div v-else v-for="role in group.roles"
                   :key="role.identifier"
                   class="form-check">

              <b-spinner v-if="loadingIdentifiers.includes(role.identifier)"
                         variant="primary"
                         small
                         style="margin-left: -1.25rem; margin-right: 0.25rem;"/>

              <input class="form-check-input"
                     :ref="role.identifier"
                     type="checkbox"
                     v-else
                     :value="role.identifier"
                     :id="role.identifier + '-role-checkbox'"
                     :checked="role.default || hasUserRole(identifier, role.identifier)"
                     :disabled="role.default"
                     @click.prevent="onRoleChange($event, identifier, group.identifier, role.identifier)">

                <label class="form-check-label"
                       style="margin-left:7px; vertical-align:middle"
                       :for="role.identifier + '-role-checkbox'">

                  <b-badge variant="light"
                           v-b-tooltip.hover
                           :title="role.description">
                    <font-awesome-icon :icon="['fa', 'lock']"
                                       v-if="role.protected"
                                       style="margin-right: 2px; color:grey;"/>
                    {{role.name}}
                  </b-badge>

                </label>

              </div>
          </b-card>
        </div>
        <div v-else>
          <b-spinner variant="primary"
                     small
                     style="margin-left: -1.25rem; margin-right: 0.25rem;"/>
        </div>
      </b-card>
    </form>
  </b-modal>
</template>

<script>
import store from '../../store'
import {sanitize} from '@/common'
import InputImage from '../form/InputImage'
import Icon from '../form/Icon'
import TypeAhead from "@/components/form/TypeAhead";

export default {
  name: 'UserEditForm',
  components: {InputImage, Icon, TypeAhead},
  data() {
    return {
      title: "",
      name: "",
      email: "",
      password: "",
      groups: [],
      confirmPassword: "",
      groupIdentifier: null,
      group: null,
      identifier: "",
      origin: "",
      edit: false,
      add: false,
      reset: true,
      icon: {},
      icon_updated: null,
      manageRoles:false,
      roles:[],
      created_at: null,
      showRoles: false,
      loadedRolesIdentifiers: [],
      loadingIdentifiers: []
    }
  },
  computed: {
  },
  methods: {
    resetform(title) {
      this.title = title
      this.edit = false
      this.add = false
      this.reset = true
      this.identifier = ""
      this.name = ""
      this.email = ""
      this.password = ""
      this.groups = []
      this.confirmPassword = ""
      this.icon = {}
      this.icon_updated = null
      this.roles = []
      this.manageRoles=false
      this.showRoles=false
    },
    onAddEditUser() {
      if (this.password !== this.confirmPassword) {
        this.$eventBus.$emit('error', 'Passwords mismatch')
        this.$refs.passwordField.focus()
        return
      }

      if (this.password.length === 0 && !this.edit) {
        this.$eventBus.$emit('error', "Passwords must be filled.")
        this.$refs.passwordField.focus()
        return
      }

      let data = {
        "name": this.name,
        "email": this.email,
        "reset": this.reset
      }

      if (this.icon_updated != null) {
        data["icon"] = this.icon_updated
      }

      if (this.password.length > 0) {
        data["password"] = this.password
      }

      if (this.edit) {
        this.$store.dispatch('users/update', {identifier: this.identifier, data: sanitize(data)}).then((response) => {
          this.$bvModal.hide("userEditFormModal")
          this.$emit('user-updated', this.identifier)
          this.$eventBus.$emit('success', 'The user has been updated')
        }).catch((response) => {
          this.$eventBus.$emit('http-error', response)
        })
      } else {
        if (this.groups.length > 0) {
          this.$store.dispatch('users/addInGroup', {
            data: sanitize(data), groupIdentifier: this.groups[0].identifier, groups: this.groups.slice(1)
          }).then(() => {
            this.$bvModal.hide("userEditFormModal")
            this.$emit('user-added')
            this.$eventBus.$emit('success', 'The user has been created')
          }).catch((response) => {
            this.$eventBus.$emit('http-error', response)
          })
        } else {
          this.$store.dispatch('users/add', sanitize(data)).then(() => {
            this.$bvModal.hide("userEditFormModal")
            this.$emit('user-added')
            this.$eventBus.$emit('success', 'The user has been created')
          }).catch((response) => {
            if (response.status === 403) {
              this.$eventBus.$emit('error', "You don't have the permission to add an user without groups.")
            } else {
              this.$eventBus.$emit('http-error', response)
            }
          })
        }
      }
    },
    getPasswordsClass() {
      if ((this.password.length > 0 || this.confirmPassword.length > 0) && this.password !== this.confirmPassword) {
        return "is-invalid"
      }
      return false
    },
    isUsernameDisabled() {
      if (!this.edit)
        return false
      if (this.origin === 'ldap')
        return true
      return store.getters['users/get'](this.identifier).name === 'admin';
    },
    hasUserRole(userIdentifier, role_identifier) {
      return store.getters['users/hasRole'](userIdentifier, role_identifier)
    },
    isGroupDisabled(group) {
      if (group.origin === 'ldap')
        return true
      return this.edit && this.name === 'admin' && group.name === 'admin'
    },
    addGroup(groupIdentifier) {
      if (groupIdentifier === null) {
        return
      }
      let groupIdentifiers = this.groups.map(({ identifier }) => identifier)
      if (groupIdentifiers.includes(groupIdentifier)) {
        this.$eventBus.$emit('error', "User is already a member of this groups")
        this.$refs.scopeTypeAhead.clear();
        return
      }
      store.dispatch('groups/load', groupIdentifier).then(() => {
        let group = store.getters["groups/get"](groupIdentifier)
        this.groups.push(group)
        this.$refs.scopeTypeAhead.clear();
        if (this.edit) {
          store.dispatch('users/addGroup',
            {userIdentifier: this.identifier, groupIdentifier: groupIdentifier}).then(() => {
            this.updateGroupRoles(group)
          }).catch((response) => {
            let index = this.groups.indexOf(group);
            this.groups.splice(index, 1)
            this.$eventBus.$emit('http-error', response)
          })
        }
      })
    },
    deleteGroup(group) {
      let index = this.groups.indexOf(group);
      this.groups.splice(index, 1)
      store.dispatch('users/deleteGroup',
        {userIdentifier: this.identifier, groupIdentifier: group.identifier}).catch((response) => {
        this.groups.push(group)
        this.$eventBus.$emit('http-error', response)
      })
    },
    updateGroupRoles(group) {
      store.dispatch('roles/loadGroup', group.identifier).then((groupIdentifier) => {
        this.loadedRolesIdentifiers.push(groupIdentifier)
        group.roles = store.getters["roles/getGroup"](group.identifier)
        this.showRoles = true
      })
    },
    waitRoles(group) {
      return ! this.loadedRolesIdentifiers.includes(group.identifier)
    },
    onRoleChange(event, userIdentifier, groupIdentifier, roleIdentifier) {
      const target = event.target;
      this.loadingIdentifiers.push(roleIdentifier)
      if (target.checked === true) {
        store.dispatch('users/addRole', {userIdentifier, groupIdentifier, roleIdentifier}).then(() => {
          this.loadingIdentifiers.splice(this.loadingIdentifiers.indexOf(roleIdentifier), 1)
          this.$forceUpdate()
        }).catch((response) => {
          this.$eventBus.$emit('http-error', response)
        })
      } else {
        store.dispatch('users/deleteRole', {userIdentifier, groupIdentifier, roleIdentifier}).then(() => {
          this.loadingIdentifiers.splice(this.loadingIdentifiers.indexOf(roleIdentifier), 1)
          this.$forceUpdate()
        }).catch((response) => {
          this.$eventBus.$emit('http-error', response)
        })
      }
    },
    show(user=null) {
      if (user) {
        this.resetform("Edit user")
        this.identifier = user.identifier
        this.name = user.name
        this.email = user.email
        this.reset = user.reset
        this.edit = true
        this.origin = user.origin
        this.icon = user.icon
        this.roles = user.roles
        this.created_at = user.created_at
        this.origin = user.origin
        this.groups = [...user.groups]
        this.loadedRolesIdentifiers = []
        this.loadingIdentifiers = []
        for (let group of this.groups) {
          this.updateGroupRoles(group)
        }
        this.$bvModal.show("userEditFormModal")
        this.manageRoles = true
      } else {
        console.log("CREATE")
        this.resetform("Add User")
        this.add = true
        this.$bvModal.show("userEditFormModal")
      }
    }
  }
}
</script>
