import Vue from 'vue'
import Config from '@/Config'
import { setDictValue } from '@/common'

const state = {
    _lookup: {},
}

const getters = {
    getBundle: (state) => (bundleIdentifier) =>
        state._lookup[bundleIdentifier],
}

const actions = {
	loadBundle ({dispatch, commit}, {bundleIdentifier, user=''}) {
		var loadReviews_url = Config.API_BUNDLES_URL + "/" + bundleIdentifier + '/reviews'
        if (user !== '')
			loadReviews_url = loadReviews_url + '?user=' + user
		return Vue.http.get(loadReviews_url).then((response) => {
			commit('setBundle', {bundleIdentifier, reviews:response.data})
		})
	},
	add ({dispatch, commit}, {bundleIdentifier, review}) {
		return Vue.http.post(Config.API_BUNDLES_URL + "/" + bundleIdentifier + "/reviews", review).then((response) => {
			return dispatch('loadBundle', {bundleIdentifier})
		})
	},
}

const mutations = {
    setBundle (state, {bundleIdentifier, reviews}) {
      if (state._lookup[bundleIdentifier] === undefined) {
        state._lookup[bundleIdentifier] = reviews.data
      } else {
        console.log(state._lookup[bundleIdentifier])
        state._lookup[bundleIdentifier].splice(0, state._lookup[bundleIdentifier].length)
        for (let review of reviews.data) {
          state._lookup[bundleIdentifier].push(review)
        }
      }
    },
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true
}
