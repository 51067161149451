<template>
  <ResourceList
    resource="bundles"
    :getter="getter"
    :dispatchActions="dispatchActions"
    addIcon="plus"
    ref="bundlelist"
    viewEvent="view-bundle-show"
    editEvent="edit-bundle-show"
    deleteEvent="delete-bundle-show"
    exportEvent="export-bundle"
    :addEvent="canAddBundle()?'add-bundle-event':undefined"
    resourceListForm="BundleListForm"
    hideSearch
    :checkbox=false
    :busy="busy"
    @viewBundles="viewBundle">

    <template slot="actionsHeader">
      <font-awesome-icon
        class='fa-icon action-icon'
        :icon="['fas', 'sync']"
        title="Reload bundles"
        @click="$store.dispatch('bundles/loadGroup', groupIdentifier)"/>

      <font-awesome-icon
        class='fa-icon action-icon'
        :icon="['fas', 'plus']"
        title="Add bundle"
        @click.stop="addBundle = !addBundle"
        style="margin-left:0.8em;"
        v-show="!addBundle"/>
    </template>

    <template v-slot:customActions="actions">
      <font-awesome-icon
        class='fa-icon action-icon'
        :icon="['fas', 'share-square']"
        title="Move bundle"
        v-if="isEditable(actions.row.item)"
        @click.stop="$eventBus.$emit('move-bundle-show', actions.row.item.identifier)"
        style="margin-left:0.8em;"/>
    </template>

    <template slot="top-row" v-if="addBundle">
      <td style="width:20%">
        <form method="GET" id="bundleForm" ref="bundleForm"></form>
        <input type="text" v-model="name" autocomplete="off" placeholder="Name"
               required class="form-control" form="bundleForm"/>
      </td>
      <td>
        <div class="input-group">
          <input type="text" v-model="alias" autocomplete="off" placeholder="Alias"
                 required class="form-control" pattern="[a-zA-Z0-9\-_]*" form="bundleForm" minlength="3" maxlength="20"
                 title="Must be alphanumeric (dashes and underscores are authorized)"/>
          <div class="input-group-prepend input-group-append">
            <span class="input-group-text">:</span>
          </div>
          <input type="text" v-model="qualifier" autocomplete="off" placeholder="Version"
                 required class="form-control" form="bundleForm"/>
        </div>
      </td>
      <td colspan="2">
      </td>
      <td style="vertical-align:middle;">

        <font-awesome-icon
          class='fa-icon action-icon'
          :icon="['fas', 'check']"
          @click.stop="addBundleInGroup()"
          style="color: dodgerblue; margin-left:0.3em;"/>

        <font-awesome-icon
          class='fa-icon action-icon'
          :icon="['fas', 'times']"
          @click.stop="addBundle=false"
          style="color: indianred; margin-left:0.8em;"/>

      </td>
    </template>
  </ResourceList>
</template>

<script>
import store from '../../store'
import {Tag} from '@/common'

export default {
  name: "BundleTab",
  data() {
    return {
      name: null,
      alias: null,
      qualifier: null,
      addBundle: null,
      busy: false,
      filters: store.getters['bundles/getFilters'](),
      group: store.getters['groups/get'](this.groupIdentifier),
      dispatchActions: [{
        event: 'bundles/loadGroup',
        parameters: {groupIdentifier: this.groupIdentifier, orderBy: 'created_date', limit: -1}
      }],
      getter: {event: 'bundles/getAll'}
    }
  },
  created() {
    this.$eventBus.$on("add-bundle-event", (identifier) => {
      this.addBundle = !this.addBundle
    })
  },
  props: {
    groupIdentifier: String
  },
  methods: {
    currentPage: function () {
      return {text: 'Group List', to: 'groups', identifier: this.group.identifier, resource:'bundles'}
    },
    viewBundle (identifier){
      this.$router.push({
        name: "bundlePage",
        params: {
          identifier: identifier, previousPage: this.currentPage()
        },
      });
      console.log(this.currentPage())
    },
    canAddBundle() {
      let required = store.getters['token/getPermission']("BUNDLE", "CREATE")
      for (let permission of this.group.authz.resources_permissions){
        if (permission.resource === "BUNDLE"){
          return (permission.permission & required) === required
        }
      }
      return false
    },
    resetFields() {
      this.name = null
      this.alias = null
      this.qualifier = null
    },
    isEditable(entry) {
      if (entry.authz && (entry.authz.permission & 0x04) === 0) {
        return false;
      }
      return this.filters.isEditable(entry)
    },
    addBundleInGroup() {
      if (!this.$refs.bundleForm.reportValidity()) {
        return
      }
      let data = {
        "tag": new Tag(this.alias, this.qualifier).print(),
        "name": this.name,
      }

      store.dispatch('bundles/add', {group_identifier: this.groupIdentifier, data: data}).then(() => {
        this.resetFields()
        this.$refs.bundlelist.loadResources()
      }).catch((response) => {
        this.$eventBus.$emit('http-error', response)
      })
    },
    exportBundle(bundle) {
      store.dispatch('bundles/export', {bundle_identifier: bundle.identifier, resources: null}).then(() => {
        this.$eventBus.$emit('success', 'The bundle has been exported')
      }).catch((response) => {
        this.$eventBus.$emit('http-error', response)
      })
    }
  },
}
</script>
