<template>
  <div class="sectionEditGroup">
    <div class="content">
      <div class="head-content">
        <h2>Users</h2>
        <div class="menu-edit-user" v-if="editUser">
          <button
            v-if="!loadingUserForm"
            @click.stop="resetEditUser = true"
            class="btn button btn-secondary">
            Cancel
          </button>
          <button
            v-if="!loadingUserForm"
            @click.stop="validatEditUser = true"
            type="submit"
            class="btn btn-primary">
            Submit
          </button>
          <b-spinner v-else class="loading top"></b-spinner>
        </div>
      </div>
      <div class="edit">
        <b-modal size="md" ref="editRolesFormModal" hide-footer>
          <template v-slot:modal-title>
            Edit users roles
          </template>

          <b-spinner v-if="roles === []" variant="primary"
                     small
                     style="margin-left: -1.25rem; margin-right: 0.25rem;"/>
          <form v-else>
            <div v-for="role in roles" :key="role.identifier" class="form-check">
              <input v-if="role.default" class="form-check-input"
                     :ref="role.identifier"
                     type="checkbox"
                     :id="role.identifier + '-role-checkbox'"
                     :checked="role.default"
                     :disabled="role.default">
              <input v-else class="form-check-input"
                     :ref="role.identifier"
                     type="checkbox"
                     :value="role"
                     :id="role.identifier + '-role-checkbox'"
                     :checked="role.default"
                     :disabled="role.default"
                     v-model="selectedRoles">

              <label class="form-check-label"
                     style="margin-left:7px; vertical-align:middle"
                     :for="role.identifier + '-role-checkbox'">

                <b-badge variant="light"
                         v-b-tooltip.hover
                         :title="role.description">
                  <font-awesome-icon :icon="['fa', 'lock']"
                                     v-if="role.protected"
                                     style="margin-right: 2px; color:grey;"/>
                  {{ role.name }}
                </b-badge>
              </label>
            </div>
            <div style="margin-top: 10px; padding-right:30px; padding-bottom:10px;">
              <b-button class="limit-button"
                        @click="addUsersRoles"
                        title="Add checked roles to users">
                <font-awesome-icon :icon="['fas', 'plus']" style="color: dodgerblue;" />
                <span style="margin-left: 5px; color: dodgerblue;">Add roles</span>
              </b-button>
              <b-button class="limit-button"
                        @click="deleteUsersRoles"
                        title="Delete checked roles from users">
                <font-awesome-icon
                  :icon="['fas', 'minus']"
                  style="color: indianred;"/>
                <span style="margin-left: 5px; color: indianred;">Delete roles</span>
              </b-button>
            </div>
          </form>
        </b-modal>

        <EditUserForm
          v-if="editUser"
          :user="userEditing"
          :resetEditUser="resetEditUser"
          :validatEditUser="validatEditUser"
          @CancelEditUser="cancelEditUser"
          @validateForm="validatEditUser = false"
          @resetForm="resetEditUser = false"
          @loading="loadingUserForm = true"
        />

        <EditGroupRoles v-if="manageRole" :group-identifier="group.identifier" @CancelEditRoles="manageRole = false"/>

        <div class="div-create-user" v-if="addUser && !editUser && !manageRole">
          <nav>
            <h3 @click.stop="addExisting = false" :class="{'active':!addExisting}">
              Create user
              <img src="@/assets/plus.png" alt="create new user"/>
            </h3>
            <h3 @click.stop="addExisting = true" :class="{'active':addExisting}">
              Add existing user
              <img src="@/assets/loupe.png" alt="add existing user"/>
            </h3>
          </nav>
          <form v-if="addExisting === false" v-on:submit.prevent="createUserInGroup" ref="userForm">
            <div class="input-group">
              <div :class="[{'active': name.length > 0}, 'required']">*</div>
              <span class="input-group-text input-group-prepend"><font-awesome-icon :icon="['fas', 'user']"/></span>
              <input type="text" v-model="name" class="form-control" autocomplete="off"
                     placeholder="User name" pattern="[0-9a-zA-ZÀ-ÿ-._ ]+" minlength="3" maxlength="20"
                     title="Must be alphanumeric (dashes and underscores are authorized)"
                     form="userForm" required>
            </div>
            <div class="names">
              <div class="input-group">
                <div :class="[{'active': firstname.length > 0}, 'required']">*</div>
                <span class="input-group-text input-group-prepend"><font-awesome-icon :icon="['fas', 'user']"/></span>
                <input type="text" v-model="firstname" class="form-control" autocomplete="off"
                       placeholder="First name" pattern="[0-9a-zA-ZÀ-ÿ-._ ]+" minlength="3" maxlength="20"
                       title="Must be alphanumeric (dashes and underscores are authorized)"
                       form="userForm" required>
              </div>
              <div class="input-group">
                <div :class="[{'active': lastname.length > 0}, 'required']">*</div>
                <span class="input-group-text input-group-prepend"><font-awesome-icon :icon="['fas', 'user']"/></span>
                <input type="text" v-model="lastname" class="form-control" autocomplete="off"
                       placeholder="Last name" pattern="[0-9a-zA-ZÀ-ÿ-._ ]+" minlength="3" maxlength="20"
                       title="Must be alphanumeric (dashes and underscores are authorized)"
                       form="userForm" required>
              </div>
            </div>
            <div class="input-group">
              <span class="input-group-text input-group-prepend"><font-awesome-icon :icon="['fas', 'at']"/></span>
              <input type="email" v-model="email" class="form-control"
                     autocomplete="off" placeholder="Email - Optional" form="userForm">
            </div>
            <div class="input-group">
              <span class="input-group-text input-group-prepend"><font-awesome-icon :icon="['fas', 'image']"/></span>
              <b-form-file :accept="accept" type="file" :id="imageId" placeholder="Profile photo - Optional"
                           v-model="image" :required="false" :disabled="false" class="select-img"/>
            </div>
            <div class="input-group">
              <div :class="[{'active': password.length > 0}, 'required']">*</div>
              <span class="input-group-text input-group-prepend"><font-awesome-icon :icon="['fas', 'key']"/></span>
              <input type="password" ref="passwordField" v-model="password"
                     class="form-control" autocomplete="off" id="UserPassword" placeholder="Password"
                     minlength="6" maxlength="50" form="userForm" required>
            </div>
            <div class="input-group">
              <div :class="[{'active': confirmPassword.length > 0}, 'required']">*</div>
              <span class="input-group-text input-group-prepend input-group-append"><font-awesome-icon :icon="['fas', 'key']"/></span>
              <input type="password" v-model="confirmPassword" class="form-control"
                     autocomplete="off" id="UserConfirmPassword" placeholder="Password confirmation" minlength="6"
                     maxlength="50"
                     form="userForm" required ref="passwordConfirm">
            </div>
            <div class="option-form">
              <button class="btn button btn-secondary"
                      @click.stop="cancelAddUser">
                Cancel
              </button>
                <button type="submit" class="btn btn-primary">
                  Create
                </button>
            </div>
          </form>
          <form v-if="addExisting"
                ref="existingUserForm" class="add-existing-user"
                v-on:submit.prevent="addUserEvent">
            <TypeAhead prefix="existingUserForm" resourceType="user"
                       v-model="existingUser" class="mb-2 search-user" id="existingUser"
                       ref="existingUser" required
                       @hit="existingUserIdentifier = $event"/>
            <div class="option-form">
              <button class="btn button btn-secondary"
                      @click.stop="addUser = false">
                Cancel
              </button>
              <button type="submit" class="btn btn-primary">
                Add
              </button>
            </div>
          </form>
        </div>

        <div class="actions-bar" v-if="!editUser && !manageRole">
          <div class="users-actions">
            <input type="checkbox" id="select-all-users" class="select-all-users" name="select" v-model="selectedUsers.length === users.length"
                   @click="selectAll">
            <div v-if="selectedUsers.length > 0" class="resource-selected-actions">
              <button v-if="group.identifier" class="roles-user"
                        @click="showEditRolesModal()">
                <font-awesome-icon
                  :icon="['fas', 'user-shield']"
                  title="Set roles selected users"/>
                <p>Set roles</p>
              </button>
              <button class="delete-user"
                        @click.stop="deleteUsers()">
                <font-awesome-icon
                  :icon="['fas', 'trash']"
                  style="color: indianred;"
                  title="Delete selected users"/>
                <p>Delete</p>
              </button>
              <span style="margin-left: 5px">|</span>
            </div>

            <div class="resource-selected-actions">

              <button class="refresh-user"
                        @click="page=1 ; get_users()">
                <font-awesome-icon :icon="['fas', 'sync']"/>
                <p>Refresh</p>
              </button>
              <button v-if="group.identifier !== undefined && !addUser" class="add-user"
                      @click="group.identifier ? addUser=true: addUserEvent()">
                <font-awesome-icon :icon="['fas', 'user-plus']"/>
                <p>Add user</p>
              </button>
              <button class="manage-roles"
                      @click.stop="manageRole = true">
                <font-awesome-icon :icon="['fas', 'id-badge']"/>
                <p>Manage roles</p>
              </button>
            </div>
          </div>
          <div
              class="search">
            <font-awesome-icon :icon="['fas', 'magnifying-glass']" />
            <input
                type="search"
                ref="searchBundle"
                class="input-search"
                v-model="search"
                placeholder="Search user"
                @keyup="filter"
                @mouseup="mouseupSearchEvent"
                style="padding: 2px; height:20px; width: 100%; margin: 0.350rem auto;"
            />
          </div>
          <div class="pagination">
              <b-button :class="[page > 1 ? 'page-chevron-active' : 'page-chevron-deactivated', 'page-chevron']"
                        @click="previous_page()">
                <font-awesome-icon :icon="['fas', 'chevron-left']"/>
              </b-button>
              <span class="pagination-text">
              <b-button class="pagination-button">
              {{ skip + 1 }} - {{ Math.min(skip + limit, total) }} on {{ total }}
              </b-button>
              <div class="page-options">
                <div style="text-align: center; color: var(--lade-color-grey); font-weight: 500; user-select: none; ">
                  Order By
                </div>
                <div style="display: flex; justify-content: center; flex-wrap: wrap">
                  <b-button v-for="item in sortOptions"
                            :key="item.text"
                            @click.up="updateSort(item)"
                            :class="[isActiveSortOption(item) ? 'page-option-active': '', 'page-option']">
                    {{ item.text }}
                  </b-button>
                </div>
                <div style="text-align: center; color: var(--lade-color-grey); font-weight: 500; user-select: none; ">
                  Display up to
                </div>
                <div style="display: flex; justify-content: center; flex-wrap: wrap">
                  <b-button v-for="lim in limitOptions"
                            :key="lim"
                            @click.up="updateLimit(lim)"
                            :class="[lim === limit? 'limit-selected': '', 'page-option']">
                    {{ lim }}
                  </b-button>
                </div>
              </div>
            </span>
              <b-button :class="[page < total_pages ? 'page-chevron-active' : 'page-chevron-deactivated', 'page-chevron']"
                        @click="next_page()">
                <font-awesome-icon :icon="['fas', 'chevron-right']" />
              </b-button>
          </div>
        </div>

        <b-table show-empty v-if="!busy && !editUser && !manageRole" :items="users"
                 ref="user-table"
                 class="table_"
                 :tbody-tr-attr="getLineAttr"
                 :fields="['checkbox', 'name', 'last_logged_in', 'created_at', 'roles', 'actions']">


          <template v-slot:cell(name)="row">
            <div class="case-name">
              <div class="div-icon">
                <img v-if="row.item.icon !== undefined && row.item.icon !== null" :src="row.item.icon.url" alt="icon of the user" class="icon-user"/>
                <img v-else src="@/assets/no-icon.png" alt="icon of the user" class="icon-user"/>
                <div v-if="row.item.locked" class="lock">
                  <img src="@/assets/lock.png" alt="user locked"/>
                </div>
                <div v-else-if="row.item.active" class="online">
                </div>
                <div v-else class="offline">
                </div>
              </div>
              <div class="name-email">
                <p>
                  {{row.item.firstname}} {{row.item.lastname}}
                </p>
                <p>{{row.item.email}}</p>
              </div>
            </div>
          </template>

          <template v-slot:head(checkbox)="row">
            <br/>
          </template>

          <template v-slot:cell(checkbox)="row">
            <input type="checkbox" id="select-user" name="select" :value="row.item" v-model="selectedUsers">
          </template>

          <template v-slot:cell(roles)="row">
            <div class="div-roles">
              <p variant="light"
                 v-for="role of row.item.roles.filter((role) => role.group_identifier === group.identifier && !role.default)"
                 :key="role.identifier"
                 v-b-tooltip.hover
                 :title="role.description">
                <font-awesome-icon :icon="['fa', 'lock']"
                                   v-if="role.protected"
                                   style="margin-right: 2px; color:grey;"/>
                {{role.name}}
                <font-awesome-icon  :icon="['fas', 'times']"
                                    class="delete-role" title="remove this role"
                                    @click="deleteUserRole(row.item, role)"/>
              </p>
              <p class="add-roles" v-if="editRoles === null" @click.stop="editRoles = row.item" title="add role at this user">
                <font-awesome-icon :icon="['fas', 'plus']" @click="showInputLabel=true" class="add-label"/>
              </p>
            </div>
            <div v-if="editRoles !== null && editRoles.identifier === row.item.identifier" class="add-role-form">
              <p>Add roles</p>
              <div class="add-role-back" @click.stop="editRoles = null">
              </div>
              <b-spinner v-if="roles === []" variant="primary"
                         small
                         style="margin-left: -1.25rem; margin-right: 0.25rem;"/>
              <form v-else class="div-roles add">
                    <p v-for="role in roles" :key="role.identifier" class="form-check"
                       :title="role.description"
                        v-if="!haveThisRole(role, row.item)"
                        @click.stop="addUserRole(role)">
                      <span>{{ role.name }}</span>
                      <img src="@/assets/plus-white.png" alt="add roles"/>
                    </p>
              </form>
            </div>
          </template>

          <template v-slot:cell(created_at)="row">
            created the {{ row.item.created_at | print_date }}
          </template>

          <template v-slot:cell(last_logged_in)="row">
            Last login on {{ row.item.last_logged_in | print_date }}
          </template>

          <template v-slot:cell(actions)="row">
            <div class="actions">
              <font-awesome-icon
                class='fa-icon action-icon'
                v-if="isEditable(row.item)"
                @click.stop="setEditUser(row.item)"
                :icon="['fas', 'pencil-alt']"
                title="Edit resource" />
              <font-awesome-icon
                class='fa-icon action-icon'
                v-if="isDeletable(row.item)"
                @click.stop="deleteUser(row.item)"
                :icon="['fas', 'trash']"
                style="color: indianred;"
                title="Delete user"/>
              <font-awesome-icon
                class='fa-icon action-icon'
                v-if="isDisconnectable(row.item) && row.item.active"
                @click.stop="disconnect(row.item)"
                :icon="['fas', 'sign-out-alt']"
                title="Log out user"/>

              <font-awesome-icon
                class='fa-icon action-icon'
                v-if="isLockable(row.item) && !row.item.locked"
                @click.stop="lock(row.item.identifier)"
                :icon="['fas', 'lock']"
                title="Lock user"/>

              <font-awesome-icon
                class='fa-icon action-icon'
                v-if="isLockable(row.item) && row.item.locked"
                @click.stop="unlock(row.item.identifier)"
                :icon="['fas', 'unlock']"
                title="Unlock user"/>

              <slot name="customActions" :row="row"/>
            </div>
          </template>

        </b-table>
        <div class="text-center"
             style="padding-top:10px; padding-bottom:10px;"
             v-else-if="!editUser && busy">
          <b-spinner class="align-middle"></b-spinner>
          <strong style="margin-left:10px;">Loading...</strong>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TypeAhead from "@/components/form/TypeAhead.vue";
import store from "@/store";
import EditUserForm from "@/components/hub/groups/EditUserForm.vue";
import EditGroupRoles from "@/components/hub/groups/EditGroupRoles.vue";

export default {
  name: "editGroupBundleUsers",
  props: {
    group: Object,
    accept: {
      default: "image/*",
      type: String,
    },
  },
  components: {
    EditUserForm,
    TypeAhead,
    EditGroupRoles
  },
  computed: {
    users: function() {
      return this.$store.getters['users/getAll']()
    },
  },
  data: function () {
    return{
      loadingUserForm: false,
      editUser:false,
      resetEditUser: false,
      validatEditUser: false,
      manageRole: false,
      editRoles: null,
      userEditing: null,
      addExisting: false,
      image: null,
      imageId: "Icone",
      searchFilter: {},
      addUser: false,
      userAddTabIndex: null,
      name: "",
      lastname: "",
      firstname: "",
      email: "",
      password: "",
      confirmPassword: "",
      existingUser: null,
      existingUserIdentifier: "",
      busy: false,
      skip: 0,
      total: 0,
      page: 0,
      total_pages: 0,
      limit: 25,
      showLimitOptions: false,
      limitOptions: [10, 15, 25, 50],
      sortOptions: [
        {orderBy: 'name', desc: false, text: 'Alphabetical'},
        {orderBy: 'last_logged_in', desc: true, text: 'Last logged in'},
        {orderBy: 'created_date', desc: false, text: 'Created date'},
        {orderBy: 'created_date', desc: true, text: 'Oldest'},
      ],
      orderBy: 'created_date',
      desc: true,
      selectedUsers: [],
      selectedRoles: [],
      roles: [],
      search: "",
    };
  },
  mounted: function() {
    this.page = 1
    this.skip = 0
    this.get_users()
    if (this.group.identifier) {
      this.$store.dispatch('roles/loadGroup', this.group.identifier).then(() => {
        this.roles = this.$store.getters["roles/getGroup"](this.group.identifier)
      })
    }

  },
  methods: {
    get_users() {
      this.busy = true

      let payload = {
        orderBy: this.orderBy, desc: this.desc,
        page: this.page, limit: this.limit,
        load: "normal",
        search: this.search,
      }

      let loadType = 'users/loadAll'
      if (this.group.identifier) {
        loadType = 'users/loadGroup'
        payload['groupIdentifier'] = this.group.identifier
      }

      this.$store.dispatch(loadType, payload).then(() => {
        let pagination = this.$store.getters['users/getPagination']()
        this.page = pagination.page
        this.total_pages = pagination.total_pages
        this.limit = pagination.limit
        this.skip = pagination.skip
        this.total = pagination.total
        this.busy = false
        this.selectedUsers = []
      }).catch((response) => {
        console.log(response)
        this.$eventBus.$emit('http-error', response)
      })
    },
    isActiveSortOption(data) {
      return data.orderBy === this.orderBy && data.desc === this.desc
    },
    updateSort(data) {
      this.showSortOptions = false
      if (!this.isActiveSortOption(data)) {
        this.orderBy = data.orderBy
        this.desc = data.desc
      }
      this.get_users()
    },
    disconnect(user) {
      store.dispatch("users/disconnect", {userIdentifier: user.identifier})
    },
    cancelAddUser() {
      this.addUser = false
      this.initAddUser()
    },
    initAddUser() {
      this.image = null
      this.lastname = ""
      this.firstname = ""
      this.name = ""
      this.email = ""
      this.password = ""
      this.confirmPassword = ""
    },
    addUserEvent() {
      if (this.group.identifier) {
        if (this.userAddTabIndex === 0) {
          this.createUserInGroup()
        } else {
          this.addUserInGroup()
        }
      } else {
        this.$refs.setEditUserFormModal.show()
      }
    },
    addUserInGroup() {
      if (this.existingUserIdentifier != null) {
        this.busy = true;
        store.dispatch("users/addGroup", {
          userIdentifier: this.existingUserIdentifier,
          groupIdentifier: this.group.identifier
        }).then(() => {
          this.busy = false;
          this.get_users()
          this.initAddUser();
          this.$eventBus.$emit('success', 'The user has been added.')
        })
      }
    },
    createUserInGroup() {
      this.$refs.passwordConfirm.setCustomValidity("")
      if (this.password !== this.confirmPassword) {
        this.$refs.passwordConfirm.setCustomValidity("Passwords Don't Match")
        this.$refs.userForm.reportValidity()
        return
      } else if (!this.$refs.userForm.reportValidity()) {
        return
      }

      let data = {
        "name": this.name,
        "firstname": this.firstname,
        "lastname": this.lastname,
        "password": this.password
      }

      if (this.email !== "") {
        data["email"] = this.email
      }
      this.busy = true;
      store.dispatch('users/addInGroup', {data: data, groupIdentifier: this.group.identifier, groups: []}).then(() => {
        this.busy = false;
        this.get_users()
        this.initAddUser();
        this.$eventBus.$emit('success', 'The user has been created and added.')
      }).catch((response) => {
        this.busy = false;
        this.$eventBus.$emit('http-error', response)
      })
    },
    filter() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.get_users()
      }, 600);
    },
    isEditable(entry) {
      return !(entry.authz && (entry.authz.permission & 0x04) === 0);
    },
    isDeletable(entry) {
      return !(entry.authz && (entry.authz.permission & 0x08) === 0);

    },
    isDisconnectable(entry) {
      return !(entry.authz && (entry.authz.permission & 0x04) === 0);
    },
    isLockable(entry) {
      return !(entry.authz && (entry.authz.permission & 0x04) === 0);
    },
    getLineAttr(entry) {
      return (entry !== null && entry.locked) ? {"style": "color: var(--lade-color-grey);"} : {}
    },
    next_page() {
      if ( this.page < this.total_pages ) {
        this.page += 1
        this.get_users()
      }
    },
    previous_page() {
      if ( this.page > 1 ) {
        this.page -= 1
        this.get_users()
      }
    },
    sort_change() {
      this.orderBy = this.sortSelected['orderBy']
      this.desc = this.sortSelected['desc']
      this.get_users()
    },
    updateLimit (limit) {
      this.showLimitOptions = false
      if (limit !== this.limit){
        this.limit = limit
        this.get_users()
      }
    },
    mouseupSearchEvent(_) {
      let oldvalue = this.$refs.searchcontent.value;

      if (oldvalue === "") {
        return;
      }

      //// When this event is fired after clicking on the clear button
      //// the value is not cleared yet. We have to wait for it.
      setTimeout(() => {
        var newValue = this.searchContent
        if (newValue === "") {
          this.get_users()
        }
      }, 1);
    },
    setEditUser(user) {
      this.editUser = true;
      this.userEditing = user;
    },
    cancelEditUser() {
      this.editUser = false;
      this.userEditing = null;
      this.loadingUserForm = false;
    },
    deleteUser(user) {
      if (this.group.identifier) {
        this.$deleteModal({
          text: "Are you sure to delete selected users from groups " + this.group.name + " ?",
          input: "checkbox",
          inputValue: false,
          inputPlaceholder: 'Delete user from platform if orphaned (not present in any group)'
        }).then((result) => {
          if (result.isConfirmed) {
            store.dispatch('users/deleteGroup', {
              userIdentifier: user.identifier,
              groupIdentifier: this.group.identifier,
              delete_orphan: result.value
            }).then(() => {
              this.get_users()
            })
          }
        })
      } else {
        let text = ""
        if (user.origin === 'sql')
          text = "Are you sure to delete user " + user.name + "?"
        else if (user.origin === 'ldap')
          text = "Are you sure to unregister user " + user.name + "? It will not affect LDAP server in any kind."
        else
          text = "Are you sure to delete user " + user.name + "? It only unregisters the user from LADE but does not affect the authentication provider in any kind."
        this.$deleteModal({
          text: text
        }).then((result) => {
          if (result.value) {
            store.dispatch('users/delete', user.identifier).then(() => {
              this.$eventBus.$emit('success', 'The user has been deleted')
              this.get_users()
            }).catch((response) => {
              this.$eventBus.$emit('http-error', response)
            })
          }
        })
      }
    },
    selectAll() {
      if (this.selectedUsers.length !== this.users.length) {
        this.selectedUsers = [...this.users]
      } else {
        this.selectedUsers = []
      }
    },
    deleteUsers() {
      let selected_users = this.selectedUsers
      let promises = []
      if (this.group.identifier !== undefined) {
        this.$deleteModal({
          text: "Are you sure to delete selected users from " + this.group.name + " ?",
          input: "checkbox",
          inputValue: false,
          inputPlaceholder: 'Removing users from the platform when they are orphans'
        }).then((result) => {
          if (! result.isConfirmed) {
            return
          }
          for (let user of selected_users) {
            let promise
            promise = store.dispatch('users/deleteGroup',
              {
                groupIdentifier: this.group.identifier, userIdentifier: user.identifier,
                delete_orphan: result.value
              })
            promises.push(promise)
          }
          Promise.all(promises).then(() => {
            this.get_users()
          })
        })
      }
      else {
        this.$deleteModal({
          text: "Are you sure to delete selected users ?"
        }).then((result) => {
          if (!result.isConfirmed) {
            return
          }
          for (let user of selected_users) {
            let promise
            promise = store.dispatch('users/delete', user.identifier)
            promises.push(promise)
          }
          Promise.all(promises).then(() => {
            this.get_users()
          })
        })
      }

    },
    showEditRolesModal() {
      this.$refs.editRolesFormModal.show()
    },
    addUsersRoles() {
      console.log(this.selectedUsers)
      this.$store.dispatch('roles/assign_roles',
        {groupIdentifier: this.group.identifier, users: this.selectedUsers, addRoles: this.selectedRoles})
        .then(() => {
          this.get_users()
          this.selectedRoles = []
          this.$refs.editRolesFormModal.hide()
        }).catch((response) => {
        this.$eventBus.$emit('http-error', response)
      })
    },
    haveThisRole(role,user){
      let res = false;
      user.roles.forEach(item => {
        if(item.identifier === role.identifier){
          console.log(item.name)
          res=true;
        }
      })
      return res;
    },
    addUserRole(role) {
      console.log(this.group)
      this.$store.dispatch('roles/assign_roles',
        {groupIdentifier: this.group.identifier, users: [this.editRoles], addRoles: [role]})
        .then(() => {
          this.get_users()
          this.selectedRoles = []
          this.$refs.editRolesFormModal.hide()
        }).catch((response) => {
        this.$eventBus.$emit('http-error', response)
      })
      // let index = 0;
      // this.roles.forEach(roleG => {
      //   if(roleG.group_identifier === this.groups.identifier){
      //     this.editRoles.roles.forEach(roleU => {
      //       if(roleU.identifier === roleG){
      //         index ++;
      //       }
      //     })
      //   }
      // })
      // if(index === 0) {
      //   this.editRoles = null;
      // }
    },
    deleteUsersRoles() {
      this.$store.dispatch('roles/assign_roles',
        {groupIdentifier: this.group.identifier, users: this.selectedUsers, deleteRoles: this.selectedRoles})
        .then(() => {
          this.get_users()
          this.selectedRoles = []
          this.$bvModal.hide("editRolesFormModal")
        }).catch((response) => {
        this.$eventBus.$emit('http-error', response)
      })
    },
    deleteUserRole(user,role) {
      this.$store.dispatch('roles/assign_roles',
        {groupIdentifier: this.group.identifier, users: [user], deleteRoles: [role]})
        .then(() => {
          this.get_users()
          this.selectedRoles = []
          this.$bvModal.hide("editRolesFormModal")
        }).catch((response) => {
        this.$eventBus.$emit('http-error', response)
      })
    },
    lock (identifier) {
      this.$store.dispatch('users/update', {identifier: identifier, data: {"locked": true}}).catch((response) => {
        this.$eventBus.$emit('http-error', response)
      })
    },
    unlock (identifier) {
      this.$store.dispatch('users/update', {identifier: identifier, data: {"locked": false}}).catch((response) => {
        this.$eventBus.$emit('http-error', response)
      })
    },
    userUpdated(identifier) {
      for (let user in this.users) {
        if (user.identifier === identifier) {
          user.update(this.$store.getters["users/get"](identifier))
          break
        }
      }
    },
  }
}
</script>

<style scoped>
::-webkit-scrollbar {
  width: 5px;               /* width of the entire scrollbar */
}

::-webkit-scrollbar-thumb {
  background-color: var(--lade-color-grey);    /* color of the scroll thumb */
  border-radius: 20px;       /* roundness of the scroll thumb */
}

#select-user{
  margin: 50% auto 0 auto;
}

#select-all-users {
  margin: 10px auto 0 auto;
}

.sectionEditGroup .content {
  border: 1px solid var(--lade-color-white);
  width: 100%;
  position: relative;
  padding-bottom: 105px;
  overflow-y: auto;
}

.sectionEditGroup .edit {
  padding: 15px 15px 0 15px;
}

.fa-icon {
  margin-left: 4px;
  cursor: pointer;
}

.div-create-user {
  z-index: 0 !important;
  width: 100%;
  margin-bottom: 20px;
}

.div-create-user {
  position: relative;
}

.div-create-user nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 340px;
  border: solid 1px var(--lade-color-white);
  border-radius: 5px 5px;
  margin: 0 auto;
  position: relative;
}

.div-create-user nav h3 {
  font-size: 1.1em;
  text-align: center;
  width: 170px;
  margin: 0 0;
  padding: 5px 5px;
  cursor: pointer;
  transition: 0.3s ease-out;
  background-color: var(--lade-color-white-hover);
  display: flex;
  align-items: center;
  justify-content: center;
}

.div-create-user nav h3.active {
  border: solid 1px var(--lade-color-dark);
  border-bottom: none;
  z-index: 2;
  border-radius: 5px 5px 0 0;
}

.div-create-user nav h3:hover {
  box-shadow: rgba(0, 0, 0, 0.10) 0 5px 15px;
  transform: translateY(-2px);
}

.div-create-user nav h3 img{
  width: 15px;
  margin-left: 7px;
}

.div-create-user form {
  position: relative;
  top: -2px;
  width: 70%;
  margin: 0 auto 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 20px;
  border-radius: 5px 5px;
  border: solid var(--lade-color-black) 1px;
  animation: open-height 0.4s;
}

.div-create-user .option-form {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 140px;
}

@keyframes open-height {
  0%{
    height: 0;
  }
  100%{
    height: 100%;
 }
}

.div-create-user input {
  border-radius: 5px 5px;
}

.div-create-user .input-group {
  margin-bottom: 10px;
  width: 100%;
  position: relative;
}

.div-create-user .input-group .required{
  color: var(--lade-color-red);
  position: absolute;
  font-size: 1.5em;
  font-weight: bold;
  left: -8px;
  top: -2px;
}

.div-create-user .input-group .required.active{
  color: var(--lade-color-green);
}

.div-create-user .names {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.div-create-user .names .input-group:first-child{
  margin-right: 5px;
}
.div-create-user .names .input-group:last-child{
  margin-left: 5px;
}

.div-create-user button {
  border: solid 1px transparent;
  border-radius: 5px 5px;
  padding: 2px 10px;
}

.div-create-user .next-submit {
  width: 15px;
  position: absolute;
  z-index: 2;
  top: 7px;
  right: 10%;
  opacity: 0;
  transition: opacity 0.2s;
}

.div-create-user .search-user {
  width: 100%;
  z-index: 100 !important;
  margin: 0 0 100px 0 !important;
}

@keyframes drop-right {
  0% {
    right: 40%;
    transform: rotateZ(0deg);
  }
  100%{
    right: 10%;
    transform: rotateZ(180deg);
  }
}

.actions-bar {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 0 !important;
}

.users-actions {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  z-index: 0 !important;
}

.add-user,
.refresh-user,
.roles-user,
.delete-user,
.manage-roles{
  z-index: 0 !important;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 26px;
  overflow-x: hidden;
  flex-wrap: nowrap;
  border: solid 1px transparent;
  border-radius: 5px 5px;
  font-size: 1rem;
  background-color: transparent;
  transition: width 0.3s ease-out;
  padding: 4px 4px;
}

.add-user:hover {
  width: 85px;
  color: var(--lade-color-white-hover);
  background-color: var(--lade-green-explore-3);
}

.refresh-user:hover {
  width: 75px;
  background-color: var(--lade-navy);
  color: var(--lade-color-white-hover);
}

.roles-user:hover{
  width: 85px;
  background-color: var(--lade-background-notify);
  color: var(--lade-color-white-hover);
}

.manage-roles:hover {
  width: 109px;
  background-color: var(--lade-background-notify);
  color: var(--lade-color-white-hover);
}

.delete-user:hover {
  width: 70px;
  background-color: var(--lade-color-red);
  color: var(--lade-color-white-hover);
}

.add-user svg,
.refresh-user svg,
.roles-user svg {
  margin-right: 6px;
}

.manage-roles svg {
  margin-right: 10px;
}

.delete-user svg{
  margin-right: 8px;
}

.add-user p,
.refresh-user p,
.roles-user p,
.delete-user p,
.manage-roles p{
  margin: 0 0;
  white-space: nowrap;
}

.select-all-users {
  position: absolute;
  top: 52px;
  left: 9.25px;
}

.resource-selected-actions {
  margin-left: 5px;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  z-index: 0 !important;
}

.page-chevron {
  background-color: var(--lade-color-white-hover) ;
}

.page-chevron:hover,
.pagination-button:hover,
.page-chevron:focus {
  background-color: var(--lade-color-white) !important;
}

.pagination-button,
.pagination-button:focus{
  color: var(--lade-color-black) !important;
  background-color: Transparent !important;
  border: none !important;
  border-radius: 3px;
}

.page-options {
  white-space: normal;
  position: absolute;
  top: 30px;
  right: 10px;
  z-index: 101;
  list-style: none;
  overflow-y: auto;
  display: none;
  max-height: 150px;
  border: 0 !important;
  background-color: var(--lade-color-white-hover) !important;
  box-shadow: 0 2px 4px var(--lade-color-black);
  border-radius: 3px;
  width: 250px;
}

.page-options button {
  color: var(--lade-color-black) !important;
  background-color: white !important;
}

.page-options button.limit-selected,
.page-options button.page-option-active{
  background-color: var(--lade-color-white) !important;
}

.pagination-text:hover .page-options {
  display: block;
}

.page-option {
  border: none !important;
  background-color: rgba(255, 255, 255, 0) !important;
}

.page-option:hover {
  background-color: rgba(28, 22, 22, 0.05) !important;
}

.page-option:focus {
  box-shadow: none ! important;
}

select {
  height: 100px;
  line-height: 25px;
  background: var(--lade-color-white);
}

select:focus {
  outline: none;
}

select[multiple] {
  height: 100px;
  line-height: 25px;
  background: var(--lade-color-white);
}

.right-actions {
  display: flex;
  float: right;
}

.pagination {
  white-space: nowrap;
  margin-right: 10px;
}

.search {
  position: relative;
  z-index: 0;
  padding: 0 0;
  margin: 0 auto;
}

.search input {
  width: 100%;
  display: block;
  border: none;
  border-radius: 25px 25px;
  padding: 20px 20px 20px 35px !important;
  position: relative;
  background-color: var(--lade-color-white-hover);
  font-size: 1.1em;
  transition: 0.2s ease-in;
  height: auto;
  box-shadow: rgba(0, 0, 0, 0.15) 0 2px 8px;
}

.search svg {
  width: 20px;
  height: 20px;
  position: absolute;
  left: 10px;
  top: 15px;
  z-index: 1;
  opacity: 0.5;
}

.search input:focus{
  padding-left: 20px !important;
  z-index: 2;
  border: none;
  background-color: var(--lade-color-white-hover);
}

.icon-user {
  width: 30px;
}

.case-name {
  display: flex;
  align-items: center;
}

.case-name .name-email  {
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 0 0;
}

.case-name .name-email p {
  margin: 0 0 0 0;
  padding: 0 0 0 0;
}

.case-name .div-icon {
  position: relative;
}

.case-name .div-icon div {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  right: -5px;
  top: -5px;
  width: 18px;
  height: 18px;
  border-radius: 25px 25px;
}

.case-name .div-icon div.offline {
  background-color: var(--lade-color-red);
}

.case-name .div-icon div.online {
  background-color: var(--lade-green-explore-3);
}

.case-name .div-icon div.lock {
  background-color: var(--lade-background-notify);
}

.case-name .div-icon div img {
  width: 12px;
}

.div-roles {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

table td {
  background-color: var(--lade-color-blue) !important;
}

.div-roles p {
  margin: 0 5px 4px 0;
  padding: 0 7px;
  white-space: nowrap;
  flex-wrap: nowrap;
  display: flex;
  align-items: center;
  font-size: 90%;
  text-align: center;
  vertical-align: center;
  background-color: var(--lade-color-white);
  border-radius: 0.25em;
  color: var(--lade-color-grey);
}

.div-roles.add p{
  color: var(--lade-color-green);
  border-color: var(--lade-color-green) !important;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.div-roles.add p img{
  opacity: 0;
  position: absolute;
  width: 10px;
  transition: opacity 0.2s;
}

@keyframes add-role {
  0%{
    transform: translateY(20px);
  }
  100%{
    transform: translateY(0px);
  }
}

.div-roles.add p:hover{
  background-color: var(--lade-color-green);
}

.div-roles.add p:hover img{
  animation: add-role 0.2s;
  opacity: 1;
}
.div-roles.add p:hover span{
  opacity: 0;
}

.delete-role {
  margin-left: 5px;
  cursor: pointer;
}

.add-roles{
  vertical-align: center;
  padding: 3px 3px !important;
  cursor: pointer;
  transition: 0.2s;
}

.add-role-form {
  border-radius: 5px 5px;
  padding: 2px 2px 2px 2px;
  position: relative;
}

.add-role-form p{
  margin: 0 2px 0 0;
  text-align: center;
  margin-bottom: 2px;
}

.add-role-form .add-role-back {
  margin: 0 0;
  border: solid 1px transparent;
  width: 17px;
  height: 17px;
  border-radius: 5px 5px;
  cursor: pointer;
  background-image: url("@/assets/annuler.png");
  background-size: 10px;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  right: 0;
}

.add-role-form .add-role-back:hover{
  border: solid 1px var(--lade-color-white);
}

.actions {
  display: flex;
  flex-wrap: nowrap;
}

.menu-edit-user {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 140px;
  position: absolute;
  top: 15px;
  right: 20px;
  z-index: 4;
}

</style>
