<template>
  <div>
    <b-card>
      <h3>Use share token:</h3>
      <InputText name="Share Token" v-model="shareToken" required></InputText>
      <b-button @click="submit">Submit</b-button>
    </b-card>
  </div>
</template>

<script>
import InputText from "../form/InputText";
import store from "../../store";

export default {
  components: {
    InputText
  },
  data() {
    return {
      shareToken: "",
    }
  },
  methods: {
    submit() {
      store.dispatch('sharetoken/useToken',
        {token:this.shareToken}).then(()=> {
          this.$modal({
            text:"This token has been activated"
          })
        }).catch((response) => {
          this.$eventBus.$emit('http-error', response)
      })
    },
  },
  name: "UseShareToken"
}
</script>

<style scoped>

</style>
