<template>
  <b-container>
    <b-row>
      <b-col class="d-flex justify-content-center">
        <div>
          <img src="@/assets/registry/fcs.png" width="100" class="my-2 mr-4" />
        </div>
        <div class="text-xl text-blue my-1 font-weight-bold d-flex align-items-center">
          France Cybersecurity 2021 Excellence Label
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Objective from "@/components/hub/landing/Objective";

export default {
  name: "Testimony",
  components: { Objective },
};
</script>
