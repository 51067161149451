<template>
  <div style="font: 0.7rem Inconsolata, monospace; background-color:#333; color:white" class="mb-0">
    <div v-if="!initialLoad">
      <p v-for="event in events" :key="event.date" class="mb-0">
                <span :class="{ 'text-danger': event.level === 'error', 'text-warning': event.level === 'warning'}">
                    [{{ event.level | capitalize }}] {{ event.date | timestamp }} {{ event.message }}
                    <span v-if="event.progress !== undefined">
                        {{ event.progress }} %
                    </span>
                </span>

      </p>
    </div>
    <div style="padding: 5px;" v-if="loading || initialLoad">
      <b-spinner class="align-middle" style="height:1.2em; width:1.2em"></b-spinner>
      <strong style="margin-left:10px;">
        Loading ...
      </strong>
    </div>
    <b-button style="font-size:1em; width:100%"
              @click="load(true)"
              v-else-if="$store.getters['events/canLoadWorkflow'](this.identifier)">Load more events
    </b-button>
  </div>
</template>

<script>
import store from '../../store/index'
import moment from 'moment'
import eventBus from '../../store/modules/eventBus'

export default {
  name: "Workflow",
  props: {
    identifier: String
  },
  filters: {
    timestamp: function (value) {
      return moment(String(value)).format("HH:mm:ss.SSS")
    }
  },
  beforeDestroy() {
    eventBus.closeStreamWorkflow(this.identifier)
  },
  created() {
    this.initialLoad = true
    this.load(false).then(() => {
      this.initialLoad = false
    })
  },
  methods: {
    load(append = false) {
      this.loading = true
      return store.dispatch("events/loadWorkflow", {
        identifier: this.identifier,
        workflow: store.getters['workflows/get'](this.identifier),
        append: append
      }).then(() => {
        this.loading = false
      })
    }
  },
  data() {
    return {
      events: store.getters['events/getWorkflow'](this.identifier),
      loading: false,
      initialLoad: false,
    }
  }

}
</script>
