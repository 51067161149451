<template>
  <div v-if="file && bundle" class="file-container">

    <FileListForm
      editEvent="edit-file-show"
      deleteEvent="delete-file-show"/>

    <b-row no-gutters class="top" >
      <b-container>
      <b-col>
        <div class="page-title">File from bundle <b-link style="color: inherit" :to="{name: 'bundlePage', params: {identifier: this.bundleIdentifier, tab: 5}}">{{ bundle.name }}</b-link></div>
        <b-row style="margin-top: 10px; text-align: left;">
          <b-col cols=2 v-if="file.icon && file.icon.url">
            <b-img class="logo" fluid :src="file.icon.url" alt="File Icon"></b-img>
          </b-col>
          <b-col :cols="(file.icon && file.icon.url)?'10':'12'" style="padding-left: 30px;">
            <b-row class="file-title">
              <h2>{{file.name}}</h2><h6><b-badge class="lade-label">{{file.qualifier}}</b-badge></h6>
              <font-awesome-icon :icon="['fas', 'pencil-alt']"
                                 @click.stop="$eventBus.$emit('edit-file-show', file.identifier)"
                                 style="margin-left:5px; cursor: pointer; height:20px;"
                                 v-if="isEditable"/>
              <font-awesome-icon :icon="['fas', 'trash']"
                                 @click.stop="$eventBus.$emit('delete-file-show', file.identifier)"
                                 v-if="isDeletable"
                                 style="margin-left:10px; cursor: pointer; height:20px; color:indianred;"/>
            </b-row>
            <b-row>
              <div>Last update {{ file.created_at | print_date }}</div>
            </b-row>
            <b-row class="tag-row"><div><B>Tag:</B> {{file.alias}}:{{file.qualifier}}</div></b-row>
            <b-row class="size-row"><div><B>Size:</B> {{printableFileSize}}</div></b-row>
            <b-row class="size-row" v-if="category !==  null"><div><B>Category:</B> {{category.name}}</div></b-row>
            <b-row style="padding-bottom: 2px">
              <ContentLabels resource-type="files" :content="file" :is-editable="isEditable"></ContentLabels>
            </b-row>

            <b-row class="my-2">
              <b-button variant="dark" @click="download()">
                <font-awesome-icon
                  class="mr-2 mt-1"
                  :icon="['fas', 'download']"
                />
                Download
              </b-button>
            </b-row>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="col-12">
            <b-tabs class="file-tabs" content-class="mt-3" v-model="tabValue">
              <b-tab :title-link-class="'tab-title-class'">
                <template #title>
                  <font-awesome-icon class="tab-icon" :icon="['fa', 'align-left']"/>
                  Description
                </template>
              </b-tab>
            </b-tabs>
          </b-col>
        </b-row>
      </b-col>
    </b-container>
    </b-row>
    <b-row no-gutters >
      <b-col md="1"></b-col>
      <b-col md="10" cols="12">
        <div class="tab-content" v-if="tabValue===0">
          <div v-if="!editDescription">
            <b-button class="button edit-tab-button" @click.stop="editDescription=true">
              <font-awesome-icon :icon="['fas', 'pencil-alt']" v-if="isEditable"/></b-button>
            <vue-markdown :source="file.description"></vue-markdown>
          </div>
          <div v-else>
            <form ref="form" @submit.stop.prevent="updateDescription()">
              <b-form-group id="form-description" label-for="description"
                            description="Please write your file description following community rules.">
                <editor height="600px" id="input-description" ref="descriptionEditor" initialEditType="wysiwyg" :initialValue="this.file.description" />
              </b-form-group>
              <b-button type="submit" class="button" variant="primary">Submit</b-button>
              <b-button variant="secondary" class="button" @click.stop="editDescription=false"
                        style="margin-left:10px;">Cancel</b-button>
            </form>
          </div>
        </div>

      </b-col>
      <b-col md="1"></b-col>
    </b-row>
  </div>

  <div class="text-center col-12 col-xl-10"
       style="padding-top:10px; padding-bottom:10px;"
       v-else>
    <b-spinner class="align-middle"></b-spinner>
    <strong style="margin-left:10px;">Loading...</strong>
  </div>
</template>

<script>

import FileListForm from "@/components/files/FileListForm";
import TextAreaForm from '@/components/form/TextAreaForm'
import InputText from '@/components/form/InputText'
import ContentLabels from '@/components/hub/ContentLabels.vue'
import {print_human_readable_size} from "@/filters";

export default {
  name: 'FileContent',
  props: {
    identifier: String,
    bundleIdentifier: String,
  },
  components: {
    FileListForm, TextAreaForm, InputText, ContentLabels
  },
  data: function() {
      return {
        file: null,
        bundle: null,
        isBusy: true,
        preview: null,
        showInputLabel: false,
        newLabel: "",
        editDescription: false,
        tabValue: 0,
        category: null,
      }
  },
  mounted: function() {
    let loadPromise = this.$store.dispatch('files/load', this.identifier).then( () => {
      this.file = this.$store.getters['files/get'](this.identifier)
      if (this.file.category_identifier) {
        this.$store.dispatch('categories/load', this.file.category_identifier).then( () => {
          this.category = this.$store.getters['categories/get'](this.file.category_identifier)
        })
      }
    })
    let loadBundlePromise = this.$store.dispatch('bundles/load', this.bundleIdentifier).then( () => {
      this.bundle = this.$store.getters['bundles/get'](this.bundleIdentifier)
    })
    Promise.all([loadPromise, loadBundlePromise]).then( () => {
      this.updateBreadcrumb()
    })

  },
  computed: {
    isEditable() {
      return !(this.bundle.authz && (this.bundle.authz.permission & 0x04) === 0);
    },
    isDeletable() {
      return !(this.bundle.authz && (this.bundle.authz.permission & 0x08) === 0);
    },
    getPreviewStyle() {
      return {
        backgroundImage: 'url(' + require('@/assets/background_pattern_light.png') + ')',
        "display": "block",
        "margin-left": "auto",
        "margin-right": "auto",
      }
    },
    printableFileSize() {
      return print_human_readable_size(this.file.size)
    },
  },
  methods: {
    updateBreadcrumb() {
      let breadcumbList = [
        { text: 'Explore', to: {name: 'bundleGrid'} },
        { text: this.bundle.name,
          to: {
            name: 'bundlePage',
            params: {
              identifier: this.bundleIdentifier,
              reload: false,
              tab: 4,
              reloadFiles: false,
            }
          }
        },
        //{ text: 'file', to: {}},
        { text: this.file.name },
      ]
      this.$emit('breadcrumbChange', breadcumbList)
    },
    toggleFavorite() {
      if (this.file.favorite === true) {
        this.$store.dispatch('favorites/delete', this.identifier)
        this.file.favorite = false
      } else {
        this.$store.dispatch('favorites/add', this.identifier)
        this.file.favorite = true
      }
    },
    updateDescription() {
      let description = this.$refs.descriptionEditor.invoke('getMarkdown')
      this.$store.dispatch('files/update', {
        identifier: this.identifier,
        data: {description: description}}).then(() => {
        this.editDescription = false

      })
    },
    updateSpecs() {
      this.$store.dispatch('files/update', {
        identifier: this.identifier,
        data: {specs: this.specs}}).then(() => {
        this.editSpecs = false
      })
    },
    updateCommand() {
      this.$store.dispatch('files/update', {
        identifier: this.identifier,
        data: {command: this.command}}).then(() => {
        this.editCommand = false
      })
    },
    download() {
      this.$store.dispatch('bundles/export',
        {bundle_identifier: this.bundleIdentifier,
          resources: [this.identifier],
          no_metadata: true}).then(() => {
        this.$eventBus.$emit('success', 'The file is being downloaded')
      }).catch((response) => {
        this.$eventBus.$emit('http-error', response)
      })
    }
  }
}
</script>

<style scoped>

.file-title {
  align-items: center;
}

.file-title h2 {
  margin-right: 10px
}

.file-tabs >>> .tab-title-class {
  color: var(--lade-color-dark-grey);
  font-size: 1.2em;
  border-radius: 0px;
}

.file-tabs {
  margin-top: 20px;
  margin-bottom: -14px;
}

.tab-content {
  background-color: var(--lade-color-white-hover);
  border: 1px solid var(--lade-color-white);
  border-radius: 2px;
  padding: 10px;
  min-height: 60px;
}
</style>
