<template>
  <div class="sectionEditGroup">
    <div class="content">
      <div class="head-content">
        <h2>
          Workflows
        </h2>
      </div>
      <div class="edit">
        <WorkflowList
          :searchFilters="{group_identifier: group.identifier}"
          :fields="fields"
          :busy="busy" class="workflow-list">
        </WorkflowList>
      </div>
    </div>
  </div>
</template>

<script>
import WorkflowList from "@/components/workflows/WorkflowList.vue";
import store from "@/store";

export default {
  name: 'EditGroupWorkflows',
  components: {
    WorkflowList
  },
  props:{
    group: Object
  },
  data() {
    return {
      busy: false,
      fields: store.getters['workflows/getFields']().filter(
        field => (field.key !== "group_identifier"
          && field.key !== "workzone_identifier")
      )
    }
  }
}
</script>

<style scoped>
.workflow-list {
  width: 100%;
}

::-webkit-scrollbar {
  width: 5px;               /* width of the entire scrollbar */
}

::-webkit-scrollbar-thumb {
  background-color: var(--lade-color-grey);    /* color of the scroll thumb */
  border-radius: 20px;       /* roundness of the scroll thumb */
}

.edit {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  overflow-y: auto;
  position: relative;
  width: 100%;
  padding: 15px 15px 105px 15px;
}
</style>
