<template>
  <div class="form-group row" v-show="!hidden">
    <label class="col-sm-3 col-form-label" :for="fieldId" v-if="!hideLabel">{{ name }} <i v-if="!required"
                                                                                          style="color: var(--lade-color-grey);"> -
      Optional </i></label>
    <div class="col-sm-7">
      <b-form-file ref="el" :accept="accept" type="file" :id="fieldId"
                   :required="required" :disabled="disabled" v-model="image" @input="updateValue()"/>
      <small :id="helpId" class="form-text text-muted">{{ help }}</small>
    </div>
    <div class="col-sm-2">
      <b-button v-on:click="reset" :disabled="!Boolean(image)">Clear</b-button>
    </div>
  </div>
</template>

<script>
import {capitalize} from "@/filters"

export default {
  name: "InputImage",
  props: {
    prefix: String,
    name: String,
    required: Boolean,
    disabled: Boolean,
    help: String,
    hidden: Boolean,
    hideLabel: Boolean,
    accept: {
      default: "image/*",
      type: String,
    },
  },
  data() {
    return {
      fieldId: this.prefix + capitalize(this.name),
      helpId: this.prefix + capitalize(this.name) + "Help",
      image: undefined
    }
  },
  methods: {
    reset() {
      this.image = undefined
      this.$emit('input', undefined)
    },
    updateValue() {
      this.$emit('input', this.image)
    },
  }
}
</script>
