<template>
  <div class="edit-group">
    <div class="nav-bar" v-if="this.group !== undefined">
      <div class="group-title">
        <div class="icon-group">
          <img
              v-if="group.icon && group.icon.url"
              class="logo"
              :src="group.icon.url"
              alt="Group logo"
          />
          <div v-else class="no-icon">
            <div
                class="generated-icon"
                :style="{'background-color': generateIconColor(group)}">
              <div class="text-icon eg">
                {{ group.name[0].toUpperCase() }}
              </div>
            </div>
          </div>
        </div>
        <div>
          <h3 @click.stop="GroupBundleGrid" >{{ group.name }}</h3>
          <p>created at <em>{{ formatDate() }}</em></p>
        </div>
      </div>
      <span class="separation"></span>
      <nav>
        <ul>
          <li :class="{'selected': tabNav === 0}"
              @click.stop="tabNav = 0">
            <img class="img-fluid" src="@/assets/reglage.png" alt="settings group"/>
            <p>Settings</p>
          </li>
          <li :class="{'selected': tabNav === 1}"
              @click.stop="tabNav = 1">
            <img class="img-fluid" src="@/assets/utilisateur.png" alt="users group" />
            <p>Users</p>
          </li>
          <li :class="{'selected': tabNav === 2}"
              @click.stop="tabNav = 2">
            <font-awesome-icon :icon="['fas', 'cubes']" />
            <p>Bundles</p>
          </li>
          <li :class="{'selected': tabNav === 3}"
              @click.stop="tabNav = 3">
            <font-awesome-icon :icon="['fas', 'tasks']" />
            <p>Workflows</p>
          </li>
        </ul>
      </nav>
      <span class="separation"></span>
      <div class="delete-line">
        <button class="delete btn btn-outline-danger"
                @click.stop="deleteGroup()">Delete</button>
      </div>
    </div>
    <EditGroupBundleSettings v-if="tabNav === 0 && group !== undefined" :group="this.group" />
    <EditGroupBundleUsers v-if="tabNav === 1 && group !== undefined" :group="this.group" />
    <EditGroupBundles v-if="tabNav === 2 && group !== undefined" :group="this.group" />
    <EditGroupWorkFlows v-if="tabNav === 3 && group !== undefined" :group="this.group" />
  </div>
</template>

<script>
import BundleGrid from "@/components/hub/Explore.vue";
import GroupListForm from "@/components/groups/GroupListForm.vue";
import Icon from "@/components/form/Icon.vue";
import CapacityChart from "@/components/groups/CapacityChart.vue";
import VisibilityBadge from "@/components/VisibilityBadge.vue";
import EditGroupBundleSettings from "@/components/hub/groups/EditGroupSettings.vue";
import EditGroupBundleUsers from "@/components/hub/groups/EditGroupUsers.vue";
import EditGroupBundles from "@/components/hub/groups/EditGroupBundles.vue";
import EditGroupWorkFlows from "@/components/hub/groups/EditGroupWorkFlows.vue";
import store from "@/store";
import {generateIconColor} from "@/common";

export default {
  name: "editGroup",
  components: {
    VisibilityBadge,
    CapacityChart,
    BundleGrid,
    GroupListForm,
    Icon,
    EditGroupBundleSettings,
    EditGroupBundleUsers,
    EditGroupBundles,
    EditGroupWorkFlows
  },
  props: {
    identifier: String,
    bundle: false,
    previousPage: null,
  },
  data: function () {
    return {
      group: undefined,
      tabNav: 0
    };
  },
  computed: {
    isEditable() {
      return !(this.group.authz && (this.group.authz.permission & 0x04) === 0);
    },
  },
  mounted: function () {
    this.$store.dispatch("groups/load", this.identifier).then(() => {
      this.group = this.$store.getters["groups/get"](this.identifier);
      if(!this.isEditable) {
        this.GroupBundleGrid();
      }
      this.formatDate();
    });
    if(this.bundle) {
      this.tabNav = 2;
    }
    let breadcumbList = []
    if (this.previousPage === undefined) {
      breadcumbList.push({text: 'Explore', to: {name: 'bundleGrid'}})
    } else {
      breadcumbList.push(this.previousPage)
    }
    breadcumbList.push({text: 'Explore', to: {name: 'bundleGrid'}})
    // if(this.previousPage.text === 'Group settings') {
    //   breadcumbList.push(this.previousPage)
    // }
    this.$emit('breadcrumbChange', breadcumbList)
  },
  methods: {
    formatDate(){
      let date = this.group.created_at;
      let jj = date.slice(8,date.indexOf('T'));
      let mm = date.slice(5,7);
      let yyyy = date.slice(0,4);
      return jj +'/'+ mm +'/'+ yyyy;
    },
    generateIconColor(group) {
      return generateIconColor(group.identifier);
    },
    GroupBundleGrid() {
      this.$router.push({
        name: 'groupBundleGrid',
        params: {identifier: this.identifier}
      });
    },
    isDeletable(entry) {
      if (entry.authz && (entry.authz.permission & 0x08) === 0) {
        return false;
      }
      return this.filters.isDeletable(entry)
    },
    deleteGroup() {
      this.$store.dispatch("bundles/loadGroup", {groupIdentifier:this.group.identifier, load:"meta"}).then(() => {
        let bundles = store.getters['bundles/getAll']()
        let html = "<br/> <h4> The following bundles will be deleted:</h4> <br/> " +
          "<ul style=\"max-height:300px; overflow-y: auto;\" class=\"list-groups list-groups-flush\">"

        bundles.forEach((bundle)=>
          html +=  "<li style=\"text-align:left;\" class=\"list-groups-item\">" + bundle.tag + "</li>"
        )
        html += "<ul> <br/>"

        let bundlesInThisGroup = bundles.length > 0
        this.$deleteModal({
          title: "Delete groups " + this.group.name + "?\n",
          html: bundlesInThisGroup?html:"No content will be deleted as there is no bundle in this groups.",
          input: bundlesInThisGroup?'checkbox':undefined,
          width: '50rem',
          inputValue: 0,
          timer: 15000,
          inputValidator: (result) => {
            return !result && 'You need to check the "Confirm" box in order to delete the groups.'
          },
          inputPlaceholder:"I am aware that all the following bundles will be deleted."
        }).then((result) => {
          if (result.value) {
            store.dispatch('groups/delete', this.group.identifier).then(() => {
              this.selectGroup(undefined)
            }).catch((response) => {
              this.$eventBus.$emit('http-error', response)
            })
            this.$router.push({name: 'bundleGrid', params: {reset: true}})
          }
        });
      })
    },
  }
};
</script>

<style scoped>
.nav-bar {
  width: 20%;
  padding: 5px 10px;
  border-radius: 0 5px 5px 0;
  background-color: var(--lade-color-white-hover);
  margin-top: 2%;
  position: fixed;
  height: calc(94% - 154.45px);
  border: 1px solid var(--lade-color-white);
}

.group-title {
  width: 100%;
  margin-top: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.group-title h3 {
  cursor: pointer;
  border-bottom: solid 1px transparent;
  transition: 0.3s;
  width: 100%;
}

.group-title h3:hover {
  border-bottom: solid 1px var(--lade-color-dark-grey);
}

.group-title div:last-child{
  width: max-content;
}

.group-title div:last-child p {
  font-size: 1.1em;
}
.group-title div:last-child h3,p {
  margin: 0 0;
  padding: 0 0;
}

.group-title .icon-group {
  margin-right: 10px;
  overflow: hidden;
  border-radius: 10px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100px;
}

.generated-icon {
  /*padding-left: 0;*/
  width: 70px !important;
  height: 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px 10px;
}

.icon-group .logo {
  max-width: 100px;
  height: 100%;
  margin: 0 0 auto;
  object-fit: scale-down;
  border-radius: 10px 10px;
}

.text-icon {
  font-size: 20px !important;
}

.separation{
  display: none;
}

.nav-bar nav{
  margin-top: 15px;
  border-top: solid 1px var(--lade-color-white);
  padding-top: 15px;
}

.nav-bar ul {
  display: inline-flex;
  flex-direction: column;
  margin: 0 0;
  padding: 0 10px;
  width: 100%;
}

.nav-bar ul li{
  list-style: none;
  text-align: left;
  font-size: 1.1em;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 4px 4px 4px 8px;
  cursor: pointer;
  border-radius: 7px 7px;
  transition: 0.2s;
  margin-bottom: 2px;
}
.nav-bar ul li:hover,
.nav-bar ul li.selected {
  background-color: var(--lade-color-white);
}

.nav-bar ul li img{
  width: 20px;
  height: 20px;
}

.nav-bar ul li p{
  margin: 4px 0 0 15px;
  border-bottom: solid 1px transparent;
  padding-bottom: 0;
  height: 20px;
  transition: 0.3s;
}

.nav-bar ul li:hover p{
  border-bottom-color: var(--lade-color-dark-grey);
}

.delete-line {
  position: absolute;
  bottom: 10px;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.delete{
  padding: 2px 10px;
}

@media screen and (max-width: 1060px) {
  .nav-bar {
    top: 142.39px;
    right: 15%;
    width: 70%;
    height: 7% !important;
    min-height: 47px;
    overflow: hidden;
    padding: 0 10px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    border-radius: 5px 5px 5px 5px;
  }

  .group-title {
    width: min-content;
    margin-top: 0;
    margin-left: 5px;
    margin-right: 0;
    padding: 0 0;
  }

  .group-title div p{
    display: none;
  }

  .icon-group{
    margin-right: 5px !important;
    padding: 0 0;
    width: 50px !important;
    height: 50px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  .group-title div:last-child {
    padding: 0 0;
    height: 100%;
  }

  span.separation {
    display: block;
    width: 1px;
    height: 60%;
    padding: 0 0;
    background-color: var(--lade-color-dark-grey);
  }

  .nav-bar nav {
    padding: 0 0;
    margin: 0 0 0 0;
    border: none;
    width: 50%;
  }

  .nav-bar nav ul {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
  }

  .nav-bar nav li {
    margin: 0 10px 0 0;
    padding: 2px 2px;
    width: auto;
  }
  .nav-bar nav li p{
    margin: 2px 0 0 2px;
  }

  .delete-line {
    width: min-content;
    position: initial;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}

@media screen and (max-width: 990px) {
  .nav-bar {
    top: 122.39px;
    width: 80%;
    left: 10%;  /* 2.5% */
  }
}

@media screen and (max-width: 762px) {
  .nav-bar {
    top: 102.39px;
    width: 95%;
    left: 2.5%;
  }
}
</style>
