<template>
  <div>
    <b-modal ref="viewFormModal" id="viewFormModal" hide-footer v-bind:title="title">wwwwwwwlwlè
      <table style="width: 100%;">
        <tr v-for="key in printableKeys" :key="key">
          <td><strong>{{ key | capitalize }} </strong></td>
          <td style="padding-left: .25em;">{{ topology[key] }}</td>
        </tr>
      </table>
    </b-modal>
    <b-modal size="lg" ref="addFormModal" id="addFormModal" hide-footer v-bind:title="title">
      <form v-on:submit.prevent="onAddEdit">
        <div class="card">
          <div class="card-header">
            Attributes
          </div>
          <div class="card-body">
            <InputText v-if="edit" prefix="Topology" name="identifier" v-model="identifier" disabled
                       required></InputText>

            <div class="form-group row">
              <label class="col-sm-3 col-form-label" for="Bundle">Bundle</label>
              <div class="col-sm-9">
                <TypeAhead prefix="Topology" id="Bundle" resourceType="bundle" v-model="bundle" class="mb-2"
                           @hit="bundleIdentifier=$event" :disabled="edit"/>
              </div>
            </div>

            <InputText prefix="Topology" name="alias" v-model="alias" required
                       pattern="[a-zA-Z0-9_.-]{3,64}"></InputText>
            <InputText prefix="Topology" name="qualifier" v-model="qualifier"></InputText>
            <InputText prefix="Topology" name="name" v-model="name"></InputText>

            <div class="form-group row">
              <label class="col-sm-3 col-form-label" for="Category">Category</label>
              <div class="col-sm-9">
                <TypeAhead prefix="Topology" resourceType="category" v-model="category" class="mb-2" id="Category"
                           @hit="categoryIdentifier=$event"
                           :filters="{bundleIdentifier: bundleIdentifier}" :disabled="bundleIdentifier === null"/>
              </div>
            </div>

            <TextAreaForm prefix="Topology" name="description" ref="description" v-model="description" :json="false"/>
            <TextAreaForm prefix="Topology" name="parameters" ref="parameters" v-model="parameters" :rows="5"
                          :json="true"/>
            <TextAreaForm prefix="Topology" name="attributes" ref="attributes" v-model="attributes" :rows="5"
                          :json="true"/>
            <button type="submit" class="btn btn-primary">Submit</button>
          </div>
        </div>
      </form>
    </b-modal>
    <b-modal size="lg" ref="addHostSpecModal" id="addHostSpecModal" hide-footer v-bind:title="title">
      <form v-on:submit.prevent="onAddEditHostSpec">
        <div class="card">
          <div class="card-header">
            Attributes
          </div>
          <div class="card-body">
            <InputText v-if="edit" prefix="HostSpec" name="identifier" v-model="identifier" disabled></InputText>
            <button type="submit" class="btn btn-primary">Submit</button>
          </div>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import store from '../../store'
import InputText from '../form/InputText'
import TextAreaForm from '../form/TextAreaForm'
import TypeAhead from '../form/TypeAhead'
import {Tag} from '@/common'
import {print_tag} from '@/filters'

export default {
  name: 'TopologyListForm',
  created() {
    this.$eventBus.$off("view-topology-show")
    this.$eventBus.$on("view-topology-show", (identifier) => {
      this.reset("Topology")
      this.topology = store.getters['topologies/get'](identifier)
      this.printableKeys = ["tag", "identifier", "bundle", "name", "category", "description", "parameters", "attributes"]
      if (this.$refs.attributes) this.$refs.attributes.$emit('reset')
      if (this.$refs.description) this.$refs.description.$emit('reset')
      if (this.$refs.parameters) this.$refs.parameters.$emit('reset')
      this.$refs.viewFormModal.show()
    })

    this.$eventBus.$off("add-topology-show")
    this.$eventBus.$on("add-topology-show", (bundleIdentifier) => {
      this.reset("Add topology")
      if (bundleIdentifier !== undefined) {
        this.bundleIdentifier = bundleIdentifier
        this.bundle = store.getters['bundles/get'](bundleIdentifier).tag
      }
      store.dispatch('bundles/loadAll', {order_by: null, labels: []}).then(() => {
        this.$refs.addFormModal.show()
      })
    })

    this.$eventBus.$off("edit-topology-show")
    this.$eventBus.$on("edit-topology-show", (identifier) => {
      store.dispatch('topologies/load', identifier).then((values) => {

        this.reset("Edit topology")
        let topology = store.getters['topologies/get'](identifier)
        this.identifier = topology.identifier
        this.alias = topology.alias
        this.qualifier = topology.qualifier
        this.tag = topology.tag
        this.hostsSpecs = topology.hostsSpecs
        this.networksSpecs = topology.networksSpecs
        this.name = topology.name
        this.description = topology.description
        this.parameters = topology.parameters
        this.attributes = topology.attributes
        this.categoryIdentifier = topology.category_identifier
        this.edit = true
        this.bundleIdentifier = topology.bundle_identifier

        if (this.$refs.attributes) this.$refs.attributes.$emit('reset', this.attributes)
        if (this.$refs.description) this.$refs.description.$emit('reset', this.description)
        if (this.$refs.parameters) this.$refs.parameters.$emit('reset', this.parameters)

        let bundleIdentifier = this.bundleIdentifier
        let categoryIdentifier = this.categoryIdentifier
        let promises = [store.dispatch('bundles/load', bundleIdentifier)]
        if (categoryIdentifier != null) {
          promises.push(store.dispatch('categories/load', categoryIdentifier))
        }
        Promise.all(promises).then((values) => {
          this.bundle = store.getters['bundles/get'](bundleIdentifier).tag
          let category = store.getters['categories/get'](categoryIdentifier)
          this.category = category != null ? category.name : null
          this.$refs.addFormModal.show()
        })
      })
    })

    this.$eventBus.$off("delete-topology-show")
    this.$eventBus.$on("delete-topology-show", (identifier) => {
      let topology = store.getters['topologies/get'](identifier)
      this.$deleteModal({
        title: "Delete topology",
        text: "Delete topology " + print_tag(false, false, topology) + " ?"
      }).then((result) => {
        if (result.value) {
          store.dispatch('topologies/delete', identifier).then(() => {
            this.$eventBus.$emit('success', 'The topology will be deleted')
          }).catch((response) => {
            this.$eventBus.$emit('http-error', response)
          })
        }
      });
    })

    this.$eventBus.$off('export-topology')
    this.$eventBus.$on('export-topology', (identifier) => {
      let topology = store.getters['topologies/get'](identifier)
      store.dispatch('bundles/export', {
        bundle_identifier: topology.bundle_identifier,
        resources: [topology.identifier]
      }).then(() => {
        this.$eventBus.$emit('success', 'The topology will be exported')
      }).catch((response) => {
        this.$eventBus.$emit('http-error', response)
      })
    })

    this.$eventBus.$off('download-topology')
    this.$eventBus.$on('download-topology', (identifier) => {
      let topology = store.getters['topologies/get'](identifier)
      store.dispatch('bundles/export', {
        bundle_identifier: topology.bundle_identifier,
        resources: [topology.identifier],
        no_metadata: true
      }).then(() => {
        this.$eventBus.$emit('success', 'The topology hosts will be exported')
      }).catch((response) => {
        this.$eventBus.$emit('http-error', response)
      })
    })

  },
  data() {
    return {
      identifier: null,
      topology: null,
      printableKeys: {},
      alias: null,
      qualifier: null,
      tag: null,
      hostsSpecs: [],
      networksSpecs: [],
      name: null,
      description: null,
      attributes: {},
      categoryIdentifier: null,
      category: null,
      parameters: {},
      title: null,
      edit: false,
      bundle: null,
      bundleIdentifier: null
    }
  },
  computed: {
    bundles: () => store.getters['bundles/getAll'](),
    topologies: () => store.getters['topologies/getAll'](),
    categories: function () {
      let categories = []
      categories.push({"identifier": null, "name": ""})
      categories.push(...store.getters['categories/getAll']())
      return categories
    }
  },
  methods: {
    reset(title) {
      this.title = title
      this.edit = false
      this.identifier = null
      this.tag = null
      this.alias = null
      this.qualifier = null
      this.categoryIdentifier = null
      this.category = null
      this.bundle = null
      this.bundleIdentifier = null
      this.name = null
      this.parameters = {}
    },
    onAddEdit() {

      let data = {
        "tag": new Tag(this.alias, this.qualifier).print(),
        "description": this.description,
        "name": this.name,
        "attributes": this.attributes,
        "parameters": this.parameters,
        "hostsSpecs": this.hostsSpecs,
        "networksSpecs": this.networksSpecs,
        "category_identifier": this.categoryIdentifier
      }

      if (this.edit) {
        store.dispatch('topologies/update', {identifier: this.identifier, data: data}).then(() => {
          this.$refs.addFormModal.hide()
        }).catch((response) => {
          this.$eventBus.$emit('http-error', response)
        })
      } else {
        store.dispatch('topologies/add', {bundleIdentifier: this.bundleIdentifier, data: data}).then(() => {
          this.$refs.addFormModal.hide()
        }).catch((response) => {
          this.$eventBus.$emit('http-error', response)
        })
      }
    },
    onDelete() {
      store.dispatch('topologies/delete', {identifier: this.identifier}).then(() => {
        this.$refs.deleteFormModal.hide()
      }).catch((response) => {
        this.$eventBus.$emit('http-error', response)
      })
    }
  },
  components: {
    InputText, TextAreaForm, TypeAhead
  }
}
</script>

<style>


</style>
