<template>
  <div v-if="action && bundle" class="action-container">

    <ActionListForm
      editEvent="edit-action-show"
      deleteEvent="delete-action-show"/>

    <b-row no-gutters class="top" >
      <b-container>
      <b-col>
        <div class="page-title">Action from bundle <b-link style="color: inherit" :to="{name: 'bundlePage', params: {identifier: this.bundleIdentifier, tab: 5}}">{{ bundle.name }}</b-link></div>
        <b-row style="margin-top: 10px; text-align: left;">
          <b-col cols=2 v-if="action.icon && action.icon.url">
            <b-img class="logo" fluid :src="action.icon.url" alt="Action Icon"></b-img>
          </b-col>
          <b-col :cols="(action.icon && action.icon.url)?'10':'12'" style="padding-left: 30px;">
            <b-row class="action-title">
              <h2>{{action.name}}</h2><h6><b-badge class="lade-label">{{action.qualifier}}</b-badge></h6>
              <font-awesome-icon :icon="['fas', 'pencil-alt']"
                                 @click.stop="$eventBus.$emit('edit-action-show', action.identifier)"
                                 style="margin-left:5px; cursor: pointer; height:20px;"
                                 v-if="isEditable"/>
              <font-awesome-icon :icon="['fas', 'trash']"
                                 @click.stop="$eventBus.$emit('delete-action-show', action.identifier)"
                                 v-if="isDeletable"
                                 style="margin-left:10px; cursor: pointer; height:20px; color:indianred;"/>
            </b-row>
            <b-row>
              <div>Last update {{ action.created_at | print_date }}</div>
            </b-row>
            <b-row class="tag-row"><div><B>Tag:</B> {{action.alias}}:{{action.qualifier}}</div></b-row>
            <b-row  v-if="category !==  null"><div><B>Category:</B> {{category.name}}</div></b-row>
            <b-row style="padding-bottom: 2px">
              <ContentLabels resource-type="actions" :content="action" :is-editable="isEditable"></ContentLabels>
            </b-row>
            <b-row class="size-row" style="padding-top: 10px;">
              <div v-if="!editCommand">
                <B>Command: </B><code>{{command}}</code>
<!--                <b-button class="button" style="margin-left: 10px" @click.stop="editCommand=true">-->
<!--                  <font-awesome-icon :icon="['fas', 'pencil-alt']" v-if="isEditable"/>-->
<!--                </b-button>-->
              </div>
<!--              <div v-else style="display: inline-block;" class="input-command">-->
<!--                <label><B>Command: </B></label>-->
<!--                <span><input type="text" prefix="Action" autocomplete="off"-->
<!--                       v-model="command" /></span>-->
<!--                <font-awesome-icon :icon="['fas', 'check']" @click="updateCommand()" class="input-accept-command"/>-->
<!--                <font-awesome-icon :icon="['fas', 'times']" @click="editCommand=false; command=action.command"-->
<!--                                   class="input-cancel-command"/>-->
<!--              </div>-->
            </b-row>
          </b-col>
        </b-row>
        <b-row>
          <b-col class="col-12">
            <b-tabs class="action-tabs" content-class="mt-3" v-model="tabValue">
              <b-tab :title-link-class="'tab-title-class'">
                <template #title>
                  <font-awesome-icon class="tab-icon" :icon="['fa', 'align-left']"/>
                  Description
                </template>
              </b-tab>
              <b-tab :title-link-class="'tab-title-class'">
                <template #title>
                  <font-awesome-icon class="tab-icon" :icon="['fa', 'align-left']"/>
                  Specs
                </template>
              </b-tab>
            </b-tabs>
          </b-col>
        </b-row>

      </b-col>
      </b-container>
    </b-row>
    <b-row no-gutters >
      <b-col md="1"></b-col>
      <b-col md="10" cols="12">
        <div class="tab-content" v-if="tabValue===0">
          <div v-if="!editDescription">
            <b-button class="button edit-tab-button" @click.stop="editDescription=true">
              <font-awesome-icon :icon="['fas', 'pencil-alt']" v-if="isEditable"/></b-button>
            <vue-markdown :source="action.description"></vue-markdown>
          </div>
          <div v-else>
            <form ref="form" @submit.stop.prevent="updateDescription()">
              <b-form-group id="form-description" label-for="description"
                            description="Please write your action description following community rules.">
                <editor height="600px" id="input-description" ref="descriptionEditor" initialEditType="wysiwyg" :initialValue="this.action.description" />
              </b-form-group>
              <b-button type="submit" class="button" variant="primary">Submit</b-button>
              <b-button variant="secondary" class="button" @click.stop="editDescription=false"
                        style="margin-left:10px;">Cancel</b-button>
            </form>
          </div>
        </div>
        <div class="tab-content" v-if="tabValue===1">

          <div v-if="!editSpecs">
            <b-button class="button edit-tab-button" @click.stop="editSpecs=true">
              <font-awesome-icon :icon="['fas', 'pencil-alt']" v-if="isEditable"/></b-button>
            <pre>{{ JSON.stringify(action.specs, null, 2) }}</pre>
          </div>
          <div v-else>
            <form ref="form" @submit.stop.prevent="updateSpecs()">
              <b-form-group id="form-description" label-for="description"
                            description="Please write your action description following community rules.">
                <TextAreaForm id="input-specs" ref="specsEditor" prefix="Action" name="specs" :rows="50" :json=true required
                              v-model="specs"></TextAreaForm>
              </b-form-group>
              <b-button type="submit" class="button" variant="primary">Submit</b-button>
              <b-button variant="secondary" class="button" @click.stop="editSpecs=false"
                        style="margin-left:10px;">Cancel</b-button>
            </form>
          </div>
        </div>

      </b-col>
      <b-col md="1"></b-col>
    </b-row>
  </div>

  <div class="text-center col-12 col-xl-10"
       style="padding-top:10px; padding-bottom:10px;"
       v-else>
    <b-spinner class="align-middle"></b-spinner>
    <strong style="margin-left:10px;">Loading...</strong>
  </div>
</template>

<script>

import ActionListForm from "@/components/actions/ActionListForm";
import TextAreaForm from '@/components/form/TextAreaForm'
import ContentLabels from '@/components/hub/ContentLabels.vue'
import InputText from '@/components/form/InputText'

export default {
  name: 'ActionContent',
  props: {
    identifier: String,
    bundleIdentifier: String,
  },
  components: {
    ActionListForm, TextAreaForm, InputText, ContentLabels
  },
  data: function() {
      return {
        action: null,
        bundle: null,
        isBusy: true,
        preview: null,
        showInputLabel: false,
        newLabel: "",
        editDescription: false,
        specs: {},
        editSpecs: false,
        command: "",
        editCommand: false,
        tabValue: 0,
        category: null,
      }
  },
  mounted: function() {
    let loadPromise = this.$store.dispatch('actions/load', this.identifier).then( () => {
      this.action = this.$store.getters['actions/get'](this.identifier)
      this.specs = this.action.specs
      this.command = this.action.command
      if (this.action.category_identifier) {
        this.$store.dispatch('categories/load', this.action.category_identifier).then( () => {
          this.category = this.$store.getters['categories/get'](this.action.category_identifier)
        })
      }
    })
    let loadBundlePromise = this.$store.dispatch('bundles/load', this.bundleIdentifier).then( () => {
      this.bundle = this.$store.getters['bundles/get'](this.bundleIdentifier)
    })
    Promise.all([loadPromise, loadBundlePromise]).then( () => {
      this.updateBreadcrumb()
    })

  },
  computed: {
    isEditable() {
      return !(this.bundle.authz && (this.bundle.authz.permission & 0x04) === 0);
    },
    isDeletable() {
      return !(this.bundle.authz && (this.bundle.authz.permission & 0x08) === 0);
    },
    getPreviewStyle() {
      return {
        backgroundImage: 'url(' + require('@/assets/background_pattern_light.png') + ')',
        "display": "block",
        "margin-left": "auto",
        "margin-right": "auto",
      }
    },
  },
  methods: {
    updateBreadcrumb() {
      let breadcumbList = [
        { text: 'Explore', to: {name: 'bundleGrid'} },
        { text: this.bundle.name,
          to: {
            name: 'bundlePage',
            params: {
              identifier: this.bundleIdentifier,
              reload: false,
              tab: 2,
              reloadActions: false,
            }
          }
        },
        //{ text: 'action', to: {}},
        { text: this.action.name },
      ]
      this.$emit('breadcrumbChange', breadcumbList)
    },
    toggleFavorite() {
      if (this.action.favorite === true) {
        this.$store.dispatch('favorites/delete', this.identifier)
        this.action.favorite = false
      } else {
        this.$store.dispatch('favorites/add', this.identifier)
        this.action.favorite = true
      }
    },
    updateDescription() {
      let description = this.$refs.descriptionEditor.invoke('getMarkdown')
      this.$store.dispatch('actions/update', {
        identifier: this.identifier,
        data: {description: description}}).then(() => {
        this.editDescription = false

      })
    },
    updateSpecs() {
      this.$store.dispatch('actions/update', {
        identifier: this.identifier,
        data: {specs: this.specs}}).then(() => {
        this.editSpecs = false
      })
    },
    updateCommand() {
      this.$store.dispatch('actions/update', {
        identifier: this.identifier,
        data: {command: this.command}}).then(() => {
        this.editCommand = false
      })
    }
  }
}
</script>

<style scoped>
.action-title {
  align-items: center;
}

.action-title h2 {
  margin-right: 10px
}

.action-tabs >>> .tab-title-class {
  color: var(--lade-color-dark-grey);
  font-size: 1.2em;
  border-radius: 0px;
}

.action-tabs {
  margin-top: 20px;
  margin-bottom: -14px;
}

.bundle-tabs >>> .tab-title-class {
  color: var(--lade-color-dark);
}

.tab-content {
  background-color: var(--lade-color-white-hover);
  border: 1px solid var(--lade-color-white);
  border-radius: 2px;
  padding: 10px;
  min-height: 60px;
}


</style>
