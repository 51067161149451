<template>
    <div>
      <b-row no-gutters >
        <b-col md="1"></b-col>
        <b-col md="10" cols="12">
          <div class="page-title" v-if="questionIdentifier === undefined">New question</div>
          <div class="page-title" v-else>Update question</div>
          <form ref="form" @submit.stop.prevent="submit()">
            <b-form-group id="input-group-1" label="Title:" label-for="input-1"
                :state="titleState"
                invalid-feedback="Title is required">
                <b-form-input
                    id="input-1"
                    v-model="title"
                    required
                ></b-form-input>
            </b-form-group>
            <b-form-group id="input-group-2" label="Question:" label-for="input-2"
                description="Please write your question following community rules."
                :state="questionState"
                invalid-feedback="Question is required">
                <editor height="400px" id="input-2" ref="editor" initialEditType="wysiwyg" :initialValue="questionIdentifier?question.question:''"/>
            </b-form-group>
            <div class="submit-buttons">
              <b-button type="submit" class="button" variant="primary">Submit</b-button>
              <b-button variant="secondary" class="button"
                        style="margin-left:10px;" @click="cancel()">Cancel</b-button>
            </div>
          </form>
        </b-col>
        <b-col md="1"></b-col>
      </b-row>
    </div>
</template>

<script>
export default {
  name: 'QuestionForm',
  props: {
    bundleIdentifier: String,
    questionIdentifier: String
  },
  data: function() {
    return {
      bundle: {},
      title: '',
      titleState: true,
      questionState: true,
      question: null
    }
  },
  mounted() {
    this.$store.dispatch('bundles/load', this.bundleIdentifier).then(() => {
      this.bundle = this.$store.getters['bundles/get'](this.bundleIdentifier)
      this.updateBreadcrumb()
    })
    if (this.questionIdentifier !== undefined) {
      this.question = this.$store.getters['questions/get'](this.questionIdentifier)
      this.title = this.question.title
    }

  },
  methods: {
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity()
      this.titleState = this.title.length > 0
      this.questionState = this.getQuestion().length > 0
      return valid && this.titleState && this.questionState
    },
    submit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
          return
      }
      let promise = null
      if (this.question === null) {
        this.$store.dispatch('questions/add', {
          bundleIdentifier: this.bundleIdentifier,
          question: {
            title: this.title,
            question: this.getQuestion(),
          }
        }).then((identifier) => {
          this.$router.push({name: 'questionPage', params: {identifier: identifier, bundleIdentifier: this.bundleIdentifier}});
        })
      }
      else
      {
        this.$store.dispatch('questions/update', {
          bundleIdentifier: this.bundleIdentifier,
          identifier: this.questionIdentifier,
          data: {
            title: this.title,
            question: this.getQuestion(),
          }
        }).then(() => {
          this.$router.push({name: 'questionPage', params: {identifier: this.questionIdentifier, bundleIdentifier: this.bundleIdentifier}});
        })
      }
    },
    reset() {
      // Reset our form values
      this.title = ''
    },
    getQuestion() {
      return this.$refs.editor.invoke('getMarkdown')
    },
    updateBreadcrumb() {
      let breadcumbList = [
        { text: 'Explore', to: {name: 'bundleGrid'} },
        { text: this.bundle.name, to: {name: 'bundlePage', params: {identifier: this.bundleIdentifier, reload: false, tab:8}} },
        { text: 'Question / New' }
      ]
      this.$emit('breadcrumbChange', breadcumbList)
    },
    cancel() {
      if (this.question) {
        this.$router.push({name: 'questionPage', params: {identifier: this.questionIdentifier,
            bundleIdentifier: this.bundleIdentifier}})
      }
      else {
        this.$router.push({name: 'bundlePage', params: {identifier: this.bundleIdentifier, tab: 8}})
      }
    }
  }
}
</script>

<style scoped>
.button {
  color: var(--lade-color-grey);
  border-color: var(--lade-color-grey);
  font-weight: 400;
  background-color: var(--lade-color-white);
}

.button:hover {
  color: var(--lade-color-white-hover);
  background-color: var(--lade-color-grey);
  border-color: var(--lade-color-grey);
}

.button:focus {
  color: var(--lade-color-white);
  background-color: var(--lade-color-grey);
  border-color: var(--lade-color-grey);
  box-shadow: none;
}

.button:active {
  color: var(--lade-color-white) !important;
  background-color: var(--lade-color-grey) !important;
  border-color: var(--lade-color-dark-grey) !important;
  box-shadow: none !important;
}

.submit-buttons {
  border-top: solid 1px var(--lade-color-white);
  padding-top: 15px;
  padding-bottom: 5px;
}

</style>
