<template>
  <b-modal ref="_accessTokenModal" :title="title" size="lg"
           @show="reset"
           @hidden="reset"
           @ok="onOk"
  >
    <form ref="form" @submit.stop.prevent="onSubmit">
      <b-form-group id="input-group-1" label-for="input-1"
                    :state="nameState"
                    invalid-feedback="Name is required"
      >
        <label><strong>Name: </strong></label>
        <b-form-input
          id="input-1"
          v-model="name"
          required
        ></b-form-input>
      </b-form-group>
      <b-form-group id="input-group-2" label-for="input-2">
        <label><strong>Status: </strong></label>
        <b-form-checkbox id="input-2" v-model="enabled" name="status-button" switch>
          ({{ statusString() }})
        </b-form-checkbox>
      </b-form-group>

      <b-form-group>
        <label><strong>Expiration date: </strong></label>
        <b-form-input type="date" v-model="expiration"></b-form-input>
      </b-form-group>
    </form>
  </b-modal>
</template>

<script>
export default {
  name: 'AccessTokenModal',
  props: {
    title: String,
  },
  data() {
    return {
      name: '',
      nameState: true,
      enabled: true,
      expiration: null,
    }
  },
  methods: {
    statusString() {
      return this.enabled ? 'enabled' : 'disabled'
    },
    expirationISO() {
      return this.expiration ? this.expiration.toISOString() : null
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity()
      // this.nameState = this.name.length > 0
      this.nameState = valid
      return valid
    },
    onOk(evt) {
      // Prevent modal from closing
      evt.preventDefault()
      // Trigger submit handler
      this.onSubmit(evt)
    },
    onSubmit(evt) {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return
      }

      this.$store.dispatch('accessTokens/add',
        {
          name: this.name,
          status: this.statusString(),
          expiration: this.expiration,
        })

      // Hide the modal manually
      this.$nextTick(() => {
        this.$refs._accessTokenModal.hide()
      })
    },
    reset() {
      // Reset our form values
      this.name = ''
      this.enabled = true
      this.expiration = null
    },
    openModal() {
      this.$refs._accessTokenModal.show()
    },
  }
}
</script>
