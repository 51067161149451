<template>
  <b-form ref="form" @submit="onSubmit" style="margin: 20px">
    <b-card header="Change personal information">
      <b-form-group
        label="Firstname:"
        :state="firstnameState"
        invalid-feedback="Firstname is required"
      >
        <input
          type="text"
          v-model="firstname"
          class="form-control"
          autocomplete="off"
          :disabled="origin === 'ldap'"
          placeholder="Enter firstname"
        />
      </b-form-group>

      <b-form-group
        label="Lastname:"
        :state="lastnameState"
        invalid-feedback="Lastname is required"
      >
        <input
          type="text"
          v-model="lastname"
          class="form-control"
          autocomplete="off"
          :disabled="origin === 'ldap'"
          placeholder="Enter lastname"
        />
      </b-form-group>
      <button type="submit" class="btn btn-primary">Submit</button>
    </b-card>
  </b-form>
</template>

<script>
export default {
  name: "PersonalInformationForm",
  components: {},
  props: {
    currentFirstname: String,
    currentLastname: String,
    origin: String,
  },
  data() {
    return {
      firstname: this.currentFirstname,
      lastname: this.currentLastname,
      firstnameState: true,
      lastnameState: true,
    };
  },
  methods: {
    reset() {
      this.firstname = this.currentFirstname;
      this.lastname = this.currentLastname;
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      this.firstnameState = this.firstname.length > 0;
      this.lastnameState = this.lastname.length > 0;
      return valid && this.firstnameState && this.lastnameState;
    },
    onSubmit() {
      if (!this.checkFormValidity()) {
        return;
      }

      let data = {
        firstname: this.firstname,
        lastname: this.lastname,
      };

      this.$store
        .dispatch("users/update_profile", { data })
        .then(() => {
          this.$eventBus.$emit(
            "success",
            "Your personal information has been updated"
          );
          this.reset();
        })
        .catch((response) => {
          this.$eventBus.$emit("http-error", response);
        });
    },
  },
};
</script>
