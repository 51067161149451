import Vue from 'vue'
import Config from '@/Config'
import {setDictValue} from '@/common'

const state = {
  list: [],
  _lookup: {},
}

const getters = {
  get: (state) => (identifier) =>
    state._lookup[identifier],
  getAll: (state) => () =>
    state.list,
}

const actions = {
  loadAll({dispatch, commit}) {
    return Vue.http.get(Config.API_PERSONAL_ACCESS_TOKENS_URL).then((response) => {
      commit('setAll', response.body.data)
    })
  },
  add({dispatch, commit}, data) {
    return Vue.http.post(Config.API_PERSONAL_ACCESS_TOKENS_URL, data).then((response) => {
      Vue.prototype.$eventBus.$emit('new-access-token', response.data)
      return dispatch('loadAll')
    }).catch((response) => {
      Vue.prototype.$eventBus.$emit('new-access-token-error', response)
    })
  },
  delete({dispatch, commit}, identifier) {
    return Vue.http.delete(Config.API_PERSONAL_ACCESS_TOKENS_URL + '/' + identifier).then((response) => {
      return commit('delete', identifier)
    })
  },
  toggle({dispatch, commit}, identifier) {
    let data = state._lookup[identifier]
    if (data) {
      let status = (data.status === 'DISABLED') ? 'ENABLED' : 'DISABLED'
      return dispatch('update', {identifier, data: {status}})
    } else {
      return dispatch('loadAll')
    }
  },
  update({dispatch, commit}, {identifier, data}) {
    return Vue.http.put(Config.API_PERSONAL_ACCESS_TOKENS_URL + '/' + identifier, data).then((response) => {
      return dispatch('loadAll')
    })
  },
}

const mutations = {
  delete(state, identifier) {
    Vue.delete(state.list, state.list.indexOf(state.list.find(item => item.identifier === identifier)))
    delete state._lookup[identifier]
  },
  setAll(state, accessTokens) {
    state.list.splice(0, state.list.length)
    for (let accessToken of accessTokens) {
      state.list.push(accessToken)
      setDictValue(state._lookup, accessToken.identifier, accessToken)
    }
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true
}
