import Vue from 'vue'
import Config from '../../Config'
import {setDictValue} from '@/common'
import {field_created_at, field_actions} from './fields'
import {print_permission, print_resource, print_scope} from '@/filters'
import token from "@/store/modules/token";

const state = {
  roleList: {},
  _lookup: {},
  fields: [
    {
      "key": "scope",
      "name": "scope",
      "sortable": true,
      "formatter": print_scope
    },
    {
      "key": "resource",
      "name": "resource",
      "sortable": true,
      "formatter": print_resource
    },
    {
      "key": "permission",
      "name": "permission",
      "sortable": true,
      "formatter": print_permission(token.state.specs)
    },
    field_created_at,
    field_actions
  ],
  filters: {
    isExportable: (entry) => false,
    isEditable: (entry) => true,
    isDeletable: (entry) => true,
    isDownloadable: (entry) => false
  }
}

const getters = {
  getRole: (state) => (roleIdentifier) =>
    state.roleList[roleIdentifier],
  get: (state) => (identifier) =>
    state._lookup[identifier],
  getFilters: (state) => () =>
    state.filters,
  getFields: (state) => () =>
    state.fields
}

const actions = {
  loadRole({dispatch, commit}, {groupIdentifier, roleIdentifier}) {
    return Vue.http.get(Config.API_GROUPS_URL + "/" + groupIdentifier + "/roles/" + roleIdentifier + "/rules").then(
      (response) => {
      let rules = response.data
      commit('setRole', {roleIdentifier, rules})
    })
  },
  add({dispatch, commit}, {groupIdentifier, roleIdentifier, data}) {
    return Vue.http.post(Config.API_GROUPS_URL + "/" + groupIdentifier +
      "/roles/" + roleIdentifier + "/rules",
      data).then((response) => {
      return dispatch("loadRole", {groupIdentifier, roleIdentifier})
    })
  },
  update({dispatch, commit}, {groupIdentifier, roleIdentifier, identifier, data}) {
    return Vue.http.put(Config.API_GROUPS_URL + "/" + groupIdentifier + "/rules/" + identifier, data).then((response) => {
      return dispatch('loadRole', {groupIdentifier, roleIdentifier})
    })
  },
  deleteInGroup({dispatch, commit}, {groupIdentifier, roleIdentifier, identifier}) {
    return Vue.http.delete(Config.API_GROUPS_URL + '/' + groupIdentifier + '/rules/' + identifier).then((response) => {
      return dispatch("loadRole", {groupIdentifier, roleIdentifier})
    })
  }
}

const mutations = {
  setRole(state, {roleIdentifier, rules}) {
    if (state.roleList[roleIdentifier] !== undefined) {
      state.roleList[roleIdentifier].splice(0, state.roleList[roleIdentifier].length)
    } else {
      state.roleList[roleIdentifier] = []
    }
    for (let rule of rules) {
      state.roleList[roleIdentifier].push(rule)
      setDictValue(state._lookup, rule.identifier, rule)
    }
  }
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true
}






