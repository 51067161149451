<template>
  <div class="my-5">
    <b-row>
      <b-col class="p-0 py-5">
        <svg width="300" height="100" class="svg">
          <rect x="0" y="0" width="15" height="35" style="fill: #dbedc4" />
          <rect x="30" y="0" width="15" height="35" style="fill: #c2e299" />
          <rect x="60" y="0" width="15" height="35" style="fill: #9ad056" />
        </svg>
        <div class="text-green text-6xl tracking-widest">
          {{ name }}
        </div>
        <svg width="500" height="300" class="svg d-none d-md-block">
          <rect x="200" y="100" width="100" height="15" style="fill: #dbedc4" />
          <rect x="300" y="100" width="100" height="15" style="fill: #c2e299" />
          <rect x="400" y="100" width="100" height="15" style="fill: #9ad056" />
        </svg>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: "Title",
  props: {
    name: String,
  },
};
</script>
