<template>
    <div v-if="questions && questions.data.length">
        <b-card no-body style="margin-bottom: 10px"
            v-for="question in questions.data" :key="question.identifier">
            <b-link style="text-decoration: none;" :to="{name: 'questionPage', params: {identifier: question.identifier, bundleIdentifier: question.bundle_identifier}}">
                <b-row>
                  <b-col md="10">
                    <b-card-title class="question-title">{{question.title}}</b-card-title>
                    <b-card-sub-title>
                      <div><b-badge class="status-badge" pill :variant="question.status==='open' ? 'success' : 'danger'">
                        {{question.status}}
                      </b-badge>
                      - Opened {{question.created_at | print_date}}</div>
                    </b-card-sub-title>
                  </b-col>
                  <b-col md="2" class="right-card" style="margin-top: 5px; margin-bottom: 5px; padding-right: 0">

                    <div class="answers">
                      <font-awesome-icon :icon="['far', 'comment-alt']"
                                         style="margin-right:3px; margin-bottom: -5px; font-size: 10px; width: 22px; height:25px;"/>
                      <div class="answers-number">{{ question.answers }}</div>
                    </div>
                    <div class="user-icon">
                      <b-avatar :src="question.icon && question.icon.url" :text="question.username[0]"></b-avatar>
                      <!--                    <div style="color: #2B2B2B">{{question.username}}</div>-->
                    </div>
                  </b-col>
                </b-row>
            </b-link>
        </b-card>
    </div>
    <div v-else>Still no question</div>
</template>

<script>
import store from "@/store";

export default {
    name: 'QuestionList',
    props: {
        bundleIdentifier: String,
    },
    data: function() {
        return {
            questions: this.$store.getters['questions/getQuestionsPage'](this.bundleIdentifier)
        }
    },
    methods: {
    },
    mounted: function() {
        this.$store.dispatch('questions/loadBundleQuestions', this.bundleIdentifier).then(() => {
            this.questions = this.$store.getters['questions/getQuestionsPage'](this.bundleIdentifier)
        })
    }
}
</script>

<style scoped>

.status-badge {
  margin-bottom: 10px;
  margin-left: 10px;
  font-size: 1em;
  width: 60px;
}

.question-title {
  margin: 10px;
  align-items: center;
  color: #2B2B2B
}

.right-card {
  height: 60px;
  margin-left: -5px;
  padding: 10px 0;
  position: relative;
  display: inline-block;
  float: right;
}

.user-icon {
  bottom: auto;
  top: auto;
  display: inline;
  margin-right: 10px;
  float: right;
  margin-top: 3px;
}

.answers-number {
  display: inline-block;
  margin-left: 5px;
}

.answers {
  margin-right: 15px;
  margin-top: 5px;
  font-size: 19px;
  color: var(--lade-color-grey);
  display: inline-block;
  float: right;
}

</style>
