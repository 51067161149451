import Vue from 'vue'
import {field_actions, field_created_at} from "./fields";
import Config from "../../Config";
import {setDictValue} from "../../common";

const state = {
  labelList: [],
  list: [],
  filteredList: [],
  _lookup: {},
  fields: [
    {
      "key": "name",
      "sortable": true
    },
    {
      "key": "version",
      "sortable": true
    },
    {
      "key": "enabled",
      "sortable": false
    },
    {
      "key": "public",
      "sortable": false
    },
    field_created_at,
    field_actions
  ],
  filters: {
    isDeletable: (entry) => true,
  }
}

const getters = {
  get: (state) => (name) =>
    state._lookup[name],
  getAll: (state) => (label) => {
    if (label !== undefined)
      return state.list.filter(plugin => plugin.labels.includes(label))
    else
      return state.list
  }
}

const actions = {
  loadPublic({dispatch, commit}, { name, params }) {
    return Vue.http.get(Config.API_PUBLIC_PLUGINS_URL + "/" + name, { params }).then((response) => {
      let plugin = response.data
      commit('set', plugin)
    })
  },
  load({dispatch, commit}, { name, params }) {
    return Vue.http.get(Config.API_PLUGINS_URL + "/" + name, { params }).then((response) => {
      let plugin = response.data
      commit('set', plugin)
    })
  },
  loadPublicAll({dispatch, commit}, { params }) {
    return Vue.http.get(Config.API_PUBLIC_PLUGINS_URL, { params }).then((response) => {
      let plugins = response.data
      commit('setAll', plugins)
    })
  },
  loadAll({dispatch, commit}, { params }) {
    return Vue.http.get(Config.API_PLUGINS_URL, { params }).then((response) => {
      let plugins = response.data
      commit('setAll', plugins)
    })
  },
  run({dispatch, commit}, { label, store, app }) {
    return dispatch('loadAll', {params: {label: label}}).then((plugins) => {
      let promises = []
      for (let plugin of this.getters['plugins/getAll'](label)) {
        let url = Config.API_PLUGINS_URL + "/" + plugin.name + "/assets/" + "main.js"
        promises.push(Vue.loadScript(url).then(() => {
          return window[plugin.name.replace('-', '_')+"_main"](plugin, Config.API_BASE_URL, store, app)
        }).catch(error => {
          console.error(error)
        }))
      }
      return Promise.all(promises)
    })
  },
  runPublic({dispatch, commit}, { label, store, app }) {
    return dispatch('loadPublicAll', {params: {label: label}}).then(() => {
      let promises = []
      for (let plugin of this.getters['plugins/getAll'](label)) {
        let url = Config.API_PUBLIC_PLUGINS_URL + "/" + plugin.name + "/assets/" + "main.js"
        promises.push(Vue.loadScript(url).then(() => {
          return window[plugin.name.replace('-', '_')+"_main"](plugin, Config.API_BASE_URL, store, app)
        }).catch(error => {
          console.error(error)
        }))
      }
      return Promise.all(promises)
    })
  },
  loadData({dispatch, commit}, { pluginName, resourceIdentifier }) {
    return Vue.http.get(Config.API_PLUGINS_URL + "/" + pluginName + "/data/" + resourceIdentifier)
  },
  loadPublicData({dispatch, commit}, { pluginName, resourceIdentifier }) {
    return Vue.http.get(Config.API_PUBLIC_PLUGINS_URL + "/" + pluginName + "/data/" + resourceIdentifier)
  },
  /*loadPlugin({dispatch, commit}, pluginIdentifier) {
    return Vue.http.get(Config.API_PLUGINS_URL + "/" + pluginIdentifier).then((response) => {
      let files = response.data
      commit('setAll', files)
    })
  },
  update({dispatch, commit}, {bundleIdentifier, identifier, data}) {
    return Vue.http.put(Config.API_BUNDLES_URL + '/' + bundleIdentifier + '/files/' + identifier, data).then((response) => {
      dispatch('load', identifier).then(() => {
      })
    })
  },
  delete({dispatch, commit}, identifier) {
    let user = state._lookup[identifier]
    return Vue.http.delete(Config.API_BUNDLES_URL + '/' + user.bundle_identifier + '/files/' + user.identifier).then((response) => {
      commit('delete', identifier)
    })
  }*/
}

const mutations = {

  setAll(state, plugins) {
    state._lookup = {}

    for (let plugin of plugins)
      state.list.push(plugin)

    for (let plugin of plugins)
      state._lookup[plugin.name] = plugin
  },
  set(state, plugin) {
    if (state._lookup[plugin.name] === undefined)
      state.list.push(plugin)
    setDictValue(state._lookup, plugin.name, plugin)
  },
  /*
  delete(state, identifier) {
  Vue.delete(state.list, state.list.indexOf(state.list.find(item => item.identifier === identifier)))
},*/
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true
}






